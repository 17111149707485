export const CryptoSecretKey = "12345678901234561234567890123456"; //"70671127870423546710640451327537"; 

//Regex
export const NameRegex = /^[ A-Za-z0-9'-]*$/;
export const NumericRegex = /^[0-9]*$/;
export const DecimalRegex = /^([0-9]{0,9})([.]{0,1})([0-9]{0,2})$/;
export const EmailRegex = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})+$/;
export const AlphabetsRegex = /^[a-zA-Z]*$/;
export const AlphabetsSplCharRegex = /^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':\"\\|,.<>\/?]*$/;
export const PhoneRegex = /^([^\s]{3})([^\s]{2})([^\s]{4})$/g;

//Error Messages
export const InvalidEmail = "Please Enter Valid Email Address";


//Modal Names
export const ModalAdjustments = "Adjustments"
export const ModalCompensations = "Compensations"
export const ModalOther = "Other"

export const columnvisibilityTime = "time";
export const columnvisibilityAmount = "amount";

//Constants
export const FromEmail = 'noreply_ems@gmail.com';
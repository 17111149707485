import React, { useState, useEffect } from "react";
import { Enum, Utils, Constants } from '../EMS_Components'

const Breadcrumb = (props) => {


    useEffect(() => {

    }, [props])


    return (
        <div class="row ml-4 mt-3">
            {
                props.paths.map((item, idx) => {
                    return (
                        <>
                            {idx == 0 ?
                                <h6 class="mt-1">{item}</h6>
                                : idx == props.paths.length - 1 ?
                                    <h4 class="ml-2"><i class="fas fa-angle-right"></i> {item}</h4>
                                    :
                                    <h6 class="ml-1 mt-1"><i class="fas fa-angle-right"></i> {item}</h6>
                            }

                        </>
                    )
                })
            }
        </div>
    );
}

export default Breadcrumb
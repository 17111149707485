import React from 'react';



export class AddUser_Role extends React.Component {
    static displayName = AddUser_Role.name;

    constructor(props) {
        super(props);
        this.state = {
            loading: true
        }
    }
    render() {
        return (<div className="col-lg-6 col-md-6">
           
            <input
                className="form-control"
                        type="text"
                        placeholder="First Name"
                        required
            />
            <br/>
                    <input
                        className="form-control"
                        type="text"
                        placeholder="Middle Name"
                        required
        />
            <br/>
                    <input
                        className="form-control"
                        type="text"
                        placeholder="Last Name"
                        required
            />
            <br />
            <input
                className="form-control"
                type="text"
                placeholder="Email"
                required
            />
            <br />
            <input
                className="form-control"
                type="text"
                placeholder="Phone"
                required
            />
            <div className="form-group">
                <label>Role</label>
                <select className="form-control" name="Role" id="ddlRole" name="Role">
                    <option value="">Select Role</option>
                   
                </select>
            </div>
            <button type="submit" className="btn btn-sm btn-brand" >Submit</button>
                </div>

           
            
           );
           
    }
}
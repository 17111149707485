import React, { useState, useEffect, useRef, Fragment } from 'react';
import moment from 'moment';
import $ from 'jquery';
import 'react-tabs/style/react-tabs.css';// 'react-tabs/style/react-tabs.css';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker, TimePicker } from '@material-ui/pickers';
import { APIGet, APIPost } from '../EMS_Components/APICall'


const EditBGActor = (props) => {

    const [toaster, settoaster] = useState('')
    const [toasterCla, settoasterCla] = useState(false)

    const [formData, setformData] = useState({ demoninators: 8, callTime: null, callTimeHH: null, callTimeMM: null, callTimeTT: null })
    const [bgActorID, setbgActorID] = useState(0)
    const [shootingID, setshootingID] = useState(0)
    const [userID, setuserID] = useState(0)


    const demoninators = [1, 2, 3, 4, 5, 6, 7, 8, 10, 12, 14]
    var defaultDenominator = props.SkinUpload.rate.split('/')[1]
  
    
    const decimalRegex = new RegExp(/^([0-9]{0,3})([.]{0,1})([0-9]{0,2})$/)
    const emailRegex = new RegExp(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})+$/)
    const numericRegex = new RegExp(/^[0-9]*$/)
    const alphabetsRegex = new RegExp(/^[a-zA-Z]*$/)
    const alphabetsSplCharRegex = new RegExp(/^[a-zA-Z!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/)

    const emailInput = useRef(null);
    const LoadData = () => {

        setshootingID(props.shootingID ? props.shootingID : 0);
        setuserID(props.userID ? props.userID : 0);

        
        let dt = formData;        
        let SkinUpload = props.SkinUpload;
        dt["email"] = SkinUpload.email;
        dt["phone"] = SkinUpload.phone;
        dt["firstName"] = SkinUpload.firstname;
        dt["lastName"] = SkinUpload.lastname;
        dt["unionId"] = SkinUpload.unionID;
        dt["gender"] = SkinUpload.gender;
        dt["role"] = SkinUpload.role;
        dt["jobTitle"] = SkinUpload.jobTitle;
        dt["rate"] = SkinUpload.rate.split('/')[0];
        dt["demoninators"] = SkinUpload.rate.split('/')[1];
        defaultDenominator = SkinUpload.rate.split('/')[1];
        dt["checkRefNumber"] = SkinUpload.checkRefNumber;
        dt["isWardrobeRequired"] = SkinUpload.isWardrobeRequired;
        dt["isPropertyRequired"] = SkinUpload.isPropertyRequired;
        dt["callTime"] = SkinUpload.callTime;
        dt["wardrobeInstructions"] = SkinUpload.wardrobeInstructions;
        
        dt["callTimeHH"] = SkinUpload.callTime.split(":")[0];//07: 30 AM
        dt["callTimeMM"] = SkinUpload.callTime.split(":")[1].split(" ")[0];
        dt["callTimeTT"] = SkinUpload.callTime.split(":")[1].split(" ")[1];

        setformData({ ...dt });

        setbgActorID(SkinUpload.bgActorID);
        //formData.email = SkinUpload.email;
        //formData.phone = SkinUpload.phone;
        //formData.firstName = SkinUpload.firstname;
        //formData.lastName = SkinUpload.lastname;
        //formData.unionId = SkinUpload.unionID;
        //formData.role = SkinUpload.role;
        //formData.jobTitle = SkinUpload.jobTitle;
        //formData.rate = SkinUpload.rate.split('/')[0];
        //defaultDenominator = SkinUpload.rate.split('/')[1];

        //formData.checkRefNumber = SkinUpload.checkRefNumber;
    }

    useEffect(() => {
        
       
        emailInput.current.focus();

        LoadData();
    }, []);

    const toasterfun2 = (e) => {
        settoasterCla(true);
        settoaster(e);
        setTimeout(
            function () {
                settoasterCla(false);
            }.bind(this), 4000);
    }

    const validateEmail = (email) => {
        email = email.replace(/\s/g, '');
        if (emailRegex.test(email) || email == '') {
            return true;
        }
        else {
            return false;
        }
    }

    const validateNumeric = (number) => {
        number = number.replace(/\s/g, '');
        if (numericRegex.test(number)) {
            return true;
        }
        else {
            return false;
        }
    }

    const validateAlphabet = (letter) => {
        letter = letter.replace(/\s/g, '');
        if (alphabetsRegex.test(letter)) {
            return true;
        }
        else {
            return false;
        }
    }

    const validateAlphabetSplChar = (letter) => {
        letter = letter.replace(/\s/g, '');
        if (alphabetsSplCharRegex.test(letter)) {
            return true;
        }
        else {
            return false;
        }
    }

    const validateDecimal = (letter) => {
        
        letter = letter.replace(/\s/g, '');
        if (decimalRegex.test(letter)) {
            return true;
        }
        else {
            return false;
        }
    }

    const handlechange = (e) => {
        
        let dt = formData;
        $('.' + e.target.id + 'Man').addClass('hide')
        if (e.target.classList.contains('alphabet')) {
            if (validateAlphabet(e.target.value) == true) {
                dt[e.target.id] = e.target.value
                setformData({ ...dt })
            }
            else {
                dt[e.target.id] = formData[e.target.id] ? formData[e.target.id] : "";
                setformData({ ...dt })
            }
        }
        else if (e.target.classList.contains('numeric')) {
            if (validateNumeric(e.target.value) == true) {
                dt[e.target.id] = e.target.value
                setformData({ ...dt })
            }
            else {
                dt[e.target.id] = formData[e.target.id] ? formData[e.target.id] : "";
                setformData({ ...dt })
            }
        }
        else if (e.target.classList.contains('email')) {
            if (!validateEmail(e.target.value)) {
                $('.invalidemail').removeClass('hide')
            }
            else {
                $('.invalidemail').addClass('hide')
            }
            dt[e.target.id] = e.target.value
            setformData({ ...dt })
        }
        else if (e.target.classList.contains('alphasplchar')) {
            if (validateAlphabetSplChar(e.target.value) == true) {
                dt[e.target.id] = e.target.value
                setformData({ ...dt })
            }
            else {
                dt[e.target.id] = formData[e.target.id] ? formData[e.target.id] : "";
                setformData({ ...dt })
            }
        }
        else if (e.target.classList.contains('decimal')) {
            if (validateDecimal(e.target.value) == true) {
                dt[e.target.id] = e.target.value
                setformData({ ...dt })
            }
            else {
                dt[e.target.id] = formData[e.target.id] ? formData[e.target.id] : "";
                setformData({ ...dt })
            }
        }
        else if (e.target.classList.contains('Time')) {
            if (e.target.id == "TimeHH") {
                dt["callTimeHH"] = e.target.value;
                dt["callTime"] = e.target.value + ":" + dt["callTimeMM"] + " " + dt["callTimeTT"];
            }
            else if (e.target.id == "TimeMM") {
                dt["callTimeMM"] = e.target.value;
                dt["callTime"] = dt["callTimeHH"] + ":" + e.target.value + " " + dt["callTimeTT"];
            }
            else if (e.target.id == "TimeTT") {
                dt["callTimeTT"] = e.target.value;
                dt["callTime"] = dt["callTimeHH"] + ":" + dt["callTimeMM"] + " " + e.target.value;
            }
            
        }
        else {
            dt[e.target.id] = e.target.value
            setformData({ ...dt })
        }
    }

    const handlecbchange = (e) => {
        let dt = formData;

        dt[e.target.id] = e.target.checked
        setformData({ ...dt })
    }

    const handleDateChange = (date) => {
        let dt = formData;

        dt['callTime'] = date
        setformData({ ...dt })
        $('.callTimeMan').addClass('hide')
    };

    const resetFields = () => {
        let dt = {
            email: '',
            firstName: '',
            lastName: '',
            phone: '',
            unionId: '',
            role: '',
            jobTitle: '',
            rate: '',
            demoninators: 8,
            checkRefNumber: '',
            isWardrobeRequired: false,
            isPropertyRequired: false,
            callTime: null
        }
        setformData({ ...dt })
        setbgActorID(0)
    }

    const getActorDetails = (e) => {
        if (!validateEmail(e.target.value)) {
            $('.invalidemail').removeClass('hide')
            emailInput.current.focus();
            return
        }
        else {
            $('.invalidemail').addClass('hide')

            var strURL = "getActorDetails";
            APIGet('SkinUpload/' + strURL + "/" + e.target.value + "/" + parseInt(shootingID), function (result) {
                if (result.statusCode == 100) {
                    let dt = formData;
                    dt['firstName'] = result.result.firstName;
                    dt['lastName'] = result.result.lastName;
                    dt['phone'] = result.result.phone;
                    dt['unionId'] = result.result.unionName;

                    setformData({ ...dt })
                    setbgActorID(result.result.id);
                } else {
                    let dt = formData;
                    dt['firstName'] = '';
                    dt['lastName'] = '';
                    dt['phone'] = '';
                    dt['unionId'] = '';

                    setformData({ ...dt })

                    toasterfun2(result.statusMessage);
                    emailInput.current.focus();
                }
            });


        }
    }

    const ValidateData = () => {
        let isvalid = true;

        if (!formData['email'] || formData['email'] == '') {
            $('.emailMan').removeClass('hide')
            isvalid = false
        }
        else {
            $('.emailMan').addClass('hide')
        }

        if (!formData['role'] || formData['role'] == '') {
            $('.roleMan').removeClass('hide')
            isvalid = false
        }
        else {
            $('.roleMan').addClass('hide')
        }

        if (!formData['jobTitle'] || formData['jobTitle'] == '') {
            $('.jobTitleMan').removeClass('hide')
            isvalid = false
        }
        else {
            $('.jobTitleMan').addClass('hide')
        }

        if (!formData['rate'] || formData['rate'] == '') {
            $('.rateMan').removeClass('hide')
            isvalid = false
        }
        else {
            $('.rateMan').addClass('hide')
        }

        if (!formData['callTime'] || formData['callTime'] == '') {
            $('.callTimeMan').removeClass('hide')
            isvalid = false
        }
        else {
            $('.callTimeMan').addClass('hide')
        }

        if (!formData['demoninators'] || formData['demoninators'] == 0) {
            $('.demoninatorsMan').removeClass('hide')
            isvalid = false
        }
        else {
            $('.demoninatorsMan').addClass('hide')
        }
       
      
        if (isvalid) {
            if (!bgActorID || bgActorID == 0) {
                toasterfun2("Talent not exists");
                isvalid = false;
                return isvalid;
            }

            if (!shootingID || shootingID == 0) {
                toasterfun2("Shooting not exists");
                isvalid = false;
                return isvalid;
            }

            if (!userID || userID == 0) {
                toasterfun2("Invalid User");
                isvalid = false;
                return isvalid;
            }
        }
        
        return isvalid;
    }
    var checkBGCheckinNo = () => {
      //  debugger;
        let isvalid = true;
        let DataSkinUpload = {

            firstname: formData.firstName,
            lastname: formData.lastName,
            email: formData.email,
            phone: formData.phone,
            unionID: formData.unionId,
            role: formData.role,
            jobTitle: formData.jobTitle,
            rate: formData.rate + '/' + parseInt(formData.demoninators),
            isWardrobeRequired: formData.isWardrobeRequired,
            isPropertyRequired: formData.isPropertyRequired,
            wardrobe: formData.isWardrobeRequired ? 2 : 0,
            property: formData.isPropertyRequired ? 2 : 0,
            wardrobeInstructions: formData.wardrobeInstructions,
            bgActorID: bgActorID,
            callTime: formData.callTime,
            checkRefNumber: formData.checkRefNumber,
            shootingID: parseInt(shootingID),

        }
        var inputdata = [];
        inputdata.push(DataSkinUpload);
        let data = JSON.stringify(inputdata);
        APIPost('AdminSkinUpload/ValidateCheckinNo/', data, function (result) {
            if (result.code === 100) {
                isvalid = true;
                return true;
            } else if (result.code === 409) {
                toasterfun2("Checkin# already assigned against another talent.");
                isvalid = false;
                return isvalid;
            }
        });

    }
    
    const AddActor = () => {
        if (ValidateData())
        {
            let DataSkinUpload = {

                firstname: formData.firstName,
                lastname: formData.lastName,
                email: formData.email,
                phone: formData.phone,
                unionID: formData.unionId,
                role: formData.role,
                jobTitle: formData.jobTitle,
                rate: formData.rate + '/' + parseInt(formData.demoninators),
                isWardrobeRequired: formData.isWardrobeRequired,
                isPropertyRequired: formData.isPropertyRequired,
                wardrobe: formData.isWardrobeRequired ? 2 : 0,
                property: formData.isPropertyRequired ? 2 : 0,
                wardrobeInstructions: formData.wardrobeInstructions,
                bgActorID: bgActorID,
                callTime: formData.callTime,
                checkRefNumber: formData.checkRefNumber,
                shootingID: parseInt(shootingID),
            }
            
            
            var inputdata = [];
            inputdata.push(DataSkinUpload); 
            //let data = JSON.stringify(inputdata);
            //console.log(data);

            let reqData = {
                Username: userID,
                ToBeChecked: false,
                SkinUploads: inputdata,
                ShootingId: shootingID
            }

            APIPost('AdminSkinUpload/ValidateCheckinNo/', inputdata, function (result) {
                if (result.code === 100) {
                    {
                        APIPost('AdminSkinUpload/InsertShootingActorsMapping', reqData, function (result) {
                            if (result.code === 200) {
                                toasterfun2("Skins Updated Sucessfully");
                                props.action();
                            } else {
                                toasterfun2(result.message);
                            }
                        });                       
                    }
                } else if (result.code === 409) {
                    toasterfun2("Checkin# already assigned against another talent.");
                    return false;
                }
            });
            


           
        }
    }

    var arrhour = [];
    for (let i = 0; i <= 12; i++) {
        var hour = ("0" + i).slice(-2);//moment(i).format('hh');
        arrhour.push(<option key={hour} value={hour} data-id={hour} selected={hour == formData.callTimeHH ? true : false}>{hour}</option>)

            
    }

    let HoursList = arrhour;

    var arrMin = [];
    for (let i = 0; i <= 59; i++) {
        var Min = ("0" + i).slice(-2);
        arrMin.push(<option key={Min} value={Min} data-id={Min} selected={Min == formData.callTimeMM ? true : false}>{Min}</option>)
    }

    let MinutesList = arrMin;

    return (
        <div>
            <div className={"toaster-div " + (toasterCla ? "show" : "hide")}><i className="fa fa-check-circle" aria-hidden="true"></i> {toaster} </div>
            <div className="">
                <div className="">
                    <div className="">
                        <div className="kt-form kt-form--label-right">
                            <div className="row">
                                <div className="col-md-6 col-sm-6 col-6">
                                    <div className="form-group">
                                        <label>Email</label>
                                        <input type="text" className="form-control email" name="email" id="email" ref={emailInput} value={formData.email} onChange={handlechange} onBlur={getActorDetails} disabled />
                                        <div className="redAlert invalidemail noPosition hide">Please Enter Valid Email Address</div>
                                        <div className="redAlert noPosition emailMan hide">* Mandatory</div>
                                    </div>
                                </div>

                                <div className="col-md-6 col-sm-6 col-6">
                                    <div className="form-group">
                                        <label>Phone</label>
                                        <input type="text" className="form-control numeric" name="phone" id="phone" value={formData.phone} onChange={handlechange} disabled />
                                        <div className="redAlert noPosition phoneMan hide">* Mandatory</div>
                                    </div>
                                </div>

                                <div className="col-md-6 col-sm-6 col-6">
                                    <div className="form-group">
                                        <label>First Name</label>
                                        <input type="text" className="form-control alphabet" name="firstName" id="firstName" value={formData.firstName} onChange={handlechange} disabled />
                                        <div className="redAlert noPosition firstNameMan hide">* Mandatory</div>
                                    </div>
                                </div>

                                <div className="col-md-6 col-sm-6 col-6">
                                    <div className="form-group">
                                        <label>Last Name</label>
                                        <input type="text" className="form-control alphabet" name="lastName" id="lastName" value={formData.lastName} onChange={handlechange} disabled />
                                        <div className="redAlert noPosition lastNameMan hide">* Mandatory</div>
                                    </div>
                                </div>

                                <div className="col-md-6 col-sm-6 col-6">
                                    <div className="form-group">
                                        <label>Guild</label>
                                        <input type="text" className="form-control alphabet" name="unionId" id="unionId" value={formData.unionId} onChange={handlechange} disabled />
                                        <div className="redAlert noPosition unionIdMan hide">* Mandatory</div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6 col-6">
                                    <div className="form-group">
                                        <label>Gender</label>
                                        <input type="text" className="form-control alphabet" name="gender" id="gender" value={formData.gender} onChange={handlechange} disabled />
                                        <div className="redAlert noPosition unionIdMan hide">* Mandatory</div>
                                    </div>
                                </div>

                                <div className="col-md-6 col-sm-6 col-6">
                                    <div className="form-group">
                                        <label>Role</label>
                                        <input type="text" className="form-control alphasplchar" name="role" id="role" value={formData.role} onChange={handlechange} autoComplete="off"  />
                                        <div className="redAlert noPosition roleMan hide">* Mandatory</div>
                                    </div>
                                </div>

                                <div className="col-md-6 col-sm-6 col-6">
                                    <div className="form-group">
                                        <label>Job Title</label>
                                        <input type="text" className="form-control alphasplchar" name="jobTitle" id="jobTitle" value={formData.jobTitle} onChange={handlechange} autoComplete="off" />
                                        <div className="redAlert noPosition jobTitleMan hide">* Mandatory</div>
                                    </div>
                                </div>

                                <div className="col-md-6 col-sm-6 col-6">
                                    <div className="form-group">
                                        <label>Rate</label>
                                        <div className="row">
                                            <div className="col-md-6 col-sm-6 col-6">
                                                <input type="text" className="form-control decimal" name="rate" id="rate" value={formData.rate} onChange={handlechange} autoComplete="off" />
                                                <div className="redAlert noPosition rateMan hide">* Mandatory</div>
                                            </div>
                                            <div className="col-md-1 col-sm-1 col-1">
                                                <span className="input-group-text" id="passeyeSet">/</span>
                                            </div>
                                            <div className="col-md-5 col-sm-5 col-5">
                                                <select className="form-control" name="demoninators" id="demoninators" onChange={handlechange}>
                                                    <option value="0">Select</option>
                                                    {
                                                        demoninators.map((item, idx) => {
                                                            return (
                                                                <option value={item} selected={item == defaultDenominator ? true : false}>{item}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                                <div className="redAlert noPosition demoninatorsMan hide">* Mandatory</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6 col-sm-6 col-6">
                                    <div className="form-group">
                                        <label>CheckIn #</label>
                                        <input type="text" className="form-control numeric" name="checkRefNumber" id="checkRefNumber" value={formData.checkRefNumber} onChange={handlechange} autoComplete="off" />
                                        <div className="redAlert noPosition checkRefNumberMan hide">* Mandatory</div>
                                    </div>
                                </div>

                                <div className="col-md-3 col-sm-3 col-3">
                                    <div className="form-group">
                                        <label>Is Wardrobe Required</label>
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" className="custom-control-input" id="isWardrobeRequired" name="isWardrobeRequired" onChange={handlecbchange} checked={formData.isWardrobeRequired} />
                                            <label className="custom-control-label" for="isWardrobeRequired">&nbsp;</label>
                                        </div>
                                        <div className="redAlert noPosition isWardrobeRequiredMan hide">* Mandatory</div>
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-3 col-3">
                                    <div className="form-group">
                                        <label>Is Property Required</label>
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" className="custom-control-input" id="isPropertyRequired" name="isPropertyRequired" onChange={handlecbchange} checked={formData.isPropertyRequired} />
                                            <label className="custom-control-label" for="isPropertyRequired">&nbsp;</label>
                                        </div>
                                        <div className="redAlert noPosition isPropertyRequiredMan hide">* Mandatory</div>
                                    </div>
                                </div>


                                <div className="col-md-6 col-sm-6 col-6">
                                    <div className="form-group">
                                        <label>Call Time</label>
                                        <div className="row">
                                            <div className="">
                                                <select className="form-control Time" id="TimeHH" onChange={handlechange}>
                                                       {HoursList}
                                                 </select>
                                                
                                            </div>
                                            <div className="">
                                                <span className="input-group-text" id="passeyeSet">:</span>
                                            </div>
                                            <div className="">
                                                <select className="form-control Time" id="TimeMM" onChange={handlechange}>
                                                {MinutesList}
                                                </select>
                                                
                                            </div>
                                            <div className="">
                                                <select className="form-control Time" id="TimeTT" onChange={handlechange}>
                                                    <option value="AM" selected={"AM" == formData.callTimeTT ? true : false}>AM </option>
                                                    <option value="PM" selected={"PM" == formData.callTimeTT ? true : false}>PM </option>
                                                    </select>
                                              
                                            </div>
                                        </div>
                                    </div>
                                </div>
                             
                               

                            </div>
                        </div>
                    </div>
                </div>
                <div className="kt-portlet__foot">
                    <div className="kt-form__actions">
                        <button type="button" className="btn btn-sm btn-brand mr-10" onClick={AddActor}>Update</button>
                        <button type="reset" className="btn btn-sm btn-secondary" onClick={props.action}>Close</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditBGActor

import React, { Component } from 'react';
import ReactDOM from 'react-dom'
import { ResetPassword } from './ResetPassword';
import $ from 'jquery';
import { Constants, Utils } from './EMS_Components';
import { APIGet, APIPost } from './EMS_Components/APICall'
import ChangePassword from './Admin/ChangePassword';

export class Layout extends Component {
    static displayName = Layout.name;
    constructor(props) {
        super(props);
        //this.CheckLogin();
        this.GetMenuList();
        this.state = {
            imgurl: 'ems-logo',
            isResetPasswordPopupOpen: false,
            isSettingsPopupOpen: false,
            Role: "",
            showUserDrop: false,
            toasterCla: false,
            toaster: '',
            newPassword: '',
            MenuList: [],
            UserRole: '',
            OTPFrequency: 0,
            PasswordPolicy:'',
            PasswordDescription:''
        }

        this.LogOut = this.LogOut.bind(this);
        this.ResetPassword = this.ResetPassword.bind(this);
        // this.CheckLogin = this.CheckLogin.bind(this);
        this.GetMenuList = this.GetMenuList.bind(this);
        this.handleResetPasswordcancel = this.handleResetPasswordcancel.bind(this);
        this.handleResetPasswordcancel = this.handleResetPasswordcancel.bind(this);

        //  this.handler = this.handler.bind(this);
    }

    CheckLogin() {
        let currentComponent = this;
        APIGet('AdminSkinUpload/CheckLogin', function (result) {

            if (result.code === 415) {
                currentComponent.setState({ isLoggedIn: false })
                // this.props.history.push("/ProductionAccountant/Login");
            }
            else {
                currentComponent.setState({ isLoggedIn: true })
                currentComponent.setState({ UserRole: result.result, PasswordPolicy: result.commonResult.pattern, PasswordDescription: result.commonResult.passwordDescription})
                
            }

        });
    }

    GetMenuList() {
        var windowlocation = window.location.href.toLowerCase();
        var Role = "";

        var MenuList = [];
        let currentComponent = this;

        APIGet('AdminSkinUpload/GetMenuList', function (result) {
            if (result.statusCode == 100) {
                Role = result.role;
                MenuList = result.menuList;
                if (MenuList == null || MenuList.length == 0) {
                    if (Role == "Casting Director") {
                        window.location = "/CastingDirector/Login";
                        sessionStorage.removeItem('PAuserfullName');
                    }
                    else if (Role == "ProductionAccountant") {
                        window.location = "/ProductionAccountant/login";
                        sessionStorage.removeItem('CDuserfullName');
                    }
                }
                else {
                    currentComponent.setState({ MenuList: MenuList });
                }
            }
        });

    }

    handleEvent = (event) => {
        if (event.type === "mouseup" && !event.target.classList.contains('user-drop')) {
            this.setState({ showUserDrop: false })
        }
    }

    showUserDrop = () => {
        let showdrop = this.state.showUserDrop
        this.setState({ showUserDrop: showdrop == true ? false : true })
    }

    LogOut() {
        let currentComponent = this;

        APIPost('SkinUpload/LogOut/', null, function (result) {
            if (result.message == "Success") {
                var windowlocation = window.location.href.toLowerCase();
                if (windowlocation.includes("timecardfileexport")) {
                    window.location = "/ProductionAccountant/login";
                    sessionStorage.removeItem('PAuserfullName');
                }
                else //if (windowlocation.includes("skinupload")) 
                {
                    window.location = "/CastingDirector/Login";
                    sessionStorage.removeItem('CDuserfullName');
                }
            }
        });



        //var Role = localStorage.getItem('Role');
        var windowlocation = window.location.href.toLowerCase();
        if (windowlocation.includes("timecardfileexport")) {
            window.location = "/ProductionAccountant/login";
            sessionStorage.removeItem('PAuserfullName');
            sessionStorage.removeItem('PACurrentUserId');
            sessionStorage.removeItem('PARole');
            sessionStorage.removeItem('PAUsername');
        }
        else //if (windowlocation.includes("skinupload")) 
        {
            window.location = "/CastingDirector/Login";
            sessionStorage.removeItem('CDuserfullName');
            sessionStorage.removeItem('DataSkinUpload');
            sessionStorage.removeItem('CDRole');
            sessionStorage.removeItem('CDCurrentUserId');
            sessionStorage.removeItem('CDUsername');
        }
        sessionStorage.removeItem('UserId');

        //this.props.history.push('/Home');
        //    //.props.history.push("/SkinUploadHome");
        //window.location = "/";
    }

    ResetPassword(event) {
        event.preventDefault();
        this.setState({ isResetPasswordPopupOpen: false });
        let contents = this.renderResetPassword(event);
        ReactDOM.render(contents, document.getElementById('divResetPassword'));
    }
    handleResetPasswordcancel(event) {
        event.preventDefault();
        this.setState({
            isResetPasswordPopupOpen: true
        });
        ReactDOM.unmountComponentAtNode(document.getElementById('divResetPassword'));
        return false;
    }
    componentDidMount() {
        var Role = "";
        this.CheckLogin();

        var windowlocation = window.location.href.toLowerCase();
        if (windowlocation.includes("skinupload")) {
            Role = this.state.UserRole;
            this.setState({ imgurl: 'ems-logo' });
        }
        else if (windowlocation.includes("timecardfileexport")) {
            Role = this.state.UserRole;
            this.setState({ imgurl: 'ems-pro-logo' });
        }
        else if (windowlocation.includes("bgactoraudit")) {
            Role = 'Casting Director';
            this.setState({ imgurl: 'ems-logo' });
        }

        //if (localStorage.getItem('userfullName') != null) {


        //    console.log(Role);
        //    if (Role == 'Casting Director') {
        //        this.setState({ imgurl: 'ems-logo' });
        //    }
        //    else {
        //        this.setState({ imgurl: 'ems-logo-pro' });
        //    }
        //}
    }

    testPasswordCheck = (e) => {
        e = e.replace(/\s/g, '');
        //var email = new RegExp('^(?=.*[0-9])(?=.*[!@@#$%^&*])[a-zA-Z0-9!@@#$%^&*]{8,15}$');
        //^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{8,15}$
        //^(?=.*[0-9])(?=.*[!@@#$%^&*])[a-zA-Z0-9!@@#$%^&*]{8,15}$
        //if (/^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{8,15}$/.test(e)) {
        if (/^(?=.*[0-9])(?=.*[!@@#$%^&*])[a-zA-Z0-9!@@#$%^&*]{8,15}$/.test(e)) {
            return true;
        }
        else {
            return false;
        }
    }

    eyeOnclick = (e) => {
        $('#passeyeSet i').toggleClass("fa-eye fa-eye-slash");
        var input = $(".oneventopass");
        if (input.attr("type") === "password") {
            input.attr("type", "text");
            setTimeout(
                function () {
                    input.attr("type", "password");
                    $('#passeyeSet i').toggleClass("fa-eye fa-eye-slash");
                }.bind(this), 2000);
        } else {
            input.attr("type", "password");
        }
    }

    neyeOnclick(e) {
        $('#cpasseyeSet i').toggleClass("fa-eye fa-eye-slash");
        var input = $(".oneventcpass");
        if (input.attr("type") === "password") {
            input.attr("type", "text");
            setTimeout(
                function () {
                    input.attr("type", "password");
                    $('#cpasseyeSet i').toggleClass("fa-eye fa-eye-slash");
                }.bind(this), 2000);
        } else {
            input.attr("type", "password");
        }
    }
    nceyeOnclick(e) {
        $('#ncpasseyeSet i').toggleClass("fa-eye fa-eye-slash");
        var input = $(".oneventncpass");
        if (input.attr("type") === "password") {
            input.attr("type", "text");
            setTimeout(
                function () {
                    input.attr("type", "password");
                    $('#ncpasseyeSet i').toggleClass("fa-eye fa-eye-slash");
                }.bind(this), 2000);
        } else {
            input.attr("type", "password");
        }
    }

    handleOPSChange = (e) => {
        this.setState({ OldPassword: e.target.value });
        if (!e.target.value || e.target.value == 0) {
            $('.manops').removeClass('hide');

        }
        else {
            $('.manops').addClass('hide');
        }

    }

    handlePSChange = (e) => {
        if (!this.testPasswordCheck(e.target.value)) {
            $('.psnotval').removeClass('hide');
        } else {
            $('.psnotval').addClass('hide');
        }

        if (!e.target.value || e.target.value == 0) {
            $('.manps').removeClass('hide');

        }
        else {
            $('.manps').addClass('hide');
        }
        this.setState({ NewPassword: e.target.value });

    }
    async CheckWeakPassword(Password) {
        let data = {
            NewPassword: Password
        }



        APIPost('SkinUpload/WeekPasswordCheck', data, function (result) {
            //
            //const result = await response.json();


            if (result === "true") {
                $('#Wkpwd').removeClass('hide');
                return result;

            } else {
                $('#Wkpwd').addClass('hide');
                return result;
            }
        });



    }
    handlePSBlur = (e) => {
        let currentComponent = this;
        if (!currentComponent.state.NewPassword || currentComponent.state.NewPassword == 0) {
            $('.manps').removeClass('hide');

        }
        else {
            $('.manps').addClass('hide');
        }

        if (currentComponent.state.NewPassword.length != 0) {

            currentComponent.CheckWeakPassword(currentComponent.state.NewPassword);



            if (currentComponent.state.NewPassword.length < 8) {
                $('.minps').removeClass('hide');
            }
            else if (currentComponent.state.NewPassword.length > 15) {
                $('.minps').removeClass('hide');
            }
            else if (currentComponent.state.CPassword && currentComponent.state.CPassword.length != 0) {
                if (currentComponent.state.NewPassword != currentComponent.state.CPassword) {
                    $('#mps').removeClass('hide');
                }
            }

            if (currentComponent.state.NewPassword.length >= 8 && currentComponent.state.NewPassword.length <= 15) {
                $('.minps').addClass('hide');
            }



        }
    }
    handleCPSBlur = (e) => {
        let currentComponent = this;
        if (currentComponent.state.NewPassword.length != 0 && currentComponent.state.NewPassword != currentComponent.state.CPassword) {
            $('#mps').removeClass('hide');
        }
    }
    handleCPSChange = (e) => {
        if ($('#mps').hasClass('hide') == false) {
            if (this.state.NewPassword == e.target.value) {
                $('#mps').addClass('hide')
            }
        }
        this.setState({ CPassword: e.target.value });

        if (e.target.value == "") {
            $('.mancps').removeClass('hide');
        }
        else {
            $('.mancps').addClass('hide');
        }
    }

    closeDialog = () => {
        this.setState({ isResetPasswordPopupOpen: false, NewPassword: '', CPassword: '', OldPassword: '', isSettingsPopupOpen: false })
        $('.manops').addClass('hide');
        $('.manps').addClass('hide');
        $('.mancps').addClass('hide');
        $('#mps').addClass('hide');
        $('.psnotval').addClass('hide');
        $('#Wkpwd').addClass('hide');
    }


    //    changePassword = () => {
    async changePassword() {
        let currentComponent = this;

        let err = 0;
        if (!this.state.OldPassword || this.state.OldPassword.length == 0) {
            $('.manops').removeClass('hide');
            err += 1;
        }
        else {
            $('.manops').addClass('hide');
        }
        if (!this.state.NewPassword || this.state.NewPassword.length == 0) {
            $('.manps').removeClass('hide');
            err += 1;
        }
        else {
            $('.manps').addClass('hide');
        }
        if (!this.state.CPassword) {
            $('.mancps').removeClass('hide');
            err += 1;
        }
        else {
            $('.mancps').addClass('hide');
        }
        if (this.state.CPassword) {
            $('.manops').addClass('hide');
            if (this.state.NewPassword.length != 0 && this.state.NewPassword != this.state.CPassword) {
                $('#mps').removeClass('hide');
                err += 1;
            }
        }
        if (err != 0) {
            return;
        }

        let OldPassword = this.state.OldPassword
        let NewPassword = this.state.NewPassword

        if (OldPassword == NewPassword) {
            currentComponent.toasterfun2('old Password and New Password are same');
            return;
        }

        if (!this.testPasswordCheck(this.state.NewPassword)) {
            currentComponent.toasterfun2('New Password is invalid');
            return;
        }


        let UserID = '';
        var windowlocation = window.location.href.toLowerCase();

        // let result = await currentComponent.CheckWeakPassword(currentComponent.state.NewPassword);
        let result = $('#Wkpwd').hasClass('hide');        
        //if (result === "false") {
        if (result) {

        

            let data = {
                UserID: '',
                OldPassword: OldPassword,
                NewPassword: NewPassword
            }

            APIPost('SkinUpload/ChangePassword', data, function (result) {
                if (result.code === 100) {
                    currentComponent.toasterfun2(result.message);
                    currentComponent.setState({ isResetPasswordPopupOpen: false, NewPassword: '', CPassword: '', OldPassword: '' })
                } else {
                    currentComponent.toasterfun2(result.message);
                }
            });
        }
    }

    OpenSettingsPopup = () => {
        let currentComponent = this;
        APIGet('SkinUpload/GetOTPFrequency', function (result) {
            if (result.statusCode === 100) {
                currentComponent.setState({ OTPFrequency: result.settingsId, isSettingsPopupOpen: true });
            } else {
                currentComponent.toasterfun2(result.statusMessage);
            }
        });
    }

    OTPFreqChange = (e) => {
        this.setState({ OTPFrequency: e.target.value });
    }

    changeMFASettings = () => {
        let currentComponent = this;
        let OTPFreq = this.state.OTPFrequency;;
        if (OTPFreq && OTPFreq != 0) {
            $('.OTPFreqWrong').addClass('hide');    

            let data = {
                SettingsId: OTPFreq
            }
            APIPost('SkinUpload/UpdateOTPSettings', data, function (result) {
                if (result.statusCode === 100) {
                    currentComponent.toasterfun2(result.statusMessage);
                    currentComponent.setState({ isSettingsPopupOpen: false })
                } else {
                    currentComponent.toasterfun2(result.statusMessage);
                }
            });
        }
        else{
            $('.OTPFreqWrong').removeClass('hide');
            return
        }


    }

    toasterfun2 = (e) => {
        this.setState({ toasterCla: true });
        this.setState({ toaster: e });
        setTimeout(
            function () {
                this.setState({ toasterCla: false });
            }.bind(this), 4000);
    }
    currYear = () => {
        var year = new Date().getFullYear();
        return year;
    }

    render() {

        var windowlocation = window.location.href.toLowerCase();

        let userfullName = "";
        var Role = "";
        if (windowlocation.includes("skinupload")) {
            userfullName = localStorage.getItem('CDuserfullName');
            Role = this.state.UserRole;
        }
        else if (windowlocation.includes("timecardfileexport")) {
            userfullName = localStorage.getItem('PAuserfullName');
            Role = this.state.UserRole;
        }
        if (Role == "") {
            userfullName = localStorage.getItem('CDuserfullName');
            Role = this.state.UserRole;
        }

        if (userfullName != null) {

            var FullName = userfullName;
            var FirstLetter = userfullName.charAt(0);


        }
        var LayoutHeader = "";
        var menuURL = "";
        var windowlocation = window.location.href.toLowerCase();
        if (windowlocation.includes("skinupload")) {
            LayoutHeader = "Skin Upload";
            menuURL = "SkinUploadHome"
        }
        else if (windowlocation.includes("timecardfileexport")) {
            LayoutHeader = "Production Accountant";
            menuURL = "TimeCardFileExport"
        }
        //else if (windowlocation.includes("bgactoraudit")) {
        //    LayoutHeader = "DV Log";
        //    menuURL = "BGActorAudit"
        //}




        return (
            <div>

                <div id="kt_header_mobile" className="kt-header-mobile  kt-header-mobile--fixed ">
                    <div className="kt-header-mobile__logo">
                        <a href="index.html">

                            <img alt="Logo" src="~/assets/img/ems-logo.png" style={{ width: "100%" }} />


                        </a>
                    </div>
                    <div className="kt-header-mobile__toolbar">
                        <button className="kt-header-mobile__toggler kt-header-mobile__toggler--left"
                            id="kt_aside_mobile_toggler">
                            <span></span>
                        </button>
                        <button className="kt-header-mobile__toggler" id="kt_header_mobile_toggler"><span></span></button>
                        <button className="kt-header-mobile__topbar-toggler" id="kt_header_mobile_topbar_toggler">
                            <i className="flaticon-more"></i>
                        </button>
                    </div>
                </div>


                <div className="kt-grid kt-grid--hor kt-grid--root" onMouseUp={this.handleEvent}>
                    <div className={"toaster-div " + (this.state.toasterCla ? "show" : "hide")}><i className="fa fa-check-circle" aria-hidden="true"></i> {this.state.toaster} </div>
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <div className="kt-aside  kt-aside--fixed  kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop d-none" id="kt_aside">
                            <div className="kt-aside__brand kt-grid__item " id="kt_aside_brand">
                                <div className="kt-aside__brand-logo">
                                    <a href="">
                                        <img alt="Logo" src={process.env.PUBLIC_URL + '/css/assets/img/' + this.state.imgurl + '.png'} style={{ width: '100 %' }} />

                                        <img className="logo-collapsed" alt="Logo" src={process.env.PUBLIC_URL + '/css/assets/img/ems-logo-small.png'} />


                                    </a>
                                </div>
                                <div className="kt-aside__brand-tools">
                                    <button className="kt-aside__brand-aside-toggler" id="kt_aside_toggler" />
                                    <span className="leftIcons">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                <polygon points="0 0 24 0 24 24 0 24" />
                                                <path
                                                    d="M5.29288961,6.70710318 C4.90236532,6.31657888 4.90236532,5.68341391 5.29288961,5.29288961 C5.68341391,4.90236532 6.31657888,4.90236532 6.70710318,5.29288961 L12.7071032,11.2928896 C13.0856821,11.6714686 13.0989277,12.281055 12.7371505,12.675721 L7.23715054,18.675721 C6.86395813,19.08284 6.23139076,19.1103429 5.82427177,18.7371505 C5.41715278,18.3639581 5.38964985,17.7313908 5.76284226,17.3242718 L10.6158586,12.0300721 L5.29288961,6.70710318 Z"
                                                    fill="#000000" fillRule="nonzero"
                                                    transform="translate(8.999997, 11.999999) scale(-1, 1) translate(-8.999997, -11.999999) " />
                                                <path
                                                    d="M10.7071009,15.7071068 C10.3165766,16.0976311 9.68341162,16.0976311 9.29288733,15.7071068 C8.90236304,15.3165825 8.90236304,14.6834175 9.29288733,14.2928932 L15.2928873,8.29289322 C15.6714663,7.91431428 16.2810527,7.90106866 16.6757187,8.26284586 L22.6757187,13.7628459 C23.0828377,14.1360383 23.1103407,14.7686056 22.7371482,15.1757246 C22.3639558,15.5828436 21.7313885,15.6103465 21.3242695,15.2371541 L16.0300699,10.3841378 L10.7071009,15.7071068 Z"
                                                    fill="#000000" fillRule="nonzero" opacity="0.3"
                                                    transform="translate(15.999997, 11.999999) scale(-1, 1) rotate(-270.000000) translate(-15.999997, -11.999999) " />
                                            </g>
                                        </svg>
                                    </span>
                                    <span className="rightIcons">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink"
                                            width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                <polygon points="0 0 24 0 24 24 0 24" />
                                                <path
                                                    d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                    fill="#000000" fillRule="nonzero" />
                                                <path
                                                    d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                    fill="#000000" fillRule="nonzero" opacity="0.3"
                                                    transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) " />
                                            </g>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                            <div className="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid" id="kt_aside_menu_wrapper">
                                <div id="kt_aside_menu" className="kt-aside-menu " data-ktmenu-vertical="1" data-ktmenu-scroll="1"
                                    data-ktmenu-dropdown-timeout="500">
                                    <ul className="kt-menu__nav ">
                                        <li className="kt-menu__item kt-menu__item--active" aria-haspopup="true">
                                            {this.state.MenuList.map(Menu =>
                                                <a href={Menu.menuURL} className="kt-menu__link ">
                                                    <span className="kt-menu__link-icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">

                                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                <polygon points="0 0 24 0 24 24 0 24" />
                                                                <path
                                                                    d="M12.9336061,16.072447 L19.36,10.9564761 L19.5181585,10.8312381 C20.1676248,10.3169571 20.2772143,9.3735535 19.7629333,8.72408713 C19.6917232,8.63415859 19.6104327,8.55269514 19.5206557,8.48129411 L12.9336854,3.24257445 C12.3871201,2.80788259 11.6128799,2.80788259 11.0663146,3.24257445 L4.47482784,8.48488609 C3.82645598,9.00054628 3.71887192,9.94418071 4.23453211,10.5925526 C4.30500305,10.6811601 4.38527899,10.7615046 4.47382636,10.8320511 L4.63,10.9564761 L11.0659024,16.0730648 C11.6126744,16.5077525 12.3871218,16.5074963 12.9336061,16.072447 Z"
                                                                    fill="#000000" fillRule="nonzero" />
                                                                <path
                                                                    d="M11.0563554,18.6706981 L5.33593024,14.122919 C4.94553994,13.8125559 4.37746707,13.8774308 4.06710397,14.2678211 C4.06471678,14.2708238 4.06234874,14.2738418 4.06,14.2768747 L4.06,14.2768747 C3.75257288,14.6738539 3.82516916,15.244888 4.22214834,15.5523151 C4.22358765,15.5534297 4.2250303,15.55454 4.22647627,15.555646 L11.0872776,20.8031356 C11.6250734,21.2144692 12.371757,21.2145375 12.909628,20.8033023 L19.7677785,15.559828 C20.1693192,15.2528257 20.2459576,14.6784381 19.9389553,14.2768974 C19.9376429,14.2751809 19.9363245,14.2734691 19.935,14.2717619 L19.935,14.2717619 C19.6266937,13.8743807 19.0546209,13.8021712 18.6572397,14.1104775 C18.654352,14.112718 18.6514778,14.1149757 18.6486172,14.1172508 L12.9235044,18.6705218 C12.377022,19.1051477 11.6029199,19.1052208 11.0563554,18.6706981 Z"
                                                                    fill="#000000" opacity="0.3" />
                                                            </g>
                                                        </svg>
                                                    </span>

                                                    <span className="kt-menu__link-text"> {Menu.layoutHeader}</span>
                                                </a>
                                            )}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div >
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper p-l-0" id="kt_wrapper">
                            <div id="kt_header" className="kt-header kt-grid__item  kt-header--fixed ">
                                <div className="kt-header-menu-wrapper proset-img" id="kt_header_menu_wrapper">

                                    <a href={window.location.href.toLowerCase()}>
                                        <img alt="Logo" src={process.env.PUBLIC_URL + '/css/assets/img/' + this.state.imgurl + '.png'} style={{ width: '100 %' }} />
                                    </a>

                                </div>
                                <div className="header-menu">
                                    <ul className="menu_nav p-0">
                                        <li className="kt-menu__item kt-menu__item--active" aria-haspopup="true">
                                            {this.state.MenuList.map(Menu =>
                                                <a href={Menu.menuURL} className="kt-menu__link ">
                                                    <span className={"menu-icon " + Menu.layoutHeader}></span>
                                                    <span className="menu-text"> {Menu.layoutHeader}</span>
                                                </a>
                                            )}
                                        </li>
                                    </ul>
                                </div>
                                <div className="kt-header__topbar profile user-drop">
                                    <div className="kt-header__topbar-item kt-header__topbar-item--user user-drop " onClick={() => this.showUserDrop()}>
                                        <div className="kt-header__topbar-wrapper user-drop" data-toggle="dropdown" data-offset="0px,0px">
                                            <div className="kt-header__topbar-user user-drop">
                                                <span className="kt-header__topbar-welcome kt-hidden-mobile user-drop d-none">Hi,</span>
                                                <span className="kt-header__topbar-username kt-hidden-mobile user-drop d-block">{FullName} <small className="d-block text-right">{Role}</small></span>
                                                <img className="kt-hidden user-drop" alt="Pic" src="~/assets/media/users/300_25.jpg" />
                                                <span className="kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold user-drop">{FirstLetter}</span>
                                            </div>
                                            <small className="user-drop d-none" style={{ position: 'absolute', bottom: '10px', right: '65px' }} >{Role}</small>
                                        </div>

                                        <div className={("user-profile-drop " + (this.state.showUserDrop ? "show" : "hide"))}>
                                            <ul className="m-0">
                                                <li><span type="button" onClick={() => this.setState({ isResetPasswordPopupOpen: true })}><i className="fa fa-lock p-2"></i><span className="p-2">Change Password</span></span></li>
                                                <li><span type="button" onClick={() => this.OpenSettingsPopup()}><i className="fa fa-user-cog p-2"></i><span className="p-2">Settings</span></span></li>
                                                <li><span type="button" onClick={this.LogOut}><i className="fas fa-sign-out-alt p-2"></i><span className="p-2">Logout</span></span></li>
                                            </ul>
                                        </div>

                                        <button className="btn-logout-div hide" onClick={this.LogOut}>Log Out </button>
                                        <button className="btn-logout-div hide" onClick={this.ResetPassword}>Reset Password </button>

                                        <div className="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
                                            <div className="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x"
                                                style={{ 'backgroundImage': 'url(~/assets/media/misc/bg-1.jpg)' }} >
                                                <div className="kt-user-card__avatar">
                                                    <img className="kt-hidden" alt="Pic" src="~/assets/media/users/300_25.jpg" />
                                                    <span
                                                        className="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success">K</span>
                                                </div>
                                                <div className="kt-user-card__name">
                                                    Kevin
                                            </div>
                                            </div>
                                            <div className="kt-notification">
                                                <a href="#" className="kt-notification__item">
                                                    <div className="kt-notification__item-icon">
                                                        <i className="flaticon2-calendar-3 kt-font-success"></i>
                                                    </div>
                                                    <div className="kt-notification__item-details">
                                                        <div className="kt-notification__item-title kt-font-bold">
                                                            My Profile
                                                </div>
                                                        <div className="kt-notification__item-time">
                                                            Account settings and more
                                                </div>
                                                    </div>
                                                </a>
                                                <div className="kt-notification__custom kt-space-end">
                                                    <a href="login.html" className="btn btn-label btn-label-brand btn-sm btn-bold">Sign
                                                   Out</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="divResetPassword" className={"popup-div modal " + (this.state.isResetPasswordPopupOpen ? "show" : "hide")}>
                                        <ChangePassword closeDialog={this.closeDialog} soucepage="Layout" PasswordPolicy={this.state.PasswordPolicy} PasswordDescription={this.state.PasswordDescription }/>
                                    </div>
                                    <div id="divSettings" className={"popup-div modal " + (this.state.isSettingsPopupOpen ? "show" : "hide")}>
                                        <div className="modal-title">
                                            <h4 className="headingt4">MFA Settings</h4>
                                        </div>
                                        <div className="modal-body">
                                            <div className="col-lg-12 col-md-12">
                                                <div className="form-group text-left">
                                                    <h5 className="bmd-label-floating">OTP Frequency</h5>
                                                    <div class="form-check pl-5">
                                                        <label class="form-check-label" for="radio1">
                                                            <input type="radio" class="form-check-input" value="1" checked={this.state.OTPFrequency == 1} onChange={this.OTPFreqChange} /> Everytime
                                                          </label>
                                                    </div>
                                                    <div class="form-check pl-5">
                                                        <label class="form-check-label" for="radio1">
                                                            <input type="radio" class="form-check-input" value="2" checked={this.state.OTPFrequency == 2} onChange={this.OTPFreqChange}/> Once a day
                                                          </label>
                                                    </div>
                                                    <div class="form-check pl-5">
                                                        <label class="form-check-label" for="radio1">
                                                            <input type="radio" class="form-check-input" value="3" checked={this.state.OTPFrequency == 3} onChange={this.OTPFreqChange}/> Never ask
                                                          </label>
                                                    </div>
                                                    <div className="redAlert noPosition OTPFreqWrong hide">* Please select any one option</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer text-right green-div-btn">

                                            <button type="button" className="btn btn-sm btn-brand mr-10" onClick={async () => { this.changeMFASettings(); }}>Save</button>
                                            <button type="button" className="btn btn-sm btn-brand mr-10" onClick={() => this.closeDialog()}>Close</button>
                                        </div>
                                    </div>
                                    <div className="modal-overlay"></div>
                                </div>
                            </div>
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content">
                                <div className="kt-subheader d-none  kt-grid__item" id="kt_subheader">
                                    <div className="kt-container  kt-container--fluid ">
                                        <div className="kt-subheader__main">
                                            <h3 className="kt-subheader__title">
                                                {LayoutHeader}
                                            </h3>
                                            <span className="kt-subheader__separator kt-hidden"></span>
                                        </div>
                                        <div className="kt-subheader__toolbar">
                                            <div className="kt-subheader__wrapper">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {this.props.children}
                            </div>
                            <div className="kt-footer  kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop" id="kt_footer">
                                <div className="kt-container  kt-container--fluid ">
                                    <div className="kt-footer__copyright">
                                        {this.currYear()}&nbsp;&copy;&nbsp;<a href="#" target="_blank" className="kt-link">Entertainment Services</a>
                                    </div>
                                    <div className="kt-footer__menu" style={{ 'display': 'none' }}>
                                        <a href="#" target="_blank" className="kt-footer__menu-link kt-link">About</a>
                                        <a href="#" target="_blank" className="kt-footer__menu-link kt-link">Team</a>
                                        <a href="#" target="_blank" className="kt-footer__menu-link kt-link">Contact</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
    renderResetPassword(event) {
        event.preventDefault();
        let Username = "";
        var windowlocation = window.location.href.toLowerCase();
        if (windowlocation.includes("skinupload")) {
            Username = localStorage.getItem('CDUsername');
        }
        else {
            Username = localStorage.getItem('PAUsername');
        }

        return (
            <div >
                <div className="hide-screen" onClick={this.handleResetPasswordcancel}></div>
                <ResetPassword action={this.handleResetPasswordcancel} Username={Username} />
            </div >

        );
        //    } actionclose = { this.handleResetPasswordcancel }
        //handler() {
        //    //this.setState({
        //    //    isResetPasswordPopupOpen: true
        //    //});
        //    //ReactDOM.unmountComponentAtNode(document.getElementById('divResetPassword'));
        //    this.setState({ isResetPasswordPopupOpen: false });

        //}
    }
}

import React, { useState, useEffect, useRef, Fragment } from 'react';

//import { Utils, Enum, Mask, TextBox, Dropdown, CheckBox, EMSTimePicker, Constants } from '../EMS_Components'
import { APIGet, APIPost } from '../EMS_Components/APICall'
import $ from 'jquery';

const ChangePassword = (props) => {
    const [toaster, settoaster] = useState('')
    const [toasterCla, settoasterCla] = useState(false)

    const [formData, setformData] = useState({})
    const [errormsg, seterrormsg] = useState({});
    //const [OldPassword, setOldPassword] = useState('');
    //const [NewPassword, setOldPassword] = useState('');

    let regexmaxlenth = props.PasswordPolicy ? props.PasswordPolicy.substring(props.PasswordPolicy.lastIndexOf(",") + 1, props.PasswordPolicy.lastIndexOf("}")) : 18;
    const maxlength = regexmaxlenth ? regexmaxlenth : 18;

    useEffect(() => {
    }, []);

    const toasterfun2 = (e) => {
        settoasterCla(true);
        settoaster(e);
        setTimeout(
            function () {
                settoasterCla(false);
            }.bind(this), 4000);
    }
    const handleOPSChange = (e) => {
        let dt = formData;
        dt.OldPassword = e.target.value
        setformData({ ...dt })

        if (!e.target.value || e.target.value == 0) {
            $('.manops').removeClass('hide');

        }
        else {
            $('.manops').addClass('hide');
        }

    }
    const testPasswordCheck = (e) => {

        var regx = new RegExp((props.soucepage == "Login" || props.soucepage == "Layout") ? props.PasswordPolicy : props.PasswordPolicy);
        e = e.replace(/\s/g, '');

        //if (/^(?=.*[0-9])(?=.*[!@@#$%^&*])[a-zA-Z0-9!@@#$%^&*]{8,15}$/.test(e)) {
        if (regx.test(e)) {
            return true;
        }
        else {
            return false;
        }
    }
    const handlePSChange = (e) => {

        if (!testPasswordCheck(e.target.value)) {
            $('.psnotval').removeClass('hide');
        } else {
            $('.psnotval').addClass('hide');
        }

        if (!e.target.value || e.target.value == 0) {
            $('.manps').removeClass('hide');

        }
        else {
            $('.manps').addClass('hide');
        }
        let dt = formData;
        dt.NewPassword = e.target.value
        setformData({ ...dt })


    }
    const handleCPSChange = (e) => {
        if ($('#mps').hasClass('hide') == false) {
            if (formData.NewPassword == e.target.value) {
                $('#mps').addClass('hide')
            }
        }
        let dt = formData;
        dt.CPassword = e.target.value
        setformData({ ...dt })


        if (e.target.value == "") {
            $('.mancps').removeClass('hide');
        }
        else {
            $('.mancps').addClass('hide');
        }
    }
    const eyeOnclick = (e) => {
        $('#passeyeSet i').toggleClass("fa-eye fa-eye-slash");
        var input = $(".oneventopass");
        if (input.attr("type") === "password") {
            input.attr("type", "text");
            setTimeout(
                function () {
                    input.attr("type", "password");
                    $('#passeyeSet i').toggleClass("fa-eye fa-eye-slash");
                }.bind(this), 2000);
        } else {
            input.attr("type", "password");
        }
    }

    const neyeOnclick = (e) => {
        $('#cpasseyeSet i').toggleClass("fa-eye fa-eye-slash");
        var input = $(".oneventcpass");
        if (input.attr("type") === "password") {
            input.attr("type", "text");
            setTimeout(
                function () {
                    input.attr("type", "password");
                    $('#cpasseyeSet i').toggleClass("fa-eye fa-eye-slash");
                }.bind(this), 2000);
        } else {
            input.attr("type", "password");
        }
    }
    const nceyeOnclick = (e) => {
        $('#ncpasseyeSet i').toggleClass("fa-eye fa-eye-slash");
        var input = $(".oneventncpass");
        if (input.attr("type") === "password") {
            input.attr("type", "text");
            setTimeout(
                function () {
                    input.attr("type", "password");
                    $('#ncpasseyeSet i').toggleClass("fa-eye fa-eye-slash");
                }.bind(this), 2000);
        } else {
            input.attr("type", "password");
        }
    }
    const handlePSBlur = (e) => {

        if (!formData.NewPassword || formData.NewPassword == 0) {
            $('.manps').removeClass('hide');

        }
        else {
            $('.manps').addClass('hide');
        }

        if (formData.NewPassword && formData.NewPassword.length != 0) {

            CheckWeakPassword(formData.NewPassword);



            if (formData.NewPassword.length < 8) {
                $('.minps').removeClass('hide');
            }
            else if (formData.NewPassword.length > 15) {
                $('.minps').removeClass('hide');
            }
            else if (formData.CPassword && formData.CPassword.length != 0) {
                if (formData.NewPassword != formData.CPassword) {
                    $('#mps').removeClass('hide');
                }
            }

            if (formData.NewPassword.length >= 8 && formData.NewPassword.length <= 15) {
                $('.minps').addClass('hide');
            }



        }
    }
    const handleCPSBlur = (e) => {

        if (formData.NewPassword && formData.CPassword) {
            if (formData.NewPassword.length != 0 && formData.NewPassword != formData.CPassword) {
                $('#mps').removeClass('hide');
            }
        }
    }
    const closeDialog = () => {




        //this.setState({ isResetPasswordPopupOpen: false, NewPassword: '', CPassword: '', OldPassword: '', isSettingsPopupOpen: false })
        let dt = formData;
        dt.NewPassword = ''
        dt.CPassword = ''
        dt.OldPassword = ''
        setformData({ ...dt })
        $('.manops').addClass('hide');
        $('.manps').addClass('hide');
        $('.mancps').addClass('hide');
        $('#mps').addClass('hide');
        $('.psnotval').addClass('hide');
        $('#Wkpwd').addClass('hide');
        props.closeDialog();
    }
    const CheckWeakPassword = (Password) => {
        let data = {
            NewPassword: Password
        }



        APIPost('SkinUpload/WeekPasswordCheck', data, function (result) {
            //
            //const result = await response.json();


            if (result === "true") {
                $('#Wkpwd').removeClass('hide');
                return result;

            } else {
                $('#Wkpwd').addClass('hide');
                return result;
            }
        });



    }
    const changePassword = (e) => {


        let err = 0;
        if (!formData.OldPassword || formData.OldPassword.length == 0) {
            $('.manops').removeClass('hide');
            err += 1;
        }
        else {
            $('.manops').addClass('hide');
        }
        if (!formData.NewPassword || formData.NewPassword.length == 0) {
            $('.manps').removeClass('hide');
            err += 1;
        }
        else {
            $('.manps').addClass('hide');
        }
        if (!formData.CPassword) {
            $('.mancps').removeClass('hide');
            err += 1;
        }
        else {
            $('.mancps').addClass('hide');
        }
        if (formData.CPassword) {
            $('.manops').addClass('hide');
            if (formData.NewPassword.length != 0 && formData.NewPassword != formData.CPassword) {
                $('#mps').removeClass('hide');
                err += 1;
            }
        }
        if (err != 0) {
            return;
        }

        let OldPassword = formData.OldPassword
        let NewPassword = formData.NewPassword

        if (OldPassword == NewPassword) {
            toasterfun2('old Password and New Password are same');
            return;
        }

        if (!testPasswordCheck(formData.NewPassword)) {
            toasterfun2('New Password is invalid');
            return;
        }


        let UserID = '';
        var windowlocation = window.location.href.toLowerCase();

        // let result = await currentComponent.CheckWeakPassword(formData.NewPassword);
        let result = $('#Wkpwd').hasClass('hide');
        //if (result === "false") {
        if (result) {



            let data = {
                UserID: props.username,
                OldPassword: OldPassword,
                NewPassword: NewPassword,
                Role: props.role
            }

            APIPost('SkinUpload/ChangePassword', data, function (result) {
                if (result.code === 100) {
                    toasterfun2(result.message);
                    $('.pswrong').addClass('hide');
                    // currentComponent.setState({ isResetPasswordPopupOpen: false, NewPassword: '', CPassword: '', OldPassword: '' })
                    let dt = formData;
                    dt.NewPassword = ''
                    dt.CPassword = ''
                    dt.OldPassword = ''
                    setformData({ ...dt })
                    props.closeDialog();
                } else {
                    $('.pswrong').removeClass('hide');
                    toasterfun2(result.message);
                }
            });
        }
    }
    return (
        <>
            <div className="modal-title">
                <div className={"db-label line-x noPosition " + (props.soucepage == "Login" ? "show" : "hide")}>* Your password has expired. Please reset your password!.</div>
                <h4 className={"headingt4 " + (props.soucepage == "Login" ? "hide" : "show")}>Change Password</h4>
            </div>
            <div className="modal-body">
                <div className="col-lg-12 col-md-12">
                    <div className="form-group text-left">
                        <label className="bmd-label-floating">Old Password</label>
                        <div className="input-group mb-3">
                            <input type="password" autoComplete="old-password" id="old-password" className="form-control oneventopass" value={formData.OldPassword} maxLength={maxlength} onChange={handleOPSChange} />
                            <div className="input-group-append">
                                <span className="input-group-text eye-icon-style" id="passeyeSet" onClick={eyeOnclick}><i className="fa fa-eye" aria-hidden="true"></i></span>
                            </div>
                        </div>
                        <div className="redAlert noPosition pswrong hide">* Wrong Password</div>
                        <div className="redAlert noPosition manops hide">* Mandatory</div>
                    </div>
                </div>
                <div className="col-lg-12 col-md-12">
                    <div className="form-group text-left">
                        <label className="bmd-label-floating">New Password</label>
                        <div className="input-group mb-3">
                            <input type="password" autoComplete="new-password" className="form-control oneventcpass" value={formData.NewPassword} onChange={handlePSChange} onBlur={handlePSBlur} maxLength={maxlength} />
                            <div className="input-group-append">
                                <span className="input-group-text eye-icon-style" id="cpasseyeSet" onClick={neyeOnclick}><i className="fa fa-eye" aria-hidden="true"></i></span>
                            </div>
                        </div>
                        <div className="db-label line-x noPosition">
                            {props.PasswordDescription ? props.PasswordDescription :
                                "Minimum 12 & max 18 characters and must include at least one upper case, one lower case, one digit and one special character.Ex:P@ss1234"
                            }
                        </div>
                        <div className="redAlert noPosition manps hide">* Mandatory</div>
                        <div className="redAlert noPosition psnotval hide">* Is not valid </div>
                        <div className="redAlert noPosition hide" id="Wkpwd">* Weak Password </div>
                    </div>
                </div>
                <div className="col-lg-12 col-md-12">
                    <div className="form-group text-left">
                        <label className="bmd-label-floating">Confirm Password</label>

                        <div className="input-group mb-3">
                            <input type="password" autoComplete="confirm-password" className="form-control oneventncpass" value={formData.CPassword} maxLength={maxlength} onChange={handleCPSChange} onBlur={handleCPSBlur} />
                            <div className="input-group-append">
                                <span className="input-group-text eye-icon-style" id="ncpasseyeSet" onClick={nceyeOnclick}><i className="fa fa-eye" aria-hidden="true"></i></span>
                            </div>
                        </div>
                        <div className="redAlert noPosition mancps hide">* Mandatory</div>
                        <div className="redAlert noPosition hide" id="mps">Mismatch Password</div>
                    </div>
                </div>
            </div>
            <div className="modal-footer text-right green-div-btn">

                <button type="button" className="btn btn-sm btn-brand mr-10" onClick={() => changePassword()}>Change</button>  {/*onClick={async () => { await this.changePassword(); }}*/}
                <button type="button" className={"btn btn-sm btn-brand mr-10 " + (props.soucepage == "Login" ? "hide" : "show")} onClick={() => closeDialog()}>Close</button>
            </div>
        </>
    );
}

export default ChangePassword
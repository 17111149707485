import React, { useState } from 'react';
import { Layout } from '../Layout';
import moment from 'moment';
import { DatePickerInput } from 'rc-datepicker';
import MaterialTable from "material-table";
import $ from 'jquery';
import ReactTooltip from 'react-tooltip';
import { onlyDigits } from '../Validation'
//import { Tabs, Tab } from 'react-bootstrap-tabs';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';// 'react-tabs/style/react-tabs.css';
import { APIGet, APIPost } from '../EMS_Components/APICall'
import * as FileSaver from 'file-saver';
import { Dropdown } from '../EMS_Components'

//<script src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/2.3.1/jspdf.umd.min.js"></script>


export class ShootWiseVoucher extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            toasterCla: false,
            toaster: '',
            isResetPasswordPopupOpen: false,
            RegistrationLink: '',
            loadingTime: false,
            Shooting: [],
            formData: {}
        }

    }

    componentDidMount() {
        this.getShootingList();
    }

    toasterfun2 = (e) => {
        this.setState({ toasterCla: true });
        this.setState({ toaster: e });
        setTimeout(
            function () {
                this.setState({ toasterCla: false });
            }.bind(this), 4000);
    }

    handlechange = (e) => {
        if (e.target.id == "Email") {
            this.setState({ Email: e.target.value })
        }
        else if (e.target.id == "Phone") {
            this.setState({ Phone: e.target.value })
        }
    }

    getDetails = () => {
        if ((!this.state.Email || this.state.Email.Length == 0) && (!this.state.Phone || this.state.Phone.Length == 0)) {
            if (!this.state.formData.Shooting || this.state.formData.Shooting == "Select") {
                $('.manfields').removeClass('hide')
                return
            }
        }

        let type = (this.state.formData.Shooting && this.state.formData.Shooting != "Select") ? 3 : this.state.Email ? 1 : 2
        let data = (this.state.formData.Shooting && this.state.formData.Shooting != "Select") ? this.state.formData.Shooting : this.state.Email ? this.state.Email : this.state.Phone ? this.state.Phone : ''


        let currentComponent = this;
        var strURL = "getVoucherDetails";
        currentComponent.setState({ loadingTime: true });
        APIGet('LogDetails/' + strURL + "/" + type + "/" + data, function (result) {
            if (result.statusCode == 100) {
                currentComponent.setState({ loadingTime: false });
                currentComponent.setState({ rows: result.listResult, loading: false });
                //currentComponent.setState({ isTableDisplay: result.result.length > 0 ? true : false });
            } else {
                console.log(result.statusMessage);
                currentComponent.setState({ loadingTime: false });
                currentComponent.setState({ rows: result.listResult, loading: false });
                currentComponent.toasterfun2(result.statusMessage);
            }
        });
    }

    getPdfFile = (encryptid) => {

        let currentComponent = this;
        var strURL = "getPdfFile";
        let data = { Encryptid: encryptid };
        currentComponent.setState({ loadingTime: true });
        APIPost('LogDetails/' + strURL, data, function (result) {
            //console.log(result);

            if (result.code == 100) {
                var byteCharacters = atob(result.result);
                var byteNumbers = new Array(byteCharacters.length);
                for (var i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                var byteArray = new Uint8Array(byteNumbers);


                var blob = new Blob([byteArray], { type: "application/pdf;charset=utf-8" });
                FileSaver.saveAs(blob, result.fileName);
                currentComponent.setState({ loadingTime: false });
                currentComponent.toasterfun2("Successfully Downloaded.");
            } else {
                console.log(result.message);
                currentComponent.setState({ loadingTime: false });
                currentComponent.toasterfun2(result.message);
            }


        });


        //fetch('LogDetails/' + strURL, {
        //    method: 'post',
        //     headers: {
        //        Accept: 'application/json',
        //        'Content-Type': 'application/json',
        //    },
        //    body: JSON.stringify({ Encryptid: Encryptid})
        //}).then(function (response) {
        //    return response.json();
        //}).then(function (result) {
        //    console.log(result);

        //    if (result.code == 100) {
        //        //currentComponent.setState({ isResetPasswordPopupOpen: true, RegistrationLink: result.result });

        //        console.log(process.env.PUBLIC_URL);
        //        var blob = new Blob([result.result],{ type: "application/pdf;charset=utf-8" });
        //        FileSaver.saveAs(blob, "Pdftest.pdf");
        //        //window.open(result.result);
        //        currentComponent.toasterfun2("Successfully Downloaded.");
        //        //currentComponent.setState({ isTableDisplay: result.result.length > 0 ? true : false });
        //    } else {
        //        console.log(result.message);
        //        currentComponent.toasterfun2(result.message);
        //    }

        //}).catch(function (error) {
        //    console.log("-------- error ------- " + error);
        //});
    }

    copyToClipboard = (element) => {
        var $temp = $("<input>");
        $("body").append($temp);
        $temp.val($(element).text()).select();
        document.execCommand("copy");
        $temp.remove();
    }

    getShootingList = () => {
        let currentComponent = this;

        APIGet('SkinUpload/getShootings/' + 0, function (result) {
            if (result.code == 100) {
                let ddlData = [];
                result.result.map((shoot, index) => {
                    ddlData.push({
                        Value: shoot.value,
                        Text: shoot.text
                    })
                });
                currentComponent.setState({ Shooting: ddlData });
            }
        });
    }

    downloadAllVoucher = (data) => {
        let currentComponent = this;

        var Data = { data }

        APIPost('LogDetails/downloadAllVoucher/', Data, function (result) {
            if (result.code == 100) {
                var byteCharacters = atob(result.result);
                var byteNumbers = new Array(byteCharacters.length);
                for (var i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                var byteArray = new Uint8Array(byteNumbers);

                var blob = new Blob([byteArray], { type: "application/pdf;charset=utf-8" });
                FileSaver.saveAs(blob, result.fileName);

                currentComponent.toasterfun2("Successfully Downloaded.");
            } else {
                currentComponent.toasterfun2(result.message);
            }
        });
    }

    render() {
        var landing = '/css/assets/img/ems-logo.gif';


        return (
            <Layout>

                <span className={"loading " + (this.state.loadingTime ? "show" : "hide")} ><span className="loading-div-d"></span> <img src={process.env.PUBLIC_URL + landing} /> </span>
                <div className={"toaster-div " + (this.state.toasterCla ? "show" : "hide")}><i className="fa fa-check-circle" aria-hidden="true"></i> {this.state.toaster} </div>

                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                    <div className="kt-portlet kt-portlet--mobile mb-0">
                        <div className="kt-portlet__body">
                            <div action="" className="kt-form kt-form--label-right">
                                <div className="row">
                                    <div className="col-lg-5 col-md-5">
                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group text-left">
                                                <label className="bmd-label-floating">Email</label>
                                                <div class="input-group mb-3">
                                                    <input type="text" autoComplete="Email" id="Email" className="form-control" value={this.state.Email} onChange={this.handlechange} />
                                                </div>
                                                <div className="redAlert noPosition emwrong hide">* Email not found</div>
                                                <div className="redAlert noPosition emnotval hide">* Is not valid </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 text-center mt-4">
                                            <span> <b>(OR)</b> </span>
                                        </div>
                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group text-left">
                                                <label className="bmd-label-floating">Phone</label>
                                                <div class="input-group mb-3">
                                                    <input type="text" autoComplete="Phone" id="Phone" className="form-control" value={this.state.Phone} onChange={this.handlechange} />
                                                </div>
                                                <div className="redAlert noPosition phwrong hide">* Phone # not found</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-2 text-center mt-5 pt-5">
                                        <span> <b>(OR)</b> </span>
                                    </div>
                                    <div className="col-lg-5 col-md-5">
                                        <div className="col-lg-12 col-md-12">
                                            <Dropdown label="Gender" id="Shooting" label="Shooting" source={this.state.Shooting} formdata={this.state.formData} />
                                        </div>
                                        <div className="col-lg-12 col-md-12 text-right">
                                            <button type="button" className="btn btn-primary btn-sm mt-5" onClick={this.getDetails}>Get Details </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="redAlert noPosition manfields hide">* Enter Email or Phone # or Select Shooting</div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                        <MaterialTable
                                            title=""
                                            columns={[
                                                { title: "Shoot Name", field: "0", editable: "never", hidden: (this.state.formData.Shooting && this.state.formData.Shooting != "Select") ? true : false },
                                                { title: "Shoot Date", field: "1", editable: "never", hidden: (this.state.formData.Shooting && this.state.formData.Shooting != "Select") ? true : false },
                                                { title: "Shoot Location", field: "3", editable: "never", hidden: (this.state.formData.Shooting && this.state.formData.Shooting != "Select") ? true : false },

                                                { title: "First Name", editable: "never", field: "4", hidden: (this.state.formData.Shooting && this.state.formData.Shooting != "Select") ? false : true },
                                                { title: "Last Name", editable: "never", field: "5", hidden: (this.state.formData.Shooting && this.state.formData.Shooting != "Select") ? false : true },
                                                { title: "Email", editable: "never", field: "6", hidden: (this.state.formData.Shooting && this.state.formData.Shooting != "Select") ? false : true },
                                                { title: "Phone #", editable: "never", field: "7", hidden: (this.state.formData.Shooting && this.state.formData.Shooting != "Select") ? false : true },

                                                {
                                                    title: "Voucher", width: "10%", editable: "never", render: rowData =>
                                                        rowData[8] == 'False' ?
                                                            <small className="text-danger">Unregistered Talent</small>
                                                            :
                                                            <button type="button" onClick={() => this.getPdfFile(rowData[2])} class="btn btn-sm btn-primary"> Download  </button>

                                                },
                                            ]}
                                            data={this.state.rows}
                                            class="mat-paginator-sticky"
                                            title=""
                                            cellEditable={{
                                                onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                                                    return new Promise((resolve, reject) => {
                                                        this.updateDetail(resolve, oldValue, newValue, rowData, columnDef);
                                                    });
                                                }
                                            }}
                                            options={{
                                                pageSizeOptions: [5, 10, 20, 50],
                                                pageSize: 10
                                            }}
                                        //components={{
                                        //    Toolbar: props => (
                                        //        <div>
                                        //            <MTableToolbar {...props} />
                                        //            <div style={{ padding: '0px 10px' }}>
                                        //                <button type="button" onClick={() => this.downloadAllVoucher(this.state.rows)} class="btn btn-sm btn-primary"> Download  </button>
                                        //            </div>
                                        //        </div>
                                        //    ),
                                        //}}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="divResetPassword" className={"popup-div modal " + (this.state.isResetPasswordPopupOpen ? "show" : "hide")}>
                    <div className="modal-title">
                        <h4>Registration Link</h4>
                    </div>
                    <div className="modal-body">
                        <div className="col-lg-12 col-md-12">
                            <span> <a href={this.state.RegistrationLink} id="supportMail" target="_blank">{this.state.RegistrationLink}</a>
                                <i className="fa fa-copy ml-2" type="button" onClick={() => this.copyToClipboard('#supportMail')} />
                            </span>
                        </div>
                    </div>
                    <div className="modal-footer text-right">
                        <button type="button" className="btn btn-sm btn-brand mr-10" onClick={() => this.setState({ isResetPasswordPopupOpen: false })}>Close</button>
                    </div>
                </div>
            </Layout>
        );
    }
}


import React, { useState, useEffect } from "react";
import { Enum, Utils, Constants } from '../EMS_Components'

const TextBox = (props) => {
    const [error, seterror] = useState(props.error ? props.error : {})
    const [fieldValue, setfieldValue] = useState(props.formdata[props.id] ? props.formdata[props.id] : "")

    useEffect(() => {
        seterror(props.error ? props.error : {});
        setfieldValue(props.formdata[props.id] ? props.formdata[props.id] : "");
    }, [props])

    const handleChange = (e) => {
        seterror({});
        let data = e.target.value;

        switch (props.TextFor) {
            case Enum.Name:
                if (Utils.validateName(data) == true) {
                    setfieldValue(data);
                    props.formdata[props.id] = data
                }
                break;

            case Enum.Email:
                seterror({});
                if (Utils.validateEmail(data) == false) {
                    error[props.id] = Constants.InvalidEmail;
                    seterror(error);
                }
                setfieldValue(data);
                props.formdata[props.id] = data
                break;

            case Enum.Alphabets:
                if (Utils.validateAlphabet(data) == true) {
                    setfieldValue(data);
                    props.formdata[props.id] = data
                }
                break;

            //case Enum.AlphaNumeric:
            //    if (Utils.vali(data) == true) {
            //        setfieldValue(data);
            //        props.formdata[props.id] = data
            //    }
            //    break;

            case Enum.Numeric:
                if (Utils.validateNumeric(data) == true) {
                    setfieldValue(data);
                    props.formdata[props.id] = data
                }
                break;

            case Enum.AlphaNumericWithSplChar:
                if (Utils.validateAlphabetSplChar(data) == true) {
                    setfieldValue(data);
                    props.formdata[props.id] = data
                }
                break;

            case Enum.Decimal:
                if (Utils.validateDecimal(data) == true) {
                    setfieldValue(data);
                    props.formdata[props.id] = data
                }
                break;

            default:
                setfieldValue(data);
                props.formdata[props.id] = data
        }

        if (props.onChange)
            props.onChange(e)
    };

    const onBlur = (e) => {
        seterror({});
        if (props.onBlur)
            props.onBlur(e)
    }

    return (
        <div className="form-group">
            {props.label ? <label>{props.label} {props.required ? <span className="redAlert">*</span> : <></>}</label> : <></>}
            <input type="text" className={"form-control " + (props.className ? props.className : "")} name={props.id} id={props.id} value={fieldValue}
                onChange={handleChange} onBlur={onBlur} ref={props.refValue ? props.refValue : null}
                disabled={props.disabled ? true : false} TextFor={props.TextFor} placeholder={props.placeholder ? props.placeholder : "" }/>
            <div className="redAlert noPosition">{error[props.id]}</div>
        </div>
    );
}

export default TextBox
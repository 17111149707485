import React, { useState, useEffect } from 'react';
import { ReactDataGridComponent } from './ReactDataGrid';
import { EditReactDataGridComponent } from './EditReactDataGrid';

import $ from 'jquery';
import TagsInput from 'react-tagsinput'
import 'react-tabs/style/react-tabs.css';// 'react-tabs/style/react-tabs.css';
import { Utils, Enum, Mask, TextBox, Dropdown, CheckBox, EMSTimePicker, Constants } from '../EMS_Components'
import { APIGet, APIPost } from '../EMS_Components/APICall'
import moment from 'moment';
const DatasourceConstants = require('./DatasourceConstants.json');
const landing = '/css/assets/img/ems-logo.gif';



const AddMultipleTalent = (props) => {

    const [toaster, settoaster] = useState('')
    const [toasterCla, settoasterCla] = useState(false)
    const [loadingTime, setloadingTime] = useState(false)


    const [openModal, setopenModal] = useState(false)
    const [ModalName, setModalName] = useState(Constants.ModalAdjustments)

    const [formData, setformData] = useState({ demoninators: 8, callTime: null, TBD: true })
    const [errormsg, seterrormsg] = useState({});
    const [shootingId, setshootingId] = useState(0)
    const [userID, setuserID] = useState(0)
    const [bumpList, setbumpList] = useState([]);
    const [modalData, setmodalData] = useState({});
    const [adjustmentData, setadjustmentData] = useState([]);
    const [compensationData, setcompensationData] = useState([]);
    const [othersData, setothersData] = useState([]);
    const [isunionDisable, setisunionDisable] = useState((adjustmentData.length + compensationData.length + othersData.length) > 0 ? true : false);
    const [showTalentError, setshowTalentError] = useState("");
    const [Wardrobe1Rate, setWardrobe1Rate] = useState(0)
    const [Wardrobe2Rate, setWardrobe2Rate] = useState(0)

    useEffect(() => {
        if (props.isEditMode == true) {
            let dt = {}
            dt = props.editData.formData
            dt.Person = props.editData.Person
            dt.checkRefNumber = props.editData.formData.checkRefNumber
            setformData({ ...dt })

            setadjustmentData(props.editData.adjustmentData)
            setcompensationData(props.editData.compensationData)
            setothersData(props.editData.othersData)

            let ud = (props.editData.adjustmentData.length + props.editData.compensationData.length + props.editData.othersData.length) > 0 ? true : false;
            //setisunionDisable({ ...ud })
            setisunionDisable(ud);
        }
        else {
            // resetfields()
            setisunionDisable(false)
        }
        seterrormsg({})
        setshowTalentError("")
    }, [props]);

    useEffect(() => {
        setshootingId(props.shootingId ? props.shootingId : 0);
        // setuserID(props.userId ? props.userId : 0);

        //if (emailInput.current != null)
        //    emailInput.current.focus();       
    }, []);
    const getDuplicateArrayElements = (arr) => {
        let sorted_arr = arr.slice().sort();
        let results = [];
        for (let i = 0; i < sorted_arr.length - 1; i++) {
            if (sorted_arr[i + 1] === sorted_arr[i]) {
                results.push(sorted_arr[i]);
            }
        }
        return results;
    }



    const LoadBumpRateWardrobe = (bumpName) => {

        let isUnion = formData.unionId ? formData.unionId : false

        APIGet('AdminSkinUpload/GetShootingBumpRate/' + bumpName + '/' + isUnion + '/' + parseInt(props.shootingId), function (result) {
            if (result.code == 100) {

                if (bumpName == "1st Wardrobe Change") {

                    setWardrobe1Rate(result.result.toString());
                }
                if (bumpName == "2nd Wardrobe Change onwards") {

                    setWardrobe2Rate(result.result.toString());
                }


            }
        });


    }

    const LoadBumpRate = () => {

        let bump = {}
        bump = DatasourceConstants.BumpList.find(B => B.Value == modalData.bumpId);
        let isUnion = formData.unionId ? formData.unionId : false
        if (bump && bump.type != Constants.ModalOther) {
            APIGet('AdminSkinUpload/GetShootingBumpRate/' + bump.BumpName + '/' + isUnion + '/' + parseInt(props.shootingId), function (result) {
                if (result.code == 100) {
                    let mdata = modalData;
                    mdata.bumprate = result.result.toString();
                    setmodalData({ ...mdata })
                }
            });
        }
        if (modalData.bumpId == 13) {
            LoadBumpRateWardrobe("1st Wardrobe Change");
            LoadBumpRateWardrobe("2nd Wardrobe Change onwards");
        }

    }

    const addadjustmentData = () => {
        let dt = {}

        dt = DatasourceConstants.BumpList.find(B => B.Value == modalData.bumpId);
        if (modalData.bumpId == "3")
            dt.Description = modalData.bumpdescription.trim();
        dt.Rate = modalData.bumprate;
        let addData = []
        addData = adjustmentData;
        addData.push(dt);
        setadjustmentData(addData)
        loadbumpList(Constants.ModalAdjustments);
        setmodalData({})

    }

    const removeadjustmentData = (value) => {
        let addData = []
        addData = adjustmentData;
        addData.splice(addData.findIndex(x => x.Value == value), 1)
        setadjustmentData(addData)
        loadbumpList(Constants.ModalAdjustments);
    }
    // (modalData.bumpId == 13 || modalData.bumpId == 14 || modalData.bumpId == 15 || modalData.bumpId == 16)
    const addcompensationData = () => {

        let dt = {}
        dt = DatasourceConstants.BumpList.find(B => B.Value == modalData.bumpId);
        if (modalData.bumpId != 13 || modalData.bumpId == 14 || modalData.bumpId == 15 || modalData.bumpId == 16) dt.Rate = modalData.bumprate;
        if (modalData.bumpId == 13 || modalData.bumpId == 14 || modalData.bumpId == 15 || modalData.bumpId == 16) {
            modalData.bumpQuantity = parseFloat(modalData.bumpQuantity);
            dt.Quantity = modalData.bumpQuantity
            if (modalData.bumpId == 14 || modalData.bumpId == 15) {
                dt.Rate = parseFloat(modalData.bumprate * modalData.bumpQuantity * (formData.rate && formData.demoninators ? (formData.rate / formData.demoninators) : 1)).toFixed(2);
            }
            else if (modalData.bumpId == 16) {
                dt.Rate = parseFloat(modalData.bumprate * modalData.bumpQuantity).toFixed(2);
            }
            else if (modalData.bumpId == 13) {
                let WardrobeRate = parseFloat(0).toFixed(2);
                if (modalData.bumpQuantity == 1) {

                    WardrobeRate = parseFloat(Wardrobe1Rate).toFixed(2)
                }
                else if (modalData.bumpQuantity > 1) {

                    WardrobeRate = (parseFloat(Wardrobe1Rate) + (parseFloat(Wardrobe2Rate) * parseFloat(modalData.bumpQuantity - 1))).toFixed(2);

                }
                dt.Rate = WardrobeRate;
            }

        }

        let addData = []
        addData = compensationData;
        addData.push(dt);
        setcompensationData(addData)
        loadbumpList(Constants.ModalCompensations);
        setmodalData({})
    }

    const removecompensationData = (value) => {
        let addData = []
        addData = compensationData;
        addData.splice(addData.findIndex(x => x.Value == value), 1)
        setcompensationData(addData)
        loadbumpList(Constants.ModalCompensations);
    }

    const addothersData = () => {
        let dt = {}
        dt = DatasourceConstants.BumpList.find(B => B.Value == modalData.bumpId);
        dt.Description = modalData.bumpdescription;
        dt.Rate = modalData.bumprate;
        let addData = []
        addData = othersData;
        addData.push(dt);
        setothersData(addData)
        loadbumpList(Constants.ModalOther);
        setmodalData({})
    }

    const removeothersData = (value) => {
        let addData = []
        addData = othersData;
        addData.splice(addData.findIndex(x => x.Value == value), 1)
        setothersData(addData)
        loadbumpList(Constants.ModalOther);
    }

    const chkChange = (e) => {
        let dt = formData;
        setformData({ ...dt })
    }

    const toasterfun2 = (e) => {
        settoasterCla(true);
        settoaster(e);
        setTimeout(
            function () {
                settoasterCla(false);
            }.bind(this), 4000);
    }

    const loadbumpList = (bumpType) => {

        let bumpData = []
        bumpData = DatasourceConstants.BumpList.filter(B => B.type == bumpType);
        let filterArray = []
        filterArray = bumpType == Constants.ModalAdjustments ? adjustmentData : (bumpType == Constants.ModalCompensations ? compensationData : othersData)

        filterArray.map((bump, i) => {
            bumpData.splice(bumpData.findIndex(x => x.Value == bump.Value), 1)
        })

        setbumpList(bumpData);
        setisunionDisable((adjustmentData.length + compensationData.length + othersData.length) > 0 ? true : false)
    }

    const onOpensubmodal = (ModalName) => {
        let msg = {};
        seterrormsg(msg);
        setopenModal(true);
        setModalName(ModalName);
        loadbumpList(ModalName)
    }

    const onOpensubmodalClose = () => {
        setmodalData({});
        setopenModal(false);
    }

    const AddBump = () => {

        let msg = {};
        let err = 0;
        if (modalData.bumpId == 3 || modalData.bumpId == 10 || modalData.bumpId == 11 || modalData.bumpId == 12) {
            if (!modalData.bumpdescription || modalData.bumpdescription.trim() == "") { msg.bumpdescription = "Please enter description"; err++; }

        }
        if (modalData.bumpId == 13 || modalData.bumpId == 14 || modalData.bumpId == 15 || modalData.bumpId == 16) {
            if (!modalData.bumpQuantity || modalData.bumpQuantity.trim() == "" || modalData.bumpQuantity == "0") { msg.bumpQuantity = "Please enter Quantity"; err++; }

        }
        if (!modalData.bumpId) {
            msg.bumpId = "Please select a Bump";
            err++;
        }
        if ((!modalData.bumprate || modalData.bumprate == "0") && modalData.bumpId != 13) {
            msg.bumprate = "Please enter Rate";
            err++;
        }
        if (err == 0) {
            if (modalData.bumpId) {
                if (ModalName == Constants.ModalAdjustments) {
                    addadjustmentData()
                }
                else if (ModalName == Constants.ModalCompensations) {
                    addcompensationData()
                }
                else if (ModalName == Constants.ModalOther) {
                    addothersData()
                }
            }
            seterrormsg({})
        }
        else {
            seterrormsg(msg);
        }
    }

    const validateData = () => {

        setshowTalentError("")
        let msg = {};
        let isValid = true;
        let dt = formData;
        let Person = dt["Person"];
        let errMessage = "";
        let emails = [];
        let checkRefNumbers = [];


        if (!Person || Person.length <= 0 || Person == undefined) {
            errMessage = "* No Talents Added";
            isValid = false;
        }
        else {
            //let negcount = (Person && Person[Person.length - 1][0] != undefined && Person[Person.length - 1][0].email == "") ? Person[Person.length - 1][0].email.length : 0;
            //let personlength = Person?(Person.filter(P => P.lastName != "").length - negcount):0;
            let personlength = 0;
            if (props.isEditMode && props.isMultiEdit) { personlength = Person ? (Person.filter(P => P.email != "").length) : 1; }
            else if (props.isEditMode && !props.isMultiEdit) { personlength = 1 }
            else //if (!props.isEditMode)
            { personlength = Person.length - 1 }
            if (personlength == 0) {
                if (!Person[0].lastName || !Person[0].firstName || !Person[0].phone || !Person[0].email) {
                    isValid = false
                    errMessage = "* No Talents Added";

                }
            }
            else {
                if (!props.isEditMode && (!Person[0].lastName || !Person[0].firstName || !Person[0].phone || !Person[0].email
                    || Person[0].lastName.trim() == "" || Person[0].firstName.trim() == "" || Person[0].phone.trim() == "" || Person[0].email.trim() == "")) {
                    //errMessage = "* Please provide must  Last Name, First Name, Email, Phone Number and Rate for all the talent's";
                    errMessage = "* Please provide First Name, Last Name, valid Email, Phone Number and Rate for all the talent(s)";
                    isValid = false
                }

                for (let index = 0; index < personlength; ++index) {

                    if (!props.isEditMode && (!Person[index].lastName || !Person[index].firstName || !Person[index].phone || !Person[index].email
                        || Person[index].lastName.trim() == "" || Person[index].firstName.trim() == "" || Person[index].phone.trim() == "" || Person[index].email.trim() == "")

                    ) {
                        //errMessage = "* Please provide Last Name, First Name, Email, Phone Number and Rate for all the talent's";
                        errMessage = "* Please provide First Name, Last Name, valid Email, Phone Number and Rate for all the talent(s)";
                        isValid = false
                    }
                    if ((Person[index].lastName && Person[index].lastName.trim() != "") && (Person[index].firstName && Person[index].firstName.trim() != "") &&
                        (Person[index].phone && Person[index].phone.trim() != "") && (Person[index].email && Person[index].email.trim() != "")) {

                        if (!props.isEditMode && props.allSkinData.filter(D => D.email == Person[index].email).length >= 1) {
                            errMessage = "* Talent(s) already exists in this shoot";
                            isValid = false

                        }

                        if (dt.chkRole) {
                            if (!dt.role || dt.role == '') {
                                msg.role = "* Mandatory";
                                isValid = false
                            }
                            else {
                                if (props.isEditMode) {
                                    Person[index].role = formData.role;
                                }
                                else {
                                    if (Person[index].role == undefined || Person[index].role.trim() == "")
                                        Person[index].role = formData.role;
                                }
                            }
                        }
                        if (dt.chkjobTitle) {
                            if (!dt.jobTitle || dt.jobTitle == '') {
                                msg.jobTitle = "* Mandatory";
                                isValid = false
                            } else {
                                Person[index].jobTitle = formData.jobTitle == "Select" ? "" : formData.jobTitle;
                            }
                        }
                        //if (!props.isEditMode || (props.isEditMode && !props.isMultiEdit)) {
                        if (!props.isEditMode) {
                            if (!dt.rate || dt.rate == '') {
                                msg.rate = "* Mandatory";
                                isValid = false
                            }
                        }
                        else {
                            //if (dt.chkRate) {
                            if ((props.isEditMode && !props.isMultiEdit))
                            {
                                if (!dt.rate || dt.rate == '') {
                                    msg.rate = "* Mandatory";
                                    isValid = false
                                }
                                else {
                                    if (Utils.validateDecimal(dt.rate) && Utils.validateNumeric(dt.demoninators.toString())) {//&& parseFloat(dt.rate) < 999999
                                        Person[index].rate = dt.rate;
                                        Person[index].demoninators = dt.demoninators;
                                    }
                                    else {
                                        msg.rate = "* Please enter valid Rate";
                                        isValid = false
                                    }
                                }
                        }
                    }
                        //}
                        //if (dt.chkRate || !props.isEditMode || (props.isEditMode && !props.isMultiEdit)) {
                        if (dt.chkRate || !props.isEditMode ) {

                            if (!dt.rate || dt.rate == '') {
                                msg.rate = "* Mandatory";
                                isValid = false
                            }
                            else {
                                if (Utils.validateDecimal(dt.rate) && Utils.validateNumeric(dt.demoninators.toString())) {//&& parseFloat(dt.rate) < 999999
                                    Person[index].rate = dt.rate;
                                    Person[index].demoninators = dt.demoninators;
                                }
                                else {
                                    msg.rate = "* Please enter valid Rate";
                                    isValid = false
                                }
                            }
                        }
                        else {
                            if (Person[index].rate != undefined && Utils.validateDecimal(Person[index].rate) && Utils.validateNumeric(Person[index].demoninators.toString())) {//&& parseFloat(Person[index].rate)< 9999
                                if (Person[index].rate == "" || Person[index].demoninators == "") {
                                    errMessage = "* Please enter valid Rate";
                                    isValid = false
                                }
                            }
                            else if (Person[index].rate != undefined || Person[index].rate == "") { //|| Person[index].demoninators != undefined || Person[index].demoninators == ""

                                errMessage = "* Please enter valid Rate";
                                isValid = false
                            }
                        }

                        if (Person[index].role == undefined || Person[index].role.trim() == "") {
                            if (dt.chkRole) {
                                if (!dt.role || dt.role == '') {
                                    //msg.role = "* Mandatory";
                                    //isValid = false
                                }
                                Person[index].role = dt.role;
                            }
                            else {
                                //isValid = false
                                //errMessage = "* Please provide Role for all the talent's";
                            }
                        }

                        if (dt.chkWPBulk) {

                            Person[index].isWardrobeRequired = dt.isWardrobeRequired ? "Yes" : "No";
                            Person[index].isPropertyRequired = dt.isPropertyRequired ? "Yes" : "No";
                        }

                        if (!props.isEditMode && (!Person[index].lastName || !Person[index].firstName || !Person[index].phone || !Person[index].email
                            || Person[index].lastName.trim() == "" || Person[index].firstName.trim() == "" || Person[index].phone.trim() == "" || Person[index].email.trim() == "")

                        ) {
                            //errMessage = "* Please provide Last Name, First Name, Email, Phone Number and Rate for all the talent's";
                            errMessage = "* Please provide First Name, Last Name, valid Email, Phone Number and Rate for all the talent(s)";
                            isValid = false
                        }

                        else if (!props.isEditMode && (Utils.validateName(Person[index].firstName) == false || Utils.validateName(Person[index].lastName) == false)) {
                            isValid = false
                            errMessage = "* Please provide only letters, numbers, spaces, hyphens, & single quotes in Firstname and Lastname";
                        }
                        else if (!props.isEditMode && (Utils.validatePhoneNumber(Person[index].phone) == false || Utils.validateEmail(Person[index].email) == false)) {
                            isValid = false
                            errMessage = "* Provided phone number or Email is not in correct format";
                        }
                        else if (Person[index].callTime != undefined && Person[index].callTime.toString().trim() != "" && !(moment(Person[index].callTime, 'h:mm A', true).isValid() || moment(Person[index].callTime, 'HH:mm', true).isValid() || moment(Person[index].callTime, 'h:mmA', true).isValid())) {
                            isValid = false
                            errMessage = "* Provided Call time is not in correct format eg. 07:00 PM or 19:00";
                        }
                        if (Person[index].jobTitle && Person[index].jobTitle != "" && !DatasourceConstants.Jobtitle.filter(J => J.title == Person[index].jobTitle).length > 0) {
                            isValid = false
                            errMessage = "* Provided Data is not valid";
                        }
                        if (Person[index].demoninators && !DatasourceConstants.demoninators.filter(J => J.title == Person[index].demoninators).length > 0) {
                            isValid = false
                            errMessage = "* Provided Data is not valid";
                        }
                        if (Person[index].rate && !Utils.validateDecimal(Person[index].rate)) {
                            isValid = false
                            errMessage = "* Provided Data is not valid";
                        }

                        if (Person[index].isWardrobeRequired && !DatasourceConstants.wardrobePropRequired.filter(J => J.title == Person[index].isWardrobeRequired).length > 0) {
                            isValid = false
                            errMessage = "* Provided Data is not valid";
                        }
                        if (Person[index].isWardrobeRequired && !DatasourceConstants.wardrobePropRequired.filter(J => J.title == Person[index].isPropertyRequired).length > 0) {
                            isValid = false
                            errMessage = "* Provided Data is not valid";
                        }
                        if (Person[index].checkRefNumber && !Utils.validateNumeric(Person[index].checkRefNumber)) {
                            isValid = false
                            errMessage = "* Provided Data is not valid";
                        }


                        if (isValid) {
                            if (dt.chkcallTime) {
                                if (formData.TBD == false && (!formData.callTime || formData.callTime.toDate().toLocaleTimeString() == '' || !moment(formData.callTime).isValid())) {
                                    msg.callTime = "* Mandatory";
                                    isValid = false
                                }
                                else if (formData.TBD == false) {
                                    //Person[index].callTime = formData.callTime;
                                    Person[index].callTime = moment(formData.callTime).format('h:mm A')
                                }
                            }
                            if (Person[index].callTime) {
                                let callisValid = moment(Person[index].callTime, 'h:mm A', true).isValid() || moment(Person[index].callTime, 'HH:mm', true).isValid() || moment(Person[index].callTime, 'h:mmA', true).isValid()
                                if (!callisValid) {
                                    isValid = callisValid;
                                    errMessage = "* Provided Data is not valid";
                                }
                            }
                        }

                        if (isValid) {

                            if (Person[index].email != "") {
                                emails.push(Person[index].email);
                                if (Person[index].checkRefNumber != undefined && Person[index].checkRefNumber.toString().trim() != "") {
                                    checkRefNumbers.push(Person[index].checkRefNumber);
                                }
                            }
                        }
                    }

                }


            }
        }


        if (isValid) {
            let duplicateEmails = getDuplicateArrayElements(emails);
            if (duplicateEmails.length > 0) {
                isValid = false
                errMessage = "* There are duplicate email address please check and update";

            }

            let duplicatecheckRefNumbers = getDuplicateArrayElements(checkRefNumbers);
            if (duplicatecheckRefNumbers.length > 0) {
                isValid = false
                errMessage = "* There are duplicate Check-in # please check and update";
            }
            if (duplicateEmails.length > 0 && duplicatecheckRefNumbers.length > 0) {
                isValid = false
                errMessage = "* There are duplicate Check-in # and email address please check and update";

            }
        }
        setshowTalentError(errMessage);

        //if (!formData.role || formData.role == '') {
        //    msg.role = "* Mandatory";
        //    isValid = false
        //}

        //if (formData.demoninators == "Select") {
        //    msg.demoninators = "* Mandatory";
        //    isValid = false
        //}
        //if (!formData.jobTitle || formData.jobTitle == '') {
        //    msg.jobTitle = "* Mandatory";
        //    isValid = false
        //}

        //if (!formData.rate || formData.rate == '') {
        //    msg.rate = "* Mandatory";
        //    isValid = false
        //}
        // if (props.isEditMode ) {

        //}
        //else {
        //    if (formData.TBD == false && (!formData.callTime || formData.callTime.toLocaleTimeString() == '')) {
        //        msg.callTime = "* Mandatory";
        //        isValid = false
        //    }

        //}
        seterrormsg(msg);
        return isValid;
    }
    const onCancel = () => {
        resetfields();
        props.actionclose();

    }
    const BuildObjData = (Person) => {
        let data = [];
        //let loopLength = Person.length - 1 == 0 ? 1 : Person.length - 1;
        let loopLength = Person.length - 1 == 0 ? 1 : Person.length;
        // props.isEditMode ? 1 : Person.length - 1
        let callTime = null;
        if (formData.TBD == false) {
            //debugger;
            //callTime = formData.callTime;//.toDate().toLocaleTimeString();
            //callTime = new Date(callTime._i.getTime() - (callTime._i.getTimezoneOffset() * 60000)).toJSON();
            callTime = moment(formData.callTime).format('h:mm A')
        }
        if (props.isMultiEdit) {
            for (let index = 0; index < Person.length; ++index) {
                if (Person[index].email && Person[index].email != "" && Person[index].email != null && Person[index].email != undefined) {
                    let obj = {
                        shootingId: parseInt(props.shootingId),
                        firstName: Person[index].firstName.trim(),
                        lastName: Person[index].lastName.trim(),
                        email: Person[index].email.trim(),
                        phone: Utils.MaskPhoneNumber(Person[index].phone.trim()),
                        //   role: formData.role.trim(),
                        role: Person[index].role ? Person[index].role.trim() : null,
                        jobTitle: Person[index].jobTitle
                            ? Person[index].jobTitle == "Select" ? "" : Person[index].jobTitle//Person[index].jobTitle.trim()
                            : null,
                        rate: Person[index].rate ? Person[index].rate + "/" + Person[index].demoninators : null,
                        //  callTime: formData.TBD ? null : callTime,
                        callTime: (Person[index].callTime == undefined || Person[index].callTime.toString().trim() == "" ? null : Person[index].callTime),
                        checkRefNumber: ((Person[index].checkRefNumber != undefined && Person[index].checkRefNumber.toString().trim() != "0") ? Person[index].checkRefNumber.toString() : "0"),

                        //checkRefNumber: formData.checkRefNumber ? formData.checkRefNumber.toString() : "0"
                        demoninators: parseInt(Person[index].demoninators),
                        isWardrobeRequired: Person[index].isWardrobeRequired == "Yes" ? true : false,
                        isPropertyRequired: Person[index].isPropertyRequired == "Yes" ? true : false,
                        wardrobe: Person[index].isWardrobeRequired == "Yes" ? 2 : 0,
                        property: Person[index].isPropertyRequired == "Yes" ? 2 : 0,
                        userID: userID,
                        userTime: new Date(),
                        unionID: props.editUnionNU ? null : (formData.unionId ? "SAG/AFTRA" : "Non-Union"),

                        carAllowance: compensationData.find(x => x.DbColumn == "carAllowance") ? parseFloat(compensationData.find(x => x.DbColumn == "carAllowance").Rate) : null,
                        auto: compensationData.find(x => x.DbColumn == "auto") ? parseFloat(compensationData.find(x => x.DbColumn == "auto").Rate) : null,
                        dressUniformCompensation: compensationData.find(x => x.DbColumn == "dressUniformCompensation") ? parseFloat(compensationData.find(x => x.DbColumn == "dressUniformCompensation").Rate) : null,

                        wardrobeRate: compensationData.find(x => x.DbColumn == "wardrobeQuantity") ? parseFloat(compensationData.find(x => x.DbColumn == "wardrobeQuantity").Rate) : null,
                        wardrobeQuantity: compensationData.find(x => x.DbColumn == "wardrobeQuantity") ? compensationData.find(x => x.DbColumn == "wardrobeQuantity").Quantity : null,

                        mileage: compensationData.find(x => x.DbColumn == "mileage") ? parseFloat(compensationData.find(x => x.DbColumn == "mileage").Rate) : null,
                        mileageMiles: compensationData.find(x => x.DbColumn == "mileage") ? compensationData.find(x => x.DbColumn == "mileage").Quantity : null,

                        fitting: compensationData.find(x => x.DbColumn == "fitting") ? parseFloat(compensationData.find(x => x.DbColumn == "fitting").Rate) : null,
                        fittingHours: compensationData.find(x => x.DbColumn == "fitting") ? compensationData.find(x => x.DbColumn == "fitting").Quantity : null,

                        interview: compensationData.find(x => x.DbColumn == "interview") ? parseFloat(compensationData.find(x => x.DbColumn == "interview").Rate) : null,
                        interviewHours: compensationData.find(x => x.DbColumn == "interview") ? compensationData.find(x => x.DbColumn == "interview").Quantity : null,


                        specialAbility: adjustmentData.find(x => x.DbColumn == "specialAbility") ? parseFloat(adjustmentData.find(x => x.DbColumn == "specialAbility").Rate) : null,
                        specialCompensationDesc: adjustmentData.find(x => x.DbColumn == "specialAbility") ? adjustmentData.find(x => x.DbColumn == "specialAbility").Description : null,

                        smokeWork: adjustmentData.find(x => x.DbColumn == "smokeWork") ? parseFloat(adjustmentData.find(x => x.DbColumn == "smokeWork").Rate) : null,
                        bodyMakeup: adjustmentData.find(x => x.DbColumn == "bodyMakeup") ? parseFloat(adjustmentData.find(x => x.DbColumn == "bodyMakeup").Rate) : null,
                        hairCompensation: adjustmentData.find(x => x.DbColumn == "hairCompensation") ? parseFloat(adjustmentData.find(x => x.DbColumn == "hairCompensation").Rate) : null,
                        wetWork: adjustmentData.find(x => x.DbColumn == "wetWork") ? parseFloat(adjustmentData.find(x => x.DbColumn == "wetWork").Rate) : null,
                        beardCompensation: adjustmentData.find(x => x.DbColumn == "beardCompensation") ? parseFloat(adjustmentData.find(x => x.DbColumn == "beardCompensation").Rate) : null,

                        other: othersData.find(x => x.DbColumn == "other") ? parseFloat(othersData.find(x => x.DbColumn == "other").Rate) : null,
                        other1: othersData.find(x => x.DbColumn == "other1") ? parseFloat(othersData.find(x => x.DbColumn == "other1").Rate) : null,
                        other2: othersData.find(x => x.DbColumn == "other2") ? parseFloat(othersData.find(x => x.DbColumn == "other2").Rate) : null,
                        otherDescription: othersData.find(x => x.DbColumn == "other") ? othersData.find(x => x.DbColumn == "other").Description : null,
                        otherDescription1: othersData.find(x => x.DbColumn == "other1") ? othersData.find(x => x.DbColumn == "other1").Description : null,
                        otherDescription2: othersData.find(x => x.DbColumn == "other2") ? othersData.find(x => x.DbColumn == "other2").Description : null,


                    }
                    data.push(obj);
                }

            }

        }
        else {
            for (let index = 0; index < loopLength; ++index) {
                if (Person[index].lastName && Person[index].lastName.trim() != "") {
                    let obj = {
                        shootingId: parseInt(props.shootingId),
                        firstName: Person[index].firstName.trim(),
                        lastName: Person[index].lastName.trim(),
                        email: Person[index].email.trim(),
                        phone: Utils.MaskPhoneNumber(Person[index].phone.trim()),
                        //   role: formData.role.trim(),
                        role: props.isEditMode
                            ? (formData.role ? formData.role.trim() : null)
                            : (Person[index].role ? Person[index].role.trim() : null),
                        jobTitle: formData.jobTitle == "Select" ? "" : formData.jobTitle,
                        //formData.jobTitle,
                        rate: formData.rate ? (formData.rate + "/" + formData.demoninators) : null,
                        //  callTime: formData.TBD ? null : callTime,
                        callTime: props.isEditMode ?
                            (formData.TBD ? null : callTime)
                            : (Person[index].callTime == undefined || Person[index].callTime.toString().trim() == "" ? null : Person[index].callTime),
                        checkRefNumber: props.isEditMode ?
                            (formData.checkRefNumber ? formData.checkRefNumber.toString() : "0")
                            : ((Person[index].checkRefNumber != undefined && Person[index].checkRefNumber.toString().trim() != "0") ? Person[index].checkRefNumber.toString() : "0"),

                        //checkRefNumber: formData.checkRefNumber ? formData.checkRefNumber.toString() : "0"
                        demoninators: parseInt(formData.demoninators),
                        isWardrobeRequired: formData.isWardrobeRequired,
                        isPropertyRequired: formData.isPropertyRequired,
                        wardrobe: formData.isWardrobeRequired ? 2 : 0,
                        property: formData.isPropertyRequired ? 2 : 0,
                        userID: userID.toString(),
                        userTime: new Date(),
                        unionID: formData.unionId ? "SAG/AFTRA" : "Non-Union",

                        carAllowance: compensationData.find(x => x.DbColumn == "carAllowance") ? parseFloat(compensationData.find(x => x.DbColumn == "carAllowance").Rate) : null,
                        auto: compensationData.find(x => x.DbColumn == "auto") ? parseFloat(compensationData.find(x => x.DbColumn == "auto").Rate) : null,
                        dressUniformCompensation: compensationData.find(x => x.DbColumn == "dressUniformCompensation") ? parseFloat(compensationData.find(x => x.DbColumn == "dressUniformCompensation").Rate) : null,

                        wardrobeRate: compensationData.find(x => x.DbColumn == "wardrobeQuantity") ? parseFloat(compensationData.find(x => x.DbColumn == "wardrobeQuantity").Rate) : null,
                        wardrobeQuantity: compensationData.find(x => x.DbColumn == "wardrobeQuantity") ? compensationData.find(x => x.DbColumn == "wardrobeQuantity").Quantity : null,

                        mileage: compensationData.find(x => x.DbColumn == "mileage") ? parseFloat(compensationData.find(x => x.DbColumn == "mileage").Rate) : null,
                        mileageMiles: compensationData.find(x => x.DbColumn == "mileage") ? compensationData.find(x => x.DbColumn == "mileage").Quantity : null,

                        fitting: compensationData.find(x => x.DbColumn == "fitting") ? parseFloat(compensationData.find(x => x.DbColumn == "fitting").Rate) : null,
                        fittingHours: compensationData.find(x => x.DbColumn == "fitting") ? compensationData.find(x => x.DbColumn == "fitting").Quantity : null,

                        interview: compensationData.find(x => x.DbColumn == "interview") ? parseFloat(compensationData.find(x => x.DbColumn == "interview").Rate) : null,
                        interviewHours: compensationData.find(x => x.DbColumn == "interview") ? compensationData.find(x => x.DbColumn == "interview").Quantity : null,


                        specialAbility: adjustmentData.find(x => x.DbColumn == "specialAbility") ? parseFloat(adjustmentData.find(x => x.DbColumn == "specialAbility").Rate) : null,
                        specialCompensationDesc: adjustmentData.find(x => x.DbColumn == "specialAbility") ? adjustmentData.find(x => x.DbColumn == "specialAbility").Description : null,

                        smokeWork: adjustmentData.find(x => x.DbColumn == "smokeWork") ? parseFloat(adjustmentData.find(x => x.DbColumn == "smokeWork").Rate) : null,
                        bodyMakeup: adjustmentData.find(x => x.DbColumn == "bodyMakeup") ? parseFloat(adjustmentData.find(x => x.DbColumn == "bodyMakeup").Rate) : null,
                        hairCompensation: adjustmentData.find(x => x.DbColumn == "hairCompensation") ? parseFloat(adjustmentData.find(x => x.DbColumn == "hairCompensation").Rate) : null,
                        wetWork: adjustmentData.find(x => x.DbColumn == "wetWork") ? parseFloat(adjustmentData.find(x => x.DbColumn == "wetWork").Rate) : null,
                        beardCompensation: adjustmentData.find(x => x.DbColumn == "beardCompensation") ? parseFloat(adjustmentData.find(x => x.DbColumn == "beardCompensation").Rate) : null,

                        other: othersData.find(x => x.DbColumn == "other") ? parseFloat(othersData.find(x => x.DbColumn == "other").Rate) : null,
                        other1: othersData.find(x => x.DbColumn == "other1") ? parseFloat(othersData.find(x => x.DbColumn == "other1").Rate) : null,
                        other2: othersData.find(x => x.DbColumn == "other2") ? parseFloat(othersData.find(x => x.DbColumn == "other2").Rate) : null,
                        otherDescription: othersData.find(x => x.DbColumn == "other") ? othersData.find(x => x.DbColumn == "other").Description : null,
                        otherDescription1: othersData.find(x => x.DbColumn == "other1") ? othersData.find(x => x.DbColumn == "other1").Description : null,
                        otherDescription2: othersData.find(x => x.DbColumn == "other2") ? othersData.find(x => x.DbColumn == "other2").Description : null,


                    }
                    data.push(obj);
                }
            }
        }

        return data;
    }
    const AddActor = () => {
        setloadingTime(true);
        let dt = formData;
        if (formData.TBD == false) {
            dt.callTime = moment(dt.callTime, 'HH:mm a', true)
        }


        setformData({ ...dt })


        if (!validateData()) {
            setloadingTime(false);
            return
        }

        else {
            let Person = dt["Person"];
            let data = [];
            let loopLength = props.isEditMode ? 1 : Person.length - 1
            let callTime = null;
            if (formData.TBD == false) {
                callTime = formData.callTime.toDate().toLocaleTimeString();

            }

            if (Person != null && Person != undefined) {


                data = BuildObjData(Person);

                ValidateCheckinNo(data);

                //if (props.isEditMode == true) {
                //    ValidateCheckinNo(data);

                //}
                //else {
                //    AddMultipleTalent(data);

                //}

            }
            else {
                toasterfun2("Please enter Talent details to add against shoot");
                setloadingTime(false);
            }
        }
    }
    const ValidateCheckinNo = (data) => {
        let filtereddata = data.filter(D => D.checkRefNumber != 0);
        //        console.log(JSON.stringify(data));

        APIPost('AdminSkinUpload/ValidateCheckinNo/', data, function (result) {
            if (result.code === 100) {
                {
                    AddMultipleTalent(data);
                }
            } else if (result.code === 409) {
                toasterfun2("Checkin# already assigned against another talent.");
                setloadingTime(false);
                return false;
            }
            else {
                setloadingTime(false);
                return false;
            }
        });

    }
    const AddMultipleTalent = (data) => {
        //  console.log(JSON.stringify(data));
        let isEditModeSingle = props.isEditMode && !props.isMultiEdit
        let reqData = {
            IsEditModeSingle: isEditModeSingle.toString(),
            SkinUploads: data
        }

        APIPost('AdminSkinUpload/AddMultipleTalent', reqData, function (result) {
            if (result.code == 200) {
                toasterfun2(props.isEditMode ? "Talent Updated" : "Talents Added");
                resetfields();
                setTimeout(
                    function () {
                        props.actionclose();
                        props.action(result.result);
                        setloadingTime(false);
                    }.bind(), 500);
            } else {
                //toasterfun2(result.message);
                //  console.log(result.message);
                setloadingTime(false);
            }
        });


    }
    const resetfields = () => {
        setformData({ demoninators: 8, callTime: null, TBD: true, Person: [] });
        setadjustmentData([])
        setcompensationData([])
        setothersData([])
        setmodalData({})
        setloadingTime(false)
    }

    return (
        <div className="modal-dialog modal-dialog-scrollable modal-xl msdiv-x">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">{props.isEditMode ? "Edit Talent" : "Add Talents"}</h5>
                </div>
                <div className="modal-body">
                    <span style={{ height: "auto", position: "fixed" }} className={"loading " + (loadingTime ? "show" : "hide")} ><span className="loading-div-d"></span> <img src={process.env.PUBLIC_URL + landing} /> </span>
                    <div style={{ position: "fixed" }} className={"toaster-div " + (toasterCla ? "show" : "hide")}><i className="fa fa-check-circle" aria-hidden="true"></i> {toaster} </div>
                    <div className="form-group">
                        {(props.isEditMode == true) ?
                            (!props.isMultiEdit)
                                ?
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label className="subtitle-div">Last Name</label>
                                                <div className="sublable-div">{props.editData.Person[0].lastName} </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label className="subtitle-div">First Name</label>
                                                <div className="sublable-div">{props.editData.Person[0].firstName} </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label className="subtitle-div">Phone #</label>
                                                <div className="sublable-div">{props.editData.Person[0].phone} </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label className="subtitle-div">Email</label>
                                                <div className="sublable-div">{props.editData.Person[0].email} </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <TextBox label="Checkin #" id="checkRefNumber" formdata={formData} error={errormsg} TextFor={Enum.Numeric} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <>
                                    <label> <strong></strong></label>
                                    <span class="pull-right redAlert"> <strong>Double click on the cell and paste details</strong></span>
                                    <div style={{ "minHeight": "300px", "border": "1px solid #cccccc" }}>
                                        <EditReactDataGridComponent id="Person" formdata={formData} setformdata={props.editData} />
                                        <div className="redAlert noPosition">{showTalentError}</div>
                                    </div>
                                </>
                            :
                            <>
                                <label> <strong>To add talent please provide talent’s Last Name, First Name, Email, Phone Number and Rate *</strong></label>
                                <span class="pull-right redAlert"> <strong>Double click on the cell and paste details</strong></span>
                                <div style={{ "minHeight": "300px", "border": "1px solid #cccccc" }}>
                                    <ReactDataGridComponent id="Person" formdata={formData} allSkinData={props.allSkinData} />
                                    <div className="redAlert noPosition">{showTalentError}</div>
                                </div>
                            </>
                        }
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-group label-highlight mb-4">
                                <CheckBox label="Role" id="chkRole" formdata={formData} error={errormsg} checked={formData.chkRole} style={{ 'display': (props.isEditMode && !props.isMultiEdit) ? "none" : "inline-block" }} />
                                <TextBox label={props.isMultiEdit ? "" : "Role"} id="role" formdata={formData} error={errormsg} TextFor={Enum.AlphaNumericWithSplChar} />
                            </div>
                            <div className="form-group label-highlight mb-4">
                                <CheckBox label="Job Title" id="chkjobTitle" formdata={formData} error={errormsg} checked={formData.chkjobTitle} style={{ 'display': props.isMultiEdit ? "inline-block" : "none" }} />
                                <Dropdown label={props.isMultiEdit ? "" : "Job Title"} id="jobTitle" source={DatasourceConstants.Jobtitle} formdata={formData} error={errormsg} />
                            </div>
                            <div className="form-group mb-4" style={{ 'display': !props.editUnionNU ? "Inline" : "none" }}>
                                <CheckBox outsideLabel="Guild" label="SAG/AFTRA" id="unionId" formdata={formData} error={errormsg} disabled={isunionDisable} checked={formData.unionId} />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="label-highlight mb-4">
                                <CheckBox label="Rate" id="chkRate" formdata={formData} error={errormsg} checked={formData.chkRate} style={{ 'display': props.isMultiEdit ? "inline-block" : "none" }} />
                                <div className="d-flex justify-content-between align-items-center">
                                    <TextBox label={props.isMultiEdit ? " " : "Rate"} outsideLabel id="rate" formdata={formData} error={errormsg} TextFor={Enum.Decimal} required={props.isMultiEdit ? false : true} />
                                    <span className='space-div mt-3 py-0'>/</span>
                                    <Dropdown label="Hours" id="demoninators" source={DatasourceConstants.demoninators} formdata={formData} error={errormsg} />
                                </div>
                            </div>
                            <div>
                                <div className="form-group label-highlight">
                                    <CheckBox label="Call Time" id="chkcallTime" formdata={formData} error={errormsg} checked={formData.chkcallTime} style={{ 'display': (props.isEditMode && !props.isMultiEdit) ? "none" : "inline-block" }} />
                                </div>
                                <div className="d-flex align-items-center mb-4">
                                    <div className="mr-3"><EMSTimePicker label={props.isMultiEdit ? "" : "Call Time"} id="callTime" formdata={formData} error={errormsg} disabled={formData.TBD} /></div>
                                    <CheckBox label="TBD" id="TBD" formdata={formData} error={errormsg} checked={formData.TBD} onChange={chkChange} />
                                </div>
                            </div>
                            <div style={{ 'display': !props.editUnionNU ? "block" : "none" }}>
                                <div className="label-highlight">
                                    <CheckBox label="Is W/P Bulk Update" id="chkWPBulk" formdata={formData} error={errormsg} checked={formData.WPBulk} style={{ 'display': props.isMultiEdit ? "inline-block" : "none" }} />
                                </div>
                                <div className="row mb-4">
                                    <div className="col-md-6">
                                        <CheckBox label="Is Wardrobe" id="isWardrobeRequired" formdata={formData} error={errormsg} checked={formData.isWardrobeRequired} />
                                    </div>
                                    <div className="col-md-6">
                                        <CheckBox label="Is Property" id="isPropertyRequired" formdata={formData} error={errormsg} checked={formData.isPropertyRequired} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md">
                            <div style={{ 'display': props.editUnionNU ? "block" : "none" }}>
                                <div className="label-highlight">
                                    <CheckBox label="Is W/P Bulk Update" id="chkWPBulk" formdata={formData} error={errormsg} checked={formData.WPBulk} style={{ 'display': props.isMultiEdit ? "inline-block" : "none" }} />
                                </div>
                                <div className="row mb-4">
                                    <div className="col-md-6">
                                        <CheckBox label="Is Wardrobe" id="isWardrobeRequired" formdata={formData} error={errormsg} checked={formData.isWardrobeRequired} />
                                    </div>
                                    <div className="col-md-6">
                                        <CheckBox label="Is Property" id="isPropertyRequired" formdata={formData} error={errormsg} checked={formData.isPropertyRequired} />
                                    </div>
                                </div>
                            </div>
                            <div style={{ 'display': !props.editUnionNU ? "block" : "none" }}>
                                <div className="form-group mb-4">
                                    <label>Adjustments [<span className="label-link" onClick={() => onOpensubmodal(Constants.ModalAdjustments)}>Add</span>]</label>
                                    <div className="compensation-div">
                                        {
                                            adjustmentData.map((item, index) =>
                                                <span className="compensation-span">
                                                    <span className="compensation-tag">{item.Text == "Special Compensation" && item.Description != "" ? item.Description : item.Text} - {item.Rate}
                                                        <i className="ml-2 fa fa-times" id={item.Value} onClick={() => removeadjustmentData(item.Value)} />
                                                    </span>
                                                </span>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            <div style={{ 'display': !props.editUnionNU ? "block" : "none" }}>
                                <div className="form-group mb-4">
                                    <label>Compensations [<span className="label-link" onClick={() => onOpensubmodal(Constants.ModalCompensations)}>Add</span>]</label>
                                    <div className="compensation-div">
                                        {
                                            compensationData.map((item, index) =>
                                                <span className="compensation-span">
                                                    <span className="compensation-tag">{(item.Text != "Fittings" && item.Text != "Interviews") ? item.Text + "-" + item.Rate : item.Text + "-" + item.Quantity}
                                                        <i className="ml-2 fa fa-times" id={item.Value} onClick={() => removecompensationData(item.Value)} />
                                                    </span>
                                                </span>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            <div style={{ 'display': !props.editUnionNU ? "block" : "none" }}>
                                <div className="form-group mb-4">
                                    <label>Other Compensations [<span className="label-link" onClick={() => onOpensubmodal(Constants.ModalOther)}>Add</span>]</label>
                                    <div className="compensation-div">
                                        {
                                            othersData.map((item, index) =>
                                                <span className="compensation-span">
                                                    <span className="compensation-tag">{item.Description} - {item.Rate}
                                                        <i className="ml-2 fa fa-times" id={item.Value} onClick={() => removeothersData(item.Value)} />
                                                    </span>
                                                </span>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-sm btn-danger" onClick={onCancel}>Cancel</button>
                    <button type="button" className="btn btn-sm btn-primary" onClick={AddActor}>{props.isEditMode ? "Update" : "Submit"}</button>
                </div>
            </div>
            <div className={"newmodal " + (openModal ? "show" : "hide")}>
                <div className="modal-backdrop show"></div>
                <div className="modal-dialog modal-xl popup-div-x modalzin-div-x">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{ModalName}</h5>
                            <span className='close-btn' onClick={onOpensubmodalClose}>X</span>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <div className="row">

                                    <div className="col-md">
                                        <div className="form-group">
                                            <label>{ModalName}</label>
                                            <Dropdown id="bumpId" source={bumpList} formdata={modalData} error={errormsg} onChange={LoadBumpRate} />
                                        </div>
                                    </div>
                                    <div className="col-md" style={{ display: (ModalName == "Other" || modalData.bumpId == 3) ? 'inline' : 'none' }}>
                                        <div className="form-group">
                                            <label>Description</label>
                                            <TextBox id="bumpdescription" formdata={modalData} error={errormsg} TextFor={Enum.AlphaNumericWithSplChar} required />
                                        </div>
                                    </div>

                                    <div className="col-md" style={{ display: (ModalName == "Compensations" && (modalData.bumpId == 13 || modalData.bumpId == 14 || modalData.bumpId == 15 || modalData.bumpId == 16)) ? 'inline' : 'none' }}>
                                        <div className="form-group">
                                            <label>Quantity</label>
                                            <TextBox id="bumpQuantity" formdata={modalData} error={errormsg} TextFor={Enum.Numeric} required />
                                        </div>
                                    </div>
                                    <div className="col-md" style={{ display: (modalData.bumpId != 13) ? 'inline' : 'none' }}>
                                        <div className="form-group">
                                            <label>Rate</label>
                                            <TextBox id="bumprate" formdata={modalData} error={errormsg} TextFor={Enum.Decimal} required />
                                        </div>
                                    </div>
                                    <div className="col-lg-1 col-md-1">
                                        <div className="form-group full-label">
                                            <label>&nbsp;</label>
                                            <span className="btn-plus1 ml-0" name="AddNew" data-tip="Create Shoot" currentitem="false" onClick={AddBump}> + </span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default AddMultipleTalent
///* <div className="input-group-prepend"><span className="input-group-text">$</span></div>*/
    //< input type = "checkbox" className = "custom-control-input" id = "sagaftra" />
    //< label className = "custom-control-label" htmlFor = "sagaftra" > SAG / AFTRA</label >
//<input type="text" className="form-control" />
//                                <TagsInput value={Adjustmenttag}  />
import React, { useState, useEffect } from 'react';
import { APIGet, APIPost } from '../EMS_Components/APICall'
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
const landing = '/css/assets/img/ems-logo.gif';

const MessageWindow = (props) => {
    const [shootingActorsMappingID, setshootingActorsMappingID] = useState(props.shootingActorsMappingID ? props.shootingActorsMappingID : 0)
    const [modalData, setmodalData] = useState([]);
    const [fieldValue, setfieldValue] = useState('');
    const [Pasted, setPasted] = useState(false);
    const [loadingTime, setloadingTime] = useState(false)

    useEffect(() => {
        //setshootingActorsMappingID(props.shootingActorsMappingID ? props.shootingActorsMappingID : 0);   
        LoadConversation(shootingActorsMappingID);
    }, [props]);

    const resetfields = () => {
       
        setfieldValue('');
        setPasted(false);
        
    }
    const LoadConversation = (shootingActorsMappingID) => {

        setmodalData(props.modelData.filter(s => s.shootingActorsMappingID == shootingActorsMappingID));
    }
    const SendMessage = () => {
        
        if (fieldValue.trim() != "") {
            setloadingTime(true);
            let currentComponent = this;
            let data = [];
            let obj = {
                shootAcotrsMapingId: shootingActorsMappingID,
                comments: fieldValue,
                isDeleted: 0,
                isRead: 0,
                recipientGroup: "CDAD",
                createdBy: '', //localStorage.getItem('CDCurrentUserId'),
                createdOnDateTime: new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toJSON()
            }

            data.push(obj);
            APIPost('AdminSkinUpload/SendMessage', data, function (result) {
                if (result.code === 100) {
                    resetfields();
                    props.refresh();
                    setloadingTime(false);
                }
            });
        }
    }
    const handlePasted = (e) => {
        setPasted(true);
    }
    const handleChange = (e) => {        
        
        setfieldValue(e.target.value);
        if (Pasted) {
            setfieldValue(e.target.value);
        }
    }
    //<span style={{ height: "auto" }} className={"loading " + (loadingTime ? "show" : "hide")} ><span className="loading-div-d"></span> <img src={process.env.PUBLIC_URL + landing} /> </span>
    return (
        <div>
            <div className="msg-div-x">
                {modalData.map(modalData =>
                    <div className={modalData.createdBy == localStorage.getItem('CDuserfullName') ? "msg-rightside" : "msg-leftside"}>


                        <span style={{ "font-weight": "bold" }} className={modalData.createdBy == localStorage.getItem('CDuserfullName') ? "msg-rgtname" : "msg-letname"}>
                            {(modalData.messagedByInitial)
                                + " " +
                                (moment(modalData.createdOnDateTime).isValid() ? moment(modalData.createdOnDateTime).format('MM/DD/YYYY hh:mm A') : '')}
                        </span>
                        <span className={modalData.createdBy == localStorage.getItem('CDuserfullName') ? "msg-rgtmssage" : "msg-letmssage"}>
                            {(modalData.comments)}
                        </span>


                    </div>
                )}

            </div>
            <div className="d-flex align-items-center">
                <textarea className="form-control" placeholder="Enter Message here.." onChange={handleChange} value={fieldValue} onPaste={handlePasted}></textarea>
                <button type="button" className="btn btn-action ml-2 mr-0" onClick={() => SendMessage()} style={{ 'display': loadingTime?'none' :'inline'}}>
                    <i className="fa fa-paper-plane" style={{"color": "#85d9bf"}} aria-hidden="true"></i>
                </button>
            </div>
        </div>
    );
}
export default MessageWindow
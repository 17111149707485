import React, { useState, useEffect } from "react";
import { Utils } from '../EMS_Components'

const Mask = (props) => {
    const [error, seterror] = useState(props.error ? props.error : {})
    const [fieldValue, setfieldValue] = useState(props.formdata[props.id] ? props.formdata[props.id] : "")

    useEffect(() => {
        seterror(props.error ? props.error : {});
        setfieldValue(props.formdata[props.id] ? props.formdata[props.id] : "");
        let data = props.formdata[props.id] ? props.formdata[props.id] : "".replace(/[^0-9]/gi, ''); //replaceAll("(", "").replaceAll(")", "").replaceAll("-", "");
        if (props.maxLength == data.length && props.Mask) {
            let formatstr = formatMask(props.Mask, data)
            setfieldValue(formatstr)
            props.formdata[props.id] = formatstr
        }
    }, [props])

    const formatMask = (mask, number) => {
        var s = '' + number, r = '';
        for (var im = 0, is = 0; im < mask.length && is < s.length; im++) {
            r += mask[im] == 'X' ? s.charAt(is++) : mask.charAt(im);
        }
        return r;
    }

    const handleChange = (e) => {
        //let data = e.target.value.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "");
        let data = e.target.value.replace(/[^0-9]/gi, '');

        if (props.Numeric) {
            if (Utils.validateNumeric(data) == true) {
                setfieldValue(data);
                props.formdata[props.id] = data
            }
        }
        else {
            setfieldValue(data);
            props.formdata[props.id] = data
        }

        if (props.maxLength == data.length && props.Mask) {
            let formatstr = formatMask(props.Mask, data)
            setfieldValue(formatstr)
            props.formdata[props.id] = formatstr
        }
    };

    return (
        <div className="form-group">
            {props.label ? <label>{props.label} {props.required ? <span className="redAlert">*</span> : <></>}</label> : <></>}       
            <input type="text" className={"form-control " + (props.className ? props.className : "")} name={props.id} id={props.id} value={fieldValue}
                onChange={handleChange}
                disabled={props.disabled ? true : false} maxLength={props.maxLength ? props.maxLength : 524288} ref={props.refValue ? props.refValue : null}/>
            <div className="redAlert noPosition">{error[props.id]}</div>
        </div>
    );
}

export default Mask
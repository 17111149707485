// Tested with react-data-grid v5.0.4, earlier versions MAY NOT HAVE cellRangeSelection
// And it won't show any errors if you try this with an earlier version, so use at least v5.0.4
import React, { Component } from 'react';
import { range } from 'lodash';
import ReactDataGrid from 'react-data-grid'; // Tested with v5.0.4, earlier versions MAY NOT HAVE cellRangeSelection
import { Utils } from '../EMS_Components'
import moment from 'moment';
//import 'bootstrap/dist/css/bootstrap.css';

//const defaultColumnProperties = {


//};

const PhoneNumber = ({ value }) => {

    let isPhone = Utils.validatePhoneNumber(value);
    if (isPhone)
        value = Utils.MaskPhoneNumber(value);

    return <span className={isPhone ? "" : "text-danger"}>{value}</span>

};

const Email = ({ value }) => {
    
    let isEmail = Utils.validateEmail(value); //&& (props.allSkinData!=undefined && props.allSkinData.filter(D => D.email == value).length == 0)
    value = value.trim();
    return <span className={isEmail ? "" : "text-danger"}>{value}</span>
}
const Time = ({ value }) => {    
    //value = Utils.MaskTime(value);
    
    let isTime = true;
    isTime = moment(value, 'h:mm A').isValid() || moment(value, 'HH:mm').isValid();
    return <span className={isTime ? "" : "text-danger"}>{value}</span>

};
const checkRefNumber = ({ value }) => {
    let isNumber = Utils.validateNumeric(value);
    return <span className={isNumber ? "" : "text-danger"}>{value}</span>
}


const columns = [
    {
        key: 'checkRefNumber',
        name: '#',
        editable: true,
        formatter: checkRefNumber
    },
    {
        key: 'callTime',
        name: 'Call Time',
        editable: true,
        formatter: Time
    },
    {
        key: 'lastName',
        name: 'Lastname', editable: true

    },
    {
        key: 'firstName',
        name: 'Firstname',
        editable: true
    },
    {
        key: 'phone',
        name: 'Phone',
        editable: true,
        formatter: PhoneNumber
    },
    {
        key: 'email',
        name: 'Email',
        editable: true,
        formatter: Email
    },
    {
        key: 'role',
        name: 'Role',
        editable: true
    },
    
    //,
    //{
    //    key: "action",
    //    name: "Action"
    //}

]
//.map(c => ({ ...c, ...defaultColumnProperties }));


//const initialRows = Array.from(Array(1).keys(), (_, x) => (
//    //{ id: x, title: x * 2, count: x * 3, sarah: x * 4, jessica: x * 5 }
//    { lastName: "", firstName: "", phone: "", email: "" }
//));
const initialRows = [
    { lastName: "", firstName: "", phone: "", email: "" }
];

const defaultParsePaste = str => (
    str.split(/\r\n|\n|\r/)
        .map(row => row.split('\t'))
);


export class ReactDataGridComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rows: initialRows,
            topLeft: {},
            botRight: {},
        };

        // Copy paste event handler
        //document.addEventListener('copy', this.handleCopy);
        //document.addEventListener('paste', this.handlePaste);

        
    }
    componentDidMount() {
        document.getElementById("divReactDataGrid").addEventListener('paste', this.handlePaste);
    }
    componentWillUnmount() {
        this.removeAllListeners();
    }

    componentWillReceiveProps() {
        let Person = this.props.formdata.Person;
        if (!Person || Person.length == 0)
            this.setState({ rows: initialRows })
    }

    removeAllListeners = () => {
        //document.removeEventListener('copy', this.handleCopy);
        //document.removeEventListener('paste', this.handlePaste);
    }

    rowGetter = (i) => {
        const { rows } = this.state;
        return rows[i];
    }

    updateRows = (startIdx, newRows) => {
               
       
        this.setState((state) => {
            
            const rows = state.rows.slice();
            if (startIdx < rows.length - 1 && newRows[newRows.length - 1].lastName == "") { newRows.pop() } 
            for (let i = 0; i < newRows.length; i++) {
              
                
                if (startIdx + i < rows.length) {
                    rows[startIdx + i] = { ...rows[startIdx + i], ...newRows[i] };
                }
                else {
                    rows.push(newRows[i]);
                }
            }
            this.props.formdata[this.props.id] = rows;
            return { rows };
        });

    }

    handleCopy = (e) => {
        console.debug('handleCopy Called');
        e.preventDefault();
        const { topLeft, botRight } = this.state;

        // Loop through each row
        const text = range(topLeft.rowIdx, botRight.rowIdx + 1).map(
            // Loop through each column
            rowIdx => columns.slice(topLeft.colIdx, botRight.colIdx + 1).map(
                // Grab the row values and make a text string
                col => this.rowGetter(rowIdx)[col.key],
            ).join('\t'),
        ).join('\n');
        console.debug('text', text);
        e.clipboardData.setData('text/plain', text);
    }

    handlePaste = (e) => {
        
        console.debug('handlePaste Called');
        e.preventDefault();
        const { topLeft } = this.state;

        const newRows = [];
        const pasteData = defaultParsePaste(e.clipboardData.getData('text/plain'));
       
        console.debug('pasteData', pasteData);
        
        pasteData.forEach((row) => {
            const rowData = {};
            // Merge the values from pasting and the keys from the columns
            columns.slice(topLeft.colIdx, topLeft.colIdx + row.length)
                .forEach((col, j) => {
                    // Create the key-value pair for the row
                    rowData[col.key] = row[j];
                });
            // Push the new row to the changes
            newRows.push(rowData);
        });

        console.debug('newRows', newRows);

        this.updateRows(topLeft.rowIdx, newRows);
    }
    getCellActions = (column, row) => {

        const cellActions = [
            {
                icon: <span style={{ "border-left": "1px solid #eee" }}><i class="fa fa-trash text-danger" aria-hidden="true" style={{ 'display': this.state.rows.indexOf(row) != this.state.rows.length - 1 ? 'inline' : 'none' }}></i></span>,
                callback: () => {
                    if (this.state.rows.indexOf(row) != this.state.rows.length - 1) {
                        const rows = [...this.state.rows];
                        rows.splice(this.state.rows.indexOf(row), 1); //
                        this.props.formdata[this.props.id] = rows;
                        this.setState({ rows: rows });
                        //alert("Deleting:" + row.firstname + ":" + this.state.rows.indexOf(row));
                    }
                }
            }
        ];
        return column.key === "role" ? cellActions : null;
    };

    onGridRowsUpdated = ({ fromRow, toRow, updated, action }) => {
        
        console.log('onGridRowsUpdated!', action);
        console.log('updated', updated);
        if (action !== 'COPY_PASTE') {
            this.setState((state) => {
                const rows = state.rows.slice();
                
                if (rows.length == toRow + 1) {
                    //for (let i = 0; i < columns.length; i++) {
                    if (rows[fromRow][0] != "" && rows.length != fromRow) {
                        rows.push(initialRows);
                    }
                    // }

                }
                for (let i = fromRow; i <= toRow; i++) {
                    rows[i] = { ...rows[i], ...updated };
                }
                this.props.formdata[this.props.id] = rows;
                return { rows };
            });

            //const rows = this.state.rows;
            ////for (let i = 0; i < columns.length; i++) {
            //    if (rows[rows.length - 1][columns[0]["key"]] != "") {

            //        rows.push(initialRows);
            //    }
            ////}

        }
    };

    setSelection = (args) => {

        this.setState({
            topLeft: {
                rowIdx: args.topLeft.rowIdx,
                colIdx: args.topLeft.idx,
            },
            botRight: {
                rowIdx: args.bottomRight.rowIdx,
                colIdx: args.bottomRight.idx,
            },
        });
    };

    render() {
        const { rows } = this.state;

        return (
            <div onPaste={this.handlePaste} id="divReactDataGrid" >
                <ReactDataGrid onPaste={this.handlePaste} 
                    columns={columns}
                    rowGetter={i => rows[i]}
                    rowsCount={rows.length}
                    onGridRowsUpdated={this.onGridRowsUpdated}
                    enableCellSelect
                    minColumnWidth={166}
                    onCellSelected={s => this.setSelection({ topLeft: s, bottomRight: s })}
                    getCellActions={this.getCellActions}
                />



            </div>

        );
    }
}



//cellRangeSelection = {{
//    onComplete: this.setSelection,
//                    }}

import React, { useState, useEffect } from "react";
import { Enum, Utils, Constants } from '../EMS_Components'
import { APIGet } from './APICall'

const Dropdown = (props) => {
    const [error, seterror] = useState(props.error ? props.error : {})
    const [fieldValue, setfieldValue] = useState(props.formdata[props.id] != undefined ? props.formdata[props.id] : "")
   //const [selValue, setselValue] = useState(props.selected ? props.selected : null)

    useEffect(() => {
        seterror(props.error ? props.error : {});
        setfieldValue(props.formdata[props.id] != undefined  ? props.formdata[props.id] : "");
        //setselValue(props.selected ? props.selected : null)
    }, [props])

    const fetchData = (val) => {
        let url = props.fetchFrom + '/' + val
        APIGet(url, function (result) {
            props.fetchTarget(result)
        })
    }

    const handleChange = (e) => {
        seterror({});
        let data = e.target.value;
        props.formdata[props.id] = data   
        setfieldValue(data);
        //if (props.fetchFrom)
        //    fetchData(data)
        if (props.onChange)
            props.onChange(data)
    };
       
    return (
        <div className="form-group">
            {props.label ? <label>{props.label} {props.required ? <span className="redAlert">*</span> : <></>}</label> : <></>}
            <select className={"form-control " + (props.className ? props.className : "")} name={props.id} id={props.id} onChange={handleChange} disabled={props.disabled ? true : false} value={fieldValue}>
                <option>Select</option>
                {
                    props.source.map((item, idx) => {
                        return (
                            <option value={item.Value} >{item.Text}</option>
                        )
                    })
                }
            </select>
            <div className="redAlert noPosition">{error[props.id]}</div>
        </div>
    );
}

export default Dropdown
import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import $, { get } from 'jquery';
import 'react-tabs/style/react-tabs.css';// 'react-tabs/style/react-tabs.css';
import { Layout } from '../Layout';
import MaterialTable from "material-table";
import { DatePickerInput } from 'rc-datepicker';
import { Dropdown, Breadcrumb, EMSDatePicker } from '../EMS_Components'
import { APIGet, APIPost } from '../EMS_Components/APICall'
import * as FileSaver from 'file-saver';
import { Row } from 'react-data-grid';

const DownloadForms = (props) => {

    const [toaster, settoaster] = useState('')
    const [toasterCla, settoasterCla] = useState(false)
    const [isDownLoading, setisDownLoading] = useState(false)

    const [formData, setformData] = useState({})
    const [errormsg, seterrormsg] = useState({})
    const [projects, setprojects] = useState([])
    const [shootings, setshootings] = useState([])
    const [talents, settalents] = useState([])
    const [talentForms, settalentForms] = useState([])
    const [shootForms, setshootForms] = useState([])
    const [selectedTalentForm, setselectedTalentForm] = useState({})

    const [isModalOpen, setisModalOpen] = useState(false)
    const [selectedTalents, setselectedTalents] = useState([])
    const [removeAlert, setremoveAlert] = useState(false)
    const [retriveAlert, setretriveAlert] = useState(false)
    const [isEditMode, setisEditMode] = useState(false)
    const [isLoggedIn, setisLoggedIn] = useState(false)
    const [datePickerInputDate, setdatePickerInputDate] = useState(null)

    const numericRegex = new RegExp(/^[0-9]*$/)
    const alphabetsRegex = new RegExp(/^[a-zA-Z]*$/)
    const alphanumericRegex = new RegExp(/^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':\"\\|,.<>\/?]*$/)
    const defaultCountry = ["united states of america", "usa", "us"]

    const emailInput = useRef(null);

    useEffect(() => {
        CheckLogin()
        getProjects()
        getOBForms()
    }, []);

    const CheckLogin = () => {
        let currentComponent = this;
        APIGet('AdminSkinUpload/CheckLogin', function (result) {
            if (result.code === 415) {
                setisLoggedIn(false)
            }
            else {
                setisLoggedIn(true)
            }
        });

    }

    const getProjects = () => {
        APIGet('SkinUpload/getProjects', function (result) {
            if (result.code == 100) {
                let ddlData = [];
                result.result.map((proj, index) => {
                    ddlData.push({
                        Value: proj.id,
                        Text: proj.name
                    })
                });
                setprojects(ddlData)
            }
        });
    }

    const getOBForms = () => {
        APIGet('SkinUpload/getOBForms', function (result) {
            if (result.code == 100) {
                let ddlData = [];
                result.result.map((proj, index) => {
                    ddlData.push({
                        Value: proj.value,
                        Text: proj.text
                    })
                });
                setshootForms(ddlData)
            }
        });
    }

    const getShootings = (projId) => {
        if (projId) {
            APIGet('SkinUpload/getShootings/' + projId, function (result) {
                if (result.code == 100) {
                    let ddlData = [];
                    result.result.map((shoot, index) => {
                        ddlData.push({
                            Value: shoot.value,
                            Text: shoot.text
                        })
                    });
                    setshootings(ddlData);
                }
            });
        }
    }

    const getTalents = (shootId) => {
        if (shootId) {
            APIGet('SkinUpload/getTalents/' + shootId, function (result) {
                if (result.code == 100) {
                    let ddlData = [];
                    let obForms = [];
                    result.result.map((talent, index) => {
                        ddlData.push({
                            Value: talent.id,
                            Text: talent.firstName + ' ' + talent.lastName + ' - ' + talent.email
                        })

                        let forms = [];
                        result.commonResult.filter(x => x.personId == talent.id).map((form, i) => {
                            forms.push({
                                Value: form.obFormId,
                                Text: form.formType
                            })
                        })

                        //let str = '"0" : "Select",';
                        //forms.map((item, i) => {
                        //    str += '"' + item.obFormId + '":"' + item.formType + '",';
                        //});
                        //str = JSON.parse('{' + str.replace(/,\s*$/, "") + '}');

                        obForms.push({
                            Id: talent.id,
                            FirstName: talent.firstName,
                            LastName: talent.lastName,
                            Email: talent.email,
                            Phone: talent.phone,
                            OBForms: forms
                        })
                    });

                    //let unique = [];
                    //result.commonResult.map(x => unique.filter(a => a.formTypeId == x.formTypeId && a.formType == x.formType).length > 0 ? null : unique.push(x));
                    //let shforms = []
                    //unique.map((form, i) => {
                    //    shforms.push({
                    //        Value: form.formTypeId,
                    //        Text: form.formType
                    //    })
                    //})

                    //setshootForms(shforms);
                    settalents(ddlData);
                    settalentForms(obForms);
                }
            });
        }
    }

    const DownloadShootForm = () => {
        let currentComponent = this;

        if (selectedTalents.length > 10) {
            toasterfun2("You can select maximum 10 Talents");
            return
        }

        let data = {
            Talents: selectedTalents,
            FormType: formData.shootforms
        };
        APIPost('SkinUpload/DownloadShootForms/', data, function (result) {
            if (result.code == 100) {
                var byteCharacters = atob(result.result);
                var byteNumbers = new Array(byteCharacters.length);
                for (var i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                var byteArray = new Uint8Array(byteNumbers);

                var blob = new Blob([byteArray], { type: "application/pdf;charset=utf-8" });
                FileSaver.saveAs(blob, result.fileName);

                currentComponent.toasterfun2("Successfully Downloaded.");
            } else {
                console.log(result.message);
                currentComponent.toasterfun2(result.message);
            }
        });
    }

    const DownloadTalentForm = (personId) => {
        let currentComponent = this;


        let selForm = selectedTalentForm["forms_" + personId]

        if (personId && selForm && personId != 0 && selForm != 0) {
            var data = {
                PersonId: personId,
                OBFormId: selForm
            }

            setisDownLoading(true);
            APIPost('SkinUpload/DownloadTalentForm/', data, function (result) {
                if (result.code == 100) {
                    var byteCharacters = atob(result.result);
                    var byteNumbers = new Array(byteCharacters.length);
                    for (var i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    var byteArray = new Uint8Array(byteNumbers);

                    var blob = new Blob([byteArray], { type: "application/pdf;charset=utf-8" });
                    FileSaver.saveAs(blob, result.fileName);

                    setisDownLoading(false);
                    currentComponent.toasterfun2("Successfully Downloaded.");
                } else {
                    setisDownLoading(false);
                    currentComponent.toasterfun2(result.message);
                }
            });

        } else {
            toasterfun2("Please select a form to download.");
            return
        }
    }

    const DownloadTalentAllForms = (personId, obForms) => {
        let currentComponent = this;

        if (personId && obForms && personId != 0) {
            var data = {
                PersonId: personId,
                OBForms: obForms
            }
            setisDownLoading(true);
            APIPost('SkinUpload/DownloadTalentAllForms/', data, function (result) {
                if (result.code == 100) {
                    var byteCharacters = atob(result.result);
                    var byteNumbers = new Array(byteCharacters.length);
                    for (var i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    var byteArray = new Uint8Array(byteNumbers);

                    var blob = new Blob([byteArray], { type: "application/pdf;charset=utf-8" });
                    FileSaver.saveAs(blob, result.fileName);

                    setisDownLoading(false);
                    currentComponent.toasterfun2("Successfully Downloaded.");
                } else {
                    setisDownLoading(false);
                    currentComponent.toasterfun2(result.message);
                }
            });

        } else {
            toasterfun2("Please select a form to download.");
            return
        }
    }

    const selectTalents = (rows, e) => {
        if (rows.length > 10) {
            alert('You can select maximum 10 Talents');
        }
        setselectedTalents(rows);
    }

    const toasterfun2 = (e) => {
        settoasterCla(true);
        settoaster(e);
        setTimeout(
            function () {
                settoasterCla(false);
            }.bind(this), 4000);
    }

    const searchForms = () => {
        let currentComponent = this;

        if (!formData.project || formData.project == 0 || formData.project == "Select") {
            toasterfun2("Please select the Project");
            return
        }

        //if (!formData.shootforms || formData.shootforms == 0|| formData.shootforms == "Select") {
        //    toasterfun2("Please select Shoot Form");
        //    return
        //}

        if (!formData.FromDate || !formData.ToDate) {
            toasterfun2("Please select the Date Range");
            return
        }

        let data = {
            ProjectId: formData.project,
            FormTypeId: formData.shootforms,
            FromDate: formData.FromDate,
            ToDate: formData.ToDate
        };

        APIPost('SkinUpload/getTalents', data, function (result) {
            if (result.code == 100) {
                let ddlData = [];
                let obForms = [];
                result.result.map((talent, index) => {
                    //ddlData.push({
                    //    Value: talent.id,
                    //    Text: talent.firstName + ' ' + talent.lastName + ' - ' + talent.email
                    //})

                    let forms = [];
                    result.commonResult.filter(x => x.personId == talent.id).map((form, i) => {
                        forms.push({
                            Value: form.obFormId,
                            Text: form.formType
                        })
                    })

                    //let str = '"0" : "Select",';
                    //forms.map((item, i) => {
                    //    str += '"' + item.obFormId + '":"' + item.formType + '",';
                    //});
                    //str = JSON.parse('{' + str.replace(/,\s*$/, "") + '}');

                    obForms.push({
                        Id: talent.id,
                        FirstName: talent.firstName,
                        LastName: talent.lastName,
                        Email: talent.email,
                        Phone: talent.phone,
                        OBForms: forms
                    })
                });

                //settalents(ddlData);
                settalentForms(obForms);
            }
        });

    }

    return (
        <>
            {
                isLoggedIn ?
                    <Layout>
                        <div className={"toaster-div " + (toasterCla ? "show" : "hide")}><i className="fa fa-check-circle" aria-hidden="true"></i> {toaster} </div>
                        <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                            <div className="kt-portlet kt-portlet--mobile mb-0">
                                <Breadcrumb paths={['Home', 'DownLoad Shoot Forms']} />
                                <div className="kt-portlet__body">
                                    <div className="kt-form kt-form--label-right">
                                        <div className="row">
                                            <div className="col-md-4 col-sm-4 col-4">
                                                <Dropdown label="Projects" id="project" source={projects} formdata={formData} error={errormsg} onChange={getShootings} disabled={isDownLoading ? true : false} />
                                            </div>
                                            <div className="col-md-3 col-sm-3 col-3">
                                                <Dropdown label="Shoot Forms" id="shootforms" source={shootForms} formdata={formData} error={errormsg} />
                                                {/* <Dropdown label="Shooting" id="shooting" source={shootings} formdata={formData} error={errormsg} onChange={getTalents} className={isDownLoading ? "downloadingForm" : ""} disabled={isDownLoading ? true : false} /> */}
                                            </div>
                                            <div className="col-md-5 col-sm-5 col-5">
                                                {/* <Dropdown label="Talent" id="talent" source={talents} formdata={formData} error={errormsg} /> */}
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <EMSDatePicker id="FromDate" label="From Date" formdata={formData} />
                                                    </div>
                                                    <div className="col-md-4">
                                                        <EMSDatePicker id="ToDate" label="To Date" formdata={formData} />
                                                    </div>
                                                    <div className="col-md-2">
                                                        <button type="button" className="btn btn-sm btn-primary mt-4" onClick={searchForms}>
                                                            Search
                                                        </button>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <button type="button" className="btn btn-sm btn-primary mt-4" onClick={DownloadShootForm}>
                                                            <i class="fa fa-download" aria-hidden="true"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12">
                                            <MaterialTable
                                                title=""
                                                columns={[
                                                    { title: "First Name", field: "FirstName", editable: "never" },
                                                    { title: "Last Name", field: "LastName", editable: "never" },
                                                    { title: "Email", field: "Email", editable: "never" },
                                                    { title: "Phone #", field: "Phone", editable: "never" },
                                                    {
                                                        title: "Forms", editable: "never", render: rowData => <Dropdown id={"forms_" + rowData.Id} source={rowData.OBForms} formdata={selectedTalentForm} error={{}} />
                                                    },
                                                    {
                                                        title: "Action", width: "30%", editable: "never", render: rowData =>
                                                            <>
                                                                {rowData.OBForms.length > 0 ?
                                                                    <>
                                                                        <button type="button" className="btn btn-sm btn-primary mr-3" onClick={() => DownloadTalentForm(rowData.Id)} disabled={isDownLoading ? true : false} >
                                                                            {isDownLoading ? <i class="fas fa-spinner fa-pulse"></i> : <i class="fa fa-download" aria-hidden="true"></i>}
                                                                            Selected Form
                                                                        </button>
                                                                        <button type="button" className="btn btn-sm btn-primary" onClick={() => DownloadTalentAllForms(rowData.Id, rowData.OBForms)} disabled={isDownLoading ? true : false}>
                                                                            {isDownLoading ? <i class="fas fa-spinner fa-pulse"></i> : <i class="fa fa-download" aria-hidden="true"></i>}
                                                                            All Forms
                                                                        </button>
                                                                    </>
                                                                    :
                                                                    <span className="redAlert">No completed Forms</span>
                                                                }
                                                            </>
                                                    },
                                                ]}
                                                data={talentForms}
                                                className="mat-paginator-sticky"
                                                title=""
                                                cellEditable={{
                                                    onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                                                        return new Promise((resolve, reject) => {
                                                            this.updateDetail(resolve, oldValue, newValue, rowData, columnDef);
                                                        });
                                                    }
                                                }}
                                                options={{
                                                    pageSizeOptions: [5, 10, 15],
                                                    pageSize: 10,
                                                    selection: true,
                                                    selectionProps: rowData => ({
                                                        disabled: rowData.OBForms.length <= 0,
                                                        color: 'primary'
                                                    })
                                                }}
                                                onSelectionChange={(rows, e) =>
                                                    selectTalents(rows, e)
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Layout>
                    :
                    <></>
            }

        </>
    )
}

export default DownloadForms


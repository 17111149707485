import React from 'react';
import $ from 'jquery';
import {onlyDigits} from '../Validation'
import { APIGet, APIPost } from '../EMS_Components/APICall'

export class Bump extends React.Component {
    constructor(props) {
        super(props);
        this.LoadBump();
        this.LoadShootingBumpRate(this.props.dataFromParent);


        this.state = {
            selectedOption: null,
            rows: [{}],
            BumpList: [],
            textbox: false,
            countries: [],
            addInfo: [],
            ShootingID: this.props.dataFromParent,
             toasterCla: false,
            toaster: '',
            removeAlert: false,
            removehidden: '',

            //BumpList: [
            //    { id: "carAllowance", name: "Car Allowance" },
            //    { id: "auto", name: "Auto" },
            //    { id: "specialAbility", name: "Special compensation" },
            //    { id: "smokeWork", name: "Smoke Work" },
            //    { id: "bodyMakeup", name: "Makeup" },
            //    { id: "hairCompensation", name: "Hair Cut" },
            //    { id: "wetWork", name: "Wet Work" },
            //    { id: "beardCompensation", name: "Beard" },
            //    { id: "dressUniformCompensation", name: "Uniforms" },
            //    //{ id: "other", name: "Other" },
            //    //{ id: "otherDescription", name: "Other Description" },
            //    //{ id: "mileageMiles", name: "Mileage" },
            //    //{ id: "wardrobeQuantity", name: "Wardrobe" },
            //    //{ id: "interviewHours", name: "Interview" },
            //    //{ id: "fittingHours", name: "Fitting" },

            //]

        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDocRemove = this.handleDocRemove.bind(this);
        this.handleremoveCancelbttn = this.handleremoveCancelbttn.bind(this);
        this.handleRemoveSpecificRow = this.handleRemoveSpecificRow.bind(this);
        

    }
   
    //Toaster alert start
    toasterfun2(e) {
        this.setState({ toasterCla: true });
        this.setState({ toaster: e });
        setTimeout(
            function () {
                this.setState({ toasterCla: false });
            }.bind(this), 4000);
    }
    //Toaster alert event

    //Event start
    handleSubmit = event => {
        event.preventDefault();
        let currentComponent = this;
        //let userfullName = localStorage.getItem('userfullName');
        let userfullName = ''; //localStorage.getItem('CDCurrentUserId');
        const rows = [...this.state.rows]

        for (var i = 1; i < rows.length; i++) {
            
            rows[i].bumpId = parseInt(rows[i].bumpId);
            rows[i].bumpName = rows[i].bumpName;
            rows[i].addl_Info = rows[i].addl_Info;
            rows[i].unionRate = isNaN(rows[i].unionRate) || rows[i].unionRate == null || rows[i].unionRate=="" ?0: parseFloat(rows[i].unionRate);
            rows[i].nonUnionRate = isNaN(rows[i].nonUnionRate) || rows[i].nonUnionRate == null || rows[i].nonUnionRate == "" ? 0 :parseFloat(rows[i].nonUnionRate);

               
            
        };
        var DataBump = rows;
        let data = {
            ShootingBumpRateModel: DataBump,
            ShootingId : this.state.ShootingID
        }
        APIPost('SkinUpload/ConvertModelToShootingBumpRateModel', data, function (result) {
            if (result.code == 200) {
                var DataShootingBumpRate = JSON.stringify(result.result);
                currentComponent.InsertShootingBumpRate(DataShootingBumpRate, userfullName);
            } else {
                currentComponent.toasterfun2(result.message);
            }
        });

        
    }
    handleChange = idx => e => {

        const { name, value } = e.target;
        let currentComponent = this;
       
        if (name == "bumpId") {
            var index = e.target.selectedIndex;
            var optionElement = e.target.childNodes[index];
            var option = optionElement.getAttribute('data-id');
            var SelValue = optionElement.getAttribute('value');
            const rows = [...currentComponent.state.rows];

            if (rows.filter(row => row.bumpId === parseInt(SelValue)).length == 0) {
                let data = {
                    BumpID: SelValue
                }
                APIPost('SkinUpload/GetAddlInfo', data, function (result) {
                    if (result.code === 200) {

                        rows[idx] = {
                            [name]: parseInt(value),
                            ["addl_Info"]: result.result[0].addl_Info,
                            ["unionRate"]: parseFloat(result.result[0].unionRate),
                            ["nonUnionRate"]: parseFloat(result.result[0].nonUnionRate),
                        };


                    }
                    else if (result.code === 202) {
                        rows[idx] = {
                            [name]: parseInt(value),
                            ["addl_Info"]: "",
                            ["unionRate"]: parseFloat(0),
                            ["nonUnionRate"]: parseFloat(0)
                        };
                    }
                    currentComponent.setState({
                        rows
                    });
                });
            }
            else {
                e.target.selectedIndex = 0;
                currentComponent.toasterfun2(option + " , This Compensation has been already assigned");}
        }
        else {
            const rows = [...currentComponent.state.rows];

            if (name == "unionRate") {
                rows[idx] = {
                    ["bumpId"]: parseInt(rows[idx]["bumpId"]),
                    ["bumpName"]: rows[idx]["bumpName"],
                    ["addl_Info"]: rows[idx]["addl_Info"],
                    [name]: isNaN(value) || value == null  ? 0 : value,
                    ["nonUnionRate"]: isNaN(parseFloat(rows[idx]["nonUnionRate"])) || rows[idx]["nonUnionRate"] == null || rows[idx]["nonUnionRate"]=="" ?0 :parseFloat(rows[idx]["nonUnionRate"])
                };
            }
            else if (name == "nonUnionRate") {
                rows[idx] = {
                    ["bumpName"]: rows[idx]["bumpName"],
                    ["bumpId"]: parseInt(rows[idx]["bumpId"]),
                    ["addl_Info"]: rows[idx]["addl_Info"],
                    ["unionRate"]: isNaN(parseFloat(rows[idx]["unionRate"])) || parseFloat(rows[idx]["unionRate"]) == null || rows[idx]["unionRate"] == "" ? 0 : parseFloat(rows[idx]["unionRate"]),
                    [name]: isNaN(value) || value == null  ? 0 : value,
                };

            }
            currentComponent.setState({
                rows
            });
        }


    };
    handleAddRow = () => {
        const item = {
            bumpId: 0,
            unionRate: 0.00,
            nonUnionRate: 0.00,
            addl_Info: "",
            bumpName: ""
        };
        $('.nodata').removeClass('show-div');

        var count = parseInt(this.state.rows.length) + 1;

        this.setState({
            rows: [...this.state.rows, item]
        });
        setTimeout(function () { $('#customers tbody tr:nth-child(' + count + ') td select').addClass("show"); }, 300);

    };
    handleRemoveRow = () => {
        this.setState({
            rows: this.state.rows.slice(0, -1)
        });
    };
    handleDocRemove(e) {
        this.setState({ removeAlert: true });
        this.setState({ removehidden: e })

    }
    handleremoveCancelbttn() {
        this.setState({ removeAlert: false });
    }
    handleRemoveSpecificRow() {
        let currentComponent = this;
        var idx = currentComponent.state.removehidden;
        const rows = [...currentComponent.state.rows]
        var bumpId = parseInt(rows[idx]["bumpId"]);

        const Shootingid = currentComponent.state.ShootingID;
        rows.splice(idx, 1)
        currentComponent.setState({ rows });

        currentComponent.setState({ removeAlert: false });
        if (rows.length == 1) {
            $('.nodata').addClass('show-div');
        }

        currentComponent.DeleteBumpIdExists(Shootingid, bumpId);

    }
     //Event End

     //Function start
    InsertShootingBumpRate(DataShootingBumpRate,userfullname) {
        let currentComponent = this;   
        let data = {
            ShootingBumpRates: DataShootingBumpRate,
            ShootingId: currentComponent.state.ShootingID
        }
        APIPost('SkinUpload/InsertShootingBumpRate', data, function (result) {
            if (result.code == 200) {
                currentComponent.toasterfun2("Saved Successfully")
            } else {
                currentComponent.toasterfun2(result.message);
            }
        });
    }
    componentDidMount() {
       
        this.setState({
            countries: [
                { id: '1', name: 'Smoke Work' },
                { id: '2', name: 'Interviews' },
                { id: '3', name: 'Mileage' }
            ],
            addInfo: [
                { id: '1', name: ' to total pay' },
                { id: '2', name: 'of the base rate' },
                { id: '3', name: 'on top of the rate' }
            ]



        });
       
    }
    DeleteBumpIdExists(Shootingid, bumpId) {
        let currentComponent = this;
        let data = {
            BumpID: bumpId,
            ShootingId: Shootingid
        }

        APIPost('SkinUpload/DeleteBumpIdExists', data, function (result) {
            if (result.code === 200) {
                currentComponent.toasterfun2("Deleted Sucessfully")   
            } else {
                currentComponent.toasterfun2(result.message);
            }
        });

    }
    LoadBump() {


        let currentComponent = this;
        APIGet('SkinUpload/GetAllBumps/', function (result) {
            if (result.code === 200) {
                currentComponent.setState({ BumpList: result.result });
            } else {
                currentComponent.toasterfun2(result.message);
            }
        });


    }
    LoadShootingBumpRate(ShootingID) {

        let currentComponent = this;
        let data = {
            ShootingId: ShootingID
        }
        APIPost('SkinUpload/GetShootingBumpRate', data, function (result) {
            if (result.code === 200) {
                result.result.map((item, key) =>
                    currentComponent.setState({
                        rows: [...currentComponent.state.rows, item]
                    })
                );
            }
            else {
                //currentComponent.toasterfun2(result.message);
                $('.nodata').addClass('show-div');
            }
        });



    }
    //Function end

    //PageREnder Start
    render() {
       
        const { addInfo } = this.state;

     
        let addinfoList = addInfo.length > 0
            && addInfo.map((item, i) => {
                return (
                    <option key={i} value={item.id}>{item.name}</option>
                )
            }, this);

        
       
        let BumpList = this.state.BumpList.map((item, i) => {
            return (
                <option key={i} value={item.id} data-id={item.name}>{item.name}</option>
            )
        }, this);
        return (
            
            <div class="form-builder wid100">
                <div className={"removeAlert-div " + (this.state.removeAlert ? "show" : "hide")}><span className="alert-bg-div"></span>
                    <div className="alert-pos-x"> <i className="fa fa-bell" aria-hidden="true"></i> Are you sure you wish to delete this Bump?
                        <div className="text-center-div">
                            <div className="bttn add-bttn" onClick={this.handleRemoveSpecificRow}>
                            Yes
                           
                        </div>
                            <div className="bttn cancel-btn" onClick={this.handleremoveCancelbttn}>
                            No
                        </div>
                    </div>
                 </div>
                </div>
                <div className={"toaster-div " + (this.state.toasterCla ? "show" : "hide")}><i className="fa fa-check-circle" aria-hidden="true"></i> {this.state.toaster} </div>
                <h4 className="headingt4">Compensation</h4>
                <div class="cls-bttn" onClick={this.props.action}>x</div>
                <div className="container">
                    <div className="row clearfix">
                        <div className="col-md-12 column">
                            
                            <button onClick={this.handleAddRow} className="btn btn-primary pull-left add-x-m green-btn-div-x">
                                Add Row
                            </button>
                            <div className="popheight tableheading">
                                <table className="table table-bordered table-hover" id="customers">
                                    <thead>
                                        <tr>
                                            <th className="text-center"> # </th>
                                            <th className="text-center">Bump </th>
                                            <th className="text-center"> Union </th>
                                            <th className="text-center"> Non Union </th>
                                            <th className="text-center"> Additional Info </th>
                                            <th className="text-center"> Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.rows.map((item, idx) => (
                                            <tr id="addr0" key={idx}>
                                                <td>{idx}</td>

                                                <td>
                                                    <label className="lebel-div">
                                                        {this.state.rows[idx].bumpName} </label>
                                                    <select className="select-div-x" onChange={this.handleChange(idx)} name="bumpId" >
                                                        <option value="">Select</option>
                                                        {BumpList}
                                                    </select>
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        name="unionRate"
                                                        value={this.state.rows[idx].unionRate}
                                                        onChange={this.handleChange(idx)}
                                                        className="form-control height28 font12"
                                                        autoComplete="off"
                                                        onKeyDown={onlyDigits}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        name="nonUnionRate"
                                                        value={this.state.rows[idx].nonUnionRate}
                                                        onChange={this.handleChange(idx)}
                                                        className="form-control height28 font12"
                                                        autoComplete="off"
                                                        onKeyDown={onlyDigits}
                                                    />
                                                </td>

                                                <td>
                                                    <label className="lebel-div" name="addl_Info">
                                                        {this.state.rows[idx].addl_Info} </label>



                                                </td>
                                                <td valign="middle">
                                                    <button
                                                        className="btn btn-outline-danger btn-sm xBttn" onClick={(e) => { this.handleDocRemove(idx, 'Res') }}>X </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <div className="nodata"> No Data Found </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="popup-x text-center padding-x mar45 green-div-btn">
                    <button type="submit" class="btn btn-primary " onClick={this.handleSubmit}>Save </button>
                    <button type="button" class="btn btn-default btn-cancel " onClick={this.props.action} >Cancel </button>
                </div>
            </div>


        );
      
    }
    //PageREnder End
}

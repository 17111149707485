import React from 'react';
import ReactDOM, { render } from 'react-dom'
import { Layout } from '../Layout';
import { DatePickerInput } from 'rc-datepicker';
import InputField from "./InputField";
import InputField_Text from "./InputField_Text";
import moment from 'moment';
import $ from 'jquery';
import MaterialTable from 'material-table';
import ReactTooltip from 'react-tooltip';
import { onlyDigits } from '../Validation'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';// 'react-tabs/style/react-tabs.css';
import ExportXL from '../EMS_Components/ExportExcel'
import { APIGet, APIPost } from '../EMS_Components/APICall'
import { Constants } from '../EMS_Components'

//import { Tabs, Tab } from 'react-bootstrap-tabs';
//import * as FileSaver from 'file-saver';
//import Config from '../json/Config.json';
//import XLSX from 'xlsx'

export class TimeCardFileExport extends React.Component {
    static displayName = TimeCardFileExport.name;

    constructor(props) {
        super(props);

        // let config = require('../json/Config.json');


        this.state = {
            ShootingList: [],
            UnSubmittedShootingList: [],
            SubmittedShootingList: [],
            toasterCla: false,
            toaster: '',
            showProject: true,
            ShootingID: 0,
            ShootingName: "",
            ShootDate: null,//moment(new Date()).format('MM/DD/YYYY'),
            popDateValue: null,
            popMaxDateValue: null,
            popMinDateValue: null,
            rows: [],
            loading: true,
            isTableDisplay: false,
            popupSSn: true,
            popupAddress1: "",
            popupAddress2: "",
            popupCity: "",
            popupState: "",
            popupCountry: "",
            popupZip: "",
            popupemail: "",
            popupPhone: "",
            SubmitButtonVisible: true,
            popupBulkUpdate: true,
            BulkChangeBumpSel: "",
            BulkChangeDateSel: "",
            BulkChangeBumpSelVal: "",
            BulkChangeDateSelVal: "",
            BumpAmtDesc: "Amount",
            BumpRate: 0,
            BulkUpdateSelectedRows: [],
            prevBulkUpdateSelectedRows: [],
            BulkUpdaterows: [],
            BulkShootingRaterows: [],
            ReferBackList: [],
            popupReferBack: true,
            popupWardrobe: true,
            popupName: "",
            popupProperty: true,
            isBulkUpdateButtonDisplay: false,
            WardrobeList: [],
            PropertyList: [],
            datePickerPop: true,
            popDateValue: "",
            popMaxDateValue: "",
            popMinDateValue: "",
            popTimeHHValue: "",
            popTimeMMValue: "",
            popTimeTTValue: "",
            rowDateTimeChange: [],
            popColumnUpdate: "",
            popuprowindex: 0,

            BaseRatePop: true,
            popBaseRateNumerator: null,
            popBaseRateDenominator: 8,
            tabIndex: 0,

            LockButtonVisible: true,
            UnLockButtonVisible: true,
            LockButtonAccess: false,
            DVExcelButtonVisible: false,
            IsLocked: false,
            loadingTime: false,
            file: 'xlsx',
            ValidHourList: [
                { id: "1", name: "1" },
                { id: "2", name: "2" },
                { id: "3", name: "3" },
                { id: "4", name: "4" },
                { id: "5", name: "5" },
                { id: "6", name: "6" },
                { id: "7", name: "7" },
                { id: "8", name: "8" },
                { id: "10", name: "10" },
                { id: "12", name: "12" },
                { id: "14", name: "14" },
            ],
            BumpList: [
                { id: "carAllowance", name: "Per Diem" },
                { id: "auto", name: "Auto" },
                { id: "specialAbility", name: "Special compensation" },
                { id: "smokeWork", name: "Smoke work" },
                { id: "bodyMakeup", name: "Body Makeup" },
                { id: "hairCompensation", name: "Hair Cut" },
                { id: "wetWork", name: "Wet Work" },
                { id: "beardCompensation", name: "Beard" },
                { id: "dressUniformCompensation", name: "Dress or Uniforms" },
                //{ id: "other", name: "Other" },
                //{ id: "otherDescription", name: "Other Description" },
                { id: "mileageMiles", name: "Mileage" },
                { id: "wardrobeQuantity", name: "Wardrobe" },
                { id: "interviewHours", name: "Interviews" },
                { id: "fittingHours", name: "Fittings" },

            ],
            DateTimeList: [
                { id: "checkinTime", name: "Check-In" },
                { id: "checkoutTime", name: "Check-Out" },
                { id: "firstMealOut", name: "First Meal-Out" },
                { id: "firstMealIn", name: "First Meal-In" },
                { id: "secondMealOut", name: "Second Meal-Out" },
                { id: "secondMealIn", name: "Second Meal-In" },
                { id: "ndb", name: "NDB" },
                { id: "ndd", name: "NDD" },
                { id: "grace", name: "Grace" },

            ],
            hideColumn: {

                rowId: true,
                checkRefNumber: false,
                firstName: false,
                middleName: true,
                lastName: false,
                ssn: false,
                role: false,
                jobTitle: false,
                UnionID: false,
                callTime: true,
                baseRate: false,
                rateAdjusted: false,
                checkinTime: false,
                checkoutTime: false,
                firstMealOut: false,
                firstMealIn: false,
                secondMealOut: false,
                secondMealIn: false,
                ndb: false,
                ndd: false,
                grace: false,
                wardrobeQuantity: true,
                carAllowance: true,
                auto: true,
                interview: true,
                interviewHours: true,
                fitting: true,
                fittingHours: true,
                specialAbility: false,
                smokeWork: false,
                bodyMakeup: false,
                hairCompensation: false,
                wetWork: false,
                beardCompensation: false,
                dressUniformCompensation: true,
                Mileage: true,
                mileageMiles: true,
                other: false,
                otherDescription: false,
                other1: true,
                otherDescription1: true,
                other2: true,
                otherDescription2: true,
                isWardrobeComments: true,
                isPropertyComments: true,
                checkoutNotes: true,
                hourlyHours: true,
                hourlyRate: true,
                hourlyAmount: true,
                overTimeHours: true,
                overTimeRate: true,
                overTimeAmount: true,
                doubleTimeHours: true,
                doubleTimeRate: true,
                doubleTimeAmount: true,
                goldTimeHours: true,
                goldTimeAmount: true,
                mealPenalty1Qty: true,
                mealPenalty1Amount: true,
                mealPenalty2Qty: true,
                mealPenalty2Amount: true,
                mealPenalty3Qty: true,
                mealPenalty3Amount: true,
                mealPenalty: true,
                mpV1: true,
                mpV2: true,
                nightPremium1Qty: true,
                nightPremium1Amount: true,
                nightPremium2Qty: true,
                nightPremium2Amount: true,
                nightPremium: true,
                isReferBackComments: true
            },
            colOtherName1: true,
            HTGConfiguration: false,
            PAShootReport: false,
            PADetailedReport: true,
            ReportType: 0,
            TalentBreakdownResult: [],
            TalentBreakdownreplaceKeys: {},
            TalentBreakdownFileName: '',
            TalentBreakdownAggregationResult: [],
            TalentBreakdownAggregationReplaceKeys: {},
            DetailedReportResult: [],
            DetailedReportreplaceKeys: {},
            DetailedReportFileName: '',
            check_Delete: false,
            isLoggedIn: false,
            ShootNotes: "",
            DVExcel: [],
            ARConfimation: 0,
            dVExcelModelsReplaceKeys: {},
            dVExcelModelsReportFileName: '',
            ShowNotesPopup: true,
            UserRole: '',
        }
        this.CheckLogin();
        this.LoadHTGVisibility();
        this.LoadShooting(null, true);
        this.LoadShooting(null, false);
        this.OnDateChange = this.OnDateChange.bind(this);
        this.OnDateChangepopup = this.OnDateChangepopup.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);


        this.handleSubmitNotificaction = this.handleSubmitNotificaction.bind(this);

        this.handleBulkSubmit = this.handleBulkSubmit.bind(this);

        this.handleRowSubmit = this.handleRowSubmit.bind(this);
        this.handleReferBack = this.handleReferBack.bind(this);
        this.PopupCloseReferBack = this.PopupCloseReferBack.bind(this);

        this.handleWardrobe = this.handleWardrobe.bind(this);
        this.PopupCloseWardrobe = this.PopupCloseWardrobe.bind(this);



        this.handleARReviewSubmit = this.handleARReviewSubmit.bind(this);
        this.handleExcelExport = this.handleExcelExport.bind(this);
        this.handleBulkUpdate = this.handleBulkUpdate.bind(this);


        this.PopupCloseBulkUpdate = this.PopupCloseBulkUpdate.bind(this);
        this.CalculateHTG = this.CalculateHTG.bind(this);
        this.GenerateShootReport = this.GenerateShootReport.bind(this);
        this.GenerateDetailedReport = this.GenerateDetailedReport.bind(this);

        this.ClearData = this.ClearData.bind(this);
        this.PopupOpenSSn = this.PopupOpenSSn.bind(this);
        this.PopupCloseSSn = this.PopupCloseSSn.bind(this);
        this.handleRowEditPopup = this.handleRowEditPopup.bind(this);
        this.handleEditdatecancel = this.handleEditdatecancel.bind(this);
        this.handleEditdateOk = this.handleEditdateOk.bind(this);

        this.handlePopupValueChange = this.handlePopupValueChange.bind(this);

        this.handleEditBaseRateOk = this.handleEditBaseRateOk.bind(this);
        this.handleEditBaseRatecancel = this.handleEditBaseRatecancel.bind(this);

        this.handleUnLock = this.handleUnLock.bind(this);
        this.handleLock = this.handleLock.bind(this);



    }
    handlecheck_Delete = (e) => {

        this.setState({ check_Delete: e.target.checked })
    }
    fnVisibilityAgainstColumn = (data, column, type) => {

        if (type = Constants.columnvisibilityTime && (data.length == data.filter(D => D[column] == "" || D[column] == null).length)) {
            return true;
        }
        else if (type = Constants.columnvisibilityAmount && (data.length == data.filter(D => D[column] == "" || parseFloat(D[column]).toFixed(2) == 0.00 || D[column] == null).length)) {
            return true;
        }
        else return false;
    }

    columnvisibility = (data) => {

        let columnstate = {
            rowId: true,
            checkRefNumber: false,
            firstName: false,
            middleName: true,
            lastName: false,
            ssn: false,
            role: false,
            jobTitle: false,
            UnionID: false,
            callTime: this.fnVisibilityAgainstColumn(data, 'callTime', Constants.columnvisibilityTime),
            baseRate: false,
            rateAdjusted: this.fnVisibilityAgainstColumn(data, 'rateAdjusted', Constants.columnvisibilityAmount),
            checkinTime: this.fnVisibilityAgainstColumn(data, 'checkinTime', Constants.columnvisibilityTime),
            checkoutTime: this.fnVisibilityAgainstColumn(data, 'checkoutTime', Constants.columnvisibilityTime),
            firstMealOut: this.fnVisibilityAgainstColumn(data, 'firstMealOut', Constants.columnvisibilityTime),
            firstMealIn: this.fnVisibilityAgainstColumn(data, 'firstMealIn', Constants.columnvisibilityTime),
            secondMealOut: this.fnVisibilityAgainstColumn(data, 'secondMealOut', Constants.columnvisibilityTime),
            secondMealIn: this.fnVisibilityAgainstColumn(data, 'secondMealIn', Constants.columnvisibilityTime),
            ndb: this.fnVisibilityAgainstColumn(data, 'ndb', Constants.columnvisibilityTime),
            ndd: this.fnVisibilityAgainstColumn(data, 'ndd', Constants.columnvisibilityTime),
            grace: this.fnVisibilityAgainstColumn(data, 'grace', Constants.columnvisibilityTime),
            wardrobeQuantity: this.fnVisibilityAgainstColumn(data, 'wardrobeQuantity', Constants.columnvisibilityAmount),
            wardrobeRate: this.fnVisibilityAgainstColumn(data, 'wardrobeRate', Constants.columnvisibilityAmount),
            carAllowance: this.fnVisibilityAgainstColumn(data, 'carAllowance', Constants.columnvisibilityAmount),
            auto: this.fnVisibilityAgainstColumn(data, 'auto', Constants.columnvisibilityAmount),
            interview: this.fnVisibilityAgainstColumn(data, 'interview', Constants.columnvisibilityAmount),
            interviewHours: this.fnVisibilityAgainstColumn(data, 'interviewHours', Constants.columnvisibilityAmount),
            fitting: this.fnVisibilityAgainstColumn(data, 'fitting', Constants.columnvisibilityAmount),
            fittingHours: this.fnVisibilityAgainstColumn(data, 'fittingHours', Constants.columnvisibilityAmount),
            specialAbility: this.fnVisibilityAgainstColumn(data, 'specialAbility', Constants.columnvisibilityAmount),
            smokeWork: this.fnVisibilityAgainstColumn(data, 'smokeWork', Constants.columnvisibilityAmount),
            bodyMakeup: this.fnVisibilityAgainstColumn(data, 'bodyMakeup', Constants.columnvisibilityAmount),
            hairCompensation: this.fnVisibilityAgainstColumn(data, 'hairCompensation', Constants.columnvisibilityAmount),
            wetWork: this.fnVisibilityAgainstColumn(data, 'wetWork', Constants.columnvisibilityAmount),
            beardCompensation: this.fnVisibilityAgainstColumn(data, 'beardCompensation', Constants.columnvisibilityAmount),
            dressUniformCompensation: this.fnVisibilityAgainstColumn(data, 'dressUniformCompensation', Constants.columnvisibilityAmount),
            mileage: this.fnVisibilityAgainstColumn(data, 'mileage', Constants.columnvisibilityAmount),
            mileageMiles: this.fnVisibilityAgainstColumn(data, 'mileageMiles', Constants.columnvisibilityAmount),
            other: this.fnVisibilityAgainstColumn(data, 'other', Constants.columnvisibilityAmount),
            otherDescription: this.fnVisibilityAgainstColumn(data, 'otherDescription', Constants.columnvisibilityAmount),
            other1: this.fnVisibilityAgainstColumn(data, 'other1', Constants.columnvisibilityAmount),
            otherDescription1: this.fnVisibilityAgainstColumn(data, 'otherDescription1', Constants.columnvisibilityAmount),
            other2: this.fnVisibilityAgainstColumn(data, 'other2', Constants.columnvisibilityAmount),
            otherDescription2: this.fnVisibilityAgainstColumn(data, 'otherDescription2', Constants.columnvisibilityAmount),
            isWardrobeComments: true,
            isPropertyComments: true,
            checkoutNotes: true,
            hourlyHours: true,
            hourlyRate: true,
            hourlyAmount: true,
            overTimeHours: true,
            overTimeRate: true,
            overTimeAmount: true,
            doubleTimeHours: true,
            doubleTimeRate: true,
            doubleTimeAmount: true,
            goldTimeHours: true,
            goldTimeAmount: true,
            mealPenalty1Qty: true,
            mealPenalty1Amount: true,
            mealPenalty2Qty: true,
            mealPenalty2Amount: true,
            mealPenalty3Qty: true,
            mealPenalty3Amount: true,
            mealPenalty: true,
            mpV1: true,
            mpV2: true,
            nightPremium1Qty: true,
            nightPremium1Amount: true,
            nightPremium2Qty: true,
            nightPremium2Amount: true,
            nightPremium: true,
            isReferBackComments: true
        }
        this.setState({ hideColumn: columnstate });
    }
    handlecolumnvisibility = (column) => {
        let columnstate = this.state.hideColumn;
        columnstate[column.field] = column.hidden;
        this.setState({ hideColumn: columnstate })
    }

    handleLock() {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.UplateShootingLock(this.state.ShootingID, true);
    }
    handleUnLock() {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.UplateShootingLock(this.state.ShootingID, false);
    }
    UplateShootingLock(ShootingID, IsLocked) {
        let currentComponent = this;
        let data = {
            ShootingId: ShootingID,
            IsLocked: IsLocked
        }

        APIPost('SkinUpload/UpdateShooting', data, function (result) {
            if (result.code == 200) {
                currentComponent.setState({
                    LockButtonVisible: !result.result[0].isLocked,
                    UnLockButtonVisible: result.result[0].isLocked,
                    IsLocked: result.result[0].isLocked
                });
            } else {
                currentComponent.toasterfun2(result.message);
            }
            currentComponent.setState({ loadingTime: false });
        });
    }
    handleEditBaseRatecancel() {
        this.setState({ BaseRatePop: true });
    }

    CalculateInterview_Fitting(row) {
        var BulkShootingRaterows = this.state.BulkShootingRaterows;
        var interviewBumpRate = BulkShootingRaterows.filter(BulkShootingRaterows => BulkShootingRaterows.bumpName == "Interviews")[0];
        var fittingHoursBumpRate = BulkShootingRaterows.filter(BulkShootingRaterows => BulkShootingRaterows.bumpName == "Fittings")[0];
        var interviewRate = 0;
        var fittingRate = 0;
        if (row.unionID == "Non Union") {
            interviewRate = interviewBumpRate.nonUnionRate;
            fittingRate = fittingHoursBumpRate.nonUnionRate;
        }
        else {
            interviewRate = interviewBumpRate.unionRate;
            fittingRate = fittingHoursBumpRate.unionRate;
        }
        //var baseRate = (parseFloat(this.state.popBaseRateNumerator) / parseFloat(this.state.popBaseRateDenominator)).toFixed(2)
        var baseRate = 0;
        var popBaseRateNumerator = parseFloat(this.state.popBaseRateNumerator);
        var popBaseRateDenominator = parseFloat(this.state.popBaseRateDenominator);
        var rateAdjusted = row.rateAdjusted;
        if (row.unionID == "Non Union" || (row.unionID != "Non Union" && rateAdjusted == "")) {
            if (popBaseRateDenominator >= 8) {
                baseRate = popBaseRateNumerator / (popBaseRateDenominator + ((popBaseRateDenominator - 8) / 2));
            }
            else {
                baseRate = popBaseRateNumerator / popBaseRateDenominator;
            }
        }
        else {
            baseRate = rateAdjusted;
        }
        row.interview = parseFloat((baseRate * parseFloat(isNaN(row.interviewHours) ? 0 : row.interviewHours) * parseFloat(interviewRate)).toFixed(2));
        row.fitting = parseFloat((baseRate * parseFloat(isNaN(row.fittingHours) ? 0 : row.fittingHours) * parseFloat(fittingRate)).toFixed(2));

    }
    handleEditBaseRateOk() {




        var rows = this.state.rows;
        var id = this.state.rows[this.state.popuprowindex].id;
        rows = rows.filter(rows => rows.id === id)
        rows[0][this.state.popColumnUpdate] = this.state.popBaseRateNumerator + '/' + this.state.popBaseRateDenominator;
        if (rows[0]["unionID"] != "Non Union") {
            this.CalculateRateAdjusted(rows[0]);
        }
        else { this.CalculateInterview_Fitting(rows[0]); }


        let data = '';
        data = JSON.stringify(rows)
        this.setState({
            BaseRatePop: true,
            popBaseRateNumerator: null,
            popBaseRateDenominator: 8,
        });
        this.CommonFunctionToSaveData(data);

    }

    //OnDateChange(jsDate, dateString) {
    //    this.setState({ popDateValue: dateString });
    //}
    handlePopupValueChange(event) {

        var target = event.target;
        var name = target.getAttribute('name');
        var type = target.type;
        var value = target.value;

        if (name == "popTimeHHValue") {

            this.setState({ popTimeHHValue: value });
        }
        if (name == "popTimeMMValue") {

            this.setState({ popTimeMMValue: value });
        }
        if (name == "popTimeTTValue") {
            this.setState({ popTimeTTValue: value });
        }
        if (name == "popBaseRateNumerator") {
            this.setState({ popBaseRateNumerator: value });
        }
        if (name == "popBaseRateDenominator") {

            this.setState({ popBaseRateDenominator: value });
        }

    }

    deleteTimevalue = (index, event, row) => {


        //row[event.target.getAttribute('name')] = null;

        var rows = this.state.rows;
        var id = this.state.rows[index].id;
        rows = rows.filter(rows => rows.id === id)
        rows[0][event.target.getAttribute('name')] = null;
        let data = '';
        data = JSON.stringify(rows)

        this.CommonFunctionToSaveData(data);


        //alert("deleteTimevalue" + index + "-" + event + "-" +row);
    }
    handleRowEditPopup = (index, event, row) => {
        // ;
        var target = event.target;
        var name = target.getAttribute('name');
        this.setState({
            rowDateTimeChange: row,
            popColumnUpdate: name,
            popuprowindex: index
        });

        if (name == "checkinTime") {
            this.setState({
                datePickerPop: false,
                popDateValue: moment(row.checkinTime).format('MM/DD/YYYY'),
                popMaxDateValue: moment(row.checkinTime).add(1, 'd').format('MM/DD/YYYY'),
                popMinDateValue: moment(row.checkinTime).add(-1, 'd').format('MM/DD/YYYY'),
                popTimeHHValue: moment(row.checkinTime).format('hh'),
                popTimeMMValue: moment(row.checkinTime).format('mm'),
                popTimeTTValue: moment(row.checkinTime).format('A'),
            });
        }
        else if (name == "checkoutTime") {
            this.setState({
                datePickerPop: false,
                popDateValue: moment(row.checkoutTime).format('MM/DD/YYYY'),
                popMaxDateValue: moment(row.checkoutTime).add(1, 'd').format('MM/DD/YYYY'),
                popMinDateValue: moment(row.checkoutTime).add(-1, 'd').format('MM/DD/YYYY'),
                popTimeHHValue: moment(row.checkoutTime).format('hh'),
                popTimeMMValue: moment(row.checkoutTime).format('mm'),
                popTimeTTValue: moment(row.checkoutTime).format('A'),
            });
        }
        else if (name == "firstMealOut") {
            this.setState({
                datePickerPop: false,
                popDateValue: moment(row.firstMealOut).format('MM/DD/YYYY'),
                popMaxDateValue: moment(row.firstMealOut).add(1, 'd').format('MM/DD/YYYY'),
                popMinDateValue: moment(row.firstMealOut).add(-1, 'd').format('MM/DD/YYYY'),
                popTimeHHValue: moment(row.firstMealOut).format('hh'),
                popTimeMMValue: moment(row.firstMealOut).format('mm'),
                popTimeTTValue: moment(row.firstMealOut).format('A'),
            });
        }
        else if (name == "firstMealIn") {
            this.setState({
                datePickerPop: false,
                popDateValue: moment(row.firstMealIn).format('MM/DD/YYYY'),
                popMaxDateValue: moment(row.firstMealIn).add(1, 'd').format('MM/DD/YYYY'),
                popMinDateValue: moment(row.firstMealIn).add(-1, 'd').format('MM/DD/YYYY'),
                popTimeHHValue: moment(row.firstMealIn).format('hh'),
                popTimeMMValue: moment(row.firstMealIn).format('mm'),
                popTimeTTValue: moment(row.firstMealIn).format('A'),
            });
        }
        else if (name == "secondMealOut") {
            this.setState({
                datePickerPop: false,
                popDateValue: moment(row.secondMealOut).format('MM/DD/YYYY'),
                popMaxDateValue: moment(row.secondMealOut).add(1, 'd').format('MM/DD/YYYY'),
                popMinDateValue: moment(row.secondMealOut).add(-1, 'd').format('MM/DD/YYYY'),
                popTimeHHValue: moment(row.secondMealOut).format('hh'),
                popTimeMMValue: moment(row.secondMealOut).format('mm'),
                popTimeTTValue: moment(row.secondMealOut).format('A'),
            });
        }

        else if (name == "secondMealIn") {
            this.setState({
                datePickerPop: false,
                popDateValue: moment(row.secondMealIn).format('MM/DD/YYYY'),
                popMaxDateValue: moment(row.secondMealIn).add(1, 'd').format('MM/DD/YYYY'),
                popMinDateValue: moment(row.secondMealIn).add(-1, 'd').format('MM/DD/YYYY'),
                popTimeHHValue: moment(row.secondMealIn).format('hh'),
                popTimeMMValue: moment(row.secondMealIn).format('mm'),
                popTimeTTValue: moment(row.secondMealIn).format('A'),
            });
        }
        else if (name == "ndb") {
            this.setState({
                datePickerPop: false,
                popDateValue: moment(row.ndb).format('MM/DD/YYYY'),
                popMaxDateValue: moment(row.ndb).add(1, 'd').format('MM/DD/YYYY'),
                popMinDateValue: moment(row.ndb).add(-1, 'd').format('MM/DD/YYYY'),
                popTimeHHValue: moment(row.ndb).format('hh'),
                popTimeMMValue: moment(row.ndb).format('mm'),
                popTimeTTValue: moment(row.ndb).format('A'),
            });
        }
        else if (name == "ndd") {
            this.setState({
                datePickerPop: false,
                popDateValue: moment(row.ndd).format('MM/DD/YYYY'),
                popMaxDateValue: moment(row.ndd).add(1, 'd').format('MM/DD/YYYY'),
                popMinDateValue: moment(row.ndd).add(-1, 'd').format('MM/DD/YYYY'),
                popTimeHHValue: moment(row.ndd).format('hh'),
                popTimeMMValue: moment(row.ndd).format('mm'),
                popTimeTTValue: moment(row.ndd).format('A'),
            });
        }
        else if (name == "grace") {
            this.setState({
                datePickerPop: false,
                popDateValue: moment(row.grace).format('MM/DD/YYYY'),
                popMaxDateValue: moment(row.grace).add(1, 'd').format('MM/DD/YYYY'),
                popMinDateValue: moment(row.grace).add(-1, 'd').format('MM/DD/YYYY'),
                popTimeHHValue: moment(row.grace).format('hh'),
                popTimeMMValue: moment(row.grace).format('mm'),
                popTimeTTValue: moment(row.grace).format('A'),
            });
        }
        else if (name == "baseRate") {
            this.setState({
                BaseRatePop: false,
                popBaseRateNumerator: row.baseRate.split('/')[0],
                popBaseRateDenominator: row.baseRate.split('/')[1]

            });
        }
    }
    handleEditdatecancel() {
        this.setState({ datePickerPop: true });
    }

    handleEditdateOk() {

        //  ;
        var rowDateTimeChange = this.state.rowDateTimeChange;
        //'MM/DD/YYYY hh:mm A'
        var datetime = new Date(this.state.popDateValue + " " + this.state.popTimeHHValue + ":" + this.state.popTimeMMValue + " " + this.state.popTimeTTValue);//.toISOString()
        var date = datetime.getFullYear() + "-" + ("0" + (datetime.getMonth() + 1)).slice(-2) + "-" + ("0" + (datetime.getDate())).slice(-2) + 'T' + ("0" + datetime.getHours()).slice(-2) + ":" + ("0" + datetime.getMinutes()).slice(-2) + ":00";

        //rowDateTimeChange[this.state.popColumnUpdate] = datetime;
        //console.log(rowDateTimeChange);

        var rows = this.state.rows;
        var id = this.state.rows[this.state.popuprowindex].id;
        rows = rows.filter(rows => rows.id === id)
        let prevDate = rows[0][this.state.popColumnUpdate];
        rows[0][this.state.popColumnUpdate] = date;
        if (!this.ValidationOnSubmit(rows)) {
            let data = '';
            data = JSON.stringify(rows)
            this.setState({
                datePickerPop: true,
                popDateValue: "",
                popTimeHHValue: "",
                popTimeMMValue: "",
                popTimeTTValue: "",
                rowDateTimeChange: [],
            });
            this.CommonFunctionToSaveData(data);


        }
        else {
            rows[0][this.state.popColumnUpdate] = prevDate;
        }
        //this.setState({
        //    rowDateTimeChange
        //});

        ////rows = rows.filter(rows => rows.id === id)
        //let data = '';
        //data = JSON.stringify(rowDateTimeChange)
        //this.CommonFunctionToSaveData(data);
    }
    //Funcion related to Datepicker -- start
    log = (...x) => console.log(...x)       // eslint-disable-line no-console
    onClear = (e) => {
        this.ClearData(e);
        this.setState({ ShootDate: null });
    }
    formatdate(Datetobeformatted) {
        if (Datetobeformatted != null) {// format date in MM/DD/YYYY format
            return Intl.DateTimeFormat('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
            }).format(new Date(Datetobeformatted));
        }
    }
    OnDateChange(jsDate, dateString) {


        this.setState({ ShootDate: this.formatdate(dateString) });
        this.LoadShooting(this.formatdate(dateString), true);
        this.LoadShooting(this.formatdate(dateString), false);
    }
    OnDateChangepopup(jsDate, dateString) {


        this.setState({ popDateValue: this.formatdate(dateString) });

    }

    // PopupOpenSSn() {
    PopupOpenSSn = index => e => {
        let currentComponent = this;



        var row = currentComponent.state.rows[index];
        //console.log(row);
        this.setState({
            popupSSn: false,
            popupAddress1: row.address1,
            popupAddress2: row.address2,
            popupCity: row.city,
            popupState: row.state,
            popupCountry: row.country,
            popupZip: row.zipCode,
            popupemail: row.email,
            popupPhone: row.phoneNumber
        });
    }
    PopupCloseSSn() {
        this.setState({ popupSSn: true });
    }
    //Funcion related to Datepicker -- End
    toasterfun2(e) {
        this.setState({ toasterCla: true });
        this.setState({ toaster: e });
        setTimeout(
            function () {
                this.setState({ toasterCla: false });
            }.bind(this), 5000);
    }

    ClearData(event) {
        this.setState({
            ShootingList: [],
            UnSubmittedShootingList: [],
            SubmittedShootingList: [],
            toasterCla: false,
            toaster: '',
            showProject: true,
            ShootingID: 0,
            ShootingName: "",
            ShootDate: null,
            popDateValue: null,
            popMaxDateValue: null,
            popMinDateValue: null,
            rows: [],
            loading: true,
            isTableDisplay: false,
            popupSSn: true,
            popupAddress1: "",
            popupAddress2: "",
            popupCity: "",
            popupState: "",
            popupCountry: "",
            popupZip: "",
            popupemail: "",
            popupPhone: "",
            SubmitButtonVisible: true,
            popupBulkUpdate: true,
            BulkChangeBumpSel: "",
            BulkChangeDateSel: "",
            BumpRate: 0,
            BulkUpdateSelectedRows: [],
            prevBulkUpdateSelectedRows: [],
            BulkUpdaterows: [],
            ReportType: 0,
            isBulkUpdateButtonDisplay: false,

        });


        this.LoadShooting(null, true);
        this.LoadShooting(null, false);
    }
    fnCalculatedValues(row, name, BulkUpdaterow, rows, index) {

        if (name == "mileageMiles" || name == "wardrobeQuantity" || name == "interviewHours" || name == "fittingHours") {
            var BumpRate = 0;
            var value = 0;
            var BumpName = "";
            var BulkShootingRaterows = this.state.BulkShootingRaterows;
            if (name == "mileageMiles") {
                value = isNaN(row.mileageMiles) ? 0 : row.mileageMiles;
                BumpName = 'Mileage';
                BumpRate = BulkShootingRaterows.filter(BulkShootingRaterows => BulkShootingRaterows.bumpName == BumpName);
            }
            else if (name == "interviewHours") {
                value = isNaN(row.interviewHours) ? 0 : row.interviewHours;
                BumpName = 'Interviews';
                BumpRate = BulkShootingRaterows.filter(BulkShootingRaterows => BulkShootingRaterows.bumpName == BumpName);
            }
            else if (name == "fittingHours") {
                value = isNaN(row.fittingHours) ? 0 : row.fittingHours;
                BumpName = 'Fittings';
                BumpRate = BulkShootingRaterows.filter(BulkShootingRaterows => BulkShootingRaterows.bumpName == BumpName);
            }
            else if (name == "wardrobeQuantity") {

                value = isNaN(row.wardrobeQuantity) ? 0 : row.wardrobeQuantity;

                if (value == 1) {
                    BumpName = "1st Wardrobe Change";
                    BumpRate = BulkShootingRaterows.filter(BulkShootingRaterows => BulkShootingRaterows.bumpName == BumpName);
                }
                else if (value > 1) {

                    BumpName = "2nd Wardrobe Change onwards";
                    BumpRate = BulkShootingRaterows.filter(BulkShootingRaterows => BulkShootingRaterows.bumpName == "1st Wardrobe Change" || BulkShootingRaterows.bumpName == BumpName);



                }
                else {
                    BumpRate = 0;
                    rows[index].wardrobeQuantity = value;
                    rows[index].wardrobeRate = BumpRate;
                }

            }
            value = value == "" ? 0 : value;
            if (name == 'fittingHours' && row.fittingHours > 23) {
                this.toasterfun2("Fitting Hours cannot be greater than 23 hours")
                row.fittingHours = 23
                value = 23
            }
            else if (name == 'interviewHours' && row.interviewHours > 23) {
                this.toasterfun2("Interview Hours cannot be greater than 23 hours")
                row.interviewHours = 23
                value = 23
            }
            else if (name == 'wardrobeQuantity' && row.wardrobeQuantity > 50) {
                this.toasterfun2("Wardrobe Quantity cannot be greater than 50 count")
                row.wardrobeQuantity = 50
                value = 50
            }
            else if (name == 'mileageMiles' && row.mileageMiles > 999) {
                this.toasterfun2("Mileage Miles cannot be greater than 999");
                row.mileageMiles = 999
                value = 999
            }

            var Rate = 0;
            var baseRate = 0;
            //var baseRate = (parseFloat(row.baseRate.toString().split('/')[0]) / parseFloat(row.baseRate.toString().split('/')[1])).toFixed(2);
            var rateAdjusted = row.rateAdjusted;
            var popBaseRateNumerator = parseFloat(row.baseRate.toString().split('/')[0]);
            var popBaseRateDenominator = parseFloat(row.baseRate.toString().split('/')[1]);
            if (row.unionID == "Non Union" || (row.unionID != "Non Union" && rateAdjusted == "")) {
                if (popBaseRateDenominator >= 8) {
                    baseRate = popBaseRateNumerator / (popBaseRateDenominator + ((popBaseRateDenominator - 8) / 2));
                }
                else {
                    baseRate = popBaseRateNumerator / popBaseRateDenominator;
                }

            }
            else {
                baseRate = rateAdjusted;
            }

            if (BumpRate.length > 0) {
                if (name != "wardrobeQuantity") {
                    if (BulkUpdaterow[0].unionID == "Non Union") {
                        Rate = BumpRate[0].nonUnionRate;
                    }
                    else {
                        Rate = BumpRate[0].unionRate;
                    }
                }
                else {

                    if (value == "1") {
                        if (BulkUpdaterow[0].unionID == "Non Union") {
                            Rate = BumpRate[0].nonUnionRate;
                        }
                        else {
                            Rate = BumpRate[0].unionRate;
                        }
                    }
                    else {
                        if (BulkUpdaterow[0].unionID == "Non Union") {
                            Rate = BumpRate[0].nonUnionRate + (value - 1) * BumpRate[1].nonUnionRate;
                        }
                        else {
                            Rate = BumpRate[0].unionRate + (value - 1) * BumpRate[1].unionRate;
                        }
                    }
                }
                if (name == "mileageMiles") {
                    rows[index].mileageMiles = parseFloat(value);
                    //rows[index].mileage = (value * Rate).toFixed(2);
                    rows[index].mileage = parseFloat((value * Rate).toFixed(2));
                }
                else if (name == "interviewHours") {
                    rows[index].interviewHours = parseFloat(value);
                    rows[index].interview = parseFloat(baseRate * value * Rate);
                }
                else if (name == "fittingHours") {
                    rows[index].fittingHours = parseFloat(value);
                    rows[index].fitting = parseFloat(baseRate * value * Rate);
                }
                else if (name == "wardrobeQuantity") {
                    rows[index].wardrobeQuantity = parseFloat(value);
                    rows[index].wardrobeRate = parseFloat(Rate);
                }
            }
        }
        //console.log(BulkUpdaterow[0].unionID);
        if ((BulkUpdaterow[0].unionID != "Non Union") && (name == "specialAbility" || name == "smokeWork" || name == "bodyMakeup" || name == "hairCompensation" || name == "wetWork" || name == "beardCompensation")) {

            this.CalculateRateAdjusted(row);

        }

    }
    handleInputChange = (index, row, event) => {
        //console.log('master');

        var BulkUpdaterows = this.state.BulkUpdaterows;
        var BulkUpdaterow = BulkUpdaterows.filter(BulkUpdaterows => BulkUpdaterows.id == row.id);
        var target = event.target;
        var name = target.getAttribute('name');
        if (name != "otherDescription"
            && name != "otherDescription1"
            && name != "otherDescription2"

        ) {
            const rows = [...this.state.rows];

            rows.splice(index, 1, row);
            row.isChanged = true;
            this.setState({
                rows
            });

            this.fnCalculatedValues(row, name, BulkUpdaterow, rows, index);
        }
        else {
            const rows = [...this.state.rows];
            for (let i = 0; i < rows.length; i++) {
                if (rows[i].id == row.id) {
                    rows[i][name] = target.value;
                }

            }
            row.isChanged = true;
            this.setState({
                rows
            });
        }

    };
    CalculateRateAdjusted(row) {

        var baseRate = row.baseRate.toString().split('/');
        var popBaseRateDenominator = parseFloat(baseRate[1]);
        var negHrs = 0;
        if (popBaseRateDenominator >= 8) {
            negHrs = (popBaseRateDenominator + ((popBaseRateDenominator - 8) / 2))
        }
        else { negHrs = popBaseRateDenominator; }
        row.rateAdjusted = ((parseFloat(baseRate[0])
            + (!isNaN(parseFloat(row.specialAbility)) ? parseFloat(row.specialAbility) : 0)
            + (!isNaN(parseFloat(row.smokeWork)) ? parseFloat(row.smokeWork) : 0)
            + (!isNaN(parseFloat(row.bodyMakeup)) ? parseFloat(row.bodyMakeup) : 0)
            + (!isNaN(parseFloat(row.hairCompensation)) ? parseFloat(row.hairCompensation) : 0)
            + (!isNaN(parseFloat(row.wetWork)) ? parseFloat(row.wetWork) : 0)
            + (!isNaN(parseFloat(row.beardCompensation)) ? parseFloat(row.beardCompensation) : 0))
            / negHrs

        ).toFixed(2).toString();

        this.CalculateInterview_Fitting(row);

        //parseFloat(baseRate[1])).toString();
    }
    SendEmail(ShootingID) {
        let currentComponent = this;

        APIPost('AdminSkinUpload/SendEmailPayrollProcessing/' + ShootingID, null, function (result) {
            if (result.code === 200) {
                currentComponent.toasterfun2("Mail sent Successfully")
                currentComponent.setState({ SubmitButtonVisible: true });
            } else {
                currentComponent.toasterfun2(result.message);
            }
        });
    }
    ClearBulkState() {

        var strstartdate = this.state.ShootingName.toString().split(' ');
        var startdate = strstartdate[strstartdate.length - 1];
        this.setState({
            check_Delete: false,
            BulkUpdateSelectedRows: [],
            prevBulkUpdateSelectedRows: [],
            isBulkUpdateButtonDisplay: false,
            BulkChangeBumpSel: "",
            BulkChangeDateSel: "",
            BumpAmtDesc: "Amount",
            BumpRate: 0,

            //isBulkUpdateButtonDisplay: false,
            popDateValue: startdate,
            popTimeHHValue: "",
            popTimeMMValue: "",
            popTimeTTValue: "",
            rowDateTimeChange: [],
            popColumnUpdate: "",
            popuprowindex: 0,
            popBaseRateNumerator: null,
            popBaseRateDenominator: 8,
            tabIndex: 0
        });
    }
    handleBulkUpdate(event) {
        this.setState({ loadingTime: true });
        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ popupBulkUpdate: false });
        this.ClearBulkState();
        this.setState({ loadingTime: false });
    }

    PopupCloseBulkUpdate() {
        this.setState({ popupBulkUpdate: true });
        this.ClearBulkState();
    }
    CommonFunctionARReviewExcelGenerate(Action) {
        let currentComponent = this;
        currentComponent.setState({ loadingTime: true });

        var ShootingID = currentComponent.state.ShootingID;
        //let userfullName = localStorage.getItem('userfullName');
        let userfullName = '' //localStorage.getItem('PACurrentUserId');

        let data = '';
        const srows = [...currentComponent.state.rows]
        //if (srows.filter(rows => rows.isChanged == true).length > 0) {
        if (!currentComponent.ValidationOnSubmit(srows)) {
            var rows = currentComponent.parseValues();
            data = JSON.stringify(rows)
            //}
            if (Action == "ARReviewSubmit") { currentComponent.toasterfun2("Submitted Sending mail to Payroll master, Production Accountant, Second Director and to the BG's who has changes..") }

            var reqData = {
                projectDetailsForActorModels: data,
                ShootingID: ShootingID,
                Username: userfullName,
                For: Action
            }

            APIPost('AdminSkinUpload/ARReviewAction', reqData, function (result) {
                if (Action == "ARReviewAction") {
                    currentComponent.toasterfun2("Mail sent Successfully")
                    currentComponent.handleLock();
                }
                else {

                }
                currentComponent.setState({
                    loadingTime: false
                });
            });
        }
        else {
            currentComponent.setState({ loadingTime: false });
        }

    }
    handleExcelExport(event) {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        event.preventDefault();
        this.CommonFunctionARReviewExcelGenerate("ExcelExport");
    }
    handleARReviewSubmit(event) {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        event.preventDefault();
        this.CommonFunctionARReviewExcelGenerate("ARReviewAction");
       
        // this.handleSubmitNotificaction(event);
    }
    parseValues() {

        let currentComponent = this;
        const rows = [...currentComponent.state.rows]
        var idx;
        for (idx = 0; idx < rows.length; idx++) {
            //console.log(idx);
            rows[idx] = {
                ["id"]: rows[idx]["id"],
                ["checkRefNumber"]: rows[idx]["checkRefNumber"],
                ["rowId"]: rows[idx]["rowId"],
                ["firstName"]: rows[idx]["firstName"],
                ["lastName"]: rows[idx]["lastName"],
                ["middleName"]: rows[idx]["middleName"],
                ["phoneNumber"]: rows[idx]["phoneNumber"],
                ["ssn"]: rows[idx]["ssn"],
                ["email"]: rows[idx]["email"],
                ["name"]: rows[idx]["name"],
                ["image"]: rows[idx]["image"],
                ["productionName"]: rows[idx]["productionName"],
                ["address1"]: rows[idx]["address1"],
                ["address2"]: rows[idx]["address2"],
                ["city"]: rows[idx]["city"],
                ["state"]: rows[idx]["state"],
                ["country"]: rows[idx]["country"],
                ["zipCode"]: rows[idx]["zipCode"],
                ["startDateTime"]: rows[idx]["startDateTime"],
                ["endDateTime"]: rows[idx]["endDateTime"],
                ["baseRate"]: rows[idx]["baseRate"],
                ["rateAdjusted"]: rows[idx]["rateAdjusted"],
                ["checkinTime"]: rows[idx]["checkinTime"],
                ["checkoutTime"]: rows[idx]["checkoutTime"],
                ["firstMealIn"]: rows[idx]["firstMealIn"],
                ["firstMealOut"]: rows[idx]["firstMealOut"],
                ["secondMealIn"]: rows[idx]["secondMealIn"],
                ["secondMealOut"]: rows[idx]["secondMealOut"],
                ["specialAbility"]: rows[idx]["specialAbility"] != null ? parseFloat(rows[idx]["specialAbility"]) : null,
                ["mealPenalty"]: rows[idx]["mealPenalty"] != null ? parseFloat(rows[idx]["mealPenalty"]) : null,

                ["fitting"]: rows[idx]["fitting"] != null ? parseFloat(rows[idx]["fitting"]) : null,
                ["interview"]: rows[idx]["interview"] != null ? parseFloat(rows[idx]["interview"]) : null,
                ["wetWork"]: rows[idx]["wetWork"] != null ? parseFloat(rows[idx]["wetWork"]) : null,
                ["auto"]: rows[idx]["auto"] != null ? parseFloat(rows[idx]["auto"]) : null,
                ["smokeWork"]: rows[idx]["smokeWork"] != null ? parseFloat(rows[idx]["smokeWork"]) : null,
                ["hairCompensation"]: rows[idx]["hairCompensation"] != null ? parseFloat(rows[idx]["hairCompensation"]) : null,
                ["nDB"]: rows[idx]["nDB"],
                ["nDD"]: rows[idx]["nDD"],
                ["wardrobeRate"]: rows[idx]["wardrobeRate"] != null ? parseFloat(rows[idx]["wardrobeRate"]) : null,
                ["bodyMakeup"]: rows[idx]["bodyMakeup"] != null ? parseFloat(rows[idx]["bodyMakeup"]) : null,
                ["beardCompensation"]: rows[idx]["beardCompensation"] != null ? parseFloat(rows[idx]["beardCompensation"]) : null,
                ["dressUniformCompensation"]: rows[idx]["dressUniformCompensation"] != null ? parseFloat(rows[idx]["dressUniformCompensation"]) : null,
                ["grace"]: rows[idx]["grace"],
                ["mileage"]: rows[idx]["mileage"] != null ? parseFloat(rows[idx]["mileage"]) : null,
                ["otherDescription"]: rows[idx]["otherDescription"],
                ["other"]: rows[idx]["other"] != null ? parseFloat(rows[idx]["other"]) : null,
                ["confirmStatus"]: parseInt(rows[idx]["confirmStatus"]),
                ["userConfirmStatus"]: parseInt(rows[idx]["userConfirmStatus"]),
                ["role"]: rows[idx]["role"],
                ["callTime"]: rows[idx]["callTime"],
                ["unionID"]: rows[idx]["unionID"],
                ["isWardrobeComments"]: rows[idx]["isWardrobeComments"],
                ["isPropertyComments"]: rows[idx]["isPropertyComments"],
                ["checkoutNotes"]: rows[idx]["checkoutNotes"],

                ["jobTitle"]: rows[idx]["jobTitle"],

                ["wardrobeQuantity"]: rows[idx]["wardrobeQuantity"] != null ? parseInt(rows[idx]["wardrobeQuantity"]) : null,

                ["interviewHours"]: rows[idx]["interviewHours"] != null ? parseInt(rows[idx]["interviewHours"]) : null,
                ["fittingHours"]: rows[idx]["fittingHours"] != null ? parseInt(rows[idx]["fittingHours"]) : null,
                ["mileageMiles"]: rows[idx]["mileageMiles"] != null ? parseInt(rows[idx]["mileageMiles"]) : null,

                ["carAllowance"]: rows[idx]["carAllowance"] != null ? parseFloat(rows[idx]["carAllowance"]) : null,
                ["other1"]: rows[idx]["other1"] != null ? parseFloat(rows[idx]["other1"]) : null,
                ["otherDescription1"]: rows[idx]["otherDescription1"],
                ["other2"]: rows[idx]["other2"] != null ? parseFloat(rows[idx]["other2"]) : null,
                ["otherDescription2"]: rows[idx]["otherDescription2"],
                ["referBackComments"]: rows[idx]["referBackComments"],
                ["isChanged"]: rows[idx]["isChanged"],
            };
        }

        currentComponent.setState({ rows: rows });
        //console.log(rows);
        return rows.filter(rows => rows.isChanged == true);;
    }
    parseValuesBulkUpdate() {

        let currentComponent = this;
        const rows = currentComponent.state.BulkUpdateSelectedRows;
        var idx;
        var BulkChangeBumpSel = currentComponent.state.BulkChangeBumpSel;
        ;

        for (idx = 0; idx < rows.length; idx++) {
            var strbaseRate = rows[idx]["baseRate"].toString();

            if (this.state.tabIndex == 0 || this.state.tabIndex == 2) {
                if (this.state.popBaseRateNumerator != null) {

                    strbaseRate = this.state.popBaseRateNumerator + '/' + this.state.popBaseRateDenominator;

                }
                BulkChangeBumpSel = BulkChangeBumpSel == "" ? "carAllowance" : BulkChangeBumpSel
                var baseRate = strbaseRate.split('/');
                var rateAdjusted = "";
                var BulkUpdaterows = this.state.BulkUpdaterows;
                var BulkUpdaterow = BulkUpdaterows.filter(BulkUpdaterows => BulkUpdaterows.id == rows[idx].id);
                var BulkUpdaterow_1 = BulkUpdaterow[0];
                if (BulkChangeBumpSel == "mileageMiles" || BulkChangeBumpSel == "wardrobeQuantity" || BulkChangeBumpSel == "interviewHours" || BulkChangeBumpSel == "fittingHours") {
                    // ;


                    var _rows = [...this.state.rows];

                    var row = _rows.filter(rows => rows.id == BulkUpdaterow_1.id)[0];

                    row[BulkChangeBumpSel] = currentComponent.state.BumpRate != "" ? currentComponent.state.BumpRate : 0;
                    currentComponent.fnCalculatedValues(row, BulkChangeBumpSel, BulkUpdaterow, rows, idx)
                    //currentComponent.fnCalculatedValues(BulkUpdaterow_1, BulkChangeBumpSel, BulkUpdaterow, BulkUpdaterows, idx)

                }
                else {

                    var BumpRate = currentComponent.state.BumpRate;//rows[idx][BulkChangeBumpSel];
                    rows[idx][BulkChangeBumpSel] = parseFloat(currentComponent.state.BumpRate);
                    if (currentComponent.state.BumpRate != 0) { BumpRate = currentComponent.state.BumpRate; }
                    if (BulkUpdaterow[0].unionID != "Non Union") {

                        var popBaseRateDenominator = parseFloat(baseRate[1]);
                        var negHrs = 0;
                        if (popBaseRateDenominator >= 8) {
                            negHrs = (popBaseRateDenominator + ((popBaseRateDenominator - 8) / 2))
                        }
                        else { negHrs = popBaseRateDenominator; }

                        rateAdjusted = ((parseFloat(baseRate[0])
                            + parseFloat(rows[idx]["specialAbility"] ?? 0)
                            + parseFloat(rows[idx]["smokeWork"] ?? 0)
                            + parseFloat(rows[idx]["bodyMakeup"] ?? 0)
                            + parseFloat(rows[idx]["hairCompensation"] ?? 0)
                            + parseFloat(rows[idx]["wetWork"] ?? 0)
                            + parseFloat(rows[idx]["beardCompensation"] ?? 0)

                        )
                            /
                            negHrs

                        ).toFixed(2).toString();
                    }

                    rows[idx][BulkChangeBumpSel] = parseFloat(currentComponent.state.BumpRate);
                    rows[idx]["rateAdjusted"] = rateAdjusted;
                    rows[idx]["baseRate"] = strbaseRate;


                }
            }

            if (this.state.tabIndex == 1) {
                var BulkChangeDateSel = currentComponent.state.BulkChangeDateSel;

                var prevBulkChangeDateSel = BulkChangeDateSel;
                BulkChangeDateSel = BulkChangeDateSel == "" ? "checkinTime" : BulkChangeDateSel
                //var DateTimecol = rows[idx][BulkChangeDateSel].toString();

                var DateTimecol = "";
                if (prevBulkChangeDateSel == "") {
                    DateTimecol = rows[idx][BulkChangeDateSel]?.toString();
                }
                if (prevBulkChangeDateSel != "" && moment(this.state.popDateValue + " " + this.state.popTimeHHValue + ":" + this.state.popTimeMMValue + " " + this.state.popTimeTTValue).isValid()) {
                    var datetime = new Date(this.state.popDateValue + " " + this.state.popTimeHHValue + ":" + this.state.popTimeMMValue + " " + this.state.popTimeTTValue);//.toISOString()
                    var DateTimecol = datetime.getFullYear() + "-" + ("0" + (datetime.getMonth() + 1)).slice(-2) + "-" + ("0" + (datetime.getDate())).slice(-2) + 'T' + ("0" + datetime.getHours()).slice(-2) + ":" + ("0" + datetime.getMinutes()).slice(-2) + ":00";
                }
                if (currentComponent.state.check_Delete)
                    rows[idx][BulkChangeDateSel] = null;
                else
                    rows[idx][BulkChangeDateSel] = DateTimecol;
            }


            //console.log(idx);





        }


        currentComponent.setState({ BulkUpdateSelectedRows: rows, isBulkUpdateButtonDisplay: rows.length, });
        //console.log(rows);
        return rows;
    }

    validateBulkupdate() {
        let err = true;
        let errmsg = "";
        if (this.state.tabIndex == 0) {
            if (this.state.BulkChangeBumpSel == "" || this.state.BulkChangeBumpSel == "Select") {
                errmsg = "Please select bump";
                err= false;
            }
            if (this.state.BumpRate == 0) {
                errmsg="Please enter amount for bump"
                err = false;
            }
        }
        else if (this.state.tabIndex == 1) {
            if (this.state.BulkChangeDateSel == "" || this.state.BulkChangeDateSel == "Select") {
                errmsg="Please select Date time type"
                err = false;
            }
            if (this.state.check_Delete) {
                if (this.state.BulkChangeDateSel == 'checkinTime' || this.state.BulkChangeDateSel == 'checkoutTime') {
                    this.toasterfun2("Values cannot be cleared against Check-in and Check-Out");
                    err = false;
                }

            }
        }
        else if (this.state.tabIndex == 2) {
            if (this.state.popBaseRateNumerator == null || this.state.popBaseRateNumerator==0 ) {
                errmsg = "Please enter amount for Base Rate"
                err = false;
            }
        }
        if (!err)
            this.toasterfun2(errmsg);
            return err;

    }
    handleBulkSubmit(event) {

        //console.log(this.state.BulkUpdateSelectedRows);

        let currentComponent = this;
        if (currentComponent.validateBulkupdate()) {
            currentComponent.setState({ loadingTime: true });
            let data = '';
            var rows = currentComponent.parseValuesBulkUpdate();
            if (!currentComponent.ValidationOnSubmit(rows)) {
                if (rows != false) {
                    //data = JSON.stringify(rows)
                    //console.log(data);
                    //let userfullName = localStorage.getItem('userfullName');
                    let userfullName = ''; //localStorage.getItem('PACurrentUserId');
                    APIPost('AdminSkinUpload/UpdateProjectDetailsForActorByAccountant', { ProjectDetailsForActorModels: rows }, function (result) {
                        if (result.code == 200) {
                            //;
                            currentComponent.toasterfun2("Details saved Successfully");
                            currentComponent.setState({ popupBulkUpdate: true });
                            currentComponent.GetDataShootingSel(currentComponent.state.ShootingID, true);

                        } else {
                            currentComponent.setState({ loadingTime: false });
                            currentComponent.toasterfun2(result.message);
                        }
                    });



                }
            }
            else {
                currentComponent.setState({
                    loadingTime: false,
                    //    BulkUpdateSelectedRows: currentComponent.state.prevBulkUpdateSelectedRows,
                });
                //currentComponent.GetDataShootingSel(currentComponent.state.ShootingID, true);
                var strURL = "GetShootingDetailsforAccountantByShootingID";
                data = { ShootingId: currentComponent.state.ShootingID }
                APIPost('AdminSkinUpload/' + strURL, data, function (result) {
                    if (result.code === 200) {

                        currentComponent.setState({
                            rows: result.result
                        });
                    }
                    else {
                        currentComponent.toasterfun2(result.message);
                    }
                });
            }
        }
    }
    handleSubmitNotificaction(event) {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        let currentComponent = this;
        currentComponent.setState({ loadingTime: true });
        //currentComponent.toasterfun2("Sending Notifications to BG's on changes..")
        var data = { ShootingId: currentComponent.state.ShootingID }
        APIPost('AdminSkinUpload/PASendNotificationToBGsOnChange', data, function (result) {
            if (result.code == 200) {
                currentComponent.setState({ loadingTime: false });
                //currentComponent.toasterfun2("Notification sent Successfully");
            } else {
                currentComponent.toasterfun2(result.message);
            }
        });


    }
    ValidationOnSubmit = (srows) => {
        
        $("html, body").animate({ scrollTop: 0 }, "slow");
        let currentComponent = this;
        //const srows = [...currentComponent.state.rows]

        let err = false;
        //for (let index = 0; index < srows.length; index++) {
        //    
        //}
        //checkouttime > checkintime
        if (srows.filter(rows => moment(rows.checkoutTime).isValid()
            && moment(rows.checkinTime).isValid()
            && rows.checkoutTime < rows.checkinTime).length > 0) {
            err = true;
            currentComponent.toasterfun2("Please ensure the Check-out time is greater than the Check-in time");
        }
        //else if (srows.filter(rows => moment(rows.callTime).isValid()
        //    && moment(rows.checkinTime).isValid()
        //    && rows.checkinTime > rows.callTime).length > 0) {
        //    err = true;
        //    currentComponent.toasterfun2("Please ensure the Check-in time is lesser than the Call-in time");
        //}
        else if (srows.filter(rows => moment(rows.firstMealIn).isValid()
            && moment(rows.firstMealOut).isValid()
            && rows.firstMealIn < rows.firstMealOut).length > 0) {
            err = true;
            currentComponent.toasterfun2("Please ensure the Meal-in time is greater than the Meal-out time");
        }
        else if (srows.filter(rows => moment(rows.secondMealIn).isValid()
            && moment(rows.secondMealOut).isValid()
            && rows.secondMealIn < rows.secondMealOut).length > 0) {
            err = true;
            currentComponent.toasterfun2("Please ensure the Meal-in time is greater than the Meal-out time");
        }
        else if (srows.filter(rows => moment(rows.firstMealOut).isValid()
            && moment(rows.secondMealOut).isValid()
            && rows.secondMealOut < rows.firstMealOut).length > 0) {
            err = true;
            currentComponent.toasterfun2("Please ensure the Second meal time is greater than the First Meal time");
        }
        else if (srows.filter(rows => moment(rows.checkoutTime).isValid()
            && moment(rows.firstMealIn).isValid()
            && rows.checkoutTime < rows.firstMealIn).length > 0) {
            err = true;
            currentComponent.toasterfun2("Please ensure the Check-out time is greater than the Meal time");
        }
        else if (srows.filter(rows => moment(rows.checkoutTime).isValid()
            && moment(rows.firstMealOut).isValid()
            && rows.checkoutTime < rows.firstMealOut).length > 0) {
            err = true;
            currentComponent.toasterfun2("Please ensure the Check-out time is greater than the Meal time");
        }
        else if (srows.filter(rows => moment(rows.checkoutTime).isValid()
            && moment(rows.secondMealIn).isValid()
            && rows.checkoutTime < rows.secondMealIn).length > 0) {
            err = true;
            currentComponent.toasterfun2("Please ensure the Check-out time is greater than the Meal time");
        }
        else if (srows.filter(rows => moment(rows.checkoutTime).isValid()
            && moment(rows.secondMealOut).isValid()
            && rows.checkoutTime < rows.secondMealOut).length > 0) {
            err = true;
            currentComponent.toasterfun2("Please ensure the Check-out time is greater than the Meal time");
        }
        else if (srows.filter(rows => moment(rows.checkinTime).isValid()
            && moment(rows.firstMealOut).isValid()
            && rows.checkinTime > rows.firstMealOut).length > 0) {
            err = true;
            currentComponent.toasterfun2("Please ensure the Check-in time is less than the Meal time");
        }
        else if (srows.filter(rows => moment(rows.checkinTime).isValid()
            && moment(rows.secondMealOut).isValid()
            && rows.checkinTime > rows.secondMealOut).length > 0) {
            err = true;
            currentComponent.toasterfun2("Please ensure the Check-in time is less than the Meal time");
        }
        else if (srows.filter(rows => moment(rows.checkoutTime).isValid()
            && moment(rows.ndb).isValid()
            && rows.checkoutTime < rows.ndb).length > 0) {
            err = true;
            currentComponent.toasterfun2("Please ensure the Check-out time is greater than the NDB time");
        }
        else if (srows.filter(rows => moment(rows.checkoutTime).isValid()
            && moment(rows.ndd).isValid()
            && rows.checkoutTime < rows.ndd).length > 0) {
            err = true;
            currentComponent.toasterfun2("Please ensure the Check-out time is greater than the NDD time");
        }
        else if (srows.filter(rows => moment(rows.checkinTime).isValid()
            && moment(rows.ndd).isValid()
            && rows.checkinTime > rows.ndd).length > 0) {
            err = true;
            currentComponent.toasterfun2("Please ensure the Check-in time is less than the NDD time");
        }
        else if (srows.filter(rows => moment(rows.checkoutTime).isValid()
            && moment(rows.grace).isValid()
            && rows.checkoutTime < rows.grace).length > 0) {
            err = true;
            currentComponent.toasterfun2("Please ensure the Check-out time is greater than the Grace time");
        }
        else if (srows.filter(rows => moment(rows.checkinTime).isValid()
            && moment(rows.grace).isValid()
            && rows.checkinTime > rows.grace).length > 0) {
            err = true;
            currentComponent.toasterfun2("Please ensure the Check-in time is less than the Grace time");
        }
        else if (srows.filter(rows => moment(rows.checkinTime).isValid()
            && moment(rows.ndb).isValid()
            && new Date(new Date(rows.checkinTime).setHours(new Date(rows.checkinTime).getHours() + 2))  < new Date(rows.ndb)
            // && new Date(rows.checkinTime).addHours(2) < rows.ndb
        ).length > 0) {
            err = true;
            currentComponent.toasterfun2("NDB cannot be accepted after 2 hours from the check-in time");
        }
        return err;
    }
    handleSubmit(event) {
        
        $("html, body").animate({ scrollTop: 0 }, "slow");
       
            //console.log(this.state.rows);
            let currentComponent = this;
        
            let data = '';
            const srows = [...currentComponent.state.rows]
        if (!currentComponent.ValidationOnSubmit(srows)) {
            if (srows.filter(rows => rows.isChanged == true).length > 0) {
                currentComponent.setState({ loadingTime: true });

                var rows = currentComponent.parseValues();
                data = JSON.stringify(rows)
                if (rows.length > 0) {
                    currentComponent.CommonFunctionToSaveData(data);
                }
            }
            else {
                currentComponent.setState({ loadingTime: false });
                currentComponent.toasterfun2("No Data to save as there are no changes");

            }

            //currentComponent.GetDataShootingSel(currentComponent.state.ShootingID);
        }
    }
    PopupCloseReferBack() {
        this.setState({ popupReferBack: true });
    }
    PopupCloseWardrobe() {
        this.setState({ popupWardrobe: true });
    }

    handleWardrobe = index => e => {

        let currentComponent = this;
        var id = currentComponent.state.rows[index].id;
        var target = e.target;
        var name = target.getAttribute('name');


        APIGet('AdminSkinUpload/GetWardrobePropDetails/' + id + "/" + name, function (result) {
            if (result.code === 200) {
                // ;
                if (name == "Wardrobe") {
                    currentComponent.setState({

                        WardrobeList: result.result,
                        popupWardrobe: false,
                        popupName: name
                    })
                }
                else if (name == "Property") {
                    currentComponent.setState({

                        WardrobeList: result.result,
                        popupWardrobe: false,
                        popupName: name
                    })
                }

            } else {
                currentComponent.toasterfun2(result.message);
            }
        });


    }
    handleProperty = index => e => {
        let currentComponent = this;
        var id = currentComponent.state.rows[index].id;

        APIGet('SkinUpload/GetPropertyDetails/' + id, function (result) {
            if (result.code === 200) {
                currentComponent.setState({
                    PropertyList: result.result,
                    popupProperty: false
                })

            } else {
                currentComponent.toasterfun2(result.message);
            }
        });


    }
    handleReferBack = index => e => {
        let currentComponent = this;
        var id = currentComponent.state.rows[index].id;

        APIGet('SkinUpload/GetReferBackDetails/' + id, function (result) {
            if (result.code === 200) {
                currentComponent.setState({
                    ReferBackList: result.result,
                    popupReferBack: false
                })

            } else {
                currentComponent.toasterfun2(result.message);
            }
        });


    }
    CommonFunctionToSaveData(data) {
        let currentComponent = this;
        //  console.log(data);
        //let userfullName = localStorage.getItem('userfullName');
        let userfullName = ''; //localStorage.getItem('PACurrentUserId');

        APIPost('AdminSkinUpload/UpdateProjectDetailsForActorByAccountant', { ProjectDetailsForActorModels: data }, function (result) {
            if (result.code == 200) {
                currentComponent.toasterfun2("Details saved Successfully");
                currentComponent.GetDataShootingSel(currentComponent.state.ShootingID,false);
            } else {
                currentComponent.toasterfun2(result.message);
            }
            currentComponent.setState({ loadingTime: false });
        });

    }
    //handleRowSubmit(event) {
    handleRowSubmit = index => e => {

        //alert(index);
        let currentComponent = this;
        currentComponent.setState({ loadingTime: true });
        $("html, body").animate({ scrollTop: 0 }, "slow");
        //console.log(currentComponent.state.rows[index]);  
        // var rows = [...this.state.rows];
        var rows = currentComponent.parseValues();
        //data = JSON.stringify(rows)
        var id = currentComponent.state.rows[index].id;
        rows = rows.filter(rows => rows.id === id)
        let data = '';
        data = JSON.stringify(rows)
        currentComponent.CommonFunctionToSaveData(data);
    }
    handleChange(event) {

        var target = event.target;
        var name = target.getAttribute('name');
        var type = target.type;
        if (type == "select-one") {

            var index = event.target.selectedIndex;
            var optionElement = event.target.childNodes[index];
            var option = optionElement.getAttribute('data-id');
            var value = optionElement.getAttribute('value');
            if (name == "Shooting") {

                if (event.target.id === "ddlUnSubmittedShooting") {
                    let element = document.getElementById("ddlSubmittedShooting");
                    element.value = "";

                }
                else if (event.target.id === "ddlSubmittedShooting") {
                    let element = document.getElementById("ddlUnSubmittedShooting");
                    element.value = "";
                }
                this.setState({ loadingTime: true });
                
                let userRole = this.state.UserRole;
                this.setState({ DVExcelButtonVisible: (userRole == "Paymaster" || userRole == "EMS Admin") });
                this.setState({ LockButtonAccess: (userRole == "EMS Admin") });
                this.setState({ HTGConfiguration: !(userRole == "Paymaster") });
                //if (userRole == "EMS Admin") {//userRole == "Paymaster" ||
                //    this.setState({ loadingTime: true });
                //    this.setState({ LockButtonAccess: true });
                //}
                //else {
                //    this.setState({ LockButtonAccess: false });
                //}
                
                
                //if (userRole == "Paymaster") {//userRole == "Paymaster" ||

                //    this.setState({ HTGConfiguration: false });
                //}
                //else { this.setState({ HTGConfiguration: true });}
                this.setState({
                    ShootingID: option,
                    ShootingName: value
                });
                var strstartdate = optionElement.getAttribute('value').toString().split(' ');
                var startdate = '';
                if (new Date(strstartdate[strstartdate.length - 1]) != "Invalid Date" && !isNaN(new Date(strstartdate[strstartdate.length - 1]))) {
                    startdate = this.formatdate(strstartdate[strstartdate.length - 1]);
                }
                this.setState({ ShootDate: startdate });

                if (option != null) {
                    this.setState({
                        rows: [],
                        BulkUpdaterows: [],
                        BulkShootingRaterows: [],
                        BulkUpdateSelectedRows: [],
                        prevBulkUpdateSelectedRows: [],
                         isBulkUpdateButtonDisplay: false
                        

                    });

                    this.GetDataShootingSel(option,true);
                    
                    //this.GetDataBulkUpdateShootingSel(option);
                    this.LoadShootingBumpRate(option);
                    this.CheckLock(option)
                }
                else {
                    this.setState({
                        loadingTime: false,
                        rows: [],
                        BulkUpdaterows: [],
                        BulkShootingRaterows: [],
                        BulkUpdateSelectedRows: [],
                        prevBulkUpdateSelectedRows: [],
                        isBulkUpdateButtonDisplay: false

                    });

                }
                this.setState({ ReportType: 0 })
            }
            else if (name == "ddlBump") {

                var id = optionElement.getAttribute('value')
                var value = optionElement.getAttribute('data - id')
                this.setState({
                    BulkChangeBumpSel: id,
                    BulkChangeBumpSelVal: value,
                    BulkUpdateSelectedRows: this.state.prevBulkUpdateSelectedRows,
                });
                if (id == "mileageMiles" || id == "wardrobeQuantity" || id == "interviewHours" || id == "fittingHours") {
                    this.setState({ BumpAmtDesc: "Quantity" });
                }
                else
                    this.setState({ BumpAmtDesc: "Amount" });
            }
            else if (name == "ddlDateTime") {

                var id = optionElement.getAttribute('value')
                var value = optionElement.getAttribute('data - id')

                this.setState({
                    BulkChangeDateSel: id,
                    BulkChangeDateSelVal: value,
                    BulkUpdateSelectedRows: this.state.prevBulkUpdateSelectedRows,
                });
            }
            else if (name == "ddlValidHour") {
                var id = optionElement.getAttribute('value')
                this.setState({
                    popBaseRateDenominator: id
                });
            }

        }
        else if (type == "text") {
            if (name == "BumpRate") {
                var value = target.value;
                //this.setState({ BumpRate: !isNaN(parseFloat(value)) ? parseFloat(value) : "" });
                this.setState({ BumpRate: value });
            }
        }
        $('[class*="Component-horizontalScrollContainer"]').addClass('Component-horizontalScrollContainer').removeClass('Component-horizontalScrollContainer-12');
        $('[class*="jss12"]').addClass('Component-horizontalScrollContainer').removeClass('jss12');
    }


    CheckLogin() {
        ////let userfullName = localStorage.getItem('userfullName');
        //let userfullName = localStorage.getItem('PACurrentUserId');
        //if (userfullName == null) {
        //    this.props.history.push("/ProductionAccountant/Login");
        //}

        let currentComponent = this;
        APIGet('AdminSkinUpload/CheckLogin', function (result) {
            if (result.code === 415) {
                currentComponent.setState({ isLoggedIn: false })
                // this.props.history.push("/ProductionAccountant/Login");
            }
            else {
                currentComponent.setState({ isLoggedIn: true, UserRole: result.result })
            }
        });

    }
    LoadHTGVisibility() {
        let currentComponent = this;
        APIPost('AdminSkinupload/LoadHTGVisibility/', null, function (result) {
            if (result.code === 200) {
                // console.log(result.result);
                currentComponent.setState({
                    HTGConfiguration: result.result.htgConfiguration,
                    PAShootReport: result.result.paShootReport,
                    PADetailedReport: result.result.paDetailedReport
                });

            } else {
                currentComponent.toasterfun2(result.message);
            }
        });


    }
    LoadShooting(ShootDate, IsSubmitted) {

        let currentComponent = this;
        let PAUsername = localStorage.getItem('PAUsername');
        //var ShootDate = currentComponent.state.ShootDate;
        if (ShootDate != null) {
            ShootDate = moment.utc(ShootDate).format();
        }
        //else { ShootDate = moment.utc(new Date()).format()}
        // var ShootDate = moment(currentComponent.state.ShootDate).format();
        APIGet('SkinUpload/GetAllShootingListForProdAccountant?ShootDate=' + ShootDate + '&IsSubmitted=' + IsSubmitted, function (result) {
            if (result.code === 200) {
                if (IsSubmitted) {
                    currentComponent.setState({ SubmittedShootingList: result.result });
                }
                else {
                    currentComponent.setState({ UnSubmittedShootingList: result.result });
                }
            }
            else if (result.code === 202) {
                currentComponent.setState({ loadingTime: false });
                if (IsSubmitted) {
                    currentComponent.toasterfun2("No records found for Submitted Shoot");
                    currentComponent.setState({ SubmittedShootingList: [] });
                }
                else {
                    currentComponent.toasterfun2("No records found for Unsubmitted Shoot");
                    currentComponent.setState({ UnSubmittedShootingList: [] });
                }
            }
            else {
                currentComponent.setState({ loadingTime: false });
                currentComponent.toasterfun2(result.message);
            }
        });
        currentComponent.setState({ loadingTime: false });
    }

    LoadShootingBumpRate(ShootingID) {

        let currentComponent = this;
        // var ShootingID =currentComponent.state.ShootingID
        let data = {
            ShootingId: ShootingID
        }
        APIPost('SkinUpload/GetShootingBumpRate', data, function (result) {
            if (result.code === 200) {
                currentComponent.setState({
                    BulkShootingRaterows: result.result
                });
            }
            else {
                if (result.message)
                    currentComponent.toasterfun2(result.message);
            }
        });
    }

    GetDataBulkUpdateShootingSel(ShootingSelOption) {
        let currentComponent = this;
        var strURL = "GetShootingDetailsforAccountantByShootingID";
        var data = { ShootingId: ShootingSelOption }
        APIPost('AdminSkinUpload/' + strURL, data, function (result) {
            if (result.code === 200) {
                currentComponent.setState({ BulkUpdaterows: result.result, loading: false });
            } else {
                currentComponent.toasterfun2(result.message);
            }
        });
        currentComponent.setState({ loading: false });
    }

    CheckLock(ShootingSelOption) {
        let currentComponent = this;
        let data = {
            ShootingId: ShootingSelOption
        }
        APIPost('SkinUpload/GetShootingByShootingID', data, function (result) {
            if (result.code === 200) {
                currentComponent.setState({
                    LockButtonVisible: !result.result[0].isLocked,
                    UnLockButtonVisible: result.result[0].isLocked

                });

            } else {
                currentComponent.toasterfun2(result.message);
            }
        });


    }
    ShowNotes = () => {
        this.setState({ ShowNotesPopup: false });

    }
    hideNotes = () => {
        this.setState({ ShowNotesPopup: true });

    }

    GenerateDetailedReport() {
        let currentComponent = this;
        currentComponent.setState({ loadingTime: true });
        let ShootingID = { Id: parseInt(currentComponent.state.ShootingID) };
        this.setState({ ReportType: 2 })


        APIPost('Report/GetReport_DetailedReport', ShootingID, function (result) {
            if (result.code === 100) {
                currentComponent.setState({ DetailedReportResult: result.result, DetailedReportreplaceKeys: JSON.parse(result.commonResult), DetailedReportFileName: result.fileName })
                currentComponent.toasterfun2(result.message);
                currentComponent.setState({ loadingTime: false });
            } else {
                currentComponent.toasterfun2(result.message);
                currentComponent.setState({ loadingTime: false });
            }
        });
    }
    GenerateShootReport() {
        let currentComponent = this;
        currentComponent.setState({ loadingTime: true });
        let ShootingID = {
            Id: parseInt(currentComponent.state.ShootingID)
        };
        this.setState({ ReportType: 1 })

        APIPost('Report/GetReport_TalentBreakdownReport', ShootingID, function (result) {
            if (result.code === 100) {
                //
                currentComponent.setState({ TalentBreakdownResult: result.result.talenBreakdownReportResponseModel, TalentBreakdownreplaceKeys: JSON.parse(result.result.talenBreakdownReportReplaceKeys), TalentBreakdownFileName: result.fileName })
                currentComponent.setState({ TalentBreakdownAggregationResult: result.result.talenBreakdownAggregationModel, TalentBreakdownAggregationReplaceKeys: JSON.parse(result.result.talenBreakdownAggregationReplaceKeys) })
                currentComponent.toasterfun2(result.message);
                currentComponent.setState({ loadingTime: false });
            } else {
                currentComponent.toasterfun2(result.message);
                currentComponent.setState({ loadingTime: false });
            }
        });



    }
    async CalculateHTG() {
        let currentComponent = this;
        currentComponent.setState({ loadingTime: true });
        var ShootingID = this.state.ShootingID;
        // try {
        //const response =  fetch('RuleEngine/NRuleAgainstShootingID/' + ShootingID + '/0', {
        //    method: 'post'
        //});
        //const result =  response.json();
        //if (result.code === 200) {

        //    currentComponent.GetDataShootingSel(ShootingID);

        //} else {
        //    currentComponent.setState({ loadingTime: false });
        //    currentComponent.toasterfun2(result.message);
        //}
        let rows = currentComponent.state.rows;
        if (!currentComponent.ValidationOnSubmit(rows)) {
            let data = {
                ShootingId: ShootingID,
                ShootingActorsMappingID: 0
            }
            APIPost('RuleEngine/NRuleAgainstShootingID', data, function (result) {
                currentComponent.GetDataShootingSel(ShootingID, false);
            });

        }
        else {
            currentComponent.setState({ loadingTime: false });

        }
    }
    GetDataShootingSel(ShootingSelOption,fnvisible) {
        //;
        let currentComponent = this;
        var strURL = "GetShootingDetailsforAccountantByShootingID";
        var data = { ShootingId: ShootingSelOption }
        APIPost('AdminSkinUpload/' + strURL, data, function (result) {
            if (result.code === 200) {
                
                currentComponent.setState({
                    rows: result.result,
                    loading: false,
                    isTableDisplay: result.result.length > 0 ? true : false,

                    BulkUpdaterows: result.result,
                    BulkUpdateSelectedRows: result.result,
                    prevBulkUpdateSelectedRows: result.result,
                    isBulkUpdateButtonDisplay: result.result.length,
                    ShootNotes: result.commonResult.shootNotes,
                    ARConfimation: result.commonResult.arConfimation,
                    DVExcel: result.commonResult.dVExcelModels,
                    dVExcelModelsReplaceKeys: JSON.parse(result.commonResult.dVExcelModelsReplaceKeys),
                    dVExcelModelsReportFileName: result.commonResult.dVExcelModelsReportFileName,
                });
                if (fnvisible) {
                    currentComponent.columnvisibility(result.result);
                }
                currentComponent.setState({ loadingTime: false });
                // currentComponent.setState({ isTableDisplay: result.result.length > 0 ? true : false });
                //currentComponent.GetDataBulkUpdateShootingSel(ShootingSelOption);
            } else {
                currentComponent.toasterfun2(result.message);
                currentComponent.setState({
                    loadingTime: false,
                    ShootNotes: ""
                });
            }
        });
    }


    render() {
        const isTableDisplay = this.state.isTableDisplay;
        const HTGConfiguration = !this.state.HTGConfiguration;



        let ValidHourList = this.state.ValidHourList.map((item, i) => {
            return (
                <option key={i} value={item.id} data-id={item.name}>{item.name}</option>
            )
        }, this);
        let BumpList = this.state.BumpList.map((item, i) => {
            return (
                <option key={i} value={item.id} data-id={item.name}>{item.name}</option>
            )
        }, this);
        let DateTimeList = this.state.DateTimeList.map((item, i) => {
            return (
                <option key={i} value={item.id} data-id={item.name}>{item.name}</option>
            )
        }, this);
        //;
        var arrhour = [];
        for (let i = 0; i <= 12; i++) {
            var hour = ("0" + i).slice(-2);//moment(i).format('hh');
            arrhour.push(<option key={hour} value={hour} data-id={hour}>{hour}</option>)
        }

        let HoursList = arrhour;

        var arrMin = [];
        for (let i = 0; i <= 59; i++) {
            var Min = ("0" + i).slice(-2);
            arrMin.push(<option key={Min} value={Min} data-id={Min}>{Min}</option>)
        }

        let MinutesList = arrMin;
        var landing = '/css/assets/img/ems-logo.gif';

        return (
            <>
                {this.state.isLoggedIn ?
                    <Layout>

                        <span className={"loading " + (this.state.loadingTime ? "show" : "hide")} ><span className="loading-div-d"></span> <img src={process.env.PUBLIC_URL + landing} /> </span>
                        <div className={"toaster-div " + (this.state.toasterCla ? "show" : "hide")}><i className="fa fa-check-circle" aria-hidden="true"></i> {this.state.toaster} </div>

                        <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                            <div className="kt-portlet kt-portlet--mobile mb-0">
                                <div className="kt-portlet__body">
                                    <div action="" className="kt-form kt-form--label-right">
                                        <div className="row">
                                            <div className="col-lg-2 col-md-2">

                                                <div className="form-group mm-date">
                                                    <label>Shoot Date</label>

                                                    <DatePickerInput
                                                        displayFormat='MM/DD/YYYY'
                                                        returnFormat='MM/DD/YYYY'
                                                        className='my-react-component'
                                                        onChange={this.OnDateChange}
                                                        onShow={this.log.bind(this, 'show')}
                                                        onHide={this.log.bind(this, 'hide')}
                                                        showOnInputClick
                                                        locale='de'
                                                        onClear={this.onClear}
                                                        value={this.state.ShootDate}
                                                        maxDate={new Date()}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4  plus-bttn-x">
                                                <div className="form-group">
                                                    <label>Unsubmitted Shoot</label>
                                                    <select className="form-control" name="Shooting" onChange={this.handleChange} id="ddlUnSubmittedShooting" >
                                                        <option value="">Select</option>
                                                        {this.state.UnSubmittedShootingList.map(Shooting =>
                                                            <option key={Shooting.id} value={Shooting.shootingName} data-id={Shooting.id}>{Shooting.shootingName}</option>
                                                        )}
                                                    </select>

                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4  plus-bttn-x">
                                                <div className="form-group">
                                                    <label>Submitted Shoot</label>
                                                    <select className="form-control" name="Shooting" onChange={this.handleChange} id="ddlSubmittedShooting" >
                                                        <option value="">Select</option>
                                                        {this.state.SubmittedShootingList.map(Shooting =>
                                                            <option key={Shooting.id} value={Shooting.shootingName} data-id={Shooting.id}>{Shooting.shootingName}</option>
                                                        )}
                                                    </select>

                                                </div>
                                            </div>


                                        </div>

                                        <div className="my-3  kt-separator kt-separator--border-dashed kt-separator--space-lg kt-separator--portlet-fit">
                                        </div>


                                        <div className="kt-portlet__foot employee-actions" style={{ display: isTableDisplay ? 'block' : 'none' }}  >
                                            <div className="kt-form__actions" >
                                                <div className="row" >
                                                    <div className="col-lg-12 green-btn-div">

                                                        <button type="submit" style={{ 'display': this.state.LockButtonVisible && !this.state.LockButtonAccess ? 'inline' : 'none' }} className="btn btn-sm btn-primary m-lr-5" onClick={this.handleSubmit}>Save</button>

                                                        <button type="submit" style={{ 'display': this.state.LockButtonVisible && this.state.SubmitButtonVisible && !this.state.LockButtonAccess ? 'inline' : 'none' }} className="btn btn-sm btn-primary m-lr-5" onClick={this.handleBulkUpdate}>Bulk Update </button>

                                                        <button type="submit" style={{ 'display': this.state.LockButtonVisible && this.state.SubmitButtonVisible && !this.state.LockButtonAccess ? 'inline' : 'none' }} className="btn btn-sm btn-primary m-lr-5" onClick={this.handleARReviewSubmit}>Submit </button>

                                                        <button type="submit" style={{ 'display': this.state.LockButtonAccess && this.state.LockButtonVisible ? 'inline' : 'none' }} className="btn btn-sm btn-primary m-lr-5" onClick={this.handleLock}>Lock Shoot </button>
                                                        <button type="submit" style={{ 'display': this.state.LockButtonAccess && this.state.UnLockButtonVisible ? 'inline' : 'none' }} className="btn btn-sm btn-primary m-lr-5" onClick={this.handleUnLock}>UnLock Shoot </button>
                                                        <button type="submit" style={{ 'display': 'none' }} className="btn btn-sm btn-primary m-lr-5" onClick={this.handleSubmitNotificaction}>Send Notification To BG's</button>
                                                        <button type="submit" style={{ 'display': 'none' }} className="btn btn-sm btn-primary m-lr-5" onClick={this.handleExcelExport}>Excel Export</button>

                                                        <button type="reset" className="btn btn-sm btn-primary m-lr-5" onClick={this.ClearData}>Cancel</button>
                                                        <button type="submit" style={{ 'display': this.state.HTGConfiguration ? 'inline' : 'none' }} className="btn btn-sm btn-primary m-lr-5" onClick={this.CalculateHTG}>Calculate HTG </button>
                                                        <button type="submit" style={{ 'display': this.state.PAShootReport ? 'inline' : 'none' }} className="btn btn-sm btn-primary m-lr-5" onClick={this.GenerateShootReport}>Talents Breakdown Report</button>
                                                        <button type="submit" style={{ 'display': this.state.PADetailedReport ? 'inline' : 'none' }} className="btn btn-sm btn-primary m-lr-5" onClick={this.GenerateDetailedReport}>Detailed Report</button>
                                                        <button type="submit" style={{ 'display': (this.state.ShootNotes && this.state.ShootNotes != "") ? 'inline' : 'none' }} className="btn btn-sm btn-primary m-lr-5" onClick={this.ShowNotes}>Notes</button>
                                                        <button type="button" style={{ 'display': (this.state.ReportType == 1 || this.state.ReportType == 2) ? 'inline' : 'none' }} className="btn btn-sm btn-primary m-lr-5" onClick={() => this.setState({ ReportType: 0 })}>Back</button>
                                                        <ExportXL className={this.state.DVExcelButtonVisible && this.state.ARConfimation ? "btn-download m-lr-5 la-download" : "btn-download m-lr-5 la-download hide"}
                                                            data={[
                                                                { SheetName: "Sheet1", Data: this.state.DVExcel, ReplaceKeys: this.state.dVExcelModelsReplaceKeys }
                                                            ]}
                                                            fileName={(this.state.dVExcelModelsReportFileName && this.state.dVExcelModelsReportFileName != '') ? this.state.dVExcelModelsReportFileName : 'EMS_DV'}
                                                            label="DV Excel"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="my-3  kt-separator kt-separator--border-dashed kt-separator--space-lg kt-separator--portlet-fit  hide">
                                        </div>
                                        {this.state.ReportType == 0 ?

                                            <div className="col-md-12 bulk-div-x popup-bulk divmm ">
                                                <MaterialTable
                                                    title=""
                                                    columns={[
                                                        {
                                                            title: 'Action', field: 'UnLockButtonVisible', width: 60, render: rowData => <span style={{ display: this.state.UnLockButtonVisible || this.state.LockButtonAccess ? 'none' : "inline" }}>
                                                                <button className="btn btn-sm btn-sav-xdiv" onClick={this.handleRowSubmit(rowData.rowId)}  >
                                                                    <img src={process.env.PUBLIC_URL + '/css/assets/img/save-icon.png'} alt="Save" />
                                                                </button></span>, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.Action
                                                        },
                                                        { title: 'SI No. ', field: 'rowId', width: 150, render: rowData => <span>  {parseInt(rowData.rowId) + 1} </span>, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.rowId },
                                                        { title: 'CheckIn #', field: 'checkRefNumber', width: 80, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.checkRefNumber },
                                                        { title: 'First Name', field: 'firstName', width: 150, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.firstName },
                                                        { title: 'Middle Name', field: 'middleName', width: 150, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.middleName },
                                                        { title: 'Last Name', field: 'lastName', width: 150, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.lastName },

                                                        {
                                                            title: 'SSN', field: 'ssn', width: 150, render: rowData => <span className="achorLink" onClick={this.PopupOpenSSn(rowData.rowId)}>{rowData.ssn.toString() != "" ? "XXX-XX-" + rowData.ssn.toString().substring(12, 7) : ""}</span>, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.ssn
                                                        },
                                                        { title: 'Role', field: 'role', width: 150, hiddenByColumnsButton: true, hidden: this.state.hideColumn.role },
                                                        { title: 'Job Title', field: 'jobTitle', width: 150, hiddenByColumnsButton: true, hidden: this.state.hideColumn.jobTitle },
                                                        { title: 'Guild', field: 'unionID', width: 150, hiddenByColumnsButton: true, hidden: this.state.hideColumn.unionID },
                                                        { title: 'Call Time', field: 'callTime', width: 150, hiddenByColumnsButton: true, hidden: this.state.hideColumn.callTime },

                                                        { title: 'Base Rate', field: 'baseRate', width: 80, render: rowData => <span className="pull-left"> {rowData.baseRate}< span style={{ display: !this.state.UnLockButtonVisible && !this.state.LockButtonAccess ? 'inline-block' : 'none' }} className="baseRate" name="baseRate" onClick={(e) => { this.handleRowEditPopup(rowData.rowId, e, rowData) }}>  <img name="baseRate" src={process.env.PUBLIC_URL + '/css/assets/img/edit.png'} alt="Save" /></span> </span>, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.baseRate },
                                                        { title: 'Rate Adjusted', field: 'rateAdjusted', width: 80, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.rateAdjusted },
                                                        { title: 'Check-in', field: 'checkinTime', width: 200, render: rowData => <span className="pull-left">{moment(rowData.checkinTime).isValid() ? moment(rowData.checkinTime).format('MM/DD/YYYY hh:mm A') : ''} <span style={{ display: moment(rowData.checkinTime).isValid() && !this.state.UnLockButtonVisible && !this.state.LockButtonAccess ? 'inline-block' : 'none' }} className="editOption" name="checkinTime" onClick={(e) => { this.handleRowEditPopup(rowData.rowId, e, rowData) }}>  <img name="checkinTime" src={process.env.PUBLIC_URL + '/css/assets/img/edit.png'} alt="Save" /></span></span>, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.checkinTime },
                                                        { title: 'Check-out', field: 'checkoutTime', width: 200, render: rowData => <span className="pull-left">{moment(rowData.checkoutTime).isValid() ? moment(rowData.checkoutTime).format('MM/DD/YYYY hh:mm A') : ''} <span style={{ display: moment(rowData.checkoutTime).isValid() && !this.state.UnLockButtonVisible && !this.state.LockButtonAccess ? 'inline-block' : 'none' }} className="editOption" name="checkoutTime" onClick={(e) => { this.handleRowEditPopup(rowData.rowId, e, rowData) }}>  <img name="checkoutTime" src={process.env.PUBLIC_URL + '/css/assets/img/edit.png'} alt="Save" /></span></span>, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.checkoutTime },
                                                        { title: 'First Meal-Out', field: 'firstMealOut', width: 200, render: rowData => <span className="pull-left">{moment(rowData.firstMealOut).isValid() ? moment(rowData.firstMealOut).format('MM/DD/YYYY hh:mm A') : ''} <span style={{ display: moment(rowData.firstMealOut).isValid() && !this.state.UnLockButtonVisible && !this.state.LockButtonAccess ? 'inline-block' : 'none' }} className="editOption" name="firstMealOut" onClick={(e) => { this.handleRowEditPopup(rowData.rowId, e, rowData) }}>  <img name="firstMealOut" src={process.env.PUBLIC_URL + '/css/assets/img/edit.png'} alt="Save" /></span> &nbsp;<i class="fa fa-trash" aria-hidden="true" name="firstMealOut" onClick={(e) => { this.deleteTimevalue(rowData.rowId, e, rowData) }} style={{ display: moment(rowData.firstMealOut).isValid() && !this.state.UnLockButtonVisible && !this.state.LockButtonAccess ? 'inline-block' : 'none' }}></i></span>, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.firstMealOut },
                                                        { title: 'First Meal-In', field: 'firstMealIn', width: 200, render: rowData => <span className="pull-left">{moment(rowData.firstMealIn).isValid() ? moment(rowData.firstMealIn).format('MM/DD/YYYY hh:mm A') : ''} <span style={{ display: moment(rowData.firstMealIn).isValid() && !this.state.UnLockButtonVisible && !this.state.LockButtonAccess ? 'inline-block' : 'none' }} className="editOption" name="firstMealIn" onClick={(e) => { this.handleRowEditPopup(rowData.rowId, e, rowData) }}>  <img name="firstMealIn" src={process.env.PUBLIC_URL + '/css/assets/img/edit.png'} alt="Save" /></span> &nbsp;<i class="fa fa-trash" aria-hidden="true" name="firstMealIn" onClick={(e) => { this.deleteTimevalue(rowData.rowId, e, rowData) }} style={{ display: moment(rowData.firstMealIn).isValid() && !this.state.UnLockButtonVisible && !this.state.LockButtonAccess ? 'inline-block' : 'none' }}></i></span>, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.firstMealIn },
                                                        { title: 'Second Meal-Out', field: 'secondMealOut', width: 200, render: rowData => <span className="pull-left">{moment(rowData.secondMealOut).isValid() ? moment(rowData.secondMealOut).format('MM/DD/YYYY hh:mm A') : ''} <span style={{ display: moment(rowData.secondMealOut).isValid() && !this.state.UnLockButtonVisible && !this.state.LockButtonAccess ? 'inline-block' : 'none' }} className="editOption" name="secondMealOut" onClick={(e) => { this.handleRowEditPopup(rowData.rowId, e, rowData) }}>  <img name="secondMealOut" src={process.env.PUBLIC_URL + '/css/assets/img/edit.png'} alt="Save" /></span> &nbsp;<i class="fa fa-trash" aria-hidden="true" name="secondMealOut" onClick={(e) => { this.deleteTimevalue(rowData.rowId, e, rowData) }} style={{ display: moment(rowData.secondMealOut).isValid() && !this.state.UnLockButtonVisible && !this.state.LockButtonAccess ? 'inline-block' : 'none' }}></i></span>, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.secondMealOut },
                                                        { title: 'Second Meal-In', field: 'secondMealIn', width: 200, render: rowData => <span className="pull-left">{moment(rowData.secondMealIn).isValid() ? moment(rowData.secondMealIn).format('MM/DD/YYYY hh:mm A') : ''} <span style={{ display: moment(rowData.secondMealIn).isValid() && !this.state.UnLockButtonVisible && !this.state.LockButtonAccess ? 'inline-block' : 'none' }} className="editOption" name="secondMealIn" onClick={(e) => { this.handleRowEditPopup(rowData.rowId, e, rowData) }}>  <img name="secondMealIn" src={process.env.PUBLIC_URL + '/css/assets/img/edit.png'} alt="Save" /></span> &nbsp;<i class="fa fa-trash" aria-hidden="true" name="secondMealIn" onClick={(e) => { this.deleteTimevalue(rowData.rowId, e, rowData) }} style={{ display: moment(rowData.secondMealIn).isValid() && !this.state.UnLockButtonVisible && !this.state.LockButtonAccess ? 'inline-block' : 'none' }}></i></span>, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.secondMealIn },
                                                        { title: 'NDB', field: 'ndb', width: 200, render: rowData => <span className="pull-left">{moment(rowData.ndb).isValid() ? moment(rowData.ndb).format('MM/DD/YYYY hh:mm A') : ''} <span style={{ display: moment(rowData.ndb).isValid() && !this.state.UnLockButtonVisible && !this.state.LockButtonAccess ? 'inline-block' : 'none' }} className="editOption" name="ndb" onClick={(e) => { this.handleRowEditPopup(rowData.rowId, e, rowData) }}>  <img name="ndb" src={process.env.PUBLIC_URL + '/css/assets/img/edit.png'} alt="Save" /></span> &nbsp;<i class="fa fa-trash" aria-hidden="true" name="ndb" onClick={(e) => { this.deleteTimevalue(rowData.rowId, e, rowData) }} style={{ display: moment(rowData.ndb).isValid() && !this.state.UnLockButtonVisible && !this.state.LockButtonAccess ? 'inline-block' : 'none' }}></i></span>, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.ndb },
                                                        { title: 'NDD', field: 'ndd', width: 200, render: rowData => <span className="pull-left">{moment(rowData.ndd).isValid() ? moment(rowData.ndd).format('MM/DD/YYYY hh:mm A') : ''} <span style={{ display: moment(rowData.ndd).isValid() && !this.state.UnLockButtonVisible && !this.state.LockButtonAccess ? 'inline-block' : 'none' }} className="editOption" name="ndd" onClick={(e) => { this.handleRowEditPopup(rowData.rowId, e, rowData) }}>  <img name="ndd" src={process.env.PUBLIC_URL + '/css/assets/img/edit.png'} alt="Save" /></span> &nbsp;<i class="fa fa-trash" aria-hidden="true" name="ndd" onClick={(e) => { this.deleteTimevalue(rowData.rowId, e, rowData) }} style={{ display: moment(rowData.ndd).isValid() && !this.state.UnLockButtonVisible && !this.state.LockButtonAccess ? 'inline-block' : 'none' }}></i></span>, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.ndd },
                                                        { title: 'Grace', field: 'grace', width: 200, render: rowData => <span className="pull-left">{moment(rowData.grace).isValid() ? moment(rowData.grace).format('MM/DD/YYYY hh:mm A') : ''} <span style={{ display: moment(rowData.grace).isValid() && !this.state.UnLockButtonVisible && !this.state.LockButtonAccess ? 'inline-block' : 'none' }} className="editOption" name="grace" onClick={(e) => { this.handleRowEditPopup(rowData.rowId, e, rowData) }}>  <img name="grace" src={process.env.PUBLIC_URL + '/css/assets/img/edit.png'} alt="Save" /></span> &nbsp;<i class="fa fa-trash" aria-hidden="true" name="grace" onClick={(e) => { this.deleteTimevalue(rowData.rowId, e, rowData) }} style={{ display: moment(rowData.grace).isValid() && !this.state.UnLockButtonVisible && !this.state.LockButtonAccess ? 'inline-block' : 'none' }}></i></span>, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.grace },
                                                        { title: 'Wardrobe', field: 'wardrobeRate', width: 80, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.wardrobeRate },
                                                        { title: 'Wardrobe Quantity', field: 'wardrobeQuantity', width: 80, render: rowData => <span><InputField disabled={this.state.UnLockButtonVisible && this.state.LockButtonAccess ? "true" : ""} onChange={this.handleInputChange} index={rowData.rowId} detail={rowData} value={rowData.wardrobeQuantity} name="wardrobeQuantity" /> </span>, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.wardrobeQuantity },
                                                        { title: 'Per Diem', field: 'carAllowance', width: 80, render: rowData => <span><InputField disabled={this.state.UnLockButtonVisible && this.state.LockButtonAccess ? "true" : ""} onChange={this.handleInputChange} index={rowData.rowId} detail={rowData} value={rowData.carAllowance} name="carAllowance" onKeyDown={this.onlyDigits} /> </span>, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.carAllowance },
                                                        { title: 'Auto', field: 'auto', width: 80, render: rowData => <span><InputField disabled={this.state.UnLockButtonVisible && this.state.LockButtonAccess ? "true" : ""} onChange={this.handleInputChange} index={rowData.rowId} detail={rowData} value={rowData.auto} name="auto" onKeyDown={this.onlyDigits} /> </span>, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.auto },
                                                        { title: 'Interviews', field: 'interview', width: 80, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.interview },
                                                        { title: 'Interview Hours', field: 'interviewHours', width: 80, render: rowData => <span><InputField disabled={this.state.UnLockButtonVisible && this.state.LockButtonAccess ? "true" : ""} onChange={this.handleInputChange} index={rowData.rowId} detail={rowData} value={rowData.interviewHours} name="interviewHours" /> </span>, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.interviewHours },
                                                        { title: 'Fittings', field: 'fitting', width: 80, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.fitting },
                                                        { title: 'Fitting Hours', field: 'fittingHours', width: 80, render: rowData => <span><InputField disabled={this.state.UnLockButtonVisible && this.state.LockButtonAccess ? "true" : ""} onChange={this.handleInputChange} index={rowData.rowId} detail={rowData} value={rowData.fittingHours} name="fittingHours" /> </span>, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.fittingHours },
                                                        { title: 'Special Compensation', field: 'specialAbility', width: 105, render: rowData => <span><InputField disabled={this.state.UnLockButtonVisible && this.state.LockButtonAccess ? "true" : ""} onChange={this.handleInputChange} index={rowData.rowId} detail={rowData} value={rowData.specialAbility} name="specialAbility" onKeyDown={this.onlyDigits} /> </span>, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.specialAbility },
                                                        { title: 'Smoke Work', field: 'smokeWork', width: 80, render: rowData => <span><InputField disabled={this.state.UnLockButtonVisible && this.state.LockButtonAccess ? "true" : ""} onChange={this.handleInputChange} index={rowData.rowId} detail={rowData} value={rowData.smokeWork} name="smokeWork" onKeyDown={this.onlyDigits} /> </span>, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.smokeWork },
                                                        { title: 'Body Makeup', field: 'bodyMakeup', width: 80, render: rowData => <span><InputField disabled={this.state.UnLockButtonVisible && this.state.LockButtonAccess ? "true" : ""} onChange={this.handleInputChange} index={rowData.rowId} detail={rowData} value={rowData.bodyMakeup} name="bodyMakeup" onKeyDown={this.onlyDigits} /> </span>, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.bodyMakeup },
                                                        { title: 'Hair Cut', field: 'hairCompensation', width: 80, render: rowData => <span><InputField disabled={this.state.UnLockButtonVisible && this.state.LockButtonAccess ? "true" : ""} onChange={this.handleInputChange} index={rowData.rowId} detail={rowData} value={rowData.hairCompensation} name="hairCompensation" onKeyDown={this.onlyDigits} /> </span>, filtering: false, fother1: true, hidden: this.state.hideColumn.hairCompensation },
                                                        { title: 'Wet Work', field: 'wetWork', width: 80, render: rowData => <span><InputField disabled={this.state.UnLockButtonVisible && this.state.LockButtonAccess ? "true" : ""} onChange={this.handleInputChange} index={rowData.rowId} detail={rowData} value={rowData.wetWork} name="wetWork" onKeyDown={this.onlyDigits} /> </span>, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.wetWork },
                                                        { title: 'Beard', field: 'beardCompensation', width: 80, render: rowData => <span><InputField disabled={this.state.UnLockButtonVisible && this.state.LockButtonAccess ? "true" : ""} onChange={this.handleInputChange} index={rowData.rowId} detail={rowData} value={rowData.beardCompensation} name="beardCompensation" onKeyDown={this.onlyDigits} /> </span>, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.beardCompensation },
                                                        { title: 'Dress or Uniforms', field: 'dressUniformCompensation', width: 80, render: rowData => <span><InputField disabled={this.state.UnLockButtonVisible && this.state.LockButtonAccess ? "true" : ""} onChange={this.handleInputChange} index={rowData.rowId} detail={rowData} value={rowData.dressUniformCompensation} name="dressUniformCompensation" onKeyDown={this.onlyDigits} /> </span>, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.dressUniformCompensation },
                                                        { title: 'Mileage', field: 'mileage', width: 80, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.mileage },
                                                        { title: 'Mileage Miles', field: 'mileageMiles', width: 80, render: rowData => <span><InputField disabled={this.state.UnLockButtonVisible && this.state.LockButtonAccess ? "true" : ""} onChange={this.handleInputChange} index={rowData.rowId} detail={rowData} value={rowData.mileageMiles} name="mileageMiles" /> </span>, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.mileageMiles },

                                                        { title: 'Other Amt', field: 'other', width: 80, render: rowData => <span><InputField disabled={this.state.UnLockButtonVisible && this.state.LockButtonAccess ? "true" : ""} onChange={this.handleInputChange} index={rowData.rowId} detail={rowData} value={rowData.other} name="other" onKeyDown={this.onlyDigits} /> </span>, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.other },
                                                        { title: 'Other Description', field: 'otherDescription', width: 250, render: rowData => <span><InputField_Text disabled={this.state.UnLockButtonVisible && this.state.LockButtonAccess ? "true" : ""} onChange={this.handleInputChange} index={rowData.rowId} detail={rowData} value={rowData.otherDescription} name="otherDescription" /> </span>, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.otherDescription },
                                                        { title: 'Other1 Amt', field: 'other1', width: 80, render: rowData => <span><InputField onChange={this.handleInputChange} index={rowData.rowId} detail={rowData} value={rowData.other1} name="other1" onKeyDown={this.onlyDigits} /> </span>, filtering: false, hiddenByColumnsButton: true, hidden: this.state.colOtherName1, hidden: this.state.hideColumn.other1 },
                                                        { title: 'Other Description1', field: 'otherDescription1', width: 250, render: rowData => <span><InputField_Text disabled={this.state.UnLockButtonVisible && this.state.LockButtonAccess ? "true" : ""} onChange={this.handleInputChange} index={rowData.rowId} detail={rowData} value={rowData.otherDescription1} name="otherDescription1" /> </span>, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.otherDescription1 },
                                                        { title: 'Other2 Amt', field: 'other2', width: 80, render: rowData => <span><InputField disabled={this.state.UnLockButtonVisible && this.state.LockButtonAccess ? "true" : ""} onChange={this.handleInputChange} index={rowData.rowId} detail={rowData} value={rowData.other2} name="other2" onKeyDown={this.onlyDigits} /> </span>, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.other2 },
                                                        { title: 'Other Description2', field: 'otherDescription2', width: 250, render: rowData => <span><InputField_Text disabled={this.state.UnLockButtonVisible && this.state.LockButtonAccess ? "true" : ""} onChange={this.handleInputChange} index={rowData.rowId} detail={rowData} value={rowData.otherDescription2} name="otherDescription2" /> </span>, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.otherDescription2 },

                                                        {
                                                            title: 'Inquiry', field: 'isReferBackComments', width: 80, render: rowData => <span ><button className="btn btn-sm btn-sav-xdiv"
                                                                style={{ display: rowData.isReferBackComments ? 'inline-block' : 'none' }}
                                                                onClick={this.handleReferBack(rowData.rowId)}  >
                                                                Inquiry</button></span>, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.isReferBackComments
                                                        },
                                                        {
                                                            title: 'Wardrobe', field: 'isWardrobeComments', width: 80, render: rowData => <span ><button className="btn btn-sm btn-sav-xdiv"
                                                                style={{ display: rowData.isWardrobeComments ? 'inline-block' : 'none' }}
                                                                onClick={this.handleWardrobe(rowData.rowId)}
                                                                name="Wardrobe">
                                                                Wardrobe</button></span>, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.isWardrobeComments
                                                        },
                                                        {
                                                            title: 'Property', field: 'isPropertyComments', width: 80, render: rowData => <span ><button className="btn btn-sm btn-sav-xdiv"
                                                                style={{ display: rowData.isPropertyComments ? 'inline-block' : 'none' }}
                                                                onClick={this.handleWardrobe(rowData.rowId)}
                                                                name="Property">
                                                                Property</button></span>, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.isPropertyComments
                                                        },

                                                        { title: 'Comments', field: 'checkoutNotes', width: 120, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.checkoutNotes },
                                                        { title: 'Hourly Hours ', field: 'hourlyHours', width: 80, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.hourlyHours },
                                                        { title: 'Hourly Rate ', field: 'hourlyRate', width: 80, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.hourlyRate },
                                                        { title: 'Hourly Amount ', field: 'hourlyAmount', width: 80, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.hourlyAmount },
                                                        { title: 'OverTime Hours ', field: 'overTimeHours', width: 80, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.overTimeHours },
                                                        { title: 'OverTime Rate ', field: 'overTimeRate', width: 80, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.overTimeRate },
                                                        { title: 'OverTime Amount ', field: 'overTimeAmount', width: 80, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.overTimeAmount },
                                                        { title: 'DoubleTime Hours ', field: 'doubleTimeHours', width: 80, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.doubleTimeHours },
                                                        { title: 'DoubleTime Rate ', field: 'doubleTimeRate', width: 80, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.doubleTimeRate },
                                                        { title: 'DoubleTime Amount ', field: 'doubleTimeAmount', width: 80, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.doubleTimeAmount },
                                                        { title: 'Gold Time Hours', field: 'goldTimeHours', width: 80, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.goldTimeHours },
                                                        { title: 'Gold Time Amount', field: 'goldTimeAmount', width: 80, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.goldTimeAmount },
                                                        { title: 'MealPenalty1 Qty ', field: 'mealPenalty1Qty', width: 80, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.mealPenalty1Qty },
                                                        { title: 'MealPenalty1 Amount ', field: 'mealPenalty1Amount', width: 80, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.mealPenalty1Amount },
                                                        { title: 'MealPenalty2 Qty ', field: 'mealPenalty2Qty', width: 80, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.mealPenalty2Qty },
                                                        { title: 'MealPenalty2 Amount ', field: 'mealPenalty2Amount', width: 80, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.mealPenalty2Amount },
                                                        { title: 'MealPenalty3 Qty ', field: 'mealPenalty3Qty', width: 80, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.mealPenalty3Qty },
                                                        { title: 'MealPenalty3 Amount ', field: 'mealPenalty3Amount', width: 80, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.mealPenalty3Amount },
                                                        { title: 'MealPenalty', field: 'mealPenalty', width: 80, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.mealPenalty },
                                                        { title: 'MPV1', field: 'mpV1', width: 80, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.mpV1 },
                                                        { title: 'MPV2', field: 'mpV2', width: 80, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.mpV2 },
                                                        { title: 'NightPremium1 Qty ', field: 'nightPremium1Qty', width: 90, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.nightPremium1Qty },
                                                        { title: 'NightPremium1 Amount ', field: 'nightPremium1Amount', width: 90, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.nightPremium1Amount },
                                                        { title: 'NightPremium2 Qty ', field: 'nightPremium2Qty', width: 90, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.nightPremium2Qty },
                                                        { title: 'NightPremium2 Amount ', field: 'nightPremium2Amount', width: 90, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.nightPremium2Amount },
                                                        { title: 'NightPremium ', field: 'nightPremium', width: 90, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.nightPremium },

                                                        {
                                                            title: 'Action', field: 'UnLockButtonVisible', width: 80, render: rowData => <span style={{ display: this.state.UnLockButtonVisible || this.state.LockButtonAccess ? 'none' : "inline" }}>
                                                                <button className="btn btn-sm btn-sav-xdiv" onClick={this.handleRowSubmit(rowData.rowId)}  >
                                                                    <img src={process.env.PUBLIC_URL + '/css/assets/img/save-icon.png'} alt="Save" />
                                                                </button></span>, filtering: false, hiddenByColumnsButton: true, hidden: this.state.hideColumn.Action
                                                        },



                                                    ]}
                                                    data={this.state.rows}

                                                    options={{
                                                        selection: false,
                                                        filtering: true,
                                                        pageSizeOptions: [10, 20, 50, 100, 200, 300, 400, 500, 1000, 2000, 3000, 5000, 10000],
                                                        pageSize: 10,
                                                        isLoading: true,
                                                        columnsButton: true,
                                                        //fixedColumns: {
                                                        //    left: 3,
                                                        //    right: 0
                                                        //},
                                                        //toolbar: true,
                                                        //doubleHorizontalScroll: true
                                                    }}

                                                    localization={{
                                                        toolbar: {
                                                            showColumnsTitle: "Hide / Unhide Columns",
                                                            addRemoveColumns: "Hide / Unhide Columns",
                                                        }
                                                    }}
                                                    onChangeColumnHidden={(column, hidden) => this.handlecolumnvisibility(column, hidden)}
                                                />
                                            </div>
                                            : this.state.ReportType == 1 ?
                                                <Tabs selectedIndex={this.state.tabIndex} onSelect={tabIndex => this.setState({ tabIndex })}>
                                                    <TabList>
                                                        <Tab>Details</Tab>
                                                        <Tab>Summary</Tab>
                                                        <ExportXL className="pull-right" style={{ marginTop: "-0.5%" }}
                                                            data={[
                                                                { SheetName: "Detail", Data: this.state.TalentBreakdownResult, ReplaceKeys: this.state.TalentBreakdownreplaceKeys },
                                                                { SheetName: "Summary", Data: this.state.TalentBreakdownAggregationResult, ReplaceKeys: this.state.TalentBreakdownAggregationReplaceKeys },
                                                            ]}
                                                            fileName={(this.state.TalentBreakdownFileName && this.state.TalentBreakdownFileName != '') ? this.state.TalentBreakdownFileName : 'Talent_Breakdown'}
                                                        />
                                                    </TabList>
                                                    <TabPanel>
                                                        <MaterialTable
                                                            title="Talent Breakdown"
                                                            columns={[
                                                                { title: "Count", field: "count" },
                                                                { title: "Guild", field: "guild" },
                                                                { title: "Job Title", field: "jobTitle" },
                                                                //{ title: "Last Name", field: "lastName" },
                                                                { title: "Rate", field: "baseRate" },
                                                                { title: "Rate Adjusted", field: "rateAdjusted" },
                                                                { title: "Call", field: "call" },
                                                                { title: "Dismiss", field: "dismiss" },
                                                                { title: "First Meal", field: "firstMeal" },
                                                                { title: "Second Meal", field: "secondMeal" },
                                                                { title: "NDB", field: "ndb" },
                                                                { title: "NDD", field: "ndd" },
                                                                { title: "Grace", field: "grace" },
                                                                { title: "MPV", field: "mpv" },
                                                                { title: "Night Premium 10%", field: "nightPremium1Amount" },
                                                                { title: "Night Premium 20%", field: "nightPremium2Amount" },
                                                                { title: "1.5x OT Hours", field: "overTimeAmount" },
                                                                { title: "2x OT Hours", field: "doubleTimeAmount" },
                                                                { title: "Over Sixteen", field: "goldTimeAmount" },
                                                                { title: "Wardrobe", field: "wardrobe" },
                                                                { title: "Per Diem", field: "perDiem" },
                                                                { title: "Auto", field: "auto" },
                                                                { title: "Interviews", field: "interview" },
                                                                { title: "Fittings", field: "fitting" },
                                                                { title: "Special Compensation", field: "specialCompensation" },
                                                                { title: "Smoke Work", field: "smokeWork" },
                                                                { title: "Body Makeup", field: "bodyMakeup" },
                                                                { title: "Hair Cut", field: "hairCompensation" },
                                                                { title: "Wet Work", field: "wetWork" },
                                                                { title: "Beard", field: "beard" },
                                                                { title: "Dress or Uniforms", field: "dressOrUniform" },
                                                                { title: "Mileage", field: "mileage" },
                                                                { title: "Other Description", field: "otherDescription" },
                                                                { title: "Other", field: "other" },                                                                
                                                                { title: "Gross", field: "gross" },
                                                                { title: "Work Date", field: "workDate" },
                                                                { title: "Net Hours", field: "netHours" },
                                                                

                                                            ]}
                                                            data={this.state.TalentBreakdownResult}
                                                            class="mat-paginator-sticky"
                                                            title=""
                                                            options={{
                                                                pageSizeOptions: [5, 10, 20, 50],
                                                                pageSize: 10
                                                            }}
                                                        />
                                                    </TabPanel>
                                                    <TabPanel>
                                                        <MaterialTable
                                                            title="Talent Breakdown"
                                                            columns={[
                                                                { title: "Total", field: "jobTitle" },
                                                                { title: "", field: "totalGross" }
                                                            ]}
                                                            data={this.state.TalentBreakdownAggregationResult}
                                                            class="mat-paginator-sticky"
                                                            title=""
                                                            options={{
                                                                pageSizeOptions: [5, 10, 20, 50],
                                                                pageSize: 10
                                                            }}
                                                        />
                                                    </TabPanel>
                                                </Tabs>
                                                : this.state.ReportType == 2 ?
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <ExportXL className="pull-right" style={{ marginTop: "-0.5%" }}
                                                                data={[
                                                                    { SheetName: "Detailed_Report", Data: this.state.DetailedReportResult, ReplaceKeys: this.state.DetailedReportreplaceKeys }
                                                                ]}                                                                
                                                                fileName={(this.state.DetailedReportFileName && this.state.DetailedReportFileName != '') ? this.state.DetailedReportFileName : 'Detailed_Report'}
                                                            />
                                                        </div>
                                                        <div className="col-md-12">
                                                            <MaterialTable
                                                                title="Detailed Report"
                                                                columns={[
                                                                    { title: "Last Name", field: "lastname" },
                                                                    { title: "First Name", field: "firstname" },
                                                                    { title: "Email", field: "email" },
                                                                    { title: "Guild", field: "guild" },
                                                                    { title: "Job Title", field: "jobTitle" },
                                                                    { title: "Role", field: "role" },
                                                                    { title: "Check In #", field: "checkRefNumber" },
                                                                    { title: "Base Rate", field: "baseRate" },
                                                                    { title: "Rate Adjustment", field: "rateAdjustment" },
                                                                    { title: "Call Time", field: "callTime" },
                                                                    { title: "Wrap Time", field: "wrapTime" },
                                                                    { title: "NDB", field: "ndb" },
                                                                    { title: "Grace 1", field: "grace1" },
                                                                    { title: "Grace 2", field: "grace2" },
                                                                    { title: "First Meal Out", field: "firstMealOut" },
                                                                    { title: "First Meal In", field: "firstMealIn" },
                                                                    { title: "Meal Time", field: "mealTime" },
                                                                    { title: "Second Meal Out", field: "secondMealOut" },
                                                                    { title: "Second Meal In", field: "secondMealIn" },
                                                                    { title: "SMealTime", field: "sMealTime" },
                                                                    { title: "MPV", field: "mealPenalty" },
                                                                    { title: "NetHours", field: "netHours" },
                                                                    { title: "1.5x OT Hours", field: "overTimeAmount" },
                                                                    { title: "2x OT Hours", field: "doubleTimeAmount" },
                                                                    { title: "Over Sixteen", field: "goldTimeAmount" },
                                                                    { title: "Night Premium 10%", field: "nightPremium1Amount" },
                                                                    { title: "Night Premium 20%", field: "nightPremium2Amount" },
                                                                    { title: "Project Name", field: "projectName" },
                                                                    { title: "Work State", field: "workState" },
                                                                    { title: "Date", field: "date" },
                                                                    { title: "Wardrobe", field: "wardrobe" },
                                                                    { title: "Per Diem", field: "perDiem" },
                                                                    { title: "Auto", field: "auto" },
                                                                    { title: "Interviews", field: "interview" },
                                                                    { title: "Fittings", field: "fitting" },
                                                                    { title: "Special Compensation", field: "specialCompensation" },
                                                                    { title: "Smoke Work", field: "smokeWork" },
                                                                    { title: "Body Makeup", field: "bodyMakeup" },
                                                                    { title: "Hair Cut", field: "hairCompensation" },
                                                                    { title: "Wet Work", field: "wetWork" },
                                                                    { title: "Beard", field: "beard" },
                                                                    { title: "Dress or Uniforms", field: "dressOrUniform" },
                                                                    { title: "Mileage", field: "mileage" },
                                                                    { title: "Other Description", field: "otherDescription" },
                                                                    { title: "Other", field: "other" },                                                                    
                                                                    { title: "Gross", field: "gross" },
                                                                    { title: "Comments", field: "comments" },
                                                                    { title: "Residence State", field: "residenceState" },
                                                                ]}
                                                                data={this.state.DetailedReportResult}
                                                                class="mat-paginator-sticky"
                                                                title=""
                                                                options={{
                                                                    pageSizeOptions: [5, 10, 20, 50],
                                                                    pageSize: 10
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                        }



                                        <ReactTooltip type="light" place="bottom" effect="float" border={true} wrapper="span" html={true} />
                                    </div>
                                </div>

                                <div className="kt-portlet__foot employee-actions" style={{ display: isTableDisplay ? 'block' : 'none' }}  >
                                    <div className="kt-form__actions" >
                                        <div className="row" style={{ display: 'none' }}>
                                            <div className="col-lg-12">

                                                <button type="submit" style={{ 'display': this.state.LockButtonVisible && !this.state.LockButtonAccess ? 'inline' : 'none' }} className="btn btn-sm btn-primary m-lr-5" onClick={this.handleSubmit}>Save</button>

                                                <button type="submit" style={{ 'display': this.state.LockButtonVisible && this.state.SubmitButtonVisible && !this.state.LockButtonAccess ? 'inline' : 'none' }} className="btn btn-sm btn-primary m-lr-5" onClick={this.handleBulkUpdate}>Bulk Update </button>

                                                <button type="submit" style={{ 'display': this.state.LockButtonVisible && this.state.SubmitButtonVisible && !this.state.LockButtonAccess ? 'inline' : 'none' }} className="btn btn-sm btn-primary m-lr-5" onClick={this.handleARReviewSubmit}>Submit </button>

                                                <button type="submit" style={{ 'display': this.state.LockButtonAccess && this.state.LockButtonVisible ? 'inline' : 'none' }} className="btn btn-sm btn-primary m-lr-5" onClick={this.handleLock}>Lock Shoot </button>
                                                <button type="submit" style={{ 'display': this.state.LockButtonAccess && this.state.UnLockButtonVisible ? 'inline' : 'none' }} className="btn btn-sm btn-primary m-lr-5" onClick={this.handleUnLock}>UnLock Shoot </button>
                                                <button type="submit" style={{ 'display': 'none' }} className="btn btn-sm btn-primary m-lr-5" onClick={this.handleSubmitNotificaction}>Send Notification To BG's</button>
                                                <button type="submit" style={{ 'display': 'none' }} className="btn btn-sm btn-primary m-lr-5" onClick={this.handleExcelExport}>Excel Export</button>

                                                <button type="reset" className="btn btn-sm btn-primary m-lr-5" onClick={this.ClearData}>Cancel</button>
                                                <button type="submit" style={{ 'display': this.state.HTGConfiguration ? 'inline' : 'none' }} className="btn btn-sm btn-primary m-lr-5" onClick={this.CalculateHTG}>Calculate HTG </button>
                                                <button type="submit" style={{ 'display': this.state.PAShootReport ? 'inline' : 'none' }} className="btn btn-sm btn-primary m-lr-5" onClick={this.GenerateShootReport}>Talents Breakdown Report</button>
                                                <button type="submit" style={{ 'display': this.state.PADetailedReport ? 'inline' : 'none' }} className="btn btn-sm btn-primary m-lr-5" onClick={this.GenerateDetailedReport}>Detailed Report</button>
                                                <button type="button" style={{ 'display': (this.state.ReportType == 1 || this.state.ReportType == 2) ? 'inline' : 'none' }} className="btn btn-sm btn-primary m-lr-5" onClick={() => this.setState({ ReportType: 0 })}>Back</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div >
                        <div className={"popup-div modal " + (this.state.popupSSn ? "hide" : "show")}>
                            <div className="form-builder full-width">

                                <h4 className='pull-left full-width'>SSN</h4>
                                <div className="cls-bttn" onClick={this.PopupCloseSSn}>x</div>
                                <div className="col-lg-12 popup-view">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="popup-x">
                                            <label>Address 1
                                                </label>
                                            <div className="lebel-div bd-bold">
                                                {this.state.popupAddress1}
                                            </div>


                                        </div>
                                    </div>


                                    <div className="col-lg-6 col-md-6">
                                        <div className="popup-x">
                                            <label>Address 2
                                                </label>
                                            <div className="lebel-div bd-bold">
                                                {this.state.popupAddress2}
                                            </div>


                                        </div>
                                    </div>
                                    <div className="clear"></div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="popup-x">
                                            <label>City
                                                </label>
                                            <div className="lebel-div bd-bold">
                                                {this.state.popupCity}
                                            </div>


                                        </div>
                                    </div>


                                    <div className="col-lg-6 col-md-6">
                                        <div className="popup-x">
                                            <label>State
                                        </label>
                                            <div className="lebel-div bd-bold">
                                                {this.state.popupState}
                                            </div>


                                        </div>
                                    </div>
                                    <div className="clear"></div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="popup-x">
                                            <label>Country
                                                </label>
                                            <div className="lebel-div bd-bold">
                                                {this.state.popupCountry}
                                            </div>


                                        </div>
                                    </div>


                                    <div className="col-lg-6 col-md-6">
                                        <div className="popup-x">
                                            <label>Zip
                                        </label>
                                            <div className="lebel-div bd-bold">
                                                {this.state.popupZip}
                                            </div>


                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="popup-x">
                                            <label>Email
                                        </label>
                                            <div className="lebel-div bd-bold">
                                                {this.state.popupemail}
                                            </div>


                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="popup-x">
                                            <label>Phone
                                        </label>
                                            <div className="lebel-div bd-bold">
                                                {this.state.popupPhone}
                                            </div>


                                        </div>
                                    </div>

                                    <div className="clear"></div>

                                </div>
                            </div>
                        </div>
                        <div className={"modal fade " + (this.state.ShowNotesPopup ? "hide" : "show")}>
                            <div className="modal-dialog modal-lg modal-dialog-scrollable">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Notes from AD</h5>
                                        <button type="button" className="close" onClick={this.hideNotes}>
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="col-md-12 bulk-div-x bul-taps-div">

                                            {this.state.ShootNotes}


                                        </div>


                                    </div>
                                    <div className="modal-footer justify-content-center">
                                        <button type="submit" className="btn btn-primary mr-10" onClick={this.hideNotes}>OK </button>

                                    </div>
                                </div>
                            </div>
                            <span className="loading-div"></span>
                        </div>

                        <div className={"modal fade " + (this.state.popupBulkUpdate ? "hide" : "show")}>
                            <div className="modal-dialog modal-lg modal-dialog-scrollable">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Bulk Update</h5>
                                        <button type="button" className="close" onClick={this.PopupCloseBulkUpdate}>
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="col-md-12 bulk-div-x bul-taps-div">



                                            <Tabs selectedIndex={this.state.tabIndex} className="tabsSection" onSelect={tabIndex => this.setState({ tabIndex })}>
                                                <TabList>
                                                    <Tab>Compensation/Bump</Tab>
                                                    <Tab >Date time</Tab>
                                                    <Tab label="Base Rate">Base Rate
                                                    
                                    </Tab>
                                                </TabList>
                                                <TabPanel> <div className="col-md-4 pull-left">
                                                    <div className="taps-title"><label> Bump  </label> </div>

                                                    <select className="form-control" onChange={this.handleChange} name="ddlBump" value={this.state.BulkChangeBumpSel}>
                                                        <option value="">Select</option>
                                                        {BumpList}
                                                    </select>
                                                </div>
                                                    <div className="col-md-4 pull-left">
                                                        <label>{this.state.BumpAmtDesc}  </label>
                                                        <input type="text" className="form-control" name="BumpRate" onChange={this.handleChange} value={this.state.BumpRate} onKeyDown={onlyDigits} />
                                                    </div>
                                                </TabPanel>
                                                <TabPanel>  <div className="pull-left">
                                                    <div className="taps-title">Date Time</div>
                                                    <div className="col-md-2 pull-left">
                                                        <label> </label>
                                                        <select className="form-control" onChange={this.handleChange} name="ddlDateTime" value={this.state.BulkChangeDateSel}>
                                                            <option value="">Select</option>
                                                            {DateTimeList}

                                                        </select>
                                                    </div>
                                                    <div className="col-md-3 pull-left editDatepic">

                                                        <div className="form-group mm-date labelleft">
                                                            <label></label>
                                                            <DatePickerInput
                                                                displayFormat='MM/DD/YYYY'
                                                                returnFormat='MM/DD/YYYY'
                                                                className='my-react-component'
                                                                onChange={this.OnDateChangepopup}
                                                                onShow={this.log.bind(this, 'show')}
                                                                onHide={this.log.bind(this, 'hide')}
                                                                showOnInputClick
                                                                placeholder='placeholder'
                                                                locale='de'
                                                                onClear={this.onClear}
                                                                value={this.state.popDateValue}
                                                               
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className="col-md-2 pull-left">
                                                        <label></label>

                                                        <select className="form-control" name="popTimeHHValue" value={this.state.popTimeHHValue} onChange={this.handlePopupValueChange}>
                                                            {HoursList}
                                                        </select>
                                                    </div>
                                                    <div className="pull-left marLabel">

                                                        <span className="popcommo">  : </span>

                                                    </div>
                                                    <div className="col-md-2 pull-left">
                                                        <label></label>

                                                        <select className="form-control" name="popTimeMMValue" value={this.state.popTimeMMValue} onChange={this.handlePopupValueChange}>
                                                            {MinutesList}
                                                        </select>
                                                    </div>
                                                    <div className="col-md-2 pull-left">
                                                        <label></label>
                                                        <select className="form-control" name="popTimeTTValue" value={this.state.popTimeTTValue} onChange={this.handlePopupValueChange}>
                                                            <option value="AM">AM </option>
                                                            <option value="PM">PM </option>
                                                        </select>

                                        &nbsp; &nbsp;


                                    </div>
                                                    <div className="col-md-2 pull-left">
                                                        <label for="check_Delete" >Clear Values</label>
                                                        <input
                                                            data-tip="Clear Values"
                                                            type="checkbox"
                                                            name="check_Delete"
                                                            checked={this.state.check_Delete}
                                                            onChange={this.handlecheck_Delete}

                                                        />
                                                    </div>


                                                </div></TabPanel>
                                                <TabPanel> <div className="col-md-12 pull-left">
                                                    <div className="taps-title"> <label className="baseRate">Base Rate </label> </div>
                                                    <div className="pull-left">
                                                        <div className="col-md-3 pull-left">
                                                            <label></label>
                                                            <input type="text" className="form-control" name="popBaseRateNumerator" value={this.state.popBaseRateNumerator} onKeyDown={onlyDigits} onChange={this.handlePopupValueChange} />
                                                        </div>
                                                        <div className="pull-left marLabel">
                                                            <span className="fontBold">  / </span>
                                                        </div>
                                                        <div className="col-md-3 pull-left">
                                                            <label></label>

                                                            <select style={{ "width": "auto" }} className="form-control" onChange={this.handleChange} name="ddlValidHour" value={this.state.popBaseRateDenominator}>

                                                                {ValidHourList}

                                                            </select>

                                                        </div>
                                                    </div>
                                                </div>
                                                </TabPanel>
                                            </Tabs>
                                        </div>

                                        <div className="col-md-12 bulk-div-x popup-bulk">
                                            <MaterialTable
                                                title=""
                                                columns={[

                                                    { title: 'First Name', field: 'firstName' },
                                                    { title: 'Middle Name', field: 'middleName', },
                                                    { title: 'Last Name', field: 'lastName', },
                                                    //{ title: 'SSN', field: 'ssn', },//'XXX-XX-' + 'ssn.toString().substring(12, 7)'
                                                    { title: 'Guild', field: 'unionID', },
                                                    { title: 'CallTime', field: 'callTime', },
                                                    { title: 'Role', field: 'role', },
                                                ]}
                                                data={this.state.BulkUpdaterows}
                                                className="mat-paginator-sticky"
                                                options={{
                                                    selection: true,
                                                    filtering: true,
                                                    search: false,
                                                    pageSizeOptions: [15, 30, 45],
                                                    pageSize: 15,
                                                    //maxBodyHeight: 280
                                                }}
                                                pageSize={(this.state.BulkUpdaterows.length > 10) ? 10 : this.state.BulkUpdaterows.length}
                                                onSelectionChange={
                                                    (rows) => {
                                                        this.setState({
                                                            BulkUpdateSelectedRows: rows,
                                                            prevBulkUpdateSelectedRows:rows,
                                                            isBulkUpdateButtonDisplay: rows.length
                                                        });
                                                    }
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="modal-footer justify-content-center">
                                        <button type="submit" style={{ display: this.state.isBulkUpdateButtonDisplay ? 'inline' : 'none' }} className="btn btn-primary mr-10" onClick={this.handleBulkSubmit}>Save </button>
                                        <button type="button" className="btn btn-primary btn-cancel " onClick={this.PopupCloseBulkUpdate} >Cancel </button>
                                    </div>
                                </div>
                            </div>
                            <span className="loading-div"></span>
                        </div>


                        <div className={"popup-div width70 modal " + (this.state.popupReferBack ? "hide" : "show")}>
                            <span className="loading-div"></span>
                            <div className="form-builder full-width bgwhite">
                                <h4 className='pull-left full-width'>Inquiry</h4>
                                <div className="cls-bttn" onClick={this.PopupCloseReferBack}>x</div>

                                <div className="col-md-12 bulk-div-x popup-bulk">
                                    <MaterialTable
                                        title=""
                                        columns={[

                                            { title: 'Comments', field: 'comments' },
                                            { title: 'Director Comments', field: 'directorComments', },

                                        ]}
                                        data={this.state.ReferBackList}
                                        className="mat-paginator-sticky"
                                        options={{

                                            pageSizeOptions: [10, 15, 20],
                                            pageSize: 10,
                                        }}


                                    />
                                </div>

                                <div className="popup-x text-center mar45">

                                    <button type="button" className="btn btn-primary btn-cancel " onClick={this.PopupCloseReferBack} >Cancel </button>
                                </div>


                            </div>

                        </div >
                        <div className={"popup-div width70 modal " + (this.state.popupWardrobe ? "hide" : "show")}>
                            <span className="loading-div"></span>
                            <div className="form-builder full-width bgwhite">
                                <h4 className='pull-left full-width'>{this.state.popupName}</h4>
                                <div className="cls-bttn" onClick={this.PopupCloseWardrobe}>x</div>

                                <div className="col-md-12 bulk-div-x popup-bulk">
                                    <MaterialTable
                                        title=""
                                        columns={[

                                            { title: 'Name', field: 'name' },
                                            { title: 'Quantity', field: 'quantity' },
                                            { title: 'Comments', field: 'comments' },
                                            { title: 'ReturnQuantity', field: 'returnQuantity' },
                                            { title: 'DamageQuantity', field: 'damageQuantity' },
                                            { title: 'LostQuantity', field: 'lostQuantity' },

                                        ]}
                                        data={this.state.WardrobeList}
                                        className="mat-paginator-sticky"
                                        options={{

                                            pageSizeOptions: [10, 15, 20],
                                            pageSize: 10,
                                        }}


                                    />
                                </div>

                                <div className="popup-x text-center mar45">

                                    <button type="button" className="btn btn-primary btn-cancel " onClick={this.PopupCloseWardrobe} >Cancel </button>
                                </div>


                            </div>

                        </div >


                        <div className={"popup-div modal " + (this.state.datePickerPop ? "hide" : "show")}>
                            <span className="loading-div"></span>
                            <div className="form-builder full-width bgwhite">
                                <h4 className='pull-left full-width'>Edit Date Time</h4>
                                <div className="cls-bttn" onClick={this.handleEditdatecancel}>x</div>
                                <div className="marTop25 px-3">
                                    <div className="row align-items-end">
                                        <div className="col-md-5 editDatepic">
                                            <div className="form-group mm-date labelleft">
                                                <label>Date</label>
                                                <DatePickerInput
                                                    displayFormat='MM/DD/YYYY'
                                                    returnFormat='MM/DD/YYYY'
                                                    className='my-react-component'
                                                    onChange={this.OnDateChangepopup}
                                                    onShow={this.log.bind(this, 'show')}
                                                    onHide={this.log.bind(this, 'hide')}
                                                    showOnInputClick
                                                    placeholder='placeholder'
                                                    locale='de'
                                                    onClear={this.onClear}
                                                    value={this.state.popDateValue}
                                                  


                                                />

                                            </div>
                                        </div>
                                        <div className="col-md-7">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="w-100">
                                                    <select className="form-control" name="popTimeHHValue" value={this.state.popTimeHHValue} onChange={this.handlePopupValueChange}>
                                                        {HoursList}
                                                    </select>
                                                </div>
                                                <div className="marLabel" style={{ width: "75px" }}>
                                                    <span className="colonspance float-none">  : </span>
                                                </div>
                                                <div className="w-100">
                                                    <select className="form-control" name="popTimeMMValue" value={this.state.popTimeMMValue} onChange={this.handlePopupValueChange}>
                                                        {MinutesList}
                                                    </select>
                                                </div>
                                                <div className="w-100 ml-3">
                                                    <select className="form-control" name="popTimeTTValue" value={this.state.popTimeTTValue} onChange={this.handlePopupValueChange}>
                                                        <option value="AM">AM </option>
                                                        <option value="PM">PM </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="popup-x text-center mar45 marTop45">
                                    <button type="button" className="btn btn-primary btn-cancel wid110" onClick={this.handleEditdateOk}>Ok </button>
                                    <button type="button" className="btn btn-primary btn-cancel wid110" onClick={this.handleEditdatecancel} >Cancel </button>
                                </div>

                            </div>

                        </div >

                        <div className={"popup-div modal baseFullwidth " + (this.state.BaseRatePop ? "hide" : "show")}>
                            <span className="loading-div"></span>
                            <div className="form-builder full-width bgwhite">
                                <h4 className='pull-left full-width'>Edit Base Rate</h4>
                                <div className="cls-bttn" onClick={this.handleEditBaseRatecancel}>x</div>
                                <div className="inlineBl">

                                    <div className="col-md-5 inlineBl">
                                        <label></label>
                                        <input type="text" className="form-control" name="popBaseRateNumerator" value={this.state.popBaseRateNumerator} onKeyDown={onlyDigits} onChange={this.handlePopupValueChange} />
                                    </div>
                                    <div className="inlineBl marLabel">
                                        <span className="fontBold">  / </span>
                                    </div>
                                    <div className="col-md-5 inlineBl">
                                        <label></label>

                                        <select className="form-control" onChange={this.handleChange} name="ddlValidHour" value={this.state.popBaseRateDenominator}>

                                            {ValidHourList}

                                        </select>

                                    </div>

                                </div>
                                <div className="popup-x text-center mar45 marTop45">
                                    <button type="button" className="btn btn-primary btn-cancel wid110" onClick={this.handleEditBaseRateOk} >Ok </button>
                                    <button type="button" className="btn btn-primary btn-cancel wid110" onClick={this.handleEditBaseRatecancel} >Cancel </button>
                                </div>

                            </div>

                        </div >


                    </Layout>
                    :
                    <></>
                }

            </>
        );
    }

}
//<MaterialTable
//    title=""
//    columns={[
//        { title: "SI No. ", field: "firstName" },
//        { title: "First Name", field: "middleName" },
//        { title: "Middle Name", field: "lastEditedBy" },
//        { title: "Last Name", field: "lastName" },
//        { title: "SSN", field: "ssn" }
//    ]}
//    data={this.state.rows}
///>\
//<input type="text" className="form-control" name="popBaseRateDenominator" value={this.state.popBaseRateDenominator} onChange={this.handlePopupValueChange} onKeyDown={onlyDigits} />
//this.state.LockButtonVisible && !this.state.LockButtonAccess ? 'inline' :


//{ title: 'Other', field: 'other', render: rowData => <span><InputField disabled={this.state.UnLockButtonVisible && this.state.LockButtonAccess ? "true" : ""} onChange={this.handleInputChange} index={rowData.rowId} detail={rowData} value={rowData.other} name="other" onKeyDown={this.onlyDigits} /> </span>, filtering: false },
//{ title: 'Other Description', field: 'otherDescription', render: rowData => <span><InputField disabled={this.state.UnLockButtonVisible && this.state.LockButtonAccess ? "true" : ""} onChange={this.handleInputChange} index={rowData.rowId} detail={rowData} value={rowData.otherDescription} name="otherDescription" /> </span>, filtering: false },
//{ title: 'Other1', field: 'other1', render: rowData => <span><InputField disabled={this.state.UnLockButtonVisible && this.state.LockButtonAccess ? "true" : ""} onChange={this.handleInputChange} index={rowData.rowId} detail={rowData} value={rowData.other1} name="other1" onKeyDown={this.onlyDigits} /> </span>, filtering: false },
//{ title: 'Other Description1', field: 'otherDescription1', render: rowData => <span><InputField disabled={this.state.UnLockButtonVisible && this.state.LockButtonAccess ? "true" : ""} onChange={this.handleInputChange} index={rowData.rowId} detail={rowData} value={rowData.otherDescription1} name="otherDescription1" /> </span>, filtering: false },
//{ title: 'Other2', field: 'other2', render: rowData => <span><InputField disabled={this.state.UnLockButtonVisible && this.state.LockButtonAccess ? "true" : ""} onChange={this.handleInputChange} index={rowData.rowId} detail={rowData} value={rowData.other2} name="other2" onKeyDown={this.onlyDigits} /> </span>, filtering: false },
//{ title: 'Other Description2', field: 'otherDescription2', render: rowData => <span><InputField disabled={this.state.UnLockButtonVisible && this.state.LockButtonAccess ? "true" : ""} onChange={this.handleInputChange} index={rowData.rowId} detail={rowData} value={rowData.otherDescription2} name="otherDescription2" /> </span>, filtering: false },/
//maxDate = { new Date(this.state.popMaxDateValue) }
//minDate = { new Date(this.state.popMinDateValue) }
//maxDate = { new Date(this.state.popMaxDateValue) }
//minDate = { new Date(this.state.popMinDateValue) }
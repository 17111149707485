import React, { useState, useEffect } from 'react';
import { Utils, Enum, Mask, TextBox, InputGroup, Dropdown, CheckBox, EMSTimePicker, Constants } from '../EMS_Components'
import { APIGet, APIPost, APIPostWOToken } from '../EMS_Components/APICall'

const ForgotPassword = (props) => {
    const landing = '/css/assets/img/ems-logo.gif';

    const [toaster, settoaster] = useState('');
    const [toasterCla, settoasterCla] = useState(false);
    const [isverifyUserName, setisverifyUserName] = useState(true);
    const [loading, setloading] = useState(false);
    const [isOTPSent, setisOTPSent] = useState(false);

    const [formData, setformData] = useState({});
    const [errormsg, seterrormsg] = useState({});

    const [passwordPolicy, setpasswordPolicy] = useState({});
    const [email, setemail] = useState("");
    const [phone, setphone] = useState("");
    const [otpMessage, setotpMessage] = useState("");

    const toasterfun2 = (e) => {
        settoasterCla(true);
        settoaster(e);
        setTimeout(
            function () {
                settoasterCla(false);
            }.bind(this), 4000);
    }
    const closeDialog = () => {
        props.closeDialog();
    }

    const verifyUserName = () => {
        let msg = {};
        let username = formData.username;
        if (username == null || username == undefined || username == "") {
            msg.username = "Please enter the Username";
            seterrormsg(msg);
            return;
        }

        let data = {
            UserName: username
        }
        setloading(true);
        APIPostWOToken('api/Login/ResendOTP', data, function (result) {
            if (result.code == 100) {
                setemail(result.result);
                if (result.commonResult != null && result.commonResult != "")
                    setphone(result.commonResult);
                setpasswordPolicy(result.passwordPolicy);
                setloading(false);
                setisOTPSent(true);
                setisverifyUserName(false);
                setotpMessage("OTP has been sent to your registered Email and Mobile.");
            }
            else if (result.code == 107) {
                setloading(false);
                //toasterfun2(result.result);
                toasterfun2("Invalid Username");
            }
            else {
                setloading(false);
                toasterfun2("Failed to Send OTP");
            }
        });
    }

    const resendOTP = () => {
        let data = {
            UserName: formData.username
        }
        setloading(true);
        APIPostWOToken('api/Login/ResendOTP', data, function (result) {
            if (result.code == 100) {
                setemail(result.result);
                if (result.commonResult != null && result.commonResult != "")
                    setphone(result.commonResult);
                setpasswordPolicy(result.passwordPolicy);
                setloading(false);
                setisOTPSent(true);
                setisverifyUserName(false);
                setformData({ otp: "" })
                setotpMessage("OTP has been resent to your registered Email and Mobile.");
            }
            else if (result.code == 107) {
                setloading(false);
                toasterfun2(result.result);
            }
            else {
                setloading(false);
                toasterfun2("Failed to Send OTP");
            }
        });
    }

    const checkPasswordRegex = (e) => {
        let msg = {};
        let isValidPassword = true;
        if (!Utils.validateRegex(formData.password, passwordPolicy ? passwordPolicy.pattern : "")) {
            msg.password = "Invalid Password";
            isValidPassword = false;
        }
        else {
            msg.password = "";
        }
        if (!validatePassword()) {
            msg.confirmpassword = "Password does not match";
        }
        seterrormsg(msg);
        return isValidPassword;
    }

    const CheckWeakPassword = (Password) => {
        let msg = {}
        let isWeakPassword = false;
        let data = {
            NewPassword: Password
        }

        setloading(true);
        APIPostWOToken('api/Login/WeekPasswordCheck', data, function (result) {
            if (result === "true") {
                setloading(false);
                msg.password = "Invalid Password"
                seterrormsg(msg);
                isWeakPassword = true;
            } else {
                setloading(false);
                seterrormsg(msg);
                isWeakPassword = false;
            }
        });
        setloading(false);
        return isWeakPassword;
    }
    const checkOTPlenth = (e) => {
        let msg = {};
        let isValidotp = true;
        if (!formData.otp || formData.otp.length == 0) {
             msg.otp = "Please enter a OTP number";
            isValidotp = false;
        }
        else if (formData.otp.length > 4)
        {
            //formData.otp.value = formData.otp;
            formData.otp = formData.otp.slice(0,4);
            msg.otp = "OTP field is not allowing more than 4 digits";
            isValidotp = false;
        }
       
        seterrormsg(msg);
        return isValidotp;
    }

    const validatePassword = (e) => {
        let isMatch = true;
        let msg = {};
        let cPassword = formData.confirmpassword;
        let password = formData.password;

        if (password && cPassword) {
            if (password.length != 0 && cPassword.length != 0 && password != cPassword) {
                isMatch = false;
                msg.confirmpassword = "Password does not match";
            }
        }
        seterrormsg(msg);
        return isMatch;
    }

    const onPasswordBlur = (e) => {
        let password = e.target.value;
        if (password && password.length != 0)
            CheckWeakPassword(password);
    }

    const resetPassword = (e) => {
        if (!formData.otp || formData.otp.length == 0) {
            seterrormsg({ otp: "Please enter a valid OTP" });
            return;
        }
        if (formData.otp.length < 4) {
           formData.otp = formData.otp.slice(0, 4);
            seterrormsg({ otp: "Please enter minimum 4 digits OTP" });
            return;
        }
        let validpwd = checkPasswordRegex();
        if (!validpwd) {
            toasterfun2("Please enter a valid Password");
            return;
        }

        let pwdMatch = validatePassword();
        if (!pwdMatch) {
            toasterfun2("Password does not match");
            return;
        }

        let data = {
            UserName: formData.username,
            Password: formData.password,
            Role: props.role,
            Otp: formData.otp
        }

        setloading(true);
       //debugger;
        console.log(loading);
        APIPostWOToken('api/Login/ResetPassword', data, function (result) {
            if (result.code === 100) {
                setloading(false);
                toasterfun2(result.message);
                setTimeout(
                    function () {
                        closeDialog();
                    }.bind(this), 2000);
            } else {
                setloading(false);
                toasterfun2(result.message);
            }
        });
    }

    return (
        <>
            <div className={"toaster-div " + (toasterCla ? "show" : "hide")} style={{ position: "fixed" }} ><i className="fa fa-check-circle" aria-hidden="true"></i> {toaster} </div>
            <span className={"loading " + (loading ? "show" : "hide")} ><span className="loading-div-d"></span> <img src={process.env.PUBLIC_URL + landing} /> </span>
            <h4 className="text-center">Forgot Password</h4>
            {isverifyUserName ?
                <>
                    <TextBox label="Username" id="username" formdata={formData} error={errormsg} TextFor={Enum.AlphaNumeric} required />
                    <div className="form-group text-center">
                        <button type="button" className="btn btn-primary account-btn" onClick={verifyUserName}>Verify</button>
                    </div>
                </>
                :
                <>
                    <TextBox label="OTP" id="otp" formdata={formData} error={errormsg} TextFor={Enum.Numeric} maxLength={4} onChange={checkOTPlenth} required />
                    <div className={"form-group text-left " + isOTPSent ? "show" : "hide"} style={{ marginTop: "-10px" }}>
                        <span className="test3" id="sentmgs" style={{ color: "green" }}>{otpMessage}</span><br /><br />
                        <span><b>Phone : </b>{phone}</span> <br />
                        <span><b>Email : </b>{email}</span> <br />
                    </div>
                    <div className="form-group text-center">
                        <span className="d-block mobile-text">Don't receive the OTP?
                            <button type="button" className="font-weight-bold text-danger cursor resend-btn border-0" onClick={() => resendOTP()}>Resend OTP</button>
                            {/*<span style={{ cursor: "pointer" }} onClick={() => closeDialog()}> Resend OTP</span>*/}
                        </span>
                    </div>
                    <br />
                    <InputGroup label="Password" id="password" autoComplete="Off" formdata={formData} error={errormsg} Description={passwordPolicy ? passwordPolicy.passwordDescription : ""} iconclassName={"eye-icon-style"} icon={"fa fa-eye-slash"} onChange={checkPasswordRegex} onBlur={onPasswordBlur} isPasswordGroup required />
                    <InputGroup label="Confirm Password" id="confirmpassword" autoComplete="Off" formdata={formData} error={errormsg} Description={passwordPolicy ? passwordPolicy.passwordDescription : ""} iconclassName={"eye-icon-style"} icon={"fa fa-eye-slash"} onChange={validatePassword} isPasswordGroup required />
                    <div className="form-group text-center">
                        <button type="button" className="btn btn-primary account-btn" onClick={resetPassword}>Reset Password</button>
                    </div>
                </>
            }
            <div className="form-group text-center">
                <span style={{ cursor: "pointer" }} onClick={() => closeDialog()}>Login</span>
            </div>
        </>
    )
}

export default ForgotPassword
import React from 'react'
import Button from 'react-bootstrap/Button';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Utils } from '../EMS_Components';

const ExportXL = (props) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToCSV = (filedata, fileName) => {

        let wb = { SheetNames: [], Sheets: {} };
        filedata.map((file, index) => {
            let exportData;
            if (file.ReplaceKeys) {
                exportData = Utils.ChangeKeyObjects(file.Data, file.ReplaceKeys);
            }
            else {
                exportData = file.Data;
            }

            //debugger
            let ws = XLSX.utils.json_to_sheet(exportData);
            wb.SheetNames.push(file.SheetName);
            wb.Sheets[file.SheetName] = ws;
        })
        //wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    return (
        <Button className={"btn btn-sm btn-primary " + (props.className ? props.className : "")} style={props.style ? props.style : null} onClick={(e) => exportToCSV(props.data, props.fileName)}>{props.label ? props.label : "Export Excel"}</Button>
    )
}

export default ExportXL
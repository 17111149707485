import React, { useState, useEffect, useRef, Fragment } from 'react';
import moment from 'moment';

const ViewTalentDetails = (props) => {

    const [formData, setformData] = useState(props.formData ? props.formData : []);//? 

    //setformData({ ...props.formData });
    //console.log(formData);
    useEffect(() => {
        setformData({ ...props.formData });
        console.log(formData);
    }, []);
    return (

        <div className="modaltop-div" >
            <div className="modal-backdrop show"></div>
            <div className="modal-dialog modal-dialog-scrollable modal-xl modalzin-div-x msdiv-x">
                <div className="modal-content">
                    <div className="modal-header padtb10">
                        <h5 className="modal-title headingClr">View Talent Details</h5>
                        <span className='close-btn top11' onClick={props.action} >X</span>

                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <div className="row">

                                <div className="col-md-1">
                                    <div className="form-group">
                                        <label className="subtitle-div">CheckIn #</label>
                                        <div className="sublable-div">{formData[0].checkRefNumber} </div>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label className="subtitle-div">First Name</label>
                                        <div className="sublable-div">{formData[0].firstName} </div>
                                    </div>
                                </div>

                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label className="subtitle-div">Middle Initial</label>
                                        <div className="sublable-div">{formData[0].middleName}  </div>
                                    </div>
                                </div>

                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label className="subtitle-div">Last Name</label>
                                        <div className="sublable-div">{formData[0].lastName}  </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label className="subtitle-div">Email</label>
                                        <div className="sublable-div"> {formData[0].email} </div>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label className="subtitle-div">Phone</label>
                                        <div className="sublable-div">{formData[0].phoneNumber}  </div>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label className="subtitle-div">Job Title </label>
                                        <div className="sublable-div">{formData[0].jobTitle}  </div>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label className="subtitle-div">Role </label>
                                        <div className="sublable-div"> {formData[0].role}  </div>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label className="subtitle-div">Guild </label>
                                        <div className="sublable-div"> {formData[0].unionID}  </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="subtitle-div">Feedback</label>
                                        <div className="sublable-div"> {formData[0].prevDNRCheckoutNotes}  </div>
                                    </div>
                                </div>
                                <div className="col-md-2 hide">
                                    <div className="form-group">
                                        <label className="subtitle-div">Comments </label>
                                        <div className="sublable-div"> {formData[0].checkoutNotes}  </div>
                                    </div>
                                </div>
                                <hr className="hrDiv" />
                                <div className="col-md-1">
                                    <div className="form-group">
                                        <label className="subtitle-div">Base Rate </label>
                                        <div className="sublable-div"> {formData[0].baseRate}  </div>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label className="subtitle-div">Adjusted Rate</label>
                                        <div className="sublable-div"> {formData[0].rateAdjusted}  </div>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label className="subtitle-div">Check-In </label>
                                        <div className="sublable-div"> {moment(formData[0].checkinTime).isValid() ? moment(formData[0].checkinTime).format('MM/DD/YYYY hh:mm A') : '-'} </div>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label className="subtitle-div">NDB </label>
                                        <div className="sublable-div">{moment(formData[0].ndb).isValid() ? moment(formData[0].ndb).format('MM/DD/YYYY hh:mm A') : '-'}  </div>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label className="subtitle-div">First Meal-Out</label>
                                        <div className="sublable-div"> {moment(formData[0].firstMealOut).isValid() ? moment(formData[0].firstMealOut).format('MM/DD/YYYY hh:mm A') : '-'} </div>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label className="subtitle-div">First Meal-In </label>
                                        <div className="sublable-div"> {moment(formData[0].firstMealIn).isValid() ? moment(formData[0].firstMealIn).format('MM/DD/YYYY hh:mm A') : '-'} </div>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label className="subtitle-div">Second Meal-Out </label>
                                        <div className="sublable-div"> {moment(formData[0].secondMealOut).isValid() ? moment(formData[0].secondMealOut).format('MM/DD/YYYY hh:mm A') : '-'} </div>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label className="subtitle-div">Second Meal-In </label>
                                        <div className="sublable-div"> {moment(formData[0].secondMealIn).isValid() ? moment(formData[0].secondMealIn).format('MM/DD/YYYY hh:mm A') : '-'} </div>
                                    </div>
                                </div>

                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label className="subtitle-div">Grace </label>
                                        <div className="sublable-div">{moment(formData[0].grace).isValid() ? moment(formData[0].grace).format('MM/DD/YYYY hh:mm A') : '-'} </div>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label className="subtitle-div">Check-Out </label>
                                        <div className="sublable-div">{moment(formData[0].checkoutTime).isValid() ? moment(formData[0].checkoutTime).format('MM/DD/YYYY hh:mm A') : '-'}  </div>
                                    </div>
                                </div>
                                <hr className="hrDiv" />
                                <div className="col-md-6">
                                    <h4 className="greentit-div">ADJUSTMENTS</h4>
                                    <div className="col-md-4 pull-left" style={{ 'display': formData[0].specialAbility ? 'inline' : 'none' }}>
                                        <div className="form-group">
                                            <label className="subtitle-div">{formData[0].specialCompensationDesc}</label>
                                            <div className="sublable-div">{formData[0].specialAbility}  </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 pull-left" style={{ 'display': formData[0].smokeWork ? 'inline' : 'none' }}>
                                        <div className="form-group">
                                            <label className="subtitle-div">Smoke Work</label>
                                            <div className="sublable-div">{formData[0].smokeWork}  </div>
                                        </div>
                                    </div>

                                    <div className="col-md-3 pull-left" style={{ 'display': formData[0].bodyMakeup ? 'inline' : 'none' }}>
                                        <div className="form-group">
                                            <label className="subtitle-div">Body Makeup</label>
                                            <div className="sublable-div">{formData[0].bodyMakeup}  </div>
                                        </div>
                                    </div>

                                    <div className="col-md-3 pull-left" style={{ 'display': formData[0].hairCompensation ? 'inline' : 'none' }}>
                                        <div className="form-group">
                                            <label className="subtitle-div">Hair Cut</label>
                                            <div className="sublable-div">{formData[0].hairCompensation} </div>
                                        </div>
                                    </div>

                                    <div className="col-md-3 pull-left" style={{ 'display': formData[0].wetWork ? 'inline' : 'none' }}>
                                        <div className="form-group">
                                            <label className="subtitle-div">Wet Work</label>
                                            <div className="sublable-div">{formData[0].wetWork}  </div>
                                        </div>
                                    </div>

                                    <div className="col-md-3 pull-left" style={{ 'display': formData[0].beardCompensation ? 'inline' : 'none' }}>
                                        <div className="form-group">
                                            <label className="subtitle-div">Beard</label>
                                            <div className="sublable-div">{formData[0].beardCompensation}  </div>
                                        </div>
                                    </div>


                                </div>
                                <div className="col-md-6">
                                    <h4 className="greentit-div">COMPENSATIONS</h4>
                                    <div className="col-md-3 pull-left" style={{ 'display': formData[0].wardrobeRate ? 'inline' : 'none' }}>
                                        <div className="form-group">
                                            <label className="subtitle-div">Wardrobe</label>
                                            <div className="sublable-div">{formData[0].wardrobeRate}  </div>
                                        </div>
                                    </div>

                                    <div className="col-md-3 pull-left" style={{ 'display': formData[0].carAllowance ? 'inline' : 'none' }}>
                                        <div className="form-group">
                                            <label className="subtitle-div">Per Diem</label>
                                            <div className="sublable-div">{formData[0].carAllowance}  </div>
                                        </div>
                                    </div>

                                    <div className="col-md-3 pull-left" style={{ 'display': formData[0].auto ? 'inline' : 'none' }}>
                                        <div className="form-group">
                                            <label className="subtitle-div">Auto</label>
                                            <div className="sublable-div">{formData[0].auto}  </div>
                                        </div>
                                    </div>

                                    <div className="col-md-3 pull-left" style={{ 'display': formData[0].interview ? 'inline' : 'none' }}>
                                        <div className="form-group">
                                            <label className="subtitle-div">Interviews</label>
                                            <div className="sublable-div">{formData[0].interview}  </div>
                                        </div>
                                    </div>

                                    <div className="col-md-3 pull-left" style={{ 'display': formData[0].fitting ? 'inline' : 'none' }}>
                                        <div className="form-group">
                                            <label className="subtitle-div">Fittings</label>
                                            <div className="sublable-div">{formData[0].fitting}  </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 pull-left" style={{ 'display': formData[0].dressUniformCompensation ? 'inline' : 'none' }}>
                                        <div className="form-group">
                                            <label className="subtitle-div">Dress or Uniforms</label>
                                            <div className="sublable-div">{formData[0].dressUniformCompensation}  </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 pull-left" style={{ 'display': formData[0].mileage ? 'inline' : 'none' }}>
                                        <div className="form-group">
                                            <label className="subtitle-div">Mileage</label>
                                            <div className="sublable-div">{formData[0].mileage}  </div>
                                        </div>
                                    </div>


                                </div>

                                <div className="col-md-6">
                                    <h4 className="greentit-div">OTHER COMPENSATIONS</h4>
                                    <div className="col-md-3 pull-left" style={{ 'display': formData[0].other ? 'inline' : 'none' }}>
                                        <div className="form-group">
                                            <label className="subtitle-div">{formData[0].otherDescription}</label>
                                            <div className="sublable-div">{formData[0].other}  </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 pull-left" style={{ 'display': formData[0].other1 ? 'inline' : 'none' }}>
                                        <div className="form-group">
                                            <label className="subtitle-div">{formData[0].otherDescription1}</label>
                                            <div className="sublable-div">{formData[0].other1}  </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 pull-left" style={{ 'display': formData[0].other2 ? 'inline' : 'none' }}>
                                        <div className="form-group">
                                            <label className="subtitle-div">{formData[0].otherDescription2}</label>
                                            <div className="sublable-div">{formData[0].other2}  </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}
export default ViewTalentDetails
import React, { useState, useEffect, useRef, Fragment } from 'react';
import moment from 'moment';
import $ from 'jquery';
//import DateFnsUtils from '@date-io/date-fns';
//import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker, TimePicker } from '@material-ui/pickers';
import { Utils, Enum, Mask, TextBox, Dropdown, CheckBox, EMSTimePicker, Constants } from '../EMS_Components'
import { APIGetWOToken, APIPostWOToken  } from '../EMS_Components/APICall'

const AddBGActor = (props) => {

    const [toaster, settoaster] = useState('')
    const [toasterCla, settoasterCla] = useState(false)

    const [formData, setformData] = useState({ demoninators: 8, callTime: null, TBD: true  })
    const [errormsg, seterrormsg] = useState({});
    const [bgActorID, setbgActorID] = useState(0)
    const [shootingID, setshootingID] = useState(0)
    const [userID, setuserID] = useState(0)


    const demoninators = [
        { Value: 1, Text: "1" },
        { Value: 2, Text: "2" },
        { Value: 3, Text: "3" },
        { Value: 4, Text: "4" },
        { Value: 5, Text: "5" },
        { Value: 6, Text: "6" },
        { Value: 7, Text: "7" },
        { Value: 8, Text: "8" },
        { Value: 10, Text: "10" },
        { Value: 12, Text: "12" },
        { Value: 14, Text: "14" },
    ]
    const Gender = [
        { Value: 0, Text: "Male" },
        { Value: 1, Text: "Female" },
        { Value: 2, Text: "NonBinary" }
    ]
    const Jobtitle = [
        { Value: "Extra/Back Ground", Text: "Extra/Back Ground" },
        { Value: "Stand In", Text: "Stand In" },
        { Value: "Special Ability", Text: "Special Ability" },
        { Value: "Actor/Voice Over", Text: "Actor/Voice Over" },
        { Value: "Model", Text: "Model" }
    ]
    const UnionIdList = [
        { Value: "Non Union", Text: "Non Union" },
        { Value: "SAG - AFTRA", Text: "SAG - AFTRA" }
    ]

    const emailInput = useRef(null);
    const phoneInput = useRef(null);

    useEffect(() => {
        setshootingID(props.match.params.shootingId ? props.match.params.shootingId : 0);
        setuserID(props.match.params.userId ? props.match.params.userId : 0);
        if (emailInput.current != null)
            emailInput.current.focus();
    }, []);
    const chkChange = (e) => {
        let dt = formData;
        setformData({ ...dt })
    }
    const toasterfun2 = (e) => {
        settoasterCla(true);
        settoaster(e);
        setTimeout(
            function () {
                settoasterCla(false);
            }.bind(this), 4000);
    }

    const resetFields = () => {
        let dt = {
            email: '',
            firstName: '',
            lastName: '',
            phone: '',
            unionId: '',
            role: '',
            jobTitle: '',
            rate: '',
            demoninators: 8,
            checkRefNumber: '',
            isWardrobeRequired: false,
            isPropertyRequired: false,
            TBD: true,
            callTime: null,
            gender: -1,
        }
        setformData({ ...dt })
        setbgActorID(0)
        seterrormsg({})
    }

    const getActorDetails = (e) => {
        
        let msg = {};
        if (!Utils.validateEmail(e.target.value)) {
            msg.email = Constants.InvalidEmail;
            seterrormsg(msg);
            emailInput.current.focus();
            return
        }
        else {
            seterrormsg(msg);
            var strURL = "getActorDetails";
            APIGetWOToken('api/Login/' + strURL + "/" + e.target.value + "/" + parseInt(shootingID), function (result) {
                if (result.statusCode == 100) {
                    let dt = formData;
                    dt['firstName'] = result.result.firstName;
                    dt['lastName'] = result.result.lastName;
                    dt['phone'] = result.result.phone;
                    dt['unionId'] = result.result.unionName;
                    dt['gender'] = result.result.gender;
                    setformData({ ...dt })
                    setbgActorID(result.result.id);
                }
                else {
                    let dt = formData;
                    dt['firstName'] = '';
                    dt['lastName'] = '';
                    dt['phone'] = '';
                    dt['unionId'] = '';
                    dt['gender'] = '';

                    setformData({ ...dt })
                    setbgActorID(0)
                    toasterfun2(result.statusMessage);
                    phoneInput.current.focus();
                }
            });
        }
    }

    const ValidateData = () => {
        
        let isvalid = true;
        let msg = {};

        if (!formData['email'] || formData['email'] == '') {
            msg.email = "* Mandatory";
            isvalid = false
        }

        if (!bgActorID || bgActorID == 0) {
            if (!formData['phone'] || formData['phone'] == '') {
                msg.phone = "* Mandatory";
                isvalid = false
            }
            else {

                if (formData['phone'].length != 13 || !Utils.validatePhoneNumber(formData['phone'])) {
                    msg.phone = "Invalid Phone Number";
                    isvalid = false
                }
            }

            if (!formData['firstName'] || formData['firstName'] == '') {
                msg.firstName = "* Mandatory";
                isvalid = false
            }

            if (!formData['lastName'] || formData['lastName'] == '') {
                msg.lastName = "* Mandatory";
                isvalid = false
            }
            //if (!formData['gender'] || formData['gender'] == -1) {
            //    msg.gender = "* Mandatory";
            //    isvalid = false
            //}

            if (!formData['unionId'] || formData['unionId'] == '') {
                msg.unionId = "* Mandatory";
                isvalid = false
            }
        }

        if (!formData['role'] || formData['role'] == '') {
            msg.role = "* Mandatory";
            isvalid = false
        }

        if (!formData['jobTitle'] || formData['jobTitle'] == '') {
            msg.jobTitle = "* Mandatory";
            isvalid = false
        }

        if (!formData['rate'] || formData['rate'] == '') {
            msg.rate = "* Mandatory";
            isvalid = false
        }
        else {
            $('.rateMan').addClass('hide')
        }
        if (formData['TBD'] == false) {
            if (!formData['callTime'] || formData['callTime'] == '') {
                msg.callTime = "* Mandatory";
                isvalid = false
            }
        }
        
        if (!formData['demoninators'] || formData['demoninators'] == 0 || formData['demoninators'] == 'Select') {
            msg.demoninators = "* Mandatory";
            isvalid = false
        }

        seterrormsg(msg);

        if (isvalid) {
            //if (!bgActorID || bgActorID == 0) {
            //    toasterfun2("Talent not exists");
            //    isvalid = false;
            //    return isvalid;
            //}

            if (!shootingID || shootingID == 0) {
                toasterfun2("Shooting not exists");
                isvalid = false;
                return isvalid;
            }

            if (!userID || userID == 0) {
                toasterfun2("Invalid User");
                isvalid = false;
                return isvalid;
            }
        }
        return isvalid;
    }

    const fnAddActor = (bgActorID) => {
        let data = {
            shootingID: parseInt(shootingID),
            bgActorID: parseInt(bgActorID),
            role: formData.role,
            jobTitle: formData.jobTitle,
            rate: formData.rate,
            callTime: !formData.TBD? formData.callTime.toLocaleTimeString():null,
            checkRefNumber: parseInt(formData.checkRefNumber) || 0,
            demoninators: parseInt(formData.demoninators),
            isWardrobeRequired: formData.isWardrobeRequired,
            isPropertyRequired: formData.isPropertyRequired,
            userID: userID,
            userTime: new Date(),
            unionID: formData.unionId,
        }

        APIPostWOToken('api/Login/AddBGActor', data, function (result) {
            if (result.code === 100) {
                toasterfun2(result.message);
                resetFields();
            } else {
                toasterfun2(result.message);
            }
        });
    }

    const AddActor = () => {
        
        if (ValidateData() && !(!bgActorID || bgActorID == 0)) {
            fnAddActor(bgActorID);
        }
        else if (ValidateData()) {
            let data = {
                shootingID: parseInt(shootingID),
                bgActorID: parseInt(bgActorID),
                //role: formData.role,
                //jobTitle: formData.jobTitle,
                //rate: formData.rate,
                //callTime: formData.callTime.toLocaleTimeString(),
                //checkRefNumber: formData.checkRefNumber,
                //demoninators: parseInt(formData.demoninators),
                isWardrobeRequired: formData.isWardrobeRequired,
                //isPropertyRequired: formData.isPropertyRequired,
                //userID: userID,
                //userTime: new Date(),
                gender: formData.gender,
                firstname: formData.firstName,
                lastname: formData.lastName,
                email: formData.email.trim(),
                unionID: formData.unionId,
                phone: formData.phone,
            }
            var dataInsert = [];
            dataInsert.push(data);

            let reqData = {
                SkinUploads: dataInsert,
                ShootingId: shootingID
            }

            APIPostWOToken('api/Login/InsertNewPersonList', reqData, function (result) {
                if (result.code == 200) {
                    setbgActorID(result.result[0].bgActorID);
                    //bgActorID = result.result[0].bgActorID;
                    fnAddActor(result.result[0].bgActorID);
                }
            });
        }
    }

    return (
        <div>
            <div className={"toaster-div " + (toasterCla ? "show" : "hide")}><i className="fa fa-check-circle" aria-hidden="true"></i> {toaster} </div>
            <div className="">
                <div className="">
                    <div className="">
                        <div className="kt-form kt-form--label-right" style={{ padding: "20px"}}>
                            <div className="row">
                                <div className="col-md-6 col-sm-6 col-6">
                                    <TextBox label="Email" id="email" formdata={formData} error={errormsg} TextFor={Enum.Email} onBlur={getActorDetails} refValue={emailInput} required />
                                </div>

                                <div className="col-md-6 col-sm-6 col-6">
                                    <Mask label="Phone" id="phone" formdata={formData} error={errormsg} maskType={"phone"} maxLength={"10"} Numeric Mask={"(XXX)XXX-XXXX"} disabled={!bgActorID || bgActorID == 0 ? false : true} refValue={phoneInput} required />
                                </div>

                                <div className="col-md-6 col-sm-6 col-6">
                                    <TextBox label="First Name" id="firstName" formdata={formData} error={errormsg} disabled={!bgActorID || bgActorID == 0 ? false : true} TextFor={Enum.Name} required />
                                </div>

                                <div className="col-md-6 col-sm-6 col-6">
                                    <TextBox label="Last Name" id="lastName" formdata={formData} error={errormsg} disabled={!bgActorID || bgActorID == 0 ? false : true} TextFor={Enum.Name} required />
                                </div>

                                <div className="col-md-6 col-sm-6 col-6 hide">
                                    <Dropdown label="Gender" id="gender" source={Gender} formdata={formData} error={errormsg} disabled={!bgActorID || bgActorID == 0 ? false : true} />
                                </div>

                                <div className="col-md-6 col-sm-6 col-6">
                                    <Dropdown label="Guild" id="unionId" source={UnionIdList} formdata={formData} error={errormsg} required />
                                </div>

                                <div className="col-md-3 col-sm-3 col-3">
                                    <TextBox label="Role" id="role" formdata={formData} error={errormsg} TextFor={Enum.AlphaNumericWithSplChar} required />
                                </div>

                                <div className="col-md-3 col-sm-3 col-3">
                                    <Dropdown label="Job Title" id="jobTitle" source={Jobtitle} formdata={formData} error={errormsg} required />
                                </div>

                                <div className="col-md-6 col-sm-6 col-6">
                                    <div className="form-group">
                                        <label>Rate <span className="redAlert">*</span></label>
                                        <div className="row">
                                            <div className="col-md-6 col-sm-6 col-6">
                                                <TextBox id="rate" formdata={formData} error={errormsg} TextFor={Enum.Decimal} required />
                                            </div>
                                            <div className="col-md-1 col-sm-1 col-1">
                                                <span className="input-group-text" id="passeyeSet">/</span>
                                            </div>
                                            <div className="col-md-5 col-sm-5 col-5">
                                                <Dropdown id="demoninators" source={demoninators} formdata={formData} error={errormsg} required />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6 col-sm-6 col-6">
                                    <TextBox label="CheckIn #" id="checkRefNumber" formdata={formData} error={errormsg} TextFor={Enum.Numeric} />
                                </div>

                                <div className="col-md-12 col-sm-12 col-12">
                                    <div className="row">
                                        <div className="col-md-3 col-sm-4 col-4">
                                            <CheckBox label="Is Wardrobe Required" outsideLabel id="isWardrobeRequired" formdata={formData} error={errormsg} />
                                        </div>
                                        <div className="col-md-3 col-sm-4 col-4">
                                            <CheckBox label="Is Property Required" outsideLabel id="isPropertyRequired" formdata={formData} error={errormsg} />
                                        </div>
                                        <div className="col-md-6 col-sm-4 col-4 d-flex align-items-center">
                                            <EMSTimePicker label="Call Time" id="callTime" formdata={formData} error={errormsg} disabled={formData.TBD} />
                                            <div className="ml-4">
                                                <CheckBox label="TBD" id="TBD" formdata={formData} error={errormsg} checked={formData.TBD} onChange={chkChange} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="kt-portlet__foot" style={{padding: "0 20px 20px"}}>
                    <div className="kt-form__actions">
                        <button type="button" className="btn btn-sm btn-green mr-10" id="btnAdd" onClick={AddActor}>Add</button>
                        <button type="reset" className="btn btn-sm btn-secondary" style={{ backgroundColor: "gray", color: "#fff" }} onClick={resetFields}>Reset</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddBGActor

//disabled={!bgActorID || bgActorID == 0 ? false : true}
import React from 'react';
import { DatePicker, DatePickerInput } from 'rc-datepicker';
import { Multiselect } from 'multiselect-react-dropdown';
import moment from 'moment';
import { APIGet, APIPost } from '../EMS_Components/APICall'

export class Shooting extends React.Component {
    constructor(props) {
        super(props);
        this.LoadProjects();
        //this.GetCastingDirectors();
        this.LoadCountry();
        this.state = {
            loadingTime: false,
            ProjectList: [],
            LocationList: [],
            Location: "",
            LocationId: "",
            ShootingName: "",
            Address1: "",
            Address2: "",
            City: "",
            State: "",
            Country: "",
            Zip: "",
            StartDate: "",//new Date()
            ProjectSelOption: 0,
            ProjectSelValue: "",
            CastingDirectorList: [],
            CastingDirectorSelOption: 0,
            CastingDirectorSelValue: "",
            datePickerInputDate: null,
            ShootingId: this.props.dataFromParent,
            isEdit: true,
            toasterCla: false,
            toaster: '',
            showProject: true,
            showShootDate: true,
            showSecondDirector: true,            
            showAddress1: true,
            showCity: true,
            showState: true,
            showCountry: true,
            showZip: true,
            showLocation: true,
            CountryList: [],
            SelectedSecondDirectors: null,
            SelectedWardrobeDirectors: null,
            SelectedPropertyDirectors: null

        };
        this.LoadShootingDetails(this.props.dataFromParent);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.OnDateChange = this.OnDateChange.bind(this);
        this.onSelectSD = this.onSelectSD.bind(this);
        this.onSelectPD = this.onSelectPD.bind(this);
        this.onSelectWD = this.onSelectWD.bind(this);
        this.onRemoveSD = this.onRemoveSD.bind(this);
        this.onRemoveWD = this.onRemoveWD.bind(this);
        this.onRemovePD = this.onRemovePD.bind(this);

    }
    toasterfun2(e) {
        this.setState({ toasterCla: true });
        this.setState({ toaster: e });
        setTimeout(
            function () {
                this.setState({ toasterCla: false });
            }.bind(this), 3000);
    }

    componentDidMount() {
        const isEdit = this.props.dataFromParent == 0 ? false : true
        const datePicker = document.getElementsByClassName("react-datepicker-input")[0];
        datePicker.childNodes[0].setAttribute("readOnly", isEdit);
    }

    log = (...x) => console.log(...x) // eslint-disable-line no-console
    onClear = () => {
        this.setState({ datePickerInputDate: null })
        this.setState({ ShootingName: this.state.ProjectSelValue });

    }

    ClearData() {
        this.setState({
            ShootingName: "",
            Address1: "",
            Address2: "",
            City: "",
            State: "",
            Country: "",
            Zip: "",
            StartDate: "",//new Date()
            ProjectSelOption: 0,
            ProjectSelValue: "",
            CastingDirectorList: [],
            CastingDirectorSelOption: 0,
            CastingDirectorSelValue: "",
            datePickerInputDate: null
        });
        this.onClear();
        this.LoadProjects();
       // this.GetCastingDirectors();
        
    }
    
    LoadShootingDetails(ShootingID) {
        if (ShootingID != 0) {

            let currentComponent = this;
            
            let data = {
                ShootingId: ShootingID
            }
            APIPost('SkinUpload/GetShootingByShootingID', data, function (result) {
                if (result.code === 200) {
                    var strstartdate = result.result[0].shootingName.toString().split(' ');
                    var startdate = strstartdate[strstartdate.length - 1];
                    var strProject = result.result[0].shootingName.toString().split(startdate.toString());
                    var Project = strProject[0].replace(result.result[0].projectLoc.location, " ");
                    currentComponent.setState({
                        ProjectSelValue: Project,
                        ProjectSelOption: result.result[0].movieId,
                        ShootingName: result.result[0].shootingName,
                        Address1: result.result[0].address1,
                        Address2: result.result[0].address2,
                        City: result.result[0].city,
                        State: result.result[0].state,
                        Country: result.result[0].country,
                        Zip: result.result[0].zipCode,
                        datePickerInputDate: new Date(startdate),
                        StartDate: startdate,
                        Location: result.result[0].projectLoc.location,
                        LocationId: result.result[0].locationId,
                    });
                    currentComponent.GetCastingDirectors(Project, ShootingID);
                    currentComponent.GetProjectLocation(result.result[0].movieId)
                } else {
                    currentComponent.toasterfun2(result.message);
                }
            });

            //fetch('SkinUpload/GetShootingByShootingID/' + ShootingID, {
            //    method: 'post',
            //    headers: {
            //        Accept: 'application/json',
            //        'Content-Type': 'application/json',
            //        'Authorization': 'Bearer ' + localStorage.getItem('UserId')
            //    }
            //}).then(function (response) {
            //    return response.json();
            //}).then(function (result) {
            //    if (result.code === 200) {
                    
            //        var strstartdate = result.result[0].shootingName.toString().split(' ');
            //        var startdate = strstartdate[strstartdate.length - 1];
            //        var strProject = result.result[0].shootingName.toString().split(startdate.toString());
            //        var Project = strProject[0].replace(result.result[0].projectLoc.location," ");
            //        currentComponent.setState({
            //            ProjectSelValue: Project,
            //            ProjectSelOption: result.result[0].movieId,
            //            ShootingName: result.result[0].shootingName,
            //            Address1: result.result[0].address1,
            //            Address2: result.result[0].address2,
            //            City: result.result[0].city,
            //            State: result.result[0].state,
            //            Country: result.result[0].country,
            //            Zip: result.result[0].zipCode,
            //            datePickerInputDate: new Date(startdate),
            //            StartDate: startdate,
            //            Location: result.result[0].projectLoc.location,
            //            LocationId: result.result[0].locationId,
            //        });
            //        currentComponent.GetCastingDirectors(Project, ShootingID);        
            //        currentComponent.GetProjectLocation(result.result[0].movieId)

            //    } else {
            //        currentComponent.toasterfun2(result.message);
            //    }

            //}).catch(function (error) {
            //    console.log("-------- error ------- " + error);
            //    currentComponent.toasterfun2("result:" + error)
            //});
        }
        else {
            this.setState({ isEdit: false });
        }
    }
    OnDateChange(jsDate, dateString) {
        this.setState({ datePickerInputDate: dateString });
        this.setState({
            StartDate: dateString,
            showShootDate: true
        });
        this.setState({ ShootingName: this.state.ProjectSelValue+" " + this.state.Location+ " " + dateString });
    }

    GetProjectLocation(option) {
        let currentComponent = this;
        fetch('AdminSkinUpload/GetProjectLocation/' + option, {
            method: 'Get',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('UserId')
            }
        }).then(function (response) {
            return response.json();
        }).then(function (result) {
            if (result.code === 200) {
                currentComponent.setState({
                    LocationList: result.result,
                   

                })

            } else {
                currentComponent.toasterfun2(result.message);
            }

        }).catch(function (error) {
            console.log("-------- error ------- " + error);
            currentComponent.toasterfun2("result:" + error)
        });
        //Address1: result.result[0].address1,
        //Address2: result.result[0].address2,
        //City: result.result[0].city,
        //State: result.result[0].state,
        //Zip: result.result[0].zipCode,
        //Country: result.result[0].country,

        //showAddress1: true,
        //showCity: true,
        //showState: true,
        //showZip: true,
        //showCountry: true,
    }

    GetCastingDirectors(ShootingName,ShootingID) {
        let currentComponent = this;
        let data = '';
        let Name = '{"Name": "' + ShootingName + '","Id": ' + ShootingID +'}';
       // data = JSON.stringify(Name);
        console.log(Name);
        //fetch('SkinUpload/GetCastingDirectors/' + ShootingName, {
        fetch('SkinUpload/GetCastingDirectors' , {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('UserId')
            },
            body: Name
        }).then(function (response) {
            return response.json();
        }).then(function (result) {
            if (result.code === 200) {
               
                //currentComponent.setState({ CastingDirectorList: result.result });
                const rows = result.result;
                for (var i = 0; i < result.result.length; i++) {
                    rows[i] = {
                        ["key"]: result.result[i].firstname + " " + result.result[i].middleName + " " + result.result[i].lastname ,
                        ["cat"]: result.result[i].castingDirectorID,
                        ["directorType"]: result.result[i].directorType
                       
                    };
                }
                console.log(rows);
                currentComponent.setState({
                    CastingDirectorList: rows,
                    SelectedSecondDirectors: rows.filter(rows => rows.directorType === "SD"),
                    SelectedWardrobeDirectors: rows.filter(rows => rows.directorType === "WD"),
                    SelectedPropertyDirectors: rows.filter(rows => rows.directorType === "PD"),
                });
                if (ShootingID == 0) {
                    currentComponent.setState({
                        SelectedSecondDirectors: rows.filter(rows => rows.directorType === "SD"),
                        SelectedWardrobeDirectors: rows.filter(rows => rows.directorType === "WD"),
                        SelectedPropertyDirectors: rows.filter(rows => rows.directorType === "PD"),
                    });
                }
                
                if (ShootingID != 0) {
                    currentComponent.SetCastingDirectorAgainstShooting(ShootingID);
                }
            } else {
                currentComponent.toasterfun2(result.message);
            }

        }).catch(function (error) {
            console.log("-------- error ------- " + error);
            currentComponent.toasterfun2("result:" + error)
        });
    }
    SetCastingDirectorAgainstShooting(ShootingID) {
        let currentComponent = this;
        let data = {
            ShootingId: ShootingID
        }

        APIPost('SkinUpload/GetCastingDirectorsAgainstShooting', data, function (result) {
            if (result.code === 200) {
                const rows = result.result;
                for (var i = 0; i < result.result.length; i++) {
                    rows[i] = {
                        ["key"]: result.result[i].firstname + " " + result.result[i].middleName + " " + result.result[i].lastname,
                        ["cat"]: result.result[i].castingDirectorID,
                        ["directorType"]: result.result[i].directorType

                    };
                }
                currentComponent.setState({ SelectedSecondDirectors: rows.filter(rows => rows.directorType == "SD") });
                currentComponent.setState({ SelectedWardrobeDirectors: rows.filter(rows => rows.directorType == "WD") });
                currentComponent.setState({ SelectedPropertyDirectors: rows.filter(rows => rows.directorType == "PD") });
                console.log(rows.filter(rows => rows.directorType == "SD"));
                console.log(rows.filter(rows => rows.directorType == "WD"));
                console.log(rows.filter(rows => rows.directorType == "PD"));
            }
            else if (result.code === 202) {
            } else {
                currentComponent.toasterfun2(result.message);
            }
        });

        //fetch('SkinUpload/GetCastingDirectorsAgainstShooting/' + ShootingID, {
        //    method: 'post',
        //    headers: {
        //        Accept: 'application/json',
        //        'Content-Type': 'application/json',
        //        'Authorization': 'Bearer ' + localStorage.getItem('UserId')
        //    }
        //}).then(function (response) {
        //    return response.json();
        //}).then(function (result) {
        //    if (result.code === 200) {
        //        //currentComponent.setState({
        //        //    CastingDirectorSelValue: result.result[0].firstname + " " + result.result[0].middleName + " " + result.result[0].lastname,
        //        //    CastingDirectorSelOption: result.result[0].castingDirectorID
        //        //});    
        //        const rows = result.result;
        //        for (var i = 0; i < result.result.length; i++) {
        //            rows[i] = {
        //                ["key"]: result.result[i].firstname + " " + result.result[i].middleName + " " + result.result[i].lastname,
        //                ["cat"]: result.result[i].castingDirectorID,
        //                ["directorType"]: result.result[i].directorType

        //            };
        //        }
        //        currentComponent.setState({ SelectedSecondDirectors: rows.filter(rows => rows.directorType == "SD") });
        //        currentComponent.setState({ SelectedWardrobeDirectors: rows.filter(rows => rows.directorType == "WD") });
        //        currentComponent.setState({ SelectedPropertyDirectors: rows.filter(rows => rows.directorType == "PD") });
        //        console.log(rows.filter(rows => rows.directorType == "SD"));
        //        console.log(rows.filter(rows => rows.directorType == "WD"));
        //        console.log(rows.filter(rows => rows.directorType == "PD"));
                
        //    }
        //    else if (result.code === 202) {
        //    } else {
        //        currentComponent.toasterfun2(result.message);
        //    }
        //}).catch(function (error) {
        //    console.log("-------- error ------- " + error);
        //    currentComponent.toasterfun2("result:" + error)
        //});
    }
    handleChange(event) {
        var target = event.target;
        var type = target.type;
        var name = target.getAttribute('name');
        var value = target.value;
        if (name == "ShootingName") {
            this.setState({ ShootingName: value })
            
        }
        else if (name == "Address1") {
            this.setState({
                Address1: value,
                showAddress1: true
            });
        }
        else if (name == "Address2") { this.setState({ Address2: value }) }
        else if (name == "City") {
            this.setState({
                City: value,
                showCity: true })
        }
        else if (name == "State") {
            this.setState({
                State: value,
                showState: true }) }
        //else if (name == "Country") {
        //    this.setState({
        //        Country: value,
        //        showCountry: true }) }
        else if (name == "Zip") {
            this.setState({
                Zip: value,
                showZip: true }) 
        }
        else if (name == "StartDate") {
            this.setState({
                StartDate: value ,
                showState: true
            });
            this.setState({ ShootingName: this.state.ProjectSelValue + " " + this.state.Location + " " + value });

        }
        else if (type == "select-one") {
            var index = event.target.selectedIndex;
            var optionElement = event.target.childNodes[index];
            var option = optionElement.getAttribute('data-id');
            var SelValue = optionElement.getAttribute('value');
            if (name == "Project") {
                this.setState({ ProjectSelOption: option });
                this.setState({
                    ProjectSelValue: SelValue,
                    showProject: true
                });
                this.setState({ ShootingName: SelValue + " " + this.state.Location + " " + this.state.StartDate });
                this.GetCastingDirectors(SelValue, 0);
                this.GetProjectLocation(option);
            }
            else if (name == "Location") {

                let LocationList = this.state.LocationList.filter(rows => rows.location === SelValue);
                if (LocationList.length > 0) {
                    this.setState({
                        Location: SelValue,
                        LocationId:option,
                        Address1: LocationList[0].address1 ?? '',
                        Address2: LocationList[0].address2 ?? '',
                        City: LocationList[0].city ?? '',
                        State: LocationList[0].state ?? '',
                        Zip: LocationList[0].zipCode ?? '',
                        Country: LocationList[0].country ?? '',

                        showAddress1: true,
                        showCity: true,
                        showState: true,
                        showZip: true,
                        showCountry: true,
                        ShootingName: this.state.ProjectSelValue + " " + SelValue + " " + this.state.StartDate          
                    });
                }
                else {
                    this.setState({
                        Location: '',
                        LocationId:"",
                        Address1: '',
                        Address2: '',
                        City: '',
                        State: '',
                        Zip: '',
                        Country: '',
                        ShootingName: this.state.ProjectSelValue +  + " " + this.state.StartDate          ,
                        showAddress1: true,
                        showCity: true,
                        showState: true,
                        showZip: true,
                        showCountry: true,
                    });
                }
            }
            //else if (name == "CastingDirector") {
            //    this.setState({ CastingDirectorSelOption: option });
            //    this.setState({
            //        CastingDirectorSelValue: SelValue,
            //        showDirector: true
            //    });               
            //}
            else if (name == "Country") {
                //this.setState({ CastingDirectorSelOption: option });
                this.setState({
                    Country: SelValue,
                    showCountry: true 
                });
            }
        }



    }
    
    SendNotification(ShootingID){
        let currentComponent = this;
        let data = {
            ShootingId: ShootingID,
            To: 'Second Director'
        }
        APIPost('Notification/SkinUploadSendNotification', data, function (result) {
            if (result.code === 200) {

            }
        });

        //fetch('Notification/SkinUploadSendNotification/' + ShootingID + '/Second Director', {
        //    method: 'post',
        //    headers: {
        //        Accept: 'application/json',
        //        'Content-Type': 'application/json',
        //        'Authorization': 'Bearer ' + localStorage.getItem('UserId')
        //    }
        //}).then(function (response) {
        //    return response.json();
        //}).then(function (result) {
        //    if (result.code === 200) {
                
        //    }

        //}).catch(function (error) {
        //    console.log("-------- error ------- " + error);
        //    currentComponent.toasterfun2("result:" + error)
        //});

    }
    InsertShootingDirectorsMapping(ShootingID, SelectedDirectors,DirectorType) { 
       // debugger;
        let currentComponent = this;
        let data = '';
       // let userfullName = localStorage.getItem('userfullName');
        let userfullName = ''; // localStorage.getItem('CDCurrentUserId');
        var StartDate = new Date(currentComponent.state.StartDate != null ? currentComponent.state.StartDate : currentComponent.state.datePickerInputDate);
        var cnt = 0;
        //var SelectedDirectors = currentComponent.state.SelectedDirectors;
        var jsonArr = [];
        for (cnt = 0; cnt < SelectedDirectors.length; cnt++) {
            jsonArr.push({
                "DirectorId": parseInt(SelectedDirectors[cnt].cat),
                "ShootingId": parseInt(ShootingID),
                "UserType": 35,
                "Checkin": new Date(StartDate.getTime() - (StartDate.getTimezoneOffset() * 60000)).toJSON(), //new Date(this.state.StartDate),
                "CheckOut": new Date(StartDate.getTime() - (StartDate.getTimezoneOffset() * 60000)).toJSON(), //new Date(this.state.StartDate),
                "Status": 25,
                "CreatedBy": userfullName,
                "CreatedOnDateTime": new Date(),//this.state.StartDate,
                "ShootingId1": parseInt(ShootingID),
                "ModifiedBy": userfullName,
                "ModifiedDateTime": new Date(),//this.state.StartDate,
                "DirectorType": SelectedDirectors[cnt].directorType ,
            });
        }


        
        //data = JSON.stringify( jsonArr)
        //data = JSON.stringify({
        //    "DirectorId": parseInt(currentComponent.state.CastingDirectorSelOption),
        //    "ShootingId": parseInt(ShootingID),
        //    "UserType": 35,
        //    "Checkin": new Date(StartDate.getTime() - (StartDate.getTimezoneOffset() * 60000)).toJSON(), //new Date(this.state.StartDate),
        //    "CheckOut": new Date(StartDate.getTime() - (StartDate.getTimezoneOffset() * 60000)).toJSON(), //new Date(this.state.StartDate),
        //    "Status": 25,
        //    "CreatedBy": userfullName,
        //    "CreatedOnDateTime": new Date(),//this.state.StartDate,
        //    "ShootingId1": parseInt(ShootingID),
        //    "ModifiedBy": userfullName,
        //    "ModifiedDateTime": new Date(),//this.state.StartDate,

        //});

        let reqData = {
            ShootingDirectorsMappingList: jsonArr,
            DirectorType: DirectorType,
            ShootingId: ShootingID
        }

        APIPost('SkinUpload/InsertShootingDirectorsMapping', reqData, function (result) {
            if (result.code == 200) {
                if (result.message == "true") {
                    currentComponent.toasterfun2("Saved Successfully");
                    currentComponent.SendNotification(ShootingID);
                }
                else {
                    currentComponent.toasterfun2("Saved Successfully")
                }
            } else {
                currentComponent.toasterfun2(result.message);
            }
        });

        //fetch('SkinUpload/InsertShootingDirectorsMapping/' + ShootingID + '/' + DirectorType, {
        //    method: 'post',
        //    headers: {
        //        Accept: 'application/json',
        //        'Content-Type': 'application/json',
        //        'Authorization': 'Bearer ' + localStorage.getItem('UserId')
        //    },
        //    body: data
        //}).then(function (response) {
        //    return response.json();
        //}).then(function (result) {
        //    if (result.code == 200) {
        //        if (result.message == "true") {
        //            currentComponent.toasterfun2("Saved Successfully");
        //            currentComponent.SendNotification(ShootingID);
        //        }
        //        else {
        //            currentComponent.toasterfun2("Saved Successfully")
        //        }
        //    } else {
        //        currentComponent.toasterfun2(result.message);
        //    }

        //}).catch(function (error) {
        //    console.log("-------- error ------- " + error);
        //    currentComponent.toasterfun2("result:" + error)
        //});
    }

    ValidationforInsert() {
        //debugger;
        var err = 0;
        if (this.state.ProjectSelOption == 0 && this.state.ProjectSelValue=="" ) {
            this.setState({ showProject: false });
            err = 1;
        } else { this.setState({ showProject: true });}
        //if (this.state.Address1 == 0) {
        //    this.setState({ showAddress1: false });
        //    err = 1;
        //}
        //else { this.setState({ showAddress1: true }); }
        //if (this.state.City == 0) {
        //    this.setState({ showCity: false });
        //    err = 1;
        //}
        //else { this.setState({ showCity: true }); }
        if (this.state.State == "" ) {
            this.setState({ showState: false });
            err = 1;
        }
        else { this.setState({ showState: true }); }
        //if (this.state.Country == 0) {
        //    this.setState({ showCountry: false });
        //    err = 1;
        //}
        //else { this.setState({ showCountry: true }); }
        //if (this.state.Zip == 0) {
        //    this.setState({ showZip: false });
        //    err = 1;
        //}
        //else { this.setState({ showZip: true }); }
        if (this.state.datePickerInputDate == null) {//this.state.StartDate == 0 ||
            this.setState({ showShootDate: false });
            err = 1;
        }  
        else { this.setState({ showShootDate: true }); }
        if (this.state.Location == "") {//this.state.StartDate == 0 ||
            this.setState({ showLocation: false });
            err = 1;
        }
        else { this.setState({ showLocation: true }); }
        //if (this.state.CastingDirectorSelOption == 0) {
        //    this.setState({ showDirector: false });
        //    err = 1;
        //}
        //else { this.setState({ showDirector: true }); }
        if (this.state.SelectedSecondDirectors == null) {
            this.setState({ showSecondDirector: false });
            err = 1;
        }
        else { this.setState({ showSecondDirector: true }); }

       
        return err;
        
    }
    ConstructDataForInsert() {
        let data = '';
        var StartDate = new Date(this.state.StartDate);
        //let userfullName = localStorage.getItem('userfullName');
        let userfullName = ''; //localStorage.getItem('CDCurrentUserId');
        data = JSON.stringify({
            "MovieId": parseInt(this.state.ProjectSelOption),
            "Address1": this.state.Address1,
            "Address2": this.state.Address2,
            "City": this.state.City,
            "State": this.state.State,
            "Country": this.state.Country,
            "ZipCode": this.state.Zip,
            "StartDateTime": new Date(StartDate.getTime() - (StartDate.getTimezoneOffset() * 60000)).toJSON(), //new Date(this.state.StartDate),
            "EndDateTime": new Date(StartDate.getTime() - (StartDate.getTimezoneOffset() * 60000)).toJSON(), //new Date(this.state.StartDate),
            "ShootingName": this.state.ShootingName,
            "CreatedBy": userfullName,
            "CreatedonDateTime": new Date(),
            "Status": 1,
            "LocationId": parseInt(this.state.LocationId),
        });
        return data;
    }
    ConstructDataForUpdate() {
        let data = '';
        //let userfullName = localStorage.getItem('userfullName');
        var StartDate = new Date(this.state.StartDate);
        let userfullName = ''; //localStorage.getItem('CDCurrentUserId');
        data = JSON.stringify({
            "Id": parseInt(this.props.dataFromParent),
            "MovieId": parseInt(this.state.ProjectSelOption),
            "Address1": this.state.Address1,
            "Address2": this.state.Address2,
            "City": this.state.City,
            "State": this.state.State,
            "Country": this.state.Country,
            "ZipCode": this.state.Zip,            
            "ModifiedBy": userfullName,
            "ModifiedDateTime": new Date(),
            "ShootingName": this.state.ShootingName,      
            "LocationId": parseInt(this.state.LocationId),
            "StartDateTime": new Date(StartDate.getTime() - (StartDate.getTimezoneOffset() * 60000)).toJSON()
        });
        return data;
    }
    handleSubmit(event) {
        
        event.preventDefault();
        let currentComponent = this;
        currentComponent.setState({ loadingTime: true });
        let data = '';
        
        const isEdit = this.props.dataFromParent == 0 ? false : true
        var err = currentComponent.ValidationforInsert();
        if (err == 0) {
            if (!isEdit) {
                data = currentComponent.ConstructDataForInsert();
                URL = "InsertShooting";
            }
            else {
                data = currentComponent.ConstructDataForUpdate();
                URL = "UpdateShooting";
            }

            let reqData = {
                Shooting: data,
                IsEdit: isEdit
            }

            APIPost('SkinUpload/InsertShooting', reqData, function (result) {
                if (result.code == 200) {
                    //debugger;
                    currentComponent.InsertShootingDirectorsMapping(result.result[0].id, currentComponent.state.SelectedSecondDirectors, "SD");
                    currentComponent.InsertShootingDirectorsMapping(result.result[0].id, currentComponent.state.SelectedWardrobeDirectors, "WD");
                    currentComponent.InsertShootingDirectorsMapping(result.result[0].id, currentComponent.state.SelectedPropertyDirectors, "PD");
                    currentComponent.setState({ loadingTime: false });
                    //currentComponent.toasterfun2("Saved Successfully")
                } else {
                    currentComponent.toasterfun2(result.message);
                    currentComponent.setState({ loadingTime: false });
                }
            });
            
            //fetch('SkinUpload/InsertShooting/' + isEdit, {
            //    method: 'post',
            //    headers: {
            //        Accept: 'application/json',
            //        'Content-Type': 'application/json',
            //        'Authorization': 'Bearer ' + localStorage.getItem('UserId')
            //    },
            //    body: data
            //}).then(function (response) {
            //    return response.json();
            //}).then(function (result) {
            //    if (result.code == 200) {
            //        //debugger;
            //        currentComponent.InsertShootingDirectorsMapping(result.result[0].id, currentComponent.state.SelectedSecondDirectors,"SD");
            //        currentComponent.InsertShootingDirectorsMapping(result.result[0].id, currentComponent.state.SelectedWardrobeDirectors, "WD");
            //        currentComponent.InsertShootingDirectorsMapping(result.result[0].id, currentComponent.state.SelectedPropertyDirectors, "PD");
            //        currentComponent.setState({ loadingTime: false });
            //        //currentComponent.toasterfun2("Saved Successfully")
            //    } else {
            //        currentComponent.toasterfun2(result.message);
            //        currentComponent.setState({ loadingTime: false });
            //    }

            //}).catch(function (error) {
            //    console.log("-------- error ------- " + error);
            //    currentComponent.toasterfun2("result:" + error)
            //    currentComponent.setState({ loadingTime: false });
            //});
        }
        else {
            currentComponent.setState({ loadingTime: false });
            return false;
        }

    }
    LoadCountry() {
        let currentComponent = this;
        fetch('SkinUpload/GetCountry/', {
            method: 'get',
            headers: {
                Accept: 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('UserId'),
                'Content-Type': 'application/json',
            }
        }).then(function (response) {
            return response.json();
        }).then(function (result) {
            if (result.code === 200) {
                currentComponent.setState({ CountryList: result.result });

            } else {
                currentComponent.toasterfun2(result.message);
            }

        }).catch(function (error) {
            console.log("-------- error ------- " + error);
            currentComponent.toasterfun2("result:" + error)
        });
    }
    LoadProjects() {
        
        let currentComponent = this;
        //let userId = ''; //localStorage.getItem('CDCurrentUserId')

        fetch('SkinUpload/GetAllProjects', {
            method: 'get',
            headers: {
                Accept: 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('UserId'),
                'Content-Type': 'application/json',
            }
        }).then(function (response) {
            return response.json();
        }).then(function (result) {
            if (result.code === 200) {
                currentComponent.setState({ ProjectList: result.result });

            } else {
                currentComponent.toasterfun2(result.message);
            }

        }).catch(function (error) {
            console.log("-------- error ------- " + error);
            currentComponent.toasterfun2("result:" + error)
        });
    }
 
    onSelectSD(selectedList, selectedItem) {
        console.log(selectedList);
        console.log(selectedItem);
        
        this.setState({
            SelectedSecondDirectors: selectedList,
            showSecondDirector: true
        });
       
    }
    onRemoveSD(selectedList, removedItem) {
        console.log(selectedList);       

        this.setState({
            SelectedSecondDirectors: selectedList
        });

    }
    onSelectWD(selectedList, selectedItem) {
        console.log(selectedList);
        console.log(selectedItem);

        this.setState({
            SelectedWardrobeDirectors: selectedList,
            
        });

    }
    onRemoveWD(selectedList, removedItem) {
        console.log(selectedList);

        this.setState({
            SelectedWardrobeDirectors: selectedList
        });

    }
    onSelectPD(selectedList, selectedItem) {
        console.log(selectedList);
        console.log(selectedItem);

        this.setState({
            SelectedPropertyDirectors: selectedList,
            
        });

    }
    onRemovePD(selectedList, removedItem) {
        console.log(selectedList);

        this.setState({
            SelectedPropertyDirectors: selectedList
        });

    }
    render() {
        const isEdit = this.props.dataFromParent == 0 ? false : true
        var landing = '/css/assets/img/ems-logo.gif';
        console.log(this.state.CastingDirectorList.filter(rows => rows.directorType === "SD"));
        console.log(this.state.CastingDirectorList.filter(rows => rows.directorType === "WD"));
        console.log(this.state.CastingDirectorList.filter(rows => rows.directorType === "PD"));
        return (

            <div class="form-builder">
                <span className={"loading " + (this.state.loadingTime ? "show" : "hide")} ><span className="loading-div-d"></span> <img src={process.env.PUBLIC_URL + landing} /> </span>
                <div className={"toaster-div " + (this.state.toasterCla ? "show" : "hide")}><i className="fa fa-check-circle" aria-hidden="true"></i> {this.state.toaster} </div>
                <h4>Shoot</h4>
                <div class="cls-bttn" onClick={this.props.action}>x</div>
                <div class="col-lg-12 popup-view">

                    <form id="msform" class="form-with-validation" >

                        <div class="col-lg-6  col-md-6">
                            <div class="popup-x">
                                <label>Project
                            </label>
                                <label className="lebel-div" style={{ display: isEdit ? 'inline-block' : 'none' }}>
                                    {this.state.ProjectSelValue} </label>

                                <select className="form-control " name="Project" id="ddlProject" name="Project" onChange={this.handleChange} value={this.state.ProjectSelValue}
                                    style={{ display: isEdit ? 'none' : 'inline-block' }}>
                                    <option value="">Select</option>
                                    {this.state.ProjectList.map(Project =>
                                        <option key={Project.id} value={Project.name} data-id={Project.id}>{Project.name}</option>
                                    )}
                                </select>

                                <div className={"rederror " + (this.state.showProject ? "hide" : "show")}>Please select project</div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="popup-x">
                                <label>Shoot Date
                            </label>
                                <label className="lebel-div" style={{ display: isEdit ? 'inline-block' : 'none' }}>
                                    {this.state.StartDate}  </label>
                                <DatePickerInput
                                    displayFormat='MM/DD/YYYY'
                                    returnFormat='MM/DD/YYYY'
                                    className='my-react-component'
                                    onChange={this.OnDateChange}
                                    onShow={this.log.bind(this, 'show')}
                                    onHide={this.log.bind(this, 'hide')}
                                    showOnInputClick
                                    
                                    locale='de'
                                    onClear={this.onClear}
                                    value={this.state.datePickerInputDate}
                                    style={{ display: isEdit ? 'none' : 'inline-block' }}                                    
                                    minDate={new moment()}
                                />
                                <div className={"rederror " + (this.state.showShootDate ? "hide" : "show")}>Please select shoot date</div>
                            </div>
                        </div>
                        <div className="clear"></div>

                        <div class="col-lg-6 col-md-6">
                            <div class="popup-x">
                                <label>Shooting Name
                            </label>
                                <input type="text" class="form-control" name="ShootingName" value={this.state.ShootingName} onChange={this.handleChange} readOnly />
                            </div>
                        </div>

                      

                        <div class="col-lg-6 col-md-6">
                            <div class="popup-x">
                                <label>Second Director
                            </label>
                                <Multiselect
                                    options={this.state.CastingDirectorList.filter(rows => rows.directorType === "SD")}
                                    displayValue="key"
                                    closeIcon="close"
                                    //onChange={this.onChange}
                                    onSelect={this.onSelectSD}
                                    onRemove={this.onRemoveSD}
                                    selectedValues={this.state.SelectedSecondDirectors}                                    
                                    style={{
                                        chips: { background: "#5d78ff", "font-size": "10px" },
                                        searchBox: {
                                            border: "none", "border-bottom": "1px solid blue", "border-radius": "0px", "font-size": "10px", "min-height": "50px"
                                        },
                                        inputField: { // To change input field position or margin
                                            "margin": "5px"
                                        },
                                    }}

                                />
                                

                              
                                <div className={"rederror " + (this.state.showSecondDirector ? "hide" : "show")}>Please select Second director</div>
                            </div>
                        </div>
                        <div className="clear"></div>
                        <div class="col-lg-6 col-md-6">
                            <div class="popup-x">
                                <label>Wardrobe Manager
                            </label>
                                <Multiselect
                                    options={this.state.CastingDirectorList.filter(rows => rows.directorType === "WD")}
                                    displayValue="key"
                                    closeIcon="close"
                                    //onChange={this.onChange}
                                    onSelect={this.onSelectWD}
                                    onRemove={this.onRemoveWD}
                                    selectedValues={this.state.SelectedWardrobeDirectors}
                                    style={{
                                        chips: { background: "#5d78ff", "font-size": "10px" },
                                        searchBox: {
                                            border: "none", "border-bottom": "1px solid blue", "border-radius": "0px", "font-size": "10px", "min-height": "50px"
                                        },
                                        inputField: { // To change input field position or margin
                                            "margin": "5px"
                                        },
                                    }}

                                />



                               
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="popup-x">
                                <label>Props Manager
                            </label>
                                <Multiselect
                                    options={this.state.CastingDirectorList.filter(rows => rows.directorType === "PD")}
                                    displayValue="key"
                                    closeIcon="close"
                                    //onChange={this.onChange}
                                    onSelect={this.onSelectPD}
                                    onRemove={this.onRemovePD}
                                    selectedValues={this.state.SelectedPropertyDirectors}
                                    style={{
                                        chips: { background: "#5d78ff", "font-size": "10px" },
                                        searchBox: {
                                            border: "none", "border-bottom": "1px solid blue", "border-radius": "0px", "font-size": "10px", "min-height": "50px"
                                        },
                                        inputField: { // To change input field position or margin
                                            "margin": "5px"
                                        },
                                    }}

                                />



                               
                            </div>
                        </div>

                        <div className="clear"></div>
                        <div class="col-lg-12  col-md-12">
                            <div class="popup-x">
                                <label>Location
                            </label>
                                {/* <label className="lebel-div" style={{ display: isEdit ? 'inline-block' : 'none' }}>
                                    {this.state.Location} </label> */}

                                <select className="form-control " id="ddlLocation" name="Location" onChange={this.handleChange} value={this.state.Location} >
                                    {/* style={{ display: isEdit ? 'none' : 'inline-block'*/}
                                    <option value="">Select</option>
                                    {this.state.LocationList.map(Location =>
                                        <option key={Location.location} value={Location.location}
                                            data-id={Location.id} selected={this.state.locationId ? (this.state.locationId == Location.id ? true : false) : false }>
                                            {Location.location}</option>
                                    )}
                                </select>

                                <div className={"rederror " + (this.state.showLocation ? "hide" : "show")}>Please select Location</div>
                            </div>
                        </div>
                        <div className="clear"></div>

                        <div class="col-lg-6 col-md-6">
                            <div class="popup-x">
                                <label>Address 1
                            </label>
                                <input type="text" class="form-control" name="Address1" onChange={this.handleChange} autoComplete="off" value={this.state.Address1} disabled/>
                                <div className={"rederror " + (this.state.showAddress1 ? "hide" : "show")}>Please provide Address1</div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="popup-x">
                                <label>Address 2
                            </label>
                                <input type="text" class="form-control" name="Address2" onChange={this.handleChange} autoComplete="off" value={this.state.Address2} disabled/>
                                
                            </div>
                        </div>
                        <div className="clear"></div>
                        <div class="col-lg-6 col-md-6">
                            <div class="popup-x">
                                <label>City
                            </label>
                                <input type="text" class="form-control" name="City" onChange={this.handleChange} autoComplete="off" value={this.state.City} disabled/>
                                <div className={"rederror " + (this.state.showCity ? "hide" : "show")}>Please provide City</div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="popup-x">
                                <label>State
                            </label>
                                <input type="text" class="form-control" name="State" onChange={this.handleChange} autoComplete="off" value={this.state.State} disabled/>
                                <div className={"rederror " + (this.state.showState ? "hide" : "show")}>Please provide State</div>
                            </div>
                        </div>
                        <div className="clear"></div>
                        <div class="col-lg-6 col-md-6">
                            <div class="popup-x">
                                <label>Country
                            </label>
                                <input type="text" class="form-control" name="Country" onChange={this.handleChange} autoComplete="off" value={this.state.Country} disabled/>
                                
                                <div className={"rederror " + (this.state.showCountry ? "hide" : "show")}>Please provide Country</div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="popup-x">
                                <label>Zip
                            </label>
                                <input type="text" class="form-control" name="Zip" onChange={this.handleChange} autoComplete="off" value={this.state.Zip} disabled/>
                                <div className={"rederror " + (this.state.showZip ? "hide" : "show")}>Please provide Zip</div>
                            </div>
                        </div>

                        <div className="clear"></div>

                        <div class="popup-x text-center padding-x">
                            <button type="submit" class="btn btn-primary" onClick={this.handleSubmit}>Save </button>
                            <button type="button" class="btn btn-default btn-cancel" onClick={this.props.action}>Cancel </button>
                        </div>
                    </form>
                </div>
            </div>


        );
    }
}
//minDate={subDays(new Date(), 5)}
//selected={this.state.datePickerInputDate ? moment(this.state.datePickerInputDate).format('MM/DD/YYYY') : moment()}

//<select className="form-control" name="CastingDirector" id="ddlCastingDirector" onChange={this.handleChange} value={this.state.CastingDirectorSelValue} >
//    <option value="">Select</option>
//    {this.state.CastingDirectorList.map(CastingDirector =>
//        <option key={CastingDirector.castingDirectorID} value={CastingDirector.firstname + " " + CastingDirector.middleName + " " + CastingDirector.lastname} data-id={CastingDirector.castingDirectorID}>{CastingDirector.firstname + " " + CastingDirector.middleName + " " + CastingDirector.lastname}</option>
//    )}
//</select>




import React from 'react';
import ReactTooltip from "react-tooltip";
import ReactDOM from 'react-dom'
import MaterialTable from "material-table";
import { InputLabel } from '@material-ui/core';
import EditBGActor from './EditBGActor';
import { APIGet, APIPost } from '../EMS_Components/APICall'


export class LoadTableSkinUpload extends React.Component {
    static displayName = LoadTableSkinUpload.name;
    constructor(props) {
        super(props);

        this.state = {

            childDataSkinUpload: this.props.dataFromParent,
            ShootingID: this.props.ShootingID,
            toasterCla: false,
            toaster: '',
            removeAlert: false,
            removehidden: '',
            allChecked: false,
            EditAlert: false,            
            EditshootingActorsMappingID: 0,
            EditSkinUpload:[],

        }
        
        this.handleChange = this.handleChange.bind(this);

        this.handleDocRemove = this.handleDocRemove.bind(this);
        this.handleDocEdit = this.handleDocEdit.bind(this);
        this.handleEditCancelbttn = this.handleEditCancelbttn.bind(this);
        
        this.handleremoveCancelbttn = this.handleremoveCancelbttn.bind(this);
        this.handleRemoveSpecificRow = this.handleRemoveSpecificRow.bind(this);
    }
    

    //Toaster alert start
    toasterfun2(e) {
        this.setState({ toasterCla: true });
        this.setState({ toaster: e });
        setTimeout(
            function () {
                this.setState({ toasterCla: false });
            }.bind(this), 4000);
    }
    //Toaster alert End

    //Events Start
    handleChange(event) {
        let currentComponent = this;
        var target = event.target;
        var name = target.getAttribute('name');
        var id = target.getAttribute('id');
        var index = id.toString().split('_')[1];
       // debugger;
        if (name === "wardrobe") {
            var value = target.type === 'checkbox' ? target.checked : target.value;
            currentComponent.state.childDataSkinUpload.filter(SkinUpload => SkinUpload.id == index).map(FilteredSkinUpload =>
                FilteredSkinUpload.isWardrobeRequired = value);
            currentComponent.state.childDataSkinUpload.filter(SkinUpload => SkinUpload.id == index).map(FilteredSkinUpload =>
                FilteredSkinUpload.wardrobe = value ? 2 : 0);
            currentComponent.state.childDataSkinUpload.filter(SkinUpload => SkinUpload.id == index).map(FilteredSkinUpload =>
                FilteredSkinUpload.isChanged = true);
            currentComponent.setState({ DataSkinUpload: currentComponent.state.childDataSkinUpload });
            localStorage.setItem('DataSkinUpload', JSON.stringify(currentComponent.state.childDataSkinUpload));
        }
        else if (name === "property") {
            var value = target.type === 'checkbox' ? target.checked : target.value;            
            currentComponent.state.childDataSkinUpload.filter(SkinUpload => SkinUpload.id == index).map(FilteredSkinUpload =>
                FilteredSkinUpload.isPropertyRequired = value);
            currentComponent.state.childDataSkinUpload.filter(SkinUpload => SkinUpload.id == index).map(FilteredSkinUpload =>
                FilteredSkinUpload.Property = value ? 2 : 0);
            currentComponent.state.childDataSkinUpload.filter(SkinUpload => SkinUpload.id == index).map(FilteredSkinUpload =>
                FilteredSkinUpload.isChanged = true);
            currentComponent.setState({ DataSkinUpload: currentComponent.state.childDataSkinUpload });
            localStorage.setItem('DataSkinUpload', JSON.stringify(currentComponent.state.childDataSkinUpload));
        }

        else if (name === "AddNew") {
           // debugger;
            const dataForInsertion = currentComponent.state.childDataSkinUpload[index ];
            if (dataForInsertion.gender != null) {
                let data = {
                    skinUpload: dataForInsertion,
                    ShootingId: currentComponent.state.ShootingID
                }
                //data = JSON.stringify(dataForInsertion);
                console.log(dataForInsertion);

                APIPost('AdminSkinUpload/InsertNewPerson', data, function (result) {
                    if (result.code == 200) {
                        currentComponent.toasterfun2("New person Added Sucessfully");
                        currentComponent.state.childDataSkinUpload[index].bgActorID = result.result;
                        console.log(currentComponent.state.DataSkinUpload);
                        currentComponent.setState({ DataSkinUpload: currentComponent.state.childDataSkinUpload });
                        console.log(currentComponent.state.DataSkinUpload);
                        localStorage.setItem('DataSkinUpload', JSON.stringify(currentComponent.state.childDataSkinUpload));
                        // ReactDOM.unmountComponentAtNode(document.getElementById('divtable'));
                    } else {
                        currentComponent.toasterfun2(result.message);
                    }
                });

                //fetch('AdminSkinUpload/InsertNewPerson/', {
                //    method: 'post',
                //    headers: {
                //        Accept: 'application/json',
                //        'Content-Type': 'application/json',
                //        'Authorization': 'Bearer ' + localStorage.getItem('UserId')
                //    },
                //    body: data

                //}).then(function (response) {
                //    return response.json();
                //}).then(function (result) {
                //    if (result.code == 200) {
                //        currentComponent.toasterfun2("New person Added Sucessfully");
                //        currentComponent.state.childDataSkinUpload[index ].bgActorID = result.result;
                //        console.log(currentComponent.state.DataSkinUpload);
                //        currentComponent.setState({ DataSkinUpload: currentComponent.state.childDataSkinUpload });
                //        console.log(currentComponent.state.DataSkinUpload);
                //        localStorage.setItem('DataSkinUpload', JSON.stringify(currentComponent.state.childDataSkinUpload));
                //        // ReactDOM.unmountComponentAtNode(document.getElementById('divtable'));
                //    } else {
                //        currentComponent.toasterfun2(result.message);
                //    }


                //}).catch(function (error) {
                //    console.log("-------- error ------- " + error);
                //    currentComponent.toasterfun2(error);

                //});
            }
            else {
                currentComponent.toasterfun2("Please update Gender before adding a new BGactor");
                return false;
            }
        }
    }
    handleDeleteChange = e => {
       // debugger;
        let itemName = e.target.name;
        let itemvalue = e.target.value;
        let checked = e.target.checked;
        
        this.setState(prevState => {
            let { childDataSkinUpload, allChecked } = prevState;
            
            if (itemName.includes("checkAll_")) {
                
                var i = itemName.split('checkAll_')[1];
                childDataSkinUpload = childDataSkinUpload.map(item =>
                    item.callTime.toString() === i && !item.isCheckedIn ? { ...item, isChecked: checked } : item
                );
            } else if (itemName.includes("check_Delete_")) {
                
                childDataSkinUpload = childDataSkinUpload.map(item =>
                    //item.bgActorID.toString() === itemName.split('_')[itemName.split('_').length - 1] ? { ...item, isChecked: checked } : item
                    item.id.toString() === itemvalue? { ...item, isChecked: checked } : item
                );
                allChecked = childDataSkinUpload.every(item => item.isChecked);
            }
            localStorage.setItem('DataSkinUpload', JSON.stringify(childDataSkinUpload));
            return { childDataSkinUpload, allChecked };
        });
        
    };
   // handleDocEdit(e) {
    handleDocEdit = (SkinUpload,EditshootingActorsMappingID,res) => {
        
        this.setState({
            EditAlert: true,            
            EditshootingActorsMappingID: EditshootingActorsMappingID,
            EditSkinUpload: SkinUpload,
        });
        let contents =  this.renderEditBG (EditshootingActorsMappingID, SkinUpload);
        ReactDOM.render(contents, document.getElementById('divEditBGActor'));
      
    }
    handleEditCancelbttn() {
        
        this.setState({ EditAlert: false });
        this.props.action();
    }
    handleEditSpecificRow() {
        let currentComponent = this;
        currentComponent.setState({ EditAlert: false });
    }
    handleDocRemove(e) {
        this.setState({ removeAlert: true });
        this.setState({ removehidden: e });
    }
    handleremoveCancelbttn() {
        this.setState({ removeAlert: false });
    }
    handleRemoveSpecificRow() {
        let currentComponent = this;

        var index = currentComponent.state.removehidden;
        
        
        console.log(index);
        console.log(currentComponent.state.childDataSkinUpload);
        currentComponent.setState({ removeAlert: false });

        currentComponent.SendCancelNotification(currentComponent.state.ShootingID, currentComponent.state.childDataSkinUpload.filter(SkinUpload => SkinUpload.id === index)[0].bgActorID);
        currentComponent.DeleteShootingActorExists(currentComponent.state.ShootingID, currentComponent.state.childDataSkinUpload.filter(SkinUpload => SkinUpload.id === index)[0].bgActorID, index)
       
       
    }
    //Events End
    SendCancelNotification(ShootingID, BGActorID) {
        let currentComponent = this;
        let data = {
            ShootingId: ShootingID,
            BGActorID: BGActorID
        }

        APIPost('Notification/SkinUploadSendCancelNotification', data, function (result) {
            if (result.code === 200) {

            }
        });

        //fetch('Notification/SkinUploadSendCancelNotification/' + ShootingID + '/' + BGActorID, {
        //    method: 'post',
        //    headers: {
        //        Accept: 'application/json',
        //        'Content-Type': 'application/json',
        //        'Authorization': 'Bearer ' + localStorage.getItem('UserId')
        //    }
        //}).then(function (response) {
        //    return response.json();
        //}).then(function (result) {
        //    if (result.code === 200) {

        //    }

        //}).catch(function (error) {
        //    console.log("-------- error ------- " + error);
        //    currentComponent.toasterfun2("result:" + error)
        //});

    }
    //Common function Start 
    DeleteShootingActorExists(ShootingID, BGActorID, index) {
        let currentComponent = this;

        let data = {
            ShootingId: ShootingID,
            BGActorID: BGActorID
        }
        APIPost('SkinUpload/DeleteShootingActorExists', data, function (result) {
            if (result.code === 200) {
                currentComponent.toasterfun2("Deleted Sucessfully")
                const dataafterdeletion = currentComponent.state.childDataSkinUpload.filter((Data) => Data.id != index);
                currentComponent.setState({ childDataSkinUpload: dataafterdeletion });
                currentComponent.setState({ DataSkinUpload: dataafterdeletion });
                localStorage.setItem('DataSkinUpload', JSON.stringify(dataafterdeletion));
                if (dataafterdeletion.length == 0) {
                    ReactDOM.unmountComponentAtNode(document.getElementById('divtable'));
                }
                currentComponent.props.setStateManually(dataafterdeletion);
            } else {
                currentComponent.toasterfun2(result.message);
            }
        });

        //fetch('SkinUpload/DeleteShootingActorExists/' + ShootingID + '/' + BGActorID, {
        //    method: 'Post',
        //    headers: {
        //        Accept: 'application/json',
        //        'Content-Type': 'application/json',
        //        'Authorization': 'Bearer ' + localStorage.getItem('UserId')
        //    }
        //}).then(function (response) {
        //    return response.json();
        //}).then(function (result) {
        //    console.log(result);
        //    if (result.code === 200) {
        //        currentComponent.toasterfun2("Deleted Sucessfully")
        //        const dataafterdeletion = currentComponent.state.childDataSkinUpload.filter((Data) => Data.id != index);
        //        currentComponent.setState({ childDataSkinUpload: dataafterdeletion });
        //        currentComponent.setState({ DataSkinUpload: dataafterdeletion });
        //        localStorage.setItem('DataSkinUpload', JSON.stringify(dataafterdeletion));
        //        if (dataafterdeletion.length == 0) {
        //            ReactDOM.unmountComponentAtNode(document.getElementById('divtable'));
        //        }
        //        currentComponent.props.setStateManually(dataafterdeletion);
        //    } else {
        //        currentComponent.toasterfun2(result.message);
        //    }

        //}).catch(function (error) {
        //    console.log("-------- error ------- " + error);
        //    currentComponent.toasterfun2("result:" + error)
        //});
        
    }    
   
    getUniqueCallTime(SkinUploads) {
        var uniqueCallTime = [];

        SkinUploads.map(SkinUpload => {
            if (uniqueCallTime.indexOf(SkinUpload.callTime) === -1) {
                uniqueCallTime.push(SkinUpload.callTime)
            }
        });
        console.log(uniqueCallTime);
        uniqueCallTime.sort(function (a, b) {
            return new Date('1970/01/01 ' + a) - new Date('1970/01/01 ' + b);
        });
        console.log(uniqueCallTime);
        return (uniqueCallTime);
    }
    getSkinUploadsBasedonCalltime(SkinUploads, CallTime) {
        const FileteddataSkinUpload = SkinUploads.filter(SkinUpload => SkinUpload.callTime === CallTime);
        return (FileteddataSkinUpload);
    }
    formatdate(Datetobeformatted) {                 // format date in MM/DD/YYYY format

        return Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        }).format(new Date(Datetobeformatted));
    }
    //Common function End

    //Page render start

    
//    <th width="3%"><input
//    type="checkbox"
//    name="checkAll"
//    checked={this.state.allChecked}
//    onChange={this.handleDeleteChange}
///> Delete all</th>
    renderEditBG(EditshootingActorsMappingID, SkinUpload) {
        return (
            <div class="form-builder">
            <h4>Edit Skin Against Talent</h4>
            <div class="cls-bttn" onClick={this.handleEditCancelbttn}>x</div>
            <div class="col-lg-12 popup-view">

                    <form id="msform" class="form-with-validation" style={{ "overflow-y": "hidden"}}>
                        <EditBGActor action={this.handleEditCancelbttn} shootingID={this.state.ShootingID} userID={localStorage.getItem('CDCurrentUserId')} shootingActorsMapping={EditshootingActorsMappingID} SkinUpload={SkinUpload}/>
                </form>
            </div>

        </div>
        );
    }

    rendertable(SkinUploads, i, CallTime) {
        console.log(SkinUploads);
        return (
            <div >
                <div className={"toaster-div " + (this.state.toasterCla ? "show" : "hide")}><i className="fa fa-check-circle" aria-hidden="true"></i> {this.state.toaster} </div>
            <table className="table table-striped- table-bordered table-hover" aria-labelledby="tabelLabel" id={"tbl_Skin_"+i}>
                <thead>
                    <tr>
                            <th width="3%"><input
                        type="checkbox"
                                name={"checkAll_" + CallTime}
                                checked={this.state.allChecked[i]}
                        onChange={this.handleDeleteChange}
                            /> </th>
                            <th width="10%">Check In#</th>
                            <th width="10%">Last Name</th>
                        <th width="10%">First Name</th>
                        
                        <th width="12%">Email</th>
                        <th width="12%">Phone</th>
                          
                            <th width="5%">Gender</th>
                            <th width="12%">Guild</th>
                        <th width="7%">I9</th>
                            <th width="5%">Role</th>
                            <th width="12%">Job Title</th>
                        <th width="5%">Rate</th>
                            <th width="5%" className="text-center" >Wardrobe</th>
                            <th width="5%" className="text-center" >Props</th>
                            <th width="10%" nowrap="true">Action</th>
                            <th width="10%" nowrap="true">Comment</th>
                        

                    </tr>
                </thead>
                <tbody>

                        {SkinUploads.map(SkinUpload =>
                            <tr key={SkinUpload.ssn} className={SkinUpload.bgActorID == 0 || SkinUpload.isExistInOtherShooting || !SkinUpload.isGuaranteedHour || SkinUpload.isCheckRefNumberDuplicate || SkinUpload.isCheckRefNumberInteger
                                || SkinUpload.isemailDuplicate || !SkinUpload.isValidEmail
                                ? 'highlight'
                                : (SkinUpload.isChanged ? 'highlight1' : '')
                              
                            }>
                                <td>
                                   
                                    <input
                                        data-tip="Selection for Talent Deletion/ Compensation Against Talent"
                                        key={SkinUpload.bgActorID}
                                        type="checkbox"
                                        name={"check_Delete_" + SkinUpload.bgActorID}
                                        value={SkinUpload.id}
                                        checked={SkinUpload.isChecked}
                                        onChange={this.handleDeleteChange}
                                        style={{ 'display': SkinUpload.isCheckedIn?'none':'inline'}}
                                        />
                                       
                                </td>
                                <td>{SkinUpload.checkRefNumber}</td>
                                <td>{SkinUpload.lastname}</td>
                            <td>{SkinUpload.firstname}</td>
                            
                            <td>{SkinUpload.email}</td>
                            <td>{SkinUpload.phone}</td>
                           
                            <td>{SkinUpload.gender}</td>
                            <td>{SkinUpload.unionID}</td>                           
                            <td>

                                <span
                                    className={"kt-badge kt-badge--" + (SkinUpload.i9Verified === "Verified" ? 'success' : 'danger') + " kt-badge--dot"}>
                                </span>&nbsp;
                                <span
                                    className={"kt-font-bold kt-font-" + (SkinUpload.i9Verified === "Verified" ? 'success' : 'danger')}>
                                    {SkinUpload.i9Verified}
                                </span>

                            </td>
                                <td>
                                    <span
                                        className="kt-font-bold kt-font-success"
                                        data-tip={SkinUpload.wardrobeInstructions!=""?"<b>Wardrobe Instructions</b><br/><hr/>" + SkinUpload.wardrobeInstructions:""}
                                        data-html="true"
                                    >
                                        {SkinUpload.role}
                                    </span>

                                   
                                </td>
                                <td>{SkinUpload.jobTitle}</td>
                                <td>{SkinUpload.rate}</td>
                            <td className="text-center" >
                                <label className="kt-checkbox kt-checkbox--bold kt-checkbox--success">
                                        <input
                                            type="checkbox"
                                            checked={SkinUpload.isWardrobeRequired}
                                            onChange={this.handleChange}
                                            id={"wardrobe_" + SkinUpload.id} name="wardrobe"
                                            disabled ={ SkinUpload.isCheckedIn ? true : false }
                                        />

                                    <span ></span></label>

                            </td>

                            <td className="text-center" >
                                <label className="kt-checkbox kt-checkbox--bold kt-checkbox--success">
                                        <input
                                            type="checkbox"
                                            checked={SkinUpload.isPropertyRequired}
                                            onChange={this.handleChange}
                                            id={"property_" + SkinUpload.id}
                                            name="property"
                                            disabled={SkinUpload.isCheckedIn ? true : false}
                                        />
                                    <span ></span></label></td>

                                <td >
                                    <button className="btn btn-sm btn-clean btn-icon removeRow" data-tip="Edit BGActor" style={{ 'display': ((SkinUpload.isCheckedIn || SkinUpload.isCheckedIn == null) && !SkinUpload.isAlreadyInDB) ? 'none' : 'inline' }}>
                                        <i className="la la-edit text-info" id={"editRow_" + SkinUpload.id} name="editRow"
                                            onClick={(e) => { this.handleDocEdit(SkinUpload,SkinUpload.shootingActorsMappingID, 'Res') }} 
                                        
                                        ></i></button>

                                    <button className="btn btn-sm btn-clean btn-icon removeRow" data-tip="Remove BGActor" style={{ 'display': SkinUpload.isCheckedIn ? 'none' : 'inline' }}>
                                        <i className="la la-trash-o text-danger" id={"removeRow_" + SkinUpload.id} name="removeRow" 
                                            onClick={(e) => { this.handleDocRemove(SkinUpload.id, 'Res') }}                                            
                                        ></i></button>
                                   

                                    <button className="btn btn-sm btn-clean btn-icon hide" style={{ display: SkinUpload.bgActorID == 0 ? 'inline-block' : 'none' }} >
                                        <i className="la la-plus text-primary" id={"btnAddNew_" + SkinUpload.id} onClick={this.handleChange} 
                                            name="AddNew"
                                            data-tip="Add new BGActor">
                                        </i>
                                    </button>


                                   

                                    
                            </td>
                                <td >
                                    <button className="btn btn-sm btn-clean btn-icon"
                                        data-toggle="kt-popover" data-trigger="focus"
                                        data-html="true"
                                        style={{ display: SkinUpload.dnr ? 'inline-block' : 'none' }}
                                        data-tip={"<b>Notes-Comments</b><br/><hr/>" + SkinUpload.checkoutNotes}><i
                                            className="la la-info-circle text-info"></i></button>
                                </td>
                        </tr>
                    )}
                </tbody>
                </table>
                <ReactTooltip type="light" place="bottom" effect="float" border={true} wrapper="span" html={true}/>
                </div>
        );
        //<button className="btn btn-sm btn-clean btn-icon" style={{ display: SkinUpload.BGActorID == 0 ? 'inline-block' : 'none' }}>
        //    <i className="la la-trash-o text-danger" id={"removeRow_" + SkinUpload.id}  >Add</i></button>

        //<button style={{ display: SkinUpload.BGActorID == 0 ? 'block' : 'none' }}>
        //    Add</button>
        //{"Notes-Comments/n"+SkinUpload.checkoutNotes}
    }
    render() {
        var SkinUploads = this.state.childDataSkinUpload;
        var uniqueCallTime = this.getUniqueCallTime(SkinUploads);
        
        
        return (
            <div>
                <div className={"removeAlert-div " + (this.state.removeAlert ? "show" : "hide")}><span className="alert-bg-div"></span>
                    <div className="alert-pos-x"> <i className="fa fa-bell" aria-hidden="true"></i> Are you sure you wish to delete this BGActor?
                        <div className="text-center-div">
                            <div className="bttn add-bttn" onClick={this.handleRemoveSpecificRow}>
                                Yes
                                
                        </div>
                            <div className="bttn cancel-btn" onClick={this.handleremoveCancelbttn}>
                                No
                        </div>
                        </div>
                    </div>
                </div>

                <div id="divEditBGActor" className={"popup-div modal " + (this.state.EditAlert ? "show" : "hide")} style={{ "width": "55px !important;"}}>
                   
                </div>


                {uniqueCallTime.map((itmCallTime, i) =>
                    <div key={i}>

                        

                        <h6>Call Time:<span className="text-danger">{itmCallTime} </span></h6>
                       

                        {this.rendertable(this.getSkinUploadsBasedonCalltime(SkinUploads, itmCallTime, i), i, itmCallTime)}
                        <div className="my-3  kt-separator kt-separator--border-dashed kt-separator--space-lg kt-separator--portlet-fit">
                        </div>
                    </div>

                )}
            </div>
        );


    }
    //Page render end
}
//<th width="13%">SSN</th>
//    <th width="5%">DOB</th>
// <td>{"XXX-XX-" + SkinUpload.ssn.toString().substring(12, 7)}</td>
//<td>{this.formatdate(SkinUpload.dob)}</td>


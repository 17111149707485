import ReactDOM from 'react-dom'
import React from 'react';

export class ResetPassword extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

            Username: this.props.Username, 
            Password: "",
            CPassword: "",
            toasterCla: false,
            toaster: '',
           
            NPMandatoryerr: true,
            NPValiderr: true,
            NPMinLengtherr: true,

            CPMandatoryerr: true,
            CPMatchingerr: true,

        }
        this.handleChange = this.handleChange.bind(this);
        this.OnResetPasswordSubmit = this.OnResetPasswordSubmit.bind(this);
    }
    // toaster alert start
    toasterfun2(e) {
        this.setState({ toasterCla: true });
        this.setState({ toaster: e });
        setTimeout(
            function () {
                this.setState({ toasterCla: false });
            }.bind(this), 4000);
    }
    // toaster alert end

    handleChange(event) {
        var target = event.target;
        var type = target.type;
        var name = target.getAttribute('name');
        var value = target.value;
      if (name == "popNewPassword") {
            this.setState({ Password: value })
        }
        else if (name == "popNewCPassword") {
            this.setState({ CPassword: value })
        }
    }

    ValidateNewPassword() {
        var err = 0;
        if (this.state.Password == "") {
            this.setState({ NPMandatoryerr: false });
            err = +1;
        }
        else { this.setState({ NPMandatoryerr: true });}
        if (this.state.Password.length<8) {
            this.setState({ NPMinLengtherr: false });
            err = +1;
        }
        else { this.setState({ NPMinLengtherr: true }); }
        return err;
//        if (err != 0) { return false; }
    }
    ValidateNewCPassword() {
        
        var err = 0;
        if (this.state.Password != this.state.CPassword) {
            this.setState({ CPMatchingerr: false });
            err = +1;
        }
        else { this.setState({ CPMatchingerr: true }); }
        if (this.state.CPassword == "") {
            this.setState({ CPMandatoryerr: false });
            err = +1;
        }
        else { this.setState({ CPMandatoryerr: true }); }
        return err;
      //  if (err != 0) { return false; }
    }
    OnResetPasswordSubmit(event) {
        event.preventDefault();
        let currentComponent = this;
        var err = (parseInt(currentComponent.ValidateNewPassword()) + parseInt(currentComponent.ValidateNewCPassword()));
        
        
        if (err == 0) {
            let Username = "";
            var windowlocation = window.location.href.toLowerCase();
            if (windowlocation.includes("skinupload")) {
                Username = localStorage.getItem('CDUsername');
            }
            else {
                Username = localStorage.getItem('PAUsername');
            }

            let data = '';
            data = JSON.stringify({ "UserName": Username, "NewPassword": this.state.Password })
            fetch('AdminSkinUpload/ResetPassword', {
                method: 'post',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: data
            }).then(function (response) {
                return response.json();
            }).then(function (result) {
                //debugger;
                if (result.code == "200") {                    
                    currentComponent.toasterfun2(result.message);
                    currentComponent.LogOut();
                   //currentComponent.props.action();
                    //currentComponent.props.setState({
                    //    isResetPasswordPopupOpen: true
                    //});
                    //ReactDOM.unmountComponentAtNode(document.getElementById('divResetPassword'));
                   
                }
              
            }).catch(function (error) {
                console.log("-------- error ------- " + error);

            });



        }
        else {
            event.preventDefault();        
            return false;
            
        }
        
    }
    LogOut() {
        //var Role = localStorage.getItem('Role');
        var windowlocation = window.location.href.toLowerCase();
        if (windowlocation.includes("skinupload")) {
            window.location = "/CastingDirector/Login";
            sessionStorage.removeItem('CDuserfullName');
            sessionStorage.removeItem('DataSkinUpload');
            sessionStorage.removeItem('CDRole');
            sessionStorage.removeItem('CDCurrentUserId');
            sessionStorage.removeItem('CDUsername');




        }
        else if (windowlocation.includes("timecardfileexport")) {
            window.location = "/ProductionAccountant/login";
            sessionStorage.removeItem('PAuserfullName');
            sessionStorage.removeItem('PACurrentUserId');
            sessionStorage.removeItem('PARole');
            sessionStorage.removeItem('PAUsername');
        }

        //this.props.history.push('/Home');
        //    //.props.history.push("/SkinUploadHome");
        //window.location = "/";
    }
    render() {
        return (
            
            <div class="form-builder" style={{width:"553px"}}>
                <div className={"toaster-div " + (this.state.toasterCla ? "show" : "hide")}><i className="fa fa-check-circle" aria-hidden="true"></i> {this.state.toaster} </div>
                <h4 className="headingt4">Reset Password</h4>
                <div class="cls-bttn" onClick={this.props.action}>x</div>
                <div class="col-lg-12 popup-view">

                    <form id="msform" class="form-with-validation" >

                       
                        <div className="clear"></div>
                        <div class="col-lg-8 col-md-8">
                            <div class="popup-x">
                                <label></label>
                                <input placeholder="New Password" type="text" value={this.state.Password} maxLength={15} className="form-control" name="popNewPassword" onChange={this.handleChange} />
                                
                                <div className={"rederror " + (this.state.NPMandatoryerr ? "hide" : "show")}>* Mandatory</div>
                                <div className={"rederror " + (this.state.NPValiderr ? "hide" : "show")}>* Is not valid</div>
                                <div className={"rederror " + (this.state.NPMinLengtherr ? "hide" : "show")}>* Minimum length 8 Characters</div>
                                
 
                            </div>
                        </div>
                        <div className="clear"></div>

                        <div class="col-lg-8 col-md-8">
                            <div class="popup-x">
                                <label></label>
                                <input placeholder="New Confirm Password" type="text" value={this.state.CPassword} maxLength={15} className="form-control" name="popNewCPassword" onChange={this.handleChange} />
                                <div className={"rederror " + (this.state.CPMandatoryerr ? "hide" : "show")}>* Mandatory</div>
                                <div className={"rederror " + (this.state.CPMatchingerr ? "hide" : "show")}>Mismatch Password</div>                                
                            </div>
                        </div>


                        <div className="clear"></div>

                        <div class="popup-x text-center padding-x green-div-btn">
                            <button type="submit" class="btn btn-primary" onClick={this.OnResetPasswordSubmit}>Save </button>
                            <button type="button" class="btn btn-default btn-cancel" onClick={this.props.action}>Cancel </button>
                        </div>
                    </form>
                </div>
            </div >

        );
    }
}
import * as Constants from './Constants';
const CryptoJS = require("crypto-js");


const validateRegex = (data, rgx) => {
    let regex = new RegExp(rgx)
    data = data.replace(/\s/g, '');
    if (regex.test(data)) {
        return true;
    }
    else {
        return false;
    }
}

const validateNumeric = (number) => {
    let regex = new RegExp(Constants.NumericRegex)
    number = number.replace(/\s/g, '');
    if (regex.test(number)) {
        return true;
    }
    else {
        return false;
    }
}

const validateName = (text) => {
    let regex = new RegExp(Constants.NameRegex)
    text = text.replace(/\s/g, '');
    if (regex.test(text)) {
        return true;
    }
    else {
        return false;
    }
}

const validateEmail = (email) => {
    let regex = new RegExp(Constants.EmailRegex)
    //email = email.replace(/\s/g, '');
    if (regex.test(email.trim()) || email == '') {
        return true;
    }
    else {
        return false;
    }
}

const validateAlphabet = (letter) => {
    let regex = new RegExp(Constants.AlphabetsRegex)
    letter = letter.replace(/\s/g, '');
    if (regex.test(letter)) {
        return true;
    }
    else {
        return false;
    }
}

const validateAlphabetSplChar = (letter) => {
    let regex = new RegExp(Constants.AlphabetsSplCharRegex)
    letter = letter.replace(/\s/g, '');
    if (regex.test(letter)) {
        return true;
    }
    else {
        return false;
    }
}

const validateDecimal = (letter) => {
    let regex = new RegExp(Constants.DecimalRegex)
    letter = letter.replace(/\s/g, '');
    if (regex.test(letter)) {
        return true;
    }
    else {
        return false;
    }
}

const MaskPhoneNumber = (Phone) => {
    if (Phone != undefined) {
        // debugger;
        //Phone = Phone.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "").replaceAll(" ", "");
        Phone = Phone.replace(/[^0-9]/gi, '');
        if (10 == Phone.length) {
            let mask = "(XXX)XXX-XXXX";
            var s = '' + Phone, r = '';
            for (var im = 0, is = 0; im < mask.length && is < s.length; im++) {
                r += mask[im] == 'X' ? s.charAt(is++) : mask.charAt(im);
            }
            return r;

        }
    }
}


const validatePhoneNumber = (phone) => {
    if (phone != undefined) {
        // let data = phone.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "").replaceAll(" ", "");
        let data = phone.replace(/[^0-9]/gi, '');
        if (data.length == 10) {
            let validateNo = validateNumeric(data);
            if (validateNo)
                return true
            else
                return false
        }
        else
            return false

    }
}

const Encryption = (data) => {
    let EncryptedData;
    //EncryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), Constants.CryptoSecretKey).toString();
    EncryptedData = CryptoJS.AES.encrypt(
        CryptoJS.enc.Utf8.parse(JSON.stringify(data)),
        CryptoJS.enc.Utf8.parse(Constants.CryptoSecretKey),
        {
            keySize: 128 / 8,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
            iv: CryptoJS.enc.Utf8.parse(Constants.CryptoSecretKey)
        });

    return EncryptedData.ciphertext.toString(CryptoJS.enc.Base64);
}

const Decryption = (data) => {
    let DecryptedData;
    //EncryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), Constants.CryptoSecretKey).toString();
    var key = CryptoJS.enc.Utf8.parse(Constants.CryptoSecretKey);
    var iv = CryptoJS.enc.Utf8.parse(Constants.CryptoSecretKey);
    var bytes = CryptoJS.AES.decrypt(data, key, { iv: iv });
    var originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText.split('+').join(' ').split('"').join('');

    //DecryptedData = CryptoJS.AES.decrypt(
    //    CryptoJS.enc.Utf8.parse(JSON.stringify(data)),
    //    CryptoJS.enc.Utf8.parse(Constants.CryptoSecretKey),
    //    {
    //        keySize: 128 / 8,
    //        mode: CryptoJS.mode.CBC,
    //        padding: CryptoJS.pad.Pkcs7,
    //        iv: CryptoJS.enc.Utf8.parse(Constants.CryptoSecretKey)
    //    });

    //return DecryptedData.ciphertext.toString(CryptoJS.enc.Base64);
}

const ChangeKeyObjects = (arr, replaceKeys) => {
    if (arr != null) {
        return arr.map(item => {
            const newItem = {};
            Object.keys(item).forEach(key => {
                newItem[replaceKeys[key]] = item[[key]];
            });
            delete (newItem["undefined"]);
            return newItem;
        });
    }
    else {
        return arr
    }
};

export {
    Encryption,
    Decryption,
    validateNumeric,
    validateName,
    validateEmail,
    validateAlphabet,
    validateAlphabetSplChar,
    validateDecimal,
    validatePhoneNumber,
    MaskPhoneNumber,
    ChangeKeyObjects,
    validateRegex
}
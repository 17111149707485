import React, { useState, useEffect } from "react";
import { Enum, Utils, Constants } from '../../EMS_Components'
import './Captcha.css';

const Captcha = (props) => {
    const [captchaText, setcaptchaText] = useState('');
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';

    const generateString = (length) => {
        let result = ' ';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }

        return result;
    }

    const setcaptchaString = () => {
        let cap = generateString(6);
        setcaptchaText(cap);
    }

    const checkCaptcha = (e) => {
        if (captchaText.replace(' ', '') === e.target.value.replace(' ', '')) {
            props.formdata[props.id] = true;
        }
        else {
            props.formdata[props.id] = false;
        }
    }

    useEffect(() => {
        setcaptchaString();
    }, [props])



    return (
        <div class="form-group">
            <div className="captcha-container">
                <div className="captcha-image">
                    <div className="captcha">
                        {captchaText}
                    </div>
                </div>
                <div className="input-group captcha-text">
                    <input type="text" className="form-control" onChange={checkCaptcha} />
                    <div className="input-group-append">
                        <button className="btn captcha-refresh"  type="button" onClick={setcaptchaString}><i className="fa fa-refresh" aria-hidden="true"></i></button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Captcha
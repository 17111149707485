import React from 'react';
import { Layout } from '../Layout';
import moment from 'moment';
import { DatePickerInput } from 'rc-datepicker';
import MaterialTable from "material-table";
import $ from 'jquery';
import ReactTooltip from 'react-tooltip';
import { onlyDigits } from '../Validation'
//import { Tabs, Tab } from 'react-bootstrap-tabs';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';// 'react-tabs/style/react-tabs.css';
import { APIGet, APIPost } from '../EMS_Components/APICall'


export class BGActorAudit extends React.Component {
    static displayName = BGActorAudit.name;

    constructor(props) {
        super(props);
       
        this.state = {
            loading: true,
            rows: [],
            ShootDate: moment.utc().format('MM/DD/YYYY'),
            toasterCla: false,
            toaster: '',
            defaultValue:100
        }
        
        this.LoadAuditData(moment.utc().format('MM/DD/YYYY'));
        this.OnDateChange = this.OnDateChange.bind(this);
        this.handleARReviewSubmit = this.handleARReviewSubmit.bind(this);
       
    }
    //increaseScore() {
    //    // 1. Get previous state from this.state
    //    this.setState({ baseRate: this.state.baseRate + 1 })

    //    // 2. Get previous state from the callback function
    //    this.setState((prevState) => {
    //        return { baseRate: prevState.baseRate + 1 }
    //    })
    //}
    toasterfun2(e) {
        this.setState({ toasterCla: true });
        this.setState({ toaster: e });
        setTimeout(
            function () {
                this.setState({ toasterCla: false });
            }.bind(this), 3000);
    }
    //Funcion related to Datepicker -- start
    log = (...x) => console.log(...x)       // eslint-disable-line no-console
    onClear = () => this.setState({ ShootDate: null })
    formatdate(Datetobeformatted) {
        if (Datetobeformatted != null) {// format date in MM/DD/YYYY format
            return Intl.DateTimeFormat('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
            }).format(new Date(Datetobeformatted));
        }
    }
    OnDateChange(jsDate, date) {
        console.log(date);
        var ShootDate = moment(date).format();
        this.setState({ ShootDate: moment.utc(date).format('MM/DD/YYYY') })
        this.LoadAuditData(ShootDate);
    }
    //Excel Export
    handleARReviewSubmit(event) {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        let currentComponent = this;
        event.preventDefault();

        var logdate = moment(date).format();
        this.setState({ logdate: moment.utc(date).format('MM/DD/YYYY') })
        var date = moment.utc(logdate).format();

        let data = {
            ShootDate: date
        }

        APIPost('SkinUpload/DVActorShootingExportExcel', data, function (result) {
            if (result.code === 200) {
                
                currentComponent.setState({ SubmitButtonVisible: false });

                //currentComponent.SendEmail(ShootingID);
                currentComponent.setState({ SubmitButtonVisible: true });
            } else {
                currentComponent.toasterfun2(result.message);
            }
        });
    }
    //Funcion related to Datepicker -- end
    LoadAuditData(ShootDate) {
        let currentComponent = this;

        //console.log(ShootDate.format());
        //ShootDate = ShootDate.format();
        var date = moment.utc(ShootDate).format()
        
        console.log(date);
        var ShootDate = moment(currentComponent.state.ShootDate).format();
        //debugger;

        var strURL = "GetAuditShootingActorsMapping";
        let data = {
            ShootDate: date
        }
        APIPost('SkinUpload/' + strURL, data, function (result) {
            if (result.code == 200) {
                currentComponent.setState({ rows: result.result, loading: false });
            } else {
                console.log(result.message);
                currentComponent.setState({ rows: result.result, loading: false });
                currentComponent.toasterfun2(result.message);
            }
        });
    }
   
    render() {
        return (
            <Layout>
                <div className={"toaster-div " + (this.state.toasterCla ? "show" : "hide")}><i className="fa fa-check-circle" aria-hidden="true"></i> {this.state.toaster} </div>

               <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                    <div className="kt-portlet kt-portlet--mobile mb-0">
                        <div className="kt-portlet__body">
                            <div action="" className="kt-form kt-form--label-right">
                               
                                <div className="row">
                                   
                                    <div className="col-lg-2 col-md-3">

                                        <div className="form-group mm-date">
                                            <label>Shoot Date</label>

                                            <DatePickerInput
                                                displayFormat='MM/DD/YYYY'
                                                returnFormat='MM/DD/YYYY'
                                                className='my-react-component'
                                                onChange={this.OnDateChange}
                                                onShow={this.log.bind(this, 'show')}
                                                onHide={this.log.bind(this, 'hide')}
                                                showOnInputClick
                                                placeholder='placeholder'
                                                locale='de'
                                                onClear={this.onClear}
                                                value={this.state.ShootDate}

                                                
                                            />

                                        </div>
                                    </div>
                                    
                                </div>

                                <div className="my-3  kt-separator kt-separator--border-dashed kt-separator--space-lg kt-separator--portlet-fit">
                                </div>
                                <div className="master-table-x">
                                <MaterialTable
                                    title=""
                                        columns={[
                                            { title: "ShootingName", field: "shootingName", render: rowData => <span className="middle-txt">{rowData.ShootingName}</span>  },
                                            { title: "BGName", field: "displayName", render: rowData => <span className="middle-txt">{rowData.displayName}</span> },
                                            { title: "CreatedOnDateTime", field: "createdOnDateTime", render: rowData => <span className="pull-left">{moment(rowData.createdOnDateTime).isValid() ? moment(rowData.createdOnDateTime).format('MM/DD/YYYY hh:mm A') : ''} </span>  },
                                            { title: "CreatedBy", field: "createdBy", render: rowData => <span className="middle-txt">{rowData.CreatedBy}</span> },
                                            { title: "CallTime", field: "callTime", render: rowData => <span className="middle-txt">{rowData.CallTime}</span>  },                                     
                                            { title: "CheckinTime", field: "checkinTime", render: rowData => <span className="pull-left">{moment(rowData.checkinTime).isValid() ? moment(rowData.checkinTime).format('MM/DD/YYYY hh:mm A') : ''} </span> },
                                            { title: "CheckoutTime", field: "checkoutTime", render: rowData => <span className="pull-left">{moment(rowData.CheckoutTime).isValid() ? moment(rowData.CheckoutTime).format('MM/DD/YYYY hh:mm A') : ''} </span>   },
                                            { title: "BaseRate", field: "baseRate", render: rowData => <span className={"curpointer " + (rowData.baseRate > this.state.prevState ? "redclr" : "greenclr")} ><span className="middle-txt">{rowData.baseRate}</span> </span> },
                                            { title: "RateAdjusted", field: "rateAdjusted", render: rowData => <span className={"curpointer " + (rowData.smokeWork > this.state.defaultValue ? "redclr" : "greenclr")} ><span className="middle-txt">{rowData.rateAdjusted}</span> </span> },
                                            { title: "Role", field: "role", render: rowData => <span className="middle-txt">{rowData.Role}</span>  },
                                            { title: "PaymentStatus", field: "paymentStatus", render: rowData => <span className="middle-txt">{rowData.PaymentStatus}</span>  },
                                            { title: "WardrobeStatus", field: "wardrobeStatus", render: rowData => <span className="middle-txt">{rowData.wardrobeStatus}</span>  },
                                            { title: "PropertyStatus", field: "propertyStatus", render: rowData => <span className={"curpointer " + (rowData.smokeWork > this.state.defaultValue ? "redclr" : "greenclr")} ><span className="middle-txt">{rowData.propertyStatus}</span> </span> },
                                            { title: "SmokeWork", field: "smokeWork", render: rowData => <span className={"curpointer " + (rowData.smokeWork > this.state.defaultValue ? "redclr" : "greenclr")} ><span className="middle-txt">{rowData.smokeWork}</span> </span> },
                                            { title: "Modified By", field: "lastEditedBy", render: rowData => <span className="middle-txt">{rowData.lastEditedBy}</span> },
                                            { title: "Modified DateTime", field: "recordedDateTime", render: rowData => <span className="pull-left">{moment(rowData.recordedDateTime).isValid() ? moment(rowData.recordedDateTime).format('MM/DD/YYYY hh:mm A') : ''} </span>  },
                    ]}
                                    data={this.state.rows}
                                    class="mat-paginator-sticky"
                                    title=""
                                    options={{
                                        pageSizeOptions: [50, 100, 500],
                                        pageSize: 50,
                                        filtering: true,
                                        exportButton: true
                                    }}
                                        detailPanel={rowData => {
                                            return (
                                                <div className="mastr-div-x">                                                   
                                                   
                                                    <div className="leftside-x">
                                                        <span className="header-tree-x"> FirstMealOut</span>
                                                        <span className="header-tree-m">{rowData.firstMealOut} </span>

                                                        <span className="header-tree-x"> FirstMealIn</span>
                                                        <span className="header-tree-m">{rowData.firstMealIn} </span>
                                                    </div>
                                                    <div className="leftside-x">
                                                        <span className="header-tree-x"> SecondMealOut</span>
                                                        <span className="header-tree-m">{rowData.secondMealOut} </span>
                                                        
                                                        <span className="header-tree-x"> SecondMealIn</span>
                                                        <span className="header-tree-m">{rowData.secondMealIn} </span>
                                                    </div>
                                                    <div className="leftside-x">
                                                        <span className="header-tree-x"> NDB</span>
                                                        <span className="header-tree-m">{rowData.nDB} </span>

                                                        <span className="header-tree-x"> NDD</span>
                                                        <span className="header-tree-m">{rowData.nDD} </span>

                                                    </div>
                                                    <div className="leftside-x">

                                                        <span className="header-tree-x"> RecordType</span>
                                                        <span className="header-tree-m">{rowData.recordType}</span>
                                                    
                                                        <span className="header-tree-x"> WardrobeRate</span>
                                                        <span className="header-tree-m">{rowData.wardrobeRate} </span>
                                                    </div>

                                                    <div className="leftside-x">
                                                        <span className="header-tree-x"> MealPenalty</span>
                                                        <span className="header-tree-m">{rowData.mealPenalty} </span>
                                                   
                                                        <span className="header-tree-x"> Auto</span>
                                                        <span className="header-tree-m">{rowData.auto} </span>
                                                    </div>
                                                    <div className="leftside-x">
                                                        <span className="header-tree-x"> Interview</span>
                                                        <span className="header-tree-m">{rowData.interview} </span>
                                                    
                                                        <span className="header-tree-x"> Fitting</span>
                                                        <span className="header-tree-m">{rowData.fitting} </span>
                                                    </div>
                                                    <div className="leftside-x">
                                                        <span className="header-tree-x"> SpecialAbility</span>
                                                        <span className="header-tree-m">{rowData.specialAbility}</span>
                                                   
                                                        <span className="header-tree-x"> BodyMakeup</span>
                                                        <span className="header-tree-m">{rowData.bodyMakeup} </span>
                                                    </div>

                                                    <div className="leftside-x">
                                                        <span className="header-tree-x"> HairCompensation</span>
                                                        <span className="header-tree-m">{rowData.hairCompensation} </span>
                                                    
                                                        <span className="header-tree-x"> WetWork</span>
                                                        <span className="header-tree-m">{rowData.wetWork} </span>
                                                    </div>

                                                    <div className="leftside-x">
                                                        <span className="header-tree-x"> BeardCompensation</span>
                                                        <span className="header-tree-m">{rowData.beardCompensation} </span>
                                                   
                                                        <span className="header-tree-x"> DressUniformCompensation</span>
                                                        <span className="header-tree-m">{rowData.dressUniformCompensation} </span>
                                                    </div>

                                                   
                                                   


                                                    <div className="leftside-x">
                                                        <span className="header-tree-x"> OtherDescription</span>
                                                        <span className="header-tree-m">{rowData.otherDescription} </span>
                                                  
                                                        <span className="header-tree-x"> Other</span>
                                                        <span className="header-tree-m">{rowData.other} </span>
                                                    </div>

                                                  

                                                    <div className="leftside-x">
                                                        <span className="header-tree-x"> CheckOutNotes</span>
                                                        <span className="header-tree-m">{rowData.checkOutNotes} </span>
                                                  
                                                        <span className="header-tree-x"> DNR</span>
                                                        <span className="header-tree-m">{rowData.dNR} </span>
                                                    </div>

                                                    <div className="leftside-x">
                                                        <span className="header-tree-x"> Grace</span>
                                                        <span className="header-tree-m">{rowData.grace} </span>
                                                   
                                                        <span className="header-tree-x"> Mileage</span>
                                                        <span className="header-tree-m">{rowData.mileage} </span>
                                                    </div>

                                                    <div className="leftside-x">
                                                        <span className="header-tree-x"> FittingHours</span>
                                                        <span className="header-tree-m">{rowData.fittingHours} </span>
                                                  
                                                        <span className="header-tree-x"> InterviewHours</span>
                                                        <span className="header-tree-m">{rowData.interviewHours} </span>
                                                    </div>


                                                    <div className="leftside-x">
                                                        <span className="header-tree-x"> MileageMiles</span>
                                                        <span className="header-tree-m">{rowData.mileageMiles} </span>
                                                    
                                                        <span className="header-tree-x"> WardrobeQuantity</span>
                                                        <span className="header-tree-m">{rowData.wardrobeQuantity} </span>
                                                    </div>

                                                    <div className="leftside-x">
                                                        <span className="header-tree-x"> Per Diem</span>
                                                        <span className="header-tree-m">{rowData.carAllowance} </span>
                                                  
                                                        <span className="header-tree-x"> Other1</span>
                                                        <span className="header-tree-m">{rowData.other1} </span>
                                                    </div>

                                                    <div className="leftside-x">
                                                        <span className="header-tree-x"> Other2</span>
                                                        <span className="header-tree-m">{rowData.other2} </span>
                                                   
                                                        <span className="header-tree-x"> OtherDescription1</span>
                                                        <span className="header-tree-m">{rowData.otherDescription1} </span>
                                                    </div>

                                                   

                                                </div>
                                            )
                                        }}

                                />
                               </div>
                            </div>
                        </div>

                    </div>
                </div >
                
            </Layout>
        );
    }
}


import React, { useState, useEffect } from 'react';
import { Layout } from '../Layout';
import moment from 'moment';
import { DatePickerInput } from 'rc-datepicker';
import MaterialTable from "material-table";
import $ from 'jquery';
import ReactTooltip from 'react-tooltip';
import { onlyDigits } from '../Validation'
//import { Tabs, Tab } from 'react-bootstrap-tabs';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';// 'react-tabs/style/react-tabs.css';
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import 'react-multi-email/style.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { APIGet, APIPost } from '../EMS_Components/APICall'


export const Email = (props) => {
    const [toasterCla, settoasterCla] = useState(false);
    const [toaster, settoasterla] = useState(null);
    const [emails, setemails] = useState(props.SelectedBGs ? props.SelectedBGs : []);
    const [ccemails, setccemails] = useState([]);
    const [bccemails, setbccemails] = useState([]);
    const [bodymessage, setbodymessage] = useState('');
    const [CurrentGalleryItem, setCurrentGalleryItem] = useState(null);
    const [showImgupload, setshowImgupload] = useState(true);
    const [imgSrc, setimgSrc] = useState([]);
    const [Base64, setBase64] = useState([]);
    const [GallName, setGallName] = useState('');
    const [subject, setsubject] = useState('');
    const [CurrentGalleryName, setCurrentGalleryName] = useState('');
    const [EmailAttachment, setEmailAttachment] = useState([])
    const [openModal, setopenModal] = useState(false)

    //useEffect(() => {
    //    debugger
    //    setCurrentGalleryItem(CurrentGalleryItem);
    //}, [CurrentGalleryItem]);

    const onOpensubmodal = (ModalName) => {
        setopenModal(prevCheck => !prevCheck);        
    }
    

    const toasterfun2 = (e) => {
        settoasterCla(true);
        settoasterla(e);
        setTimeout(
            function () {
                settoasterCla(false);
            }.bind(this), 4000);
    }
      const mailpopupclose = (e) => {
        settoasterCla(true);
        settoasterla(e);
        setTimeout(
            function () {
                settoasterCla(false);                
                setemails([])
                setccemails([])
                setbccemails([])
                setbodymessage('')
                setCurrentGalleryItem(null)
                setsubject('')
                setGallName(null)
                setEmailAttachment([])
                setGallName('')
                setCurrentGalleryName('')
                setimgSrc([])
                setBase64([])
                setimgSrc([])
                props.action();
            }.bind(this), 1000);
    }
    const GalleryChangeHandler = (event) => {
        setshowImgupload(true);
        
        //setCurrentGalleryItem(null);

        loadfiles(event.target.files, function () {
            console.log('files loaded')
        })

    };

    const loadfiles = (files, callback) => {
        let i = 0;
        Array.prototype.forEach.call(files, function (item) {
            let docName = item.name;
            let split = docName.split('.');
            
            let extension = split[split.length - 1];
            if ((extension.toLowerCase() == "csv" || extension.toLowerCase() == "doc" || extension.toLowerCase() == "docx" || extension.toLowerCase() == "gif"||
                extension.toLowerCase() == "htm" || extension.toLowerCase() == "html" || extension.toLowerCase() == "jpg" || extension.toLowerCase() == "jpeg" ||
                extension.toLowerCase() == "pdf" || extension.toLowerCase() == "png" || extension.toLowerCase() == "ppt" || extension.toLowerCase() == "pptx" ||
                extension.toLowerCase() == "rtf" || extension.toLowerCase() == "xls" || extension.toLowerCase() == "xlsx" || extension.toLowerCase() == "zip") && item.size < 2097152) {
                var reader = new FileReader();
                var url = reader.readAsDataURL(item);
                reader.onloadend = (e) => {
                    setimgSrc([reader.result])
                    setBase64([reader.result])

                    let attachment = {
                        ImageString: reader.result,
                        FileName: docName
                    }

                    let emailAttchemnts = EmailAttachment;
                    emailAttchemnts.push(attachment);
                    setEmailAttachment(emailAttchemnts);
                    setCurrentGalleryName(docName);
                    i++;
                }
            }
            else {
                document.getElementById("customFile1").value = "";
                setshowImgupload(false);
                
                setimgSrc('images/icon.png')
                setGallName('Choose File');
                setCurrentGalleryName('');
                i++;
            }

            if (i == files.length) {
                callback();
            }
        })
    }

    const removeAttachment = (e) => {
        const name = e.target.getAttribute("name")
        setEmailAttachment(EmailAttachment.filter(item => item.FileName !== name));
    }

    const handleChange = (e) => {
        if (e.target.id == "emailSubject") {
            setsubject(e.target.value);
        }
        //else if (e.target.id == "emailMessage") { 
        //    setbodymessage(e.target.value);
        //    if (props.onChange) {
        //        props.onChange(
        //            bodymessage.toString('html')
        //        );
        //    }
        //}
    }

    const onMessageChange = (value) => {
        setbodymessage(value);
    };

    const sendEmails = (e) => {
        if (emails.length == 0) {
             //&& ccemails == '' && bccemails == '')
            toasterfun2("Please provide valid recipients to send mail");
            return;
        }
        if (ccemails.length == 0 && bccemails.length == 0 && openModal == true)
        {
            toasterfun2("Please provide valid Cc / Bcc recipients to send mail");
            return;
        }
         

        let data = {
            FromEmail: '',
            ToEmail: emails,
            CCEmail: ccemails,
            BCCEmail: bccemails,
            Subject: subject,
            BodyMessage: bodymessage,
            //MimeType: CurrentGalleryName.split('.'),
            EmailAttachment: EmailAttachment
         }


        APIPost('Notification/SendEmail', data, function (result) {
            if (result =="Mail Sent Successfully") {
                mailpopupclose("Mail Sent Successfully");
            } else {
                toasterfun2("Mail Sending Failed");
            }
        });
        
         
    }

    return (
        <div>
            <div className={"toaster-div " + (toasterCla ? "show" : "hide")}><i className="fa fa-check-circle" aria-hidden="true"></i> {toaster} </div>
            <div class="messege-right">
                <div class="m-0">
                    <div class="text-white" >
                        <div class="row">
                            <div class="col-lg-12">
                                <h1 class="pt-2 text-center titlebggreen">New Message</h1>
                                <div class="cls-bttn" style={{ right: "25px", top: "6px" }} onClick={props.action}>x</div>
                            </div>
                            <div class="col-lg-6 pt-2 message-box-icon">
                                <span class="pull-right">
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="p-4 message-box-input" style={{ height: "400px", overflowY: "auto" }}>
                        <form>
                            <div class="form-group">
                                <div class="input-group mb-3">
                                    <div class="input-group-append">
                                        <span className="input-group-text eye-icon-style" style={{ minWidth: "85px" }}>From :</span>
                                    </div>
                                    <input className="form-control" value="notifications@emspayroll.com" readOnly />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group mb-3 wid90" style={{ flexWrap: "nowrap" }}>
                                    <div class="input-group-append">
                                        <span className="input-group-text eye-icon-style" style={{ minWidth: "85px" }}>To :</span>
                                    </div>
                                    <ReactMultiEmail
                                        placeholder="Input your email"
                                        emails={emails}
                                        onChange={(e) => setemails(e)}
                                        getLabel={(email, index, removeEmail) => {
                                            return (
                                                <div data-tag key={index}>
                                                    <div data-tag-item>
                                                        {email}
                                                    </div>
                                                    <span data-tag-handle onClick={() => removeEmail(index)}>×</span>
                                                </div>
                                            );
                                        }}
                                    />
                                </div>
                                <div className="ccbutton" onClick={() => onOpensubmodal()}> CC / BCC</div>
                            </div>

                            <div className={"hidecss " + (openModal ? "show" : "hide")}>
                            <div class="form-group">
                                <div class="input-group mb-3">
                                    <div class="input-group-append">
                                        <span className="input-group-text eye-icon-style" style={{ minWidth: "85px" }}>CC :</span>
                                    </div>
                                    <ReactMultiEmail
                                        placeholder="Input your email"
                                        emails={ccemails}
                                        onChange={(e) => setccemails(e)}
                                        getLabel={(ccemail, index, removeEmail) => {
                                            return (
                                                <div data-tag key={index}>
                                                    <div data-tag-item>
                                                        {ccemail}
                                                    </div>
                                                    <span data-tag-handle onClick={() => removeEmail(index)}>×</span>
                                                </div>
                                            );
                                        }}
                                    />
                                    </div>
                                   
                            </div>
                            <div class="form-group">
                                <div class="input-group mb-3">
                                    <div class="input-group-append">
                                        <span className="input-group-text eye-icon-style" style={{ minWidth: "85px" }}>BCC :</span>
                                    </div>
                                    <ReactMultiEmail
                                        placeholder="Input your email"
                                        emails={bccemails}
                                        onChange={(e) => setbccemails(e)}
                                        getLabel={(ccemail, index, removeEmail) => {
                                            return (
                                                <div data-tag key={index}>
                                                    <div data-tag-item>
                                                        {bccemails}
                                                    </div>
                                                    <span data-tag-handle onClick={() => removeEmail(index)}>×</span>
                                                </div>
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group mb-3">
                                    <div class="input-group-append">
                                        <span className="input-group-text eye-icon-style" style={{ minWidth: "85px" }}>Subject :</span>
                                    </div>
                                    <input className="form-control" id="emailSubject" value={subject} onChange={handleChange} />
                                </div>
                            </div>
                            <div class="form-group text-left">
                                <label className="bmd-label-floating">Message :</label>
                                <ReactQuill theme="snow" value={bodymessage} onChange={onMessageChange} style={{ minWidth: "300px" }} />
                            </div>
                            <div class="form-group text-left">
                                <ul className="email-attachment">
                                    {
                                        EmailAttachment.map((file, index) => {
                                            return (
                                                <li className="file-list">{file.FileName}<span className="remove-attachment" name={file.FileName} onClick={removeAttachment}>&times;</span></li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </form>
                    </div>
                    <div class="co-lg-12 message-box-last-content p-2" style={{ marginTop: "0px" }}>
                        <button class="btn btn-primary btn-sm pl-3 pr-3" type="button" onClick={sendEmails}>SEND</button>
                        <span>
                            <label for="customFile1">
                                <i className="fa fa-paperclip" type="button"></i>
                            </label>
                            <input type="file" id="customFile1" className="hide" accept=".csv,.doc,.docx,.gif,.htm,.html,.jpg,.jpeg,.pdf,.png,.ppt,.pptx,.rtf,.xls,.xlsx,.zip" multiple onChange={GalleryChangeHandler} aria-hidden="true"></input>
                            <div className="db-label noPosition">* You can upload csv,doc,docx,gif,htm,html,jpg,jpeg,pdf,png,ppt,pptx,rtf,xls,xlsx and zip files less than 2MB</div>
                            <div className={"redAlert noPosition " + (showImgupload ? "hide" : "show")}>* Select file in mentioned format and size </div>
                        </span>
                        <span class="pull-right">
                            <i class="fa fa-trash-o" aria-hidden="true"></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )

}

import React from 'react';
import $ from 'jquery';
import { Utils, Enum, Captcha, TextBox } from '../components/EMS_Components'
import { APIGet, APIPost, APIGetWOToken, APIPostWOToken } from './EMS_Components/APICall'
import ChangePassword from './Admin/ChangePassword';
import ForgotPassword from './Admin/ForgotPassword';


export class CommonLogin extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

            UserName: '',
            Password: '',
            loading: false,
            toasterCla: false,
            toaster: '',
            showemail: true, showpassword: true,
            Role: "",
            locked: false,
            formData: {},
            WrongAttemptCount: 0,
            errormsg: {},
            showMFA: false,
            Email: '',
            Phone: '',
            showChangePassword: '',
            PasswordPolicy: '',
            PasswordDescription: '',
            forgotPWD: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeusername = this.handleChangeusername.bind(this);
        this.handleChangepassword = this.handleChangepassword.bind(this);
        this.toasterfun2 = this.toasterfun2.bind(this);
        this.resendOTP = this.resendOTP.bind(this);
    }
    // toaster alert start
    toasterfun2(e) {
        this.setState({ toasterCla: true });
        this.setState({ toaster: e });
        setTimeout(
            function () {
                this.setState({ toasterCla: false });
            }.bind(this), 4000);
    }
    // toaster alert end

    componentWillMount() {
        sessionStorage.clear();
    }

    handleChangeusername(e) {
        this.setState({ showemail: true });

        this.setState({ UserName: e.target.value });
    }
    handleChangepassword(e) {
        this.setState({ showpassword: true });
        this.setState({ Password: e.target.value });
    }
    
    RedirectHome = () => {
        var windowlocation = window.location.href.toLowerCase();
        //if (windowlocation.includes("/castingdirector/login")) {

        //    this.props.history.push("/SkinUploadHome");
        //}
        //else
        if (windowlocation.includes("productionaccountant/login") || windowlocation.includes("pa.")) {

            this.props.history.push("/TimeCardFileExport");
        }
        else {
            this.props.history.push("/SkinUploadHome");
        }
    }
    handleSubmit = event => {
        event.preventDefault();
        let currentComponent = this;
        const author = currentComponent.state.UserName.trim();
        const text = currentComponent.state.Password.trim();

        var windowlocation = window.location.href.toLowerCase();
        var Role = "";
        this.setState({ loading: true });
        if (windowlocation.includes("productionaccountant/login") || windowlocation.includes("pa.")) {
            Role = 'Production Accountant';
        }
        else {
            Role = 'Casting Director' 
        }
        this.setState({ Role: Role });
        if (currentComponent.state.showMFA == false) {
            if (text.length != 0 && author.length != 0) {
                if (currentComponent.state.WrongAttemptCount > 2) {
                    if (currentComponent.state.formData.LoginCaptcha == false) {
                        currentComponent.toasterfun2("Invalid Captcha");
                        currentComponent.setState({ loading: false });
                        return;
                    }
                }

                let valid = true;
                if (text.length == 0) {

                    this.setState({ showpassword: false });
                    valid = false;
                }
                if (author.length == 0) {
                    this.setState({ showemail: false });
                    valid = false;
                }

                if (valid) {
                    if (text.length != 0 && author.length != 0) {
                        let data = '';
                        data = { "UserName": author, "Password": text, "Role": Role }

                        APIPostWOToken('api/Login/LoginWithUserNamePassword', data, function (result) {
                            if (result.statusMessage == "Success") {
                                var data = result.result;

                                localStorage.setItem('UserId', Utils.Encryption('Bearer ' + data.jwtToken));
                                localStorage.setItem('FirebaseKey', Utils.Encryption(data.userId));

                                currentComponent.setState({ loading: false, UserName: author });

                                if (result.result.isMFA == true) {
                                    currentComponent.setState({ loading: false, showMFA: true, Email: data.email, Phone: data.phoneNumber });
                                    currentComponent.toasterfun2("OTP Sent Sucessfully");
                                }
                                else {
                                    var windowlocation = window.location.href.toLowerCase();
                                    if (windowlocation.includes("/castingdirector/login")) {
                                        localStorage.setItem('CDuserfullName', data.fullName);


                                        if (data.role == "EMS Admin") {
                                            localStorage.setItem('PAuserfullName', data.fullName);
                                        }
                                    }
                                    else if (windowlocation.includes("productionaccountant/login")) {
                                        localStorage.setItem('PAuserfullName', data.fullName);

                                    }
                                    //currentComponent.setLocalStorage(data);
                                    if (result.result.isPasswordExp == true) {
                                        currentComponent.setState({
                                            loading: false, showChangePassword: true,
                                            PasswordPolicy: result.result.passwordPolicy.pattern,
                                            PasswordDescription: result.result.passwordPolicy.passwordDescription
                                        });  
                                    }
                                    else {
                                        
                                        currentComponent.RedirectHome();
                                        currentComponent.toasterfun2("Sucessfully Login");
                                    }
                                }
                            }
                            else {
                                currentComponent.setState({ loading: false, locked: result.isLocked, WrongAttemptCount: result.attemptCount });
                                var message = result.statusMessage;
                                if (!result.isLocked) {
                                    currentComponent.toasterfun2(message);
                                }
                                if (result.code == 121)
                                {
                                    currentComponent.toasterfun2(message);
                                }
                            }
                        });
                    }
                    else {
                        if (text.length == 0 && author.length == 0) {
                            this.setState({ showemail: false });
                            this.setState({ showpassword: false });
                        }
                        if (!/(.+)@(.+){2,}\.(.+){2,}/.test(author)) {
                            this.setState({ showemail: false });
                            return false;
                        }
                        if (text.length != 0) {
                            this.setState({ showemail: false });
                        }
                        if (author.length != 0) {
                            this.setState({ showpassword: false });
                        }
                    }
                }
            }
            else {
                //debugger;
                if (text.length == 0 && author.length == 0) {

                    this.setState({ showemail: false, loading: false, showpassword: false });
                }
                else if (text.length == 0) {
                    this.setState({ showpassword: false, loading: false });
                }
                else if (author.length == 0) {
                    this.setState({ showemail: false, loading: false });

                }
            }
        }
        else {
            if (!this.state.formData.otp || this.state.formData.otp == '') {
                currentComponent.toasterfun2("Please enter the OTP");
                currentComponent.setState({ loading: false });
                return;
            }
            if (this.state.formData.otp.length < 4) {
                this.state.formData.otp = this.state.formData.otp.slice(0, 4);
                this.toasterfun2("Please enter minimum 4 digits OTP");
                currentComponent.setState({ loading: false });
                return;
            }

            var data = {
                userName: author, //userID,
                otp: this.state.formData.otp,
                Role: Role
            }

            APIPost('SkinUpload/ValidateMFA', data, function (result) {
                if (result.code == 100) {
                    var data = result.result;
                    var windowlocation = window.location.href.toLowerCase();
                    //debugger
                    
                    if (windowlocation.includes("/castingdirector/login")) {
                        localStorage.setItem('CDuserfullName', data);
                        localStorage.setItem('FirebaseKey', Utils.Encryption(result.commonResult));
                        currentComponent.toasterfun2("Sucessfully Login");
                        currentComponent.props.history.push("/SkinUploadHome");

                        if (data[0].role == "EMS Admin") {
                            localStorage.setItem('PAuserfullName', data);
                            localStorage.setItem('FirebaseKey', Utils.Encryption(result.commonResult));
                        }
                    }
                    else if (windowlocation.includes("productionaccountant/login")) {
                        localStorage.setItem('FirebaseKey', Utils.Encryption(result.commonResult));
                        localStorage.setItem('PAuserfullName', data);
                        currentComponent.toasterfun2("Sucessfully Login");
                        currentComponent.props.history.push("/TimeCardFileExport");
                        }
                    
                } else {
                    currentComponent.toasterfun2(result.message);
                    currentComponent.setState({ loading: false });
                }
            });
        }
    }

    resendOTP = () => {
        let currentComponent = this;
        currentComponent.setState({ loading: true });
        const username = currentComponent.state.UserName.trim();
        let data = {
            UserName: username
        }

        APIPostWOToken('api/Login/ResendOTP', data, function (result) {
            if (result.code == 100) {
                currentComponent.toasterfun2("OTP sent Successfully");
            }
            else {
                currentComponent.toasterfun2("Falied to send OTP");
            }
            currentComponent.setState({ loading: false });
        });
    }

     checkOTPlenth = (e) => {
        
        if (this.state.formData.otp.length > 4) {
            this.state.formData.otp = this.state.formData.otp.slice(0, 4);
            this.toasterfun2("OTP field is not allowing more than 4 digits");
            return;
         }
         
    }

    //handleSubmit = event => {
    //    event.preventDefault();
    //    let currentComponent = this;
    //    const author = currentComponent.state.UserName.trim();
    //    const text = currentComponent.state.Password.trim();

    //    var windowlocation = window.location.href.toLowerCase();
    //    var Role = "";
    //    this.setState({ loading: true });
    //    if (windowlocation.includes("productionaccountant/login")) {

    //        Role = 'Production Accountant';
    //    }
    //    else if (windowlocation.includes("castingdirector/login")) {

    //        Role = 'Casting Director'
    //    }
    //    if (currentComponent.state.showMFA == false) {
    //        if (text.length != 0 && author.length != 0) {

    //            if (currentComponent.state.WrongAttemptCount > 2) {
    //                if (currentComponent.state.formData.LoginCaptcha == false) {
    //                    currentComponent.toasterfun2("Invalid Captcha");
    //                    currentComponent.setState({ loading: false });
    //                    return;
    //                }
    //            }



    //            let valid = true;
    //            if (text.length == 0) {

    //                this.setState({ showpassword: false });
    //                valid = false;
    //            }
    //            if (author.length == 0) {
    //                this.setState({ showemail: false });
    //                valid = false;
    //            }

    //            if (valid) {
    //                if (text.length != 0 && author.length != 0) {
    //                    let data = '';
    //                    data = { "UserName": author, "Password": text, "Role": Role }

    //                    APIPost('api/Login/LoginWithUserNamePassword', data, function (result) {
    //                        if (result.statusMessage == "Success") {
    //                            currentComponent.setState({ loading: false });
    //                            localStorage.setItem('UserId', result.result.jwtToken);
    //                            currentComponent.GetUserDetails(author, Role);
    //                            currentComponent.toasterfun2("Sucessfully Login");
    //                        }
    //                        else {
    //                            currentComponent.setState({ loading: false, locked: result.isLocked });
    //                            var message = result.statusMessage;
    //                            if (!result.isLocked) {
    //                                currentComponent.toasterfun2(message);
    //                            }
    //                        }
    //                    });
    //                }
    //                else {
    //                    if (text.length == 0 && author.length == 0) {
    //                        this.setState({ showemail: false });
    //                        this.setState({ showpassword: false });
    //                    }
    //                    if (!/(.+)@(.+){2,}\.(.+){2,}/.test(author)) {
    //                        this.setState({ showemail: false });
    //                        return false;
    //                    }
    //                    if (text.length != 0) {
    //                        this.setState({ showemail: false });
    //                    }
    //                    if (author.length != 0) {
    //                        this.setState({ showpassword: false });
    //                    }
    //                }
    //            }
    //        }
    //    }
    //    else {
    //        if (!this.state.formData.otp || this.state.formData.otp == '') {
    //            currentComponent.toasterfun2("Please enter the OTP");
    //            currentComponent.setState({ loading: false });
    //            return;
    //        }

    //        var data = {
    //            userName: author, //userID,
    //            otp: this.state.formData.otp,
    //            Role: Role
    //        }           

    //        APIPost('SkinUpload/ValidateMFA/', data, function (result) {
    //            if (result.code == 100) {
    //                var data = result.result;
    //                var windowlocation = window.location.href.toLowerCase();
    //                debugger
    //                if (windowlocation.includes("/castingdirector/login")) {
    //                    localStorage.setItem('CDuserfullName', data);
    //                    currentComponent.toasterfun2("Sucessfully Login");
    //                    currentComponent.props.history.push("/SkinUploadHome");

    //                    if (data[0].role == "EMS Admin") {
    //                        localStorage.setItem('PAuserfullName', data);
    //                    }
    //                }
    //                else if (windowlocation.includes("productionaccountant/login")) {
    //                    localStorage.setItem('PAuserfullName', data);
    //                    currentComponent.toasterfun2("Sucessfully Login");
    //                    currentComponent.props.history.push("/TimeCardFileExport");
    //                }
    //            } else {
    //                currentComponent.toasterfun2(result.message);
    //                currentComponent.setState({ loading: false });
    //            }
    //        });            
    //    }
    //}

    //GetUserDetails(UserEmail, Role) {
    //    console.log(UserEmail);
    //    let currentComponent = this;
    //    APIPost('SkinUpload/GetUserByEmail/' + UserEmail + '/' + Role, null, function (result) {
    //        if (result.code == 200) {
    //            var data = result.result;
    //            let userId = data[0].id;
    //            var windowlocation = window.location.href.toLowerCase();
    //            if (windowlocation.includes("/castingdirector/login")) {
    //                localStorage.setItem('CDuserfullName', data[0].fullName);
    //                currentComponent.props.history.push("/SkinUploadHome");

    //                if (data[0].role == "EMS Admin") {
    //                    localStorage.setItem('PAuserfullName', data[0].fullName);
    //                    localStorage.setItem('PARole', data[0].role);
    //                }
    //            }
    //            else if (windowlocation.includes("productionaccountant/login")) {
    //                localStorage.setItem('PAuserfullName', data[0].fullName);
    //                currentComponent.props.history.push("/TimeCardFileExport");
    //            }
    //        } else {
    //            currentComponent.toasterfun2(result.message);
    //        }
    //    });
    //}

    copyToClipboard = (element) => {
        var $temp = $("<input>");
        $("body").append($temp);
        $temp.val($(element).text()).select();
        document.execCommand("copy");
        $temp.remove();
    }
    closeDialog = () => {
        
            var windowlocation = window.location.href.toLowerCase();

            if (windowlocation.includes("/castingdirector/login")) {
                this.props.history.push("/SkinUploadHome");
            }
            else if (windowlocation.includes("/productionaccountant/login")) {
                this.props.history.push("/TimeCardFileExport");
            }
            //toasterfun2("Password has been changed Sucessfully");
        
    }

    cancelForgotPassword = () => {
        this.setState({ forgotPWD: false })
        $('.manops').addClass('hide');
        $('.manps').addClass('hide');
        $('.mancps').addClass('hide');
        $('#mps').addClass('hide');
        $('.psnotval').addClass('hide');
        $('#Wkpwd').addClass('hide');
    }

    render() {

        var Logo = "";
        var landing = '/css/assets/img/ems-logo.gif';
        var windowlocation = window.location.href.toLowerCase();
        if (windowlocation.includes("productionaccountant/login") || windowlocation.includes("pa.")) {

            Logo = '/css/assets/img/logo-prod.png';
        }
        else {

            Logo = '/css/assets/img/logo-casting.png';
        }
        return (

            <div>
                <span className={"loading " + (this.state.loading ? "show" : "hide")} ><span className="loading-div-d"></span> <img src={process.env.PUBLIC_URL + landing} /> </span>
                <div className={"toaster-div " + (this.state.toasterCla ? "show" : "hide")}><i className="fa fa-check-circle" aria-hidden="true"></i> {this.state.toaster} </div>
                <div class="main-wrapper account-wrapper">
                    <div class="account-page">
                        <div class="account-center">
                            <div class="account-box">
                                <form >
                                    <div class="account-logo">
                                        <a href="#"><img class="img-fluid" src={process.env.PUBLIC_URL + Logo} alt="" /></a>
                                    </div> { this.state.forgotPWD == true ? <>
                                        {
                                            <ForgotPassword closeDialog={this.cancelForgotPassword} role={windowlocation.includes("productionaccountant/login") ? 'Production Accountant' : 'Casting Director' } />
                                        }
                                        </>
                                        :
                                        <>
                                    {this.state.showChangePassword == false ? <> {
                                        this.state.showMFA == false ?
                                            <>
                                                {
                                                    this.state.locked == true ?
                                                        <div className="text-center">
                                                            <span> Your account is locked. Please contact <br /><a href="mailto:support@emspayroll.com" id="supportMail">support@emspayroll.com</a>
                                                                <i className="fa fa-copy ml-2" type="button" onClick={() => this.copyToClipboard('#supportMail')} />
                                                            </span>
                                                        </div>
                                                        :
                                                        <>
                                                            <div class="form-group">
                                                                <label>Username</label>
                                                                <input type="text" autofocus="" class="form-control" onChange={this.handleChangeusername} autoComplete="Off" name="Username" />
                                                                        <div className={"rederror " + (this.state.showemail ? "hide" : "show")}>Please provide valid Username </div> {/*Email Address*/}
                                                            </div>
                                                            <div class="form-group">
                                                                <label>Password</label>
                                                                <input type="password" class="form-control" onChange={this.handleChangepassword} autoComplete="Off" name="Password" />
                                                                <div className={"rederror " + (this.state.showpassword ? "hide" : "show")}>Please provide valid Password</div>
                                                            </div>
                                                            {
                                                                this.state.WrongAttemptCount > 2 ?
                                                                    <Captcha id="LoginCaptcha" formdata={this.state.formData} />
                                                                    :
                                                                    <></>
                                                            }
                                                                    <div class="form-group text-right" type="button" >
                                                                       <a onClick={() => this.setState({ forgotPWD: true })}>Forgot your password?</a> 
                                                                        {/* <a href="admin/forgotpassword">Forgot your password?</a>*/}
                                                            </div>
                                                            <div class="form-group text-center">
                                                                <button type="submit" class="btn btn-primary account-btn" onClick={this.handleSubmit}>Login</button>
                                                            </div>
                                                        </>
                                                }
                                            </>
                                            :
                                                    <>
                                                        <TextBox label="OTP" id="otp" formdata={this.state.formData} error={this.state.errormsg} TextFor={Enum.Numeric} onChange={() => this.checkOTPlenth(this.state.formData.otp)} required />
                                                <div className="form-group text-left">
                                                    <span><b>Phone : </b>{this.state.Phone}</span> <br />
                                                    <span><b>Email : </b>{this.state.Email}</span> <br />
                                                </div>
                                                <div className="form-group text-center">
                                                    <span style={{ cursor: "pointer" }} onClick={() => this.resendOTP()}>Resend OTP</span>
                                                </div>
                                                <div className="form-group text-center">
                                                    <button type="submit" class="btn btn-primary account-btn" onClick={this.handleSubmit}>Login</button>
                                                </div>
                                                <div className="form-group text-center">
                                                    <span style={{ cursor: "pointer" }} onClick={() => this.setState({ showMFA: false })}>Back</span>
                                                </div>
                                            </>
                                    } </> : <>
                                            <ChangePassword closeDialog={this.closeDialog} soucepage="Login" PasswordPolicy={this.state.PasswordPolicy} PasswordDescription={this.state.PasswordDescription} username={this.state.UserName} role={this.state.Role} />
                                        </>
                                            }
                                        </>
                                        }
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        );
    }
}

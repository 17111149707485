import React from 'react';
import ErrorPage from '../ErrorPage'
import { Constants, Utils } from '../EMS_Components';


const getRedirectPath = () => {
    let redirectPath;
    let windowlocation = window.location.href.toLowerCase();
    if (windowlocation.includes("timecardfileexport") || windowlocation.includes("productionaccountant")) {
        redirectPath = "/ProductionAccountant/login";
        sessionStorage.removeItem('PAuserfullName');
    }
    else //if (windowlocation.includes("skinupload")) 
    {
        redirectPath = "/CastingDirector/Login";
        sessionStorage.removeItem('CDuserfullName');
    }
    return redirectPath;
}

const redirectLogin = () => {
    let redirectPath = getRedirectPath();
    window.location = redirectPath;
}

const APIGet = (url, callback) => {
    fetch(url, {
        method: 'get',
        //mode: 'cors',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': Utils.Decryption(localStorage.getItem('UserId')),
            'X-Frame-Options': 'sameorigin'
        },
    }).then(function (response) {
        return response.json();
    }).then(function (result) {
        if (result == 400) {
            redirectLogin();
        }
        else {
            callback(result)
        }
    }).catch(function (error) {
        console.log("Error : " + error);
        //window.location = getRedirectPath;
        //return (<ErrorPage redirectPath={getRedirectPath} />)
    });
}

const APIPost = (url, data, callback) => {

    var encyptedData = Utils.Encryption(data);

    let reqData = {
        Data: encyptedData
    }
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': Utils.Decryption(localStorage.getItem('UserId')),
        'X-Frame-Options': 'sameorigin'
    }

    //let params = '';
    //let fetchURL = '';
    //if (urlParams != null) {
    //    urlParams.forEach(function (urlParam) {
    //        params = params + "/" + Utils.Encryption(urlParam)
    //        //params = params + "/" + urlParam;
    //        //params = params + "/" + 'L5o8yIbroaS/0nFY7Xsf6Nyu7+wlNFQf2rcTIKi94BA=';
    //    });
    //}

    //fetchURL = params == '' ? (url + '/') : (url + params);

    if (data != null) {
        fetch(url, {
            //mode: 'cors',
            method: 'post',
            headers: headers,
            body: JSON.stringify(reqData)
        }).then(function (response) {
            return response.json();
        }).then(function (result) {
            if (result == 400) {
                redirectLogin();
            }
            else {
                callback(result)
            }
        }).catch(function (error) {
            console.log("Error : " + error);
            //window.location = getRedirectPath;
            //return (<ErrorPage redirectPath={getRedirectPath} />)
        });
    }
    else {
        fetch(url, {
            //mode: 'no-cors',
            method: 'post',
            headers: headers,
        }).then(function (response) {
            return response.json();
        }).then(function (result) {
            if (result == 400) {
                redirectLogin();
            }
            else {
                callback(result)
            }
        }).catch(function (error) {
            console.log("Error : " + error);   
            //window.location = getRedirectPath;
            //return (<ErrorPage redirectPath={getRedirectPath} />)
        });
    }

}

const APIGetWOToken = (url, callback) => {
    fetch(url, {
        method: 'get',
        //mode: 'no-cors',
    }).then(function (response) {
        return response.json();
    }).then(function (result) {
        if (result == 400) {
            redirectLogin();
        }
        else {
            callback(result)
        }
    }).catch(function (error) {
        console.log("Error : " + error);
        return (<ErrorPage redirectPath={getRedirectPath} />)
    });
}

const APIPostWOToken = (url, data, callback) => {
    var encyptedData = Utils.Encryption(data);

    let reqData = {
        Data: encyptedData
    }

    //let params = '';
    //let fetchURL = '';
    //if (urlParams != null) {
    //    urlParams.forEach(function (urlParam) {
    //        params = params + "/" + Utils.Encryption(urlParam)
    //        //params = params + "/" + urlParam;
    //        //params = params + "/" + 'L5o8yIbroaS/0nFY7Xsf6Nyu7+wlNFQf2rcTIKi94BA=';
    //    });
    //}

    //fetchURL = params == '' ? (url + '/') : (url + params);

    if (data != null) {
        fetch(url, {
            method: 'post',
            //mode: 'no-cors',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(reqData)
        }).then(function (response) {
            return response.json();
        }).then(function (result) {
            if (result == 400) {
                redirectLogin();
            }
            else {
                callback(result)
            }
        }).catch(function (error) {
            console.log("Error : " + error);
            //window.location = getRedirectPath;
            //return (<ErrorPage redirectPath={getRedirectPath} />)
        });
    }
    else {
        fetch(url, {
            method: 'post',
            //mode: 'no-cors',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        }).then(function (response) {
            return response.json();
        }).then(function (result) {
            if (result == 400) {
                redirectLogin();
            }
            else {
                callback(result)
            }
        }).catch(function (error) {
            console.log("Error : " + error);
            //window.location = getRedirectPath;
            //return (<ErrorPage redirectPath={getRedirectPath} />)
        });
    }

}

export {
    APIGet,
    APIPost,
    APIGetWOToken,
    APIPostWOToken
}
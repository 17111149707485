import React from 'react';
import { Layout } from '../Layout';
import moment from 'moment';
import { DatePickerInput } from 'rc-datepicker';
import MaterialTable from "material-table";
import $ from 'jquery';
import ReactTooltip from 'react-tooltip';
import { onlyDigits } from '../Validation'
//import { Tabs, Tab } from 'react-bootstrap-tabs';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';// 'react-tabs/style/react-tabs.css';
import { APIGet, APIPost } from '../EMS_Components/APICall'


export class UnlockUser extends React.Component {

    constructor(props) {
        super(props);
       
        this.state = {
            loading: true,
            rows: [],
            ShootDate: moment.utc().format('MM/DD/YYYY'),
            toasterCla: false,
            toaster: '',
            defaultValue:100
        }
        
        this.LoadLockedUser();    
    }

    toasterfun2 = (e) => {
        this.setState({ toasterCla: true });
        this.setState({ toaster: e });
        setTimeout(
            function () {
                this.setState({ toasterCla: false });
            }.bind(this), 4000);
    }

    LoadLockedUser = () => {
        let currentComponent = this;
        var strURL = "GetLockedUsers";
        APIGet('LogDetails/' + strURL, function (result) {
            if (result.statuscode == 100) {
                currentComponent.setState({ rows: result.result, loading: false });
            } else {
                console.log(result.message);
                currentComponent.setState({ rows: result.result, loading: false });
            }
        });
    }

    UnlockLockedUser = (userName) => {       
        let currentComponent = this;
        var strURL = "UpdateLockedUser";  

        let data = {
            UserName: userName
        }

        APIPost('LogDetails/' + strURL, data, function (result) {
            if (result.statusCode == 100) {
                currentComponent.toasterfun2(result.statusMessage);
                currentComponent.LoadLockedUser();
            } else {
                currentComponent.setState({ rows: result.statusMessage, loading: false });
            }
        });
    }
       
    render() {
        return (
            <Layout>               
                <div className={"toaster-div " + (this.state.toasterCla ? "show" : "hide")}><i className="fa fa-check-circle" aria-hidden="true"></i> {this.state.toaster} </div>    
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                    <div className="kt-portlet kt-portlet--mobile mb-0">
                        <div className="kt-portlet__body">
                            <MaterialTable
                                title=""
                                columns={[
                                    { title: "Username", field: "userName" },
                                    { title: "Email", field: "email" },
                                    { title: "# of Attempt", field: "wrngOTPAttemptcountForApp" },
                                    { title: "Login Time", field: "login", render: rowData => <span className="pull-left">{moment(rowData.login).isValid() ? (moment(rowData.login).year() != 1 ? moment(rowData.login).format('MM/DD/YYYY hh:mm A') : '') : ''} </span> },
                                    {
                                        title: "Action", width: "5%", render: rowData => <button type="button" onClick={() => this.UnlockLockedUser(rowData.userName)} class="btn btn-sm btn-primary"> Unlock </button>
                                    },
                                ]}
                                data={this.state.rows}
                                class="mat-paginator-sticky"
                                title=""
                                options={{
                                    pageSizeOptions: [5, 10, 20, 50],
                                    pageSize: 10
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}


import React, { useState } from 'react';
import ReactDOM, { render } from 'react-dom'
import { ExportXL, Dropdown } from '../../EMS_Components'
import MaterialTable from 'material-table'
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers';
import { APIPost } from '../../EMS_Components/APICall'
const moment = require('moment')



const CategoryReport = (props) => {

    const [ExportData, setExportData] = useState([])
    const [ExcelData, setExcelData] = useState({})
    const [FileName, setFileName] = useState('')
    const [Label, setLabel] = useState('Export Excel')

    const [formData, setformData] = useState({});
    const [errormsg, seterrormsg] = useState({});
    const [ddlData, setddlData] = useState([
        { Value: 1, Text: "Talent Status Report" },
        { Value: 2, Text: "Talent Count With Shoot Details Report" }
    ])

    const [currentDate, setcurrentDate] = useState(new Date())


    const onReportChange = (date) => {
        
        let report = formData.report;
        if (report == 1) {
            document.getElementById("TalentStatus").classList.add("show");
            document.getElementById("StatusReport").classList.add("show");
            document.getElementById("CountReport").classList.add("hide");
            document.getElementById("CountReport").classList.remove("show");
        }
        else if (report == 2 || (date != null))
        {
            
            if (date != 2) {
                setcurrentDate(date)
            }
            else {
                date = currentDate;
            }
            document.getElementById("TalentStatus").classList.add("show");
            document.getElementById("StatusReport").classList.remove("show");
            document.getElementById("StatusReport").classList.add("hide");
            document.getElementById("CountReport").classList.add("show");
            let data = {
                FromDate: date
            }
            APIPost('Report/GetReport_TalentCount', data, function (result) {
                if (result.code === 100) {
                    setExportData(result.result)
                    toasterfun2(result.message);
                } else {
                    toasterfun2(result.message);
                }
            });
        }
        else
        {
           
            document.getElementById("TalentStatus").classList.add("hide");
            document.getElementById("TalentStatus").classList.remove("show");
        }
        //let dt = { SheetName: "Detailed_Report", Data: result.data }

        //let expData = [];
        //expData.push(dt);
        //setExportData(expData);
    }

    const [toaster, settoaster] = useState('')
    const [toasterCla, settoasterCla] = useState(false)

    const [fromDate, setfromDate] = useState(new Date())
    const [toDate, settoDate] = useState(new Date())
    const [reportResult, setreportResult] = useState([])

    const toasterfun2 = (e) => {
        settoasterCla(true);
        settoaster(e);
        setTimeout(
            function () {
                settoasterCla(false);
            }.bind(this), 4000);
    }
    const handleFromDateChange = (date) => {
        setfromDate(date);
    };

    const handleToDateChange = (date) => {
        settoDate(date);
    };

    const getReport = () => {
        let data = {
            FromDate: fromDate,
            ToDate: toDate
        }
        APIPost('Report/GetReport_TalentStatus', data, function (result) {
            if (result.code === 100) {
                setreportResult(result.result)
                toasterfun2(result.message);
            } else {
                toasterfun2(result.message);
            }
        });
    }

    return (
        <>
            <div className="kt-form kt-form--label-right">
                <div className="row">
                    <div className="col-md-6 col-sm-6 col-6">
                        <Dropdown label="Report" id="report" source={ddlData} formdata={formData} error={errormsg} required onChange={onReportChange } />
                    </div>
                  
                </div>
            </div>
            <div className="hide" id="TalentStatus">
            <div className={"toaster-div " + (toasterCla ? "show" : "hide")} ><i className="fa fa-check-circle" aria-hidden="true"></i> {toaster} </div>
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid mt-5" id="StatusReport">
                <div className="kt-portlet kt-portlet--mobile mb-0">
                    <div className="kt-portlet__body">
                        <div className="kt-form kt-form--label-right">
                            <div className="row">
                                <div className="col-md-3">
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            margin="normal"
                                            id="fromDate"
                                            label="From Date"
                                            format="MM/dd/yyyy"
                                            variant="inline"
                                            value={fromDate}
                                            onChange={handleFromDateChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                            autoOk={true}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div className="col-md-3">
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            margin="normal"
                                            id="toDate"
                                            label="To Date"
                                            format="MM/dd/yyyy"
                                            variant="inline"
                                            value={toDate}
                                            onChange={handleToDateChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                            autoOk={true}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div className="col-md-3">
                                    <button type="button" className="btn btn-sm btn-primary mt-3" onClick={getReport}>Submit</button>
                                    <ExportXL className="pull-right" style={{ marginTop: "5.5%" }}
                                        data={[
                                            { SheetName: "Talent_Status", Data: reportResult }
                                        ]}
                                        fileName={'Talent_Status'}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <MaterialTable
                                        title="Talent Status"
                                        columns={[
                                            { title: "Project Name", field: "projectName" },
                                            { title: "First Name", field: "firstName" },
                                            { title: "Middle Name", field: "middleName" },
                                            { title: "Last Name", field: "lastName" },
                                            { title: "Email", field: "email" },
                                            { title: "Phone", field: "phone" },
                                            { title: "Skin Uploaded Date", field: "Skinuploadeddate" },
                                            { title: "Registration Status", field: "registrationStatus" },
                                            { title: "I9 Status", field: "i9Status" },
                                            { title: "I9 Completed on", field: "I9completedon" },
                                            { title: "W4Status", field: "w4Status" },
                                            { title: "W4 Completed on", field: "W4completedon" }
                                        ]}
                                        data={reportResult}
                                        class="mat-paginator-sticky"
                                        title=""
                                        options={{
                                            pageSizeOptions: [5, 10, 20, 50],
                                            pageSize: 10,
                                            exportButton: { csv: true },
                                            exportAllData: true,
                                            exportFileName: 'Talent_Status_' + moment(fromDate).format('MMDDYYYY') + '_' + moment(toDate).format('MMDDYYYY')
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>

                <div className={"toaster-div " + (toasterCla ? "show" : "hide")} ><i className="fa fa-check-circle" aria-hidden="true"></i> {toaster} </div>
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid mt-5" id="CountReport">
                    <div className="kt-portlet kt-portlet--mobile mb-0">
                        <div className="kt-portlet__body">
                            <div className="kt-form kt-form--label-right">
                                <div className="row">
                                    <div className="col-md-3">
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                margin="normal"
                                                id="currentDate"
                                                label="Current Date"
                                                format="MM/dd/yyyy"
                                                variant="inline"
                                                value={currentDate}
                                                autoOk={true}
                                                onChange={onReportChange}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    <div className="col-md-3">
                                        <ExportXL className="pull-right" style={{ marginTop: "5.5%" }}
                                            data={[
                                                { SheetName: "Talent_Count", Data: ExportData }
                                            ]}
                                            fileName={'Talent_Count'}
                                        />
                                    </div>
                                </div>
                                
                               
                                <div className="row">
                                    <div className="col-md-12">
                                        <MaterialTable
                                            title="Talent Count"
                                            columns={[
                                                { title: "Shoot Name", field: "shootName" },
                                                { title: "Shoot Location", field: "projectLocation" },
                                                { title: "Talent Count", field: "talentCount" },
                                                { title: "Checked In", field: "checkedInCount" },
                                                { title: "Not Checked In", field: "notCheckedInCount" },
                                                { title: "First Meal In", field: "firstMealInCount" },
                                                { title: "First Meal Out", field: "firstMealOutCount" },
                                                { title: "Second Meal In", field: "secondMealInCount" },
                                                { title: "Second Meal Out", field: "secondMealOutCount" },
                                                { title: "Shooting Wrap Status", field: "shootingWrapStatus" },
                                                
                                            ]}
                                            data={ExportData}
                                            class="mat-paginator-sticky"
                                            title=""
                                            options={{
                                                pageSizeOptions: [5, 10, 20, 50],
                                                pageSize: 10,
                                                exportButton: { csv: true },
                                                exportAllData: true,
                                                exportFileName: 'Talent_CountReport_' + moment(fromDate).format('MMDDYYYY') 
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
        </>
    );

}
export default CategoryReport

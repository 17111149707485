import React from 'react';
import { Layout } from '../Layout';
import moment from 'moment';
import { DatePickerInput } from 'rc-datepicker';
import MaterialTable from "material-table";
import { APIGet, APIPost } from '../EMS_Components/APICall'

export class LoginDetailsReport extends React.Component {
    static displayName = LoginDetailsReport.name;

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            rows: [],
            logdate: moment.utc().format('MM/DD/YYYY'),
            toasterCla: false,
            toaster: '',
            defaultValue:100
        }
        this.LoadAuditData(moment.utc().format('MM/DD/YYYY'));
        this.OnDateChange = this.OnDateChange.bind(this);

    }
    //increaseScore() {
    //    // 1. Get previous state from this.state
    //    this.setState({ baseRate: this.state.baseRate + 1 })

    //    // 2. Get previous state from the callback function
    //    this.setState((prevState) => {
    //        return { baseRate: prevState.baseRate + 1 }
    //    })
    //}
    toasterfun2(e) {
        this.setState({ toasterCla: true });
        this.setState({ toaster: e });
        setTimeout(
            function () {
                this.setState({ toasterCla: false });
            }.bind(this), 3000);
    }
    //Funcion related to Datepicker -- start
    log = (...x) => console.log(...x)       // eslint-disable-line no-console
    onClear = () => this.setState({ logdate: null })
    formatdate(Datetobeformatted) {
        if (Datetobeformatted != null) {// format date in MM/DD/YYYY format
            return Intl.DateTimeFormat('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
            }).format(new Date(Datetobeformatted));
        }
    }
    OnDateChange(jsDate, date) {
        console.log(date);
        var logdate = moment(date).format();
        this.setState({ logdate: moment.utc(date).format('MM/DD/YYYY') })
        this.LoadAuditData(logdate);
    }
    
    //Funcion related to Datepicker -- end
    LoadAuditData(logdate) {
        let currentComponent = this;

        //console.log(ShootDate.format());
        //ShootDate = ShootDate.format();
        var date = moment.utc(logdate).format()

        console.log(date);
        var logdate = moment(currentComponent.state.logdate).format();
        

        var strURL = "GetUserLogDetails";
        APIGet('LogDetails/' + strURL + "/" + date, function (result) {
            if (result.statuscode == 100) {
                currentComponent.setState({ rows: result.result, loading: false });
            } else {
                console.log(result.message);
                currentComponent.setState({ rows: result.result, loading: false });
                currentComponent.toasterfun2(result.message);
            }
        });
    }


    render() {
        return (
            <Layout>
                <div className={"toaster-div " + (this.state.toasterCla ? "show" : "hide")}><i className="fa fa-check-circle" aria-hidden="true"></i> {this.state.toaster} </div>

                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                    <div className="kt-portlet kt-portlet--mobile mb-0">
                        <div className="kt-portlet__body">
                            <div action="" className="kt-form kt-form--label-right">

                                <div className="row">

                                    <div className="col-lg-2 col-md-3">

                                        <div className="form-group mm-date">
                                            <label>Log Date</label>

                                            <DatePickerInput
                                                displayFormat='MM/DD/YYYY'
                                                returnFormat='MM/DD/YYYY'
                                                className='my-react-component'
                                                onChange={this.OnDateChange}
                                                onShow={this.log.bind(this, 'show')}
                                                onHide={this.log.bind(this, 'hide')}
                                                showOnInputClick
                                                placeholder='placeholder'
                                                locale='de'
                                                onClear={this.onClear}
                                                value={this.state.logdate}


                                            />
                                        </div>
                                    </div>

                                </div>

                                <div className="my-3  kt-separator kt-separator--border-dashed kt-separator--space-lg kt-separator--portlet-fit">
                                </div>
                                <div className="master-table-x">
                                    <MaterialTable
                                        title=""
                                        columns={[
                                            { title: "Username", field: "userName" },
                                            { title: "Login", field: "login", render: rowData => <span className="pull-left">{moment(rowData.login).isValid() ? moment(rowData.login).format('MM/DD/YYYY hh:mm A') : ''} </span> },
                                            { title: "Logout", field: "logout", render: rowData => <span className="pull-left">{moment(rowData.logout).isValid() ? moment(rowData.logout).format('MM/DD/YYYY hh:mm A') : ''} </span> },
                                            { title: "Device Name ", field: "deviceId" },
                                            {
                                                title: "Device Type", field: "deviceType", render: rowData => <div>
                                                    {(() => {
                                                        if (rowData.deviceType==0) {
                                                            return (
                                                                <div>Web</div>
                                                            )
                                                        } else if (rowData.deviceType == 1) {
                                                            return (
                                                                <div>Android</div>
                                                            )
                                                        } else {
                                                            return (
                                                                <div>IOS </div>
                                                            )
                                                        }
                                                    })()}
                                                </div> },
                                           ]}
                                        data={this.state.rows}
                                        class="mat-paginator-sticky"
                                        title=""
                                        options={{
                                            pageSizeOptions: [50, 100, 500],
                                            pageSize: 50,
                                            filtering: true,
                                            exportButton: true
                                        }}
                                        

                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                </div >

            </Layout>
)}}
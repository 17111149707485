import React from 'react';
import { onlyDigits } from '../Validation'
import $ from 'jquery';
import ListGroup from 'react-bootstrap/ListGroup';
import { APIGet, APIPost } from '../EMS_Components/APICall'

export class BumpAgainstBG extends React.Component {
    constructor(props) {
        super(props);
        this.LoadBump();
        //this.LoadShootingBumpRate(this.props.dataFromParent);
        
        this.state = {
            toasterCla: false,
            toaster: '',
            rows: [{}],
            SelectedBGs: this.props.SelectedBGs,
            removeAlert: false,
            removehidden: '',
            BumpList: [],
            //BumpList: [
            //    { id: "carAllowance", name: "Car Allowance" },
            //    { id: "auto", name: "Auto" },
            //    { id: "specialAbility", name: "Special compensation" },
            //    { id: "smokeWork", name: "Smoke Work" },
            //    { id: "bodyMakeup", name: "Makeup" },
            //    { id: "hairCompensation", name: "Hair Cut" },
            //    { id: "wetWork", name: "Wet Work" },
            //    { id: "beardCompensation", name: "Beard" },
            //    { id: "dressUniformCompensation", name: "Uniforms" },
            //    //{ id: "other", name: "Other" },
            //    //{ id: "otherDescription", name: "Other Description" },
            //    //{ id: "mileageMiles", name: "Mileage" },
            //    //{ id: "wardrobeQuantity", name: "Wardrobe" },
            //    //{ id: "interviewHours", name: "Interview" },
            //    //{ id: "fittingHours", name: "Fitting" },

            //],
            BumpListforUpdation: [
                { id: "carAllowance", name: "Per Diem" },
                { id: "auto", name: "Auto" },
                { id: "specialAbility", name: "Special compensation" },
                { id: "smokeWork", name: "Smoke Work" },
                { id: "bodyMakeup", name: "Makeup" },
                { id: "hairCompensation", name: "Hair Cut" },
                { id: "wetWork", name: "Wet Work" },
                { id: "beardCompensation", name: "Beard" },
                { id: "dressUniformCompensation", name: "Uniforms" },
                { id: "other", name: "Other Amt" },
                { id: "otherDescription", name: "Other Description" },
                { id: "other1", name: "Other1  Amt" },
                { id: "otherDescription1", name: "Other Description1" },
                { id: "other2", name: "Other2  Amt" },
                { id: "otherDescription2", name: "Other Description2" },
                //{ id: "mileageMiles", name: "Mileage" },
                //{ id: "wardrobeQuantity", name: "Wardrobe" },
                //{ id: "interviewHours", name: "Interview" },
                //{ id: "fittingHours", name: "Fitting" },

            ]
        }
        $('.nodata').addClass('show-div');
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDocRemove = this.handleDocRemove.bind(this);
        this.handleremoveCancelbttn = this.handleremoveCancelbttn.bind(this);
        this.handleRemoveSpecificRow = this.handleRemoveSpecificRow.bind(this);
        this.onRemove = this.onRemoveSD.bind(this);
    }
    onRemoveSD(selectedList, removedItem) {
        

        this.setState({
            SelectedBGs: selectedList
        });

    }
    LoadBump() {


        let currentComponent = this;
        APIGet('AdminSkinUpload/GetAllBumpsOthers/', function (result) {
            if (result.code === 200) {
                
                currentComponent.setState({
                    BumpList: result.result.filter(Bumps => Bumps.name != "Fittings"
                        && Bumps.name != "Interviews"
                        && Bumps.name != "Mileage"
                        && Bumps.name != "1st Wardrobe Change"
                        && Bumps.name != "2nd Wardrobe Change onwards")
                });
            } else {
                currentComponent.toasterfun2(result.message);
            }
        });


    }
    //Toaster alert start
    toasterfun2(e) {
        this.setState({ toasterCla: true });
        this.setState({ toaster: e });
        setTimeout(
            function () {
                this.setState({ toasterCla: false });
            }.bind(this), 4000);
    }
    //Toaster alert event
    handleChange = idx => e => {

        const { name, value } = e.target;
        let currentComponent = this;
            
        if (name == "bumpId") {
            
            var index = e.target.selectedIndex;
            var optionElement = e.target.childNodes[index];
            var option = optionElement.getAttribute('data-id');
            var SelValue = optionElement.getAttribute('value');
            let OtherDesc = false;
            const rows = [...currentComponent.state.rows];

            if (rows.filter(row => row.bumpId === parseInt(SelValue)).length == 0) {
                if (SelValue >= 100 && option.includes("Other Description")) {
                    OtherDesc = true;


                    rows[idx] = {
                        ["bumpName"]: option,
                        [name]: SelValue,
                        ["addl_Info"]: "",
                        ["NonOtherDescription"]: "",
                        ["OtherDescription"]: "",
                        ["IsOtherDesc"]: OtherDesc,
                    };
                    currentComponent.setState({
                        rows
                    });

                }




                if (SelValue != 0) {
                    let data = {
                        BumpID: SelValue
                    }
                    APIPost('SkinUpload/GetAddlInfo', data, function (result) {
                        const rows = [...currentComponent.state.rows];
                        if (result.code === 200) {
                            rows[idx] = {
                                ["bumpName"]: result.result[0].name,
                                [name]: parseInt(value),
                                ["addl_Info"]: result.result[0].addl_Info,
                                ["unionRate"]: parseFloat(result.result[0].unionRate),
                                ["nonUnionRate"]: parseFloat(result.result[0].nonUnionRate),
                                ["IsOtherDesc"]: OtherDesc,
                            };
                        }
                        else if (result.code === 202) {
                            rows[idx] = {
                                ["bumpName"]: option,
                                [name]: parseInt(value),
                                ["addl_Info"]: "",
                                ["unionRate"]: parseFloat(0),
                                ["nonUnionRate"]: parseFloat(0),
                                ["IsOtherDesc"]: OtherDesc,
                            };
                        }
                        currentComponent.setState({
                            rows
                        });
                    });
                }
            }
            else {

                e.target.selectedIndex = 0;
                
                
                currentComponent.toasterfun2(option + " ,This Compensation has been already assigned");
            }
        }
        //---------------------------
        else {
            const rows = [...currentComponent.state.rows];
            let OtherDesc = false;
            if (parseInt(rows[idx]["bumpId"]) >=100  && rows[idx]["bumpName"].includes("Other Description")) {
                OtherDesc = true;
            }
            if (name == "unionRate") {
                
                rows[idx] = {
                    ["bumpId"]: parseInt(rows[idx]["bumpId"]),
                    ["bumpName"]: rows[idx]["bumpName"],
                    ["addl_Info"]: rows[idx]["addl_Info"],
                    [name]: isNaN(value) || value == null  ? 0 : value,
                    ["nonUnionRate"]: isNaN(parseFloat(rows[idx]["nonUnionRate"])) || rows[idx]["nonUnionRate"] == null || rows[idx]["nonUnionRate"] == "" ? 0 : parseFloat(rows[idx]["nonUnionRate"]),
                    ["IsOtherDesc"]: OtherDesc,
                };
            }
            else if (name == "nonUnionRate") {
                rows[idx] = {
                    ["bumpName"]: rows[idx]["bumpName"],
                    ["bumpId"]: parseInt(rows[idx]["bumpId"]),
                    ["addl_Info"]: rows[idx]["addl_Info"],
                    ["unionRate"]: isNaN(parseFloat(rows[idx]["unionRate"])) || parseFloat(rows[idx]["unionRate"]) == null || rows[idx]["unionRate"] == "" ? 0 : parseFloat(rows[idx]["unionRate"]),
                    [name]: isNaN(value) || value == null ? 0 : value,
                    ["IsOtherDesc"]: OtherDesc,
                };

            }
            else if (name == "OtherDescription") {
                rows[idx] = {
                    ["bumpName"]: rows[idx]["bumpName"],
                    ["bumpId"]: parseInt(rows[idx]["bumpId"]),
                    ["addl_Info"]: rows[idx]["addl_Info"],
                    ["NonOtherDescription"]: rows[idx]["NonOtherDescription"],
                    [name]: value,
                    ["IsOtherDesc"]: OtherDesc,
                };

            }
            else if (name == "NonOtherDescription") {
                rows[idx] = {
                    ["bumpName"]: rows[idx]["bumpName"],
                    ["bumpId"]: parseInt(rows[idx]["bumpId"]),
                    ["addl_Info"]: rows[idx]["addl_Info"],                    
                    [name]: value,
                    ["OtherDescription"]: rows[idx]["OtherDescription"],
                    ["IsOtherDesc"]: OtherDesc,
                };

            }
            
            currentComponent.setState({
                rows
            });
        }


    };
    handleAddRow = () => {
        const item = {
            bumpId: 0,
            unionRate: 0.00,
            nonUnionRate: 0.00,
            addl_Info: "",
            bumpName: ""
        };
        $('.nodata').removeClass('show-div');

        var count = parseInt(this.state.rows.length) + 1;

        this.setState({
            rows: [...this.state.rows, item]
        });
        setTimeout(function () { $('#customers tbody tr:nth-child(' + count + ') td select').addClass("show"); }, 300);

    };
    handleRemoveRow = () => {
        this.setState({
            rows: this.state.rows.slice(0, -1)
        });
    };
    handleDocRemove(e) {
        this.setState({ removeAlert: true });
        this.setState({ removehidden: e })

    }
    handleremoveCancelbttn() {
        this.setState({ removeAlert: false });
    }
    //handleSubmit = event => {
    //    event.preventDefault();
    //    let currentComponent = this;
    //}
    parseValuesBulkUpdate() {
        
        let currentComponent = this;
        const SelectedBGs = [...this.state.SelectedBGs]
        for (var idx = 0; idx < SelectedBGs.length; idx++) {
            var isNonUnion = SelectedBGs[idx]["unionID"] == "Non Union";
            const rows = [...this.state.rows]
            for (var i = 1; i < rows.length; i++) {
               
                var dbbumpName = this.state.BumpListforUpdation.filter(BumpListforUpdation => BumpListforUpdation.name == rows[i].bumpName)[0].id;
                if (dbbumpName.includes("otherDescription")) {
                    if (isNonUnion) {
                        SelectedBGs[idx][dbbumpName] = rows[i].NonOtherDescription;
                    }
                    else { SelectedBGs[idx][dbbumpName] = rows[i].OtherDescription;}
                }
                else {
                    if (isNonUnion) {
                        SelectedBGs[idx][dbbumpName] = isNaN(rows[i].nonUnionRate) || rows[i].nonUnionRate == null || rows[i].nonUnionRate == "" ? 0 : parseFloat(rows[i].nonUnionRate)
                        //parseFloat(rows[i].nonUnionRate)
                    }
                    else {
                        SelectedBGs[idx][dbbumpName] = isNaN(rows[i].unionRate) || rows[i].unionRate == null || rows[i].unionRate == "" ? 0 : parseFloat(rows[i].unionRate)
                        //parseFloat(rows[i].unionRate)
                    }
                }
            }
        }
        return SelectedBGs;
        

    }
    Valid() {
        
        const rows = [...this.state.rows];
        var OthersCount = 0;
        var OtherDescriptionCount = 0;

        var Others1Count = 0;
        var OtherDescription1Count = 0;

        var Others2Count = 0;
        var OtherDescription2Count = 0;
        for (var i = 1; i < rows.length; i++) {
            
            

            var dbbumpName = this.state.BumpListforUpdation.filter(BumpListforUpdation => BumpListforUpdation.name == rows[i].bumpName)[0].id;
            
            if (dbbumpName == "other" ) {
                OthersCount += 1;
            }
            else if ((dbbumpName == "otherDescription") && ((rows[i].NonOtherDescription != "" && rows[i].NonOtherDescription != undefined != "") && (rows[i].OtherDescription != "" && rows[i].OtherDescription != undefined)) ) {
                OtherDescriptionCount += 1;
            }
            else if (dbbumpName == "other1") {
                Others1Count += 1;
            }
            else if ((dbbumpName == "otherDescription1") && ((rows[i].NonOtherDescription != "" && rows[i].NonOtherDescription != undefined != "") && (rows[i].OtherDescription != "" && rows[i].OtherDescription != undefined))) {
                OtherDescription1Count += 1;
            }
            else if (dbbumpName == "other2") {
                Others2Count += 1;
            }
            else if ((dbbumpName == "otherDescription2") && ((rows[i].NonOtherDescription != "" && rows[i].NonOtherDescription != undefined != "") && (rows[i].OtherDescription != "" && rows[i].OtherDescription != undefined))) {
                OtherDescription2Count += 1;
            }
        }
        if (OthersCount != OtherDescriptionCount || Others1Count != OtherDescription1Count || Others2Count != OtherDescription2Count) {
            this.toasterfun2("Please provide the amount / description of the compensation");
            return false;
        }
        else {
            return true;
        }
    }
    handleSubmit(event) {
        
        
        let currentComponent = this;
        let data = '';
        
        if (currentComponent.Valid()) {
            var rows = currentComponent.parseValuesBulkUpdate();


            if (rows != false) {
                //data = JSON.stringify(rows)

                //let userfullName = localStorage.getItem('userfullName');
                let userfullName = ''; //localStorage.getItem('PACurrentUserId');
                APIPost('AdminSkinUpload/UpdateProjectDetailsForActorByAccountant', { ProjectDetailsForActorModels: rows }, function (result) {
                    if (result.code == 200) {
                        currentComponent.toasterfun2("Details saved Successfully");
                    } else {
                        currentComponent.toasterfun2(result.message);
                    }
                });
            }
        }
    }
    handleRemoveSpecificRow() {
        let currentComponent = this;
        var idx = currentComponent.state.removehidden;
        const rows = [...currentComponent.state.rows]
        var bumpId = parseInt(rows[idx]["bumpId"]);

        const Shootingid = currentComponent.state.ShootingID;
        rows.splice(idx, 1)
        currentComponent.setState({ rows });

        currentComponent.setState({ removeAlert: false });
        if (rows.length == 1) {
            $('.nodata').addClass('show-div');
        }

        

    }
    LoadShootingBumpRate(ShootingID) {

        let currentComponent = this;
        let data = {
            ShootingId: ShootingID
        }
        APIPost('SkinUpload/GetShootingBumpRate', data, function (result) {
            if (result.code === 200) {
                result.result.map((item, key) =>
                    currentComponent.setState({
                        rows: [...currentComponent.state.rows, item]
                    })
                );
            }
            else {
                $('.nodata').addClass('show-div');
            }
        });



    }
    
    render() {
       
        let BumpList = this.state.BumpList.map((item, i) => {
            return (
                <option key={i} value={item.id} data-id={item.name}>{item.name}</option>
            )
        }, this);
        
        const SelectedBGs = this.props.SelectedBGs;
        const listItems = SelectedBGs.map((SelectedBG) =>
            <ListGroup.Item variant="light">{SelectedBG.firstName + " " + SelectedBG.lastName + " - " + SelectedBG.checkRefNumber}</ListGroup.Item>
        );
     
      
        return (
            <div class="form-builder wid100">
                <div className={"toaster-div " + (this.state.toasterCla ? "show" : "hide")}><i className="fa fa-check-circle" aria-hidden="true"></i> {this.state.toaster} </div>
                <h4>Compensations Against Talent</h4>
                <div class="cls-bttn" onClick={this.props.action}>x</div>
                <div className={"removeAlert-div " + (this.state.removeAlert ? "show" : "hide")}><span className="alert-bg-div"></span>
                    <div className="alert-pos-x"> <i className="fa fa-bell" aria-hidden="true"></i> Are you sure you wish to delete this Bump?
                        <div className="text-center-div">
                            <div className="bttn add-bttn" onClick={this.handleRemoveSpecificRow}>
                                Yes
                                
                        </div>
                            <div className="bttn cancel-btn" onClick={this.handleremoveCancelbttn}>
                                No
                        </div>
                        </div>
                    </div>
                </div>
                <div className="container" style={{ maxHeight: "72vh", overflowY: "auto" }}>
                    <div className="row clearfix">
                        <div className="col-md-12 column">
                            <ListGroup className="d-flex flex-wrap" horizontal>{listItems}</ListGroup>
                          

                            <button onClick={this.handleAddRow} className="btn btn-primary pull-left add-x-m">
                                Add Row
                            </button>
                            <div>
                                <table className="table table-bordered table-hover" id="customers">
                                    <thead>
                                        <tr>
                                            <th className="text-center"> # </th>
                                            <th className="text-center">Bump </th>
                                            <th className="text-center"> Union </th>
                                            <th className="text-center"> Non Union </th>
                                            <th className="text-center"> Additional Info </th>
                                            <th className="text-center"> Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.rows.map((item, idx) => (
                                            <tr id="addr0" key={idx}>
                                                <td>{idx}</td>

                                                <td>
                                                    <label className="lebel-div" style={{display:"none"}}>
                                                        {this.state.rows[idx].bumpName} </label>
                                                    <select className="select-div-x" onChange={this.handleChange(idx)} name="bumpId" >
                                                        <option value="">Select</option>
                                                        {BumpList}
                                                    </select>
                                                </td>
                                                <td>
                                                    <input style={{ display: this.state.rows[idx].IsOtherDesc ? "none" : "inline"   }}
                                                        type="text"
                                                        name="unionRate"
                                                        value={this.state.rows[idx].unionRate}
                                                        onChange={this.handleChange(idx)}
                                                        className="form-control height28 font12"
                                                        autoComplete="off"
                                                        onKeyDown={onlyDigits}                                                        
                                                    />
                                                    <input style={{ display: this.state.rows[idx].IsOtherDesc ? "inline" : "none"  }}
                                                        type="text"
                                                        name="OtherDescription"
                                                        value={this.state.rows[idx].OtherDescription}
                                                        onChange={this.handleChange(idx)}
                                                        className="form-control height28 font12"
                                                        autoComplete="off"                                                        
                                                    />

                                                </td>
                                                <td>
                                                    <input style={{ display: this.state.rows[idx].IsOtherDesc ? "none" : "inline" }}
                                                        type="text"
                                                        name="nonUnionRate"
                                                        value={this.state.rows[idx].nonUnionRate}
                                                        onChange={this.handleChange(idx)}
                                                        className="form-control height28 font12"
                                                        autoComplete="off"
                                                        onKeyDown={onlyDigits}
                                                    />
                                                    <input style={{ display: this.state.rows[idx].IsOtherDesc ? "inline" : "none" }}
                                                        type="text"
                                                        name="NonOtherDescription"
                                                        value={this.state.rows[idx].NonOtherDescription}
                                                        onChange={this.handleChange(idx)}
                                                        className="form-control height28 font12"
                                                        autoComplete="off"
                                                    />

                                                </td>

                                                <td>
                                                    <label className="lebel-div" name="addl_Info">
                                                        {this.state.rows[idx].addl_Info} </label>
                                                   


                                                </td>
                                                <td valign="middle">
                                                    <button
                                                        className="btn btn-outline-danger btn-sm xBttn" onClick={(e) => { this.handleDocRemove(idx, 'Res') }}>X </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <div className="nodata"> No Data Found </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="popup-x text-center padding-x mar45">
                    <button type="submit" class="btn btn-primary " onClick={this.handleSubmit}>Save </button>
                    <button type="button" class="btn btn-default btn-cancel " onClick={this.props.action} >Cancel </button>
                </div>
            </div>
            );
    }
}

//<Multiselect
//    options={this.state.SelectedBGs}
//    displayValue="email"
//    selectedValues={this.state.SelectedBGs}
//    style={{
//        chips: { background: "#5d78ff", "font-size": "15px" },

//        inputField: { // To change input field position or margin
//            "margin": "5px"
//        },
//    }}
//    onRemove={this.onRemove}
///>
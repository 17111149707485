import React from 'react';

const ErrorPage = (props) => {  

    return (
        <div class="main-wrapper account-wrapper">
            <div class="account-page">
                <div class="account-center">
                    <div style={{ marginTop: "10%" }}>
                        <div class="account-logo">
                            <i class="fa fa-6x fa-exclamation-circle" aria-hidden="true"></i>
                            <h2 className="mt-3">404. Page not Found.</h2>
                            <a href={props.redirectPath ? props.redirectPath : "castingdirector/login"} className="btn btn-primary account-btn mt-3">Login</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ErrorPage

//disabled={!bgActorID || bgActorID == 0 ? false : true}
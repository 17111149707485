import React from "react";
import { onlyDigits } from '../Validation'

export class Input extends React.Component {
    constructor(props) {
        super(props);
    } 
        

    handleChange = event => {

       
       // console.log("handleChange");
        var target = event.target;  


        var name = target.getAttribute('name');        
        const detail = { ...this.props.detail };
        if (name == "baseRate") { detail.baseRate = target.value; }        
        else if (name == "rateAdjusted") { detail.rateAdjusted = target.value; }        
        else if (name == "checkinTime") { detail.checkinTime = target.value; }        
        else if (name == "checkoutTime") { detail.checkoutTime = target.value; }        
        else if (name == "firstMealIn") { detail.firstMealIn = target.value; }        
        else if (name == "firstMealOut") { detail.firstMealOut = target.value; }        
        else if (name == "secondMealIn") { detail.secondMealIn = target.value; }        
        else if (name == "secondMealOut") { detail.secondMealOut = target.value; }        
        else if (name == "specialAbility") { detail.specialAbility = target.value; }        
        else if (name == "mealPenalty") { detail.mealPenalty = target.value; }        
        else if (name == "fitting") { detail.fitting = target.value; }        
        else if (name == "interview") { detail.interview = target.value; }        
        else if (name == "wetWork") { detail.wetWork = target.value; }        
        else if (name == "auto") { detail.auto = target.value; }        
        else if (name == "smokeWork") { detail.smokeWork = target.value; }        
        else if (name == "hairCompensation") { detail.hairCompensation = target.value; }        
        else if (name == "nDB") { detail.nDB = target.value; }        
        else if (name == "nDD") { detail.nDD = target.value; }        
      //  else if (name == "nDB3") { detail.nDB3 = target.value; }        
        else if (name == "wardrobeRate") { detail.wardrobeRate = target.value; }        
        else if (name == "bodyMakeup") { detail.bodyMakeup = target.value; }        
        else if (name == "beardCompensation") { detail.beardCompensation = target.value; }        
        else if (name == "dressUniformCompensation") { detail.dressUniformCompensation = target.value; }        
        else if (name == "grace") { detail.grace = target.value; }        
        else if (name == "mileage") { detail.mileage = target.value; }        
        else if (name == "formals") { detail.formals = target.value; }        
        else if (name == "gas") { detail.gas = target.value; }        
        else if (name == "golfClubs") { detail.golfClubs = target.value; }        
        else if (name == "other") { detail.other = target.value; }        
        else if (name == "otherDescription") { detail.otherDescription = target.value; }        
        else if (name == "allSeason") { detail.allSeason = target.value; }        
        else if (name == "biCycle") { detail.biCycle = target.value; }        
        else if (name == "camera") { detail.camera = target.value; }        
        else if (name == "bike") { detail.bike = target.value; }
        else if (name == "outOfSeason") { detail.outOfSeason = target.value; }
        else if (name == "petAllowance") { detail.petAllowance = target.value; }
        else if (name == "scrubs") { detail.scrubs = target.value; }
        else if (name == "misc") { detail.misc = target.value; }
        else if (name == "ocd") { detail.ocd = target.value; }
        else if (name == "tolls") { detail.tolls = target.value; }
        else if (name == "tux") { detail.tux = target.value; }
        else if (name == "wardrobeQuantity") { detail.wardrobeQuantity = parseInt(target.value); }
        else if (name == "skateBoard") { detail.skateBoard = target.value; } 
        else if (name == "skates") { detail.skates = target.value; }
        else if (name == "taxi") { detail.taxi = target.value; }
        else if (name == "tennisRacquet") { detail.tennisRacquet = target.value; }
        else if (name == "interviewHours") { detail.interviewHours = parseInt(target.value); }
        else if (name == "fittingHours") { detail.fittingHours = parseInt(target.value); }
        else if (name == "mileageMiles") { detail.mileageMiles = parseInt(target.value); }
        else if (name == "autoHours") { detail.autoHours = parseInt(target.value); }
        else if (name == "styling") { detail.styling = target.value; }
        else if (name == "carAllowance") { detail.carAllowance = target.value; }
        else if (name == "luggage") { detail.luggage = target.value; } 
        else if (name == "bikeQuantity") { detail.bikeQuantity = parseInt(target.value); }
        else if (name == "taxiQuantity") { detail.taxiQuantity = parseInt(target.value); }
        else if (name == "travel") { detail.travel = target.value; }
        else if (name == "luggageQuantity") { detail.luggageQuantity = parseInt(target.value); }
        else if (name == "cameraQuantity") { detail.cameraQuantity = target.value; }
        else if (name == "other1") { detail.other1 = target.value; }
        else if (name == "otherDescription1") { detail.otherDescription1 = target.value; }
        else if (name == "other2") { detail.other2 = target.value; }
        else if (name == "otherDescription2") { detail.otherDescription2 = target.value; }
        this.props.onChange(this.props.index, detail,event);
    };

    render() {
        return (
            <input
                type="text"
                id={this.props.name}
                name={this.props.name}
                className="form-control"
                value={this.props.value || ''}
                onChange={this.handleChange}
                autoComplete="off"
                onKeyDown={onlyDigits}
                disabled={this.props.disabled}
            />
        );
    }
}
//  <input type="text" className="form-control" name="baseRate" onChange={(e) => { this.handleInputChange(index, row) }} autoComplete="off" value={row.baseRate} />
export default Input;

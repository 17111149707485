import React, { useState, useEffect } from "react";

const CheckBox = (props) => {
    const [error, seterror] = useState(props.error ? props.error : {})
    const [checked, setchecked] = useState(props.checked ? true : false)

    useEffect(() => {
        seterror(props.error ? props.error : {});
        setchecked(props.checked ? true : false);
    }, [props])

    const handleChange = (e) => {
        let data = e.target.checked;
        props.formdata[props.id] = data
        setchecked(data);
        if (props.onChange)
            props.onChange(data)
    };

    return (
        <div className="form-group">
            {props.outsideLabel ?
                props.outsideLabel ? <label>{props.outsideLabel} {props.required ? <></> : <span className="redAlert">*</span>}</label> : <></>
                :
                <></>}
            <div className="custom-control custom-checkbox" style={props.style}>
                <input type="checkbox" className="custom-control-input" id={props.id} name={props.id} onChange={handleChange} checked={checked} disabled={props.disabled} />
                <label className="custom-control-label" for={props.id}>{props.label ? props.label : ""}</label>
                <span class="redAlert">{(props.label == "Rate") ? "*" : ""}</span>
            </div>
            <div className="redAlert noPosition">{error[props.id]}</div>
        </div>        
    );
}

export default CheckBox
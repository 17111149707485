import React, { useState, useEffect } from "react";
import {
    MuiPickersUtilsProvider, TimePicker, KeyboardTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';


const EMSTimePicker = (props) => {
    const [error, seterror] = useState(props.error ? props.error : {})
    const [fieldValue, setfieldValue] = useState(props.formdata[props.id] ? props.formdata[props.id] : null)

    useEffect(() => {
        seterror(props.error ? props.error : {});
        setfieldValue(props.formdata[props.id] ? props.formdata[props.id] : null);
    }, [props])

    const handleChange = (date) => {
        props.formdata[props.id] = date
        setfieldValue(date);
    };

    return (
        <div className="form-group">
            { props.label ? <label>{props.label} {props.required ? <span className="redAlert">*</span> : <></>}</label> : <></> }
            <div>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    {props.Picker ?
                        <TimePicker
                            id={props.id}
                            clearable
                            variant="inline"
                            label=""
                            value={fieldValue}
                            onChange={handleChange}
                            autoOk
                            disabled={props.disabled ? true : false}
                        />
                        :
                            <KeyboardTimePicker
                            margin="normal"
                            id="props.id"
                            label=""
                            variant="inline"
                            value={fieldValue}                            
                            onChange={handleChange}                            
                            KeyboardButtonProps={{
                                "aria-label": "change time"
                            }}
                            disabled={props.disabled ? true : false}
                              />
                        }
                </MuiPickersUtilsProvider>
            </div>
            <div className="redAlert noPosition">{error[props.id]}</div>
        </div>
    );
}

export default EMSTimePicker
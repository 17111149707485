import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import $ from 'jquery';
import 'react-tabs/style/react-tabs.css';// 'react-tabs/style/react-tabs.css';
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers';
import { Layout } from '../Layout';
import MaterialTable from "material-table";
import { APIGet, APIPost } from '../EMS_Components/APICall'

const AddProjectLocation = (props) => {

    const [toaster, settoaster] = useState('')
    const [toasterCla, settoasterCla] = useState(false)

    const [formData, setformData] = useState({})
    const [projectName, setprojectName] = useState('')
    const [projectLocationId, setprojectLocationId] = useState(0)
    const [projects, setprojects] = useState([])
    const [states, setstates] = useState([])
    const [countries, setcountries] = useState([])
    const [isModalOpen, setisModalOpen] = useState(false)
    const [projectsLocations, setprojectsLocations] = useState([])
    const [removeAlert, setremoveAlert] = useState(false)
    const [retriveAlert, setretriveAlert] = useState(false)
    const [isEditMode, setisEditMode] = useState(false)
    const [isLoggedIn, setisLoggedIn] = useState(false)

    const numericRegex = new RegExp(/^[0-9]*$/)
    const alphabetsRegex = new RegExp(/^[a-zA-Z]*$/)
    const alphanumericRegex = new RegExp(/^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':\"\\|,.<>\/?]*$/)
    const defaultCountry = ["united states of america", "usa", "us"]

    const emailInput = useRef(null);

    useEffect(() => {
        getProjects()
        CheckLogin()
    }, []);

    const CheckLogin = () => {
        let currentComponent = this;
        APIGet('AdminSkinUpload/CheckLogin', function (result) {
            if (result.code === 415) {
                setisLoggedIn(false)
            }
            else {
                setisLoggedIn(true)
            }
        });

    }

    const getProjects = () => {
        let userId = ''; //localStorage.getItem('CDCurrentUserId')

        APIGet('SkinUpload/getProjects/' + userId, function (result) {
            if (result.code == 100) {
                setprojects(result.result)
                setstates(result.commonResult.states ? result.commonResult.states : [])
                setcountries(result.commonResult.countries ? result.commonResult.countries : [])
                let dt = formData;
                
                dt.Country = result.commonResult.countries.find(x => defaultCountry.includes(x.countryName.toLowerCase())).countryName;
                setformData({ ...dt })
            }
        });
    }

    const getProjectLocations = () => {
        APIGet('SkinUpload/getProjectLocations/' + formData.projectId, function (result) {
            if (result.code == 100) {
                setprojectsLocations(result.result)
            }
        });
    }

    const toasterfun2 = (e) => {
        settoasterCla(true);
        settoaster(e);
        setTimeout(
            function () {
                settoasterCla(false);
            }.bind(this), 4000);
    }

    const validateNumeric = (number) => {
        number = number.replace(/\s/g, '');
        if (numericRegex.test(number)) {
            return true;
        }
        else {
            return false;
        }
    }

    const validateAlphabet = (letter) => {
        letter = letter.replace(/\s/g, '');
        if (alphabetsRegex.test(letter)) {
            return true;
        }
        else {
            return false;
        }
    }

    const validateAlphaNumeric = (letter) => {
        letter = letter.replace(/\s/g, '');
        if (alphanumericRegex.test(letter)) {
            return true;
        }
        else {
            return false;
        }
    }

    const handlechange = (e) => {
        let dt = formData;
        if (e.target.classList.contains('alphabet')) {
            if (validateAlphabet(e.target.value) == true) {
                dt[e.target.id] = e.target.value
                setformData({ ...dt })
            }
            else {
                dt[e.target.id] = formData[e.target.id] ? formData[e.target.id] : "";
                setformData({ ...dt })
            }
        }
        else if (e.target.classList.contains('numeric')) {
            if (validateNumeric(e.target.value) == true) {
                dt[e.target.id] = e.target.value
                setformData({ ...dt })
            }
            else {
                dt[e.target.id] = formData[e.target.id] ? formData[e.target.id] : "";
                setformData({ ...dt })
            }
        }
        else if (e.target.classList.contains('alphanumeric')) {
            if (validateAlphaNumeric(e.target.value) == true) {
                dt[e.target.id] = e.target.value
                setformData({ ...dt })
            }
            else {
                dt[e.target.id] = formData[e.target.id] ? formData[e.target.id] : "";
                setformData({ ...dt })
            }
        }
        else {
            dt[e.target.id] = e.target.value
            setformData({ ...dt })
        }
    }

    const handleddlchange = (e) => {
        let dt = formData;
        dt[e.target.id] = e.target.value
        setformData({ ...dt })

        if (e.target.id == "projectId") {
            let projectName = projects.find(x => x.id == e.target.value) ? projects.find(x => x.id == e.target.value).name : ''
            setprojectName(projectName)
            getProjectLocations();
        }
    }

    const ValidateData = () => {
        let isvalid = true;

        if (!formData.Location || formData.Location == '') {
            $('.locationMan').removeClass('hide')
            isvalid = false
        }
        else {
            $('.locationMan').addClass('hide')
        }

        if (!formData.State || formData.State == '') {
            $('.stateMan').removeClass('hide')
            isvalid = false
        }
        else {
            $('.stateMan').addClass('hide')
        }

        return isvalid;
    }
    const CheckLocationAlreadyExists = () => {
       
        let LocationExist = projectsLocations.find(P => P.location == formData.Location && P.isActive == true);
        let LocationExistButInactive = projectsLocations.find(P => P.location == formData.Location && P.isActive == false);

        if (LocationExist && LocationExist != undefined) {
            toasterfun2("Location with the same name already exist");
            return false;
        }
        else if (LocationExistButInactive && LocationExistButInactive != undefined) {
            toasterfun2("Location  with the same name already exists, please go ahead and Retrieve from Project location screen");
            return false;
        }
        else return true
    }
    const saveLocation = () => {
        let isValid = true;
        if (!isEditMode)
            isValid = CheckLocationAlreadyExists();
        if (ValidateData() && isValid) {

            let data = {
                Id: projectLocationId ? projectLocationId : 0,
                ProjectId: parseInt(formData.projectId),
                Location: formData.Location,
                Address1: formData.Address1,
                Address2: formData.Address2,
                City: formData.City,
                State: formData.State,
                Country: formData.Country == null ? countries.find(x => defaultCountry.includes(x.countryName.toLowerCase())).countryName : formData.Country,
                ZipCode: formData.Zip
            }

            APIPost('SkinUpload/AddProjectLocation', data, function (result) {
                if (result.code === 100) {
                    toasterfun2(result.message);
                    setisModalOpen(false);
                    let projectId = formData.projectId;
                    setformData({ projectId: projectId });
                    getProjectLocations()
                    closeDialog();
                } else {
                    toasterfun2(result.message);
                }
            });
        }
    }

    const addLocation = () => {
        if (!formData.projectId || formData.projectId == 0 || formData.projectId == '') {
            toasterfun2('Please select a Project');
            return
        }
        setisEditMode(false);
        setisModalOpen(true);
    }

    const editLocation = (rowData) => {
        setisEditMode(true);
        setprojectLocationId(rowData.id);
        setformData({
            projectId: rowData.projectId,
            Location: rowData.location,
            Address1: rowData.address1,
            Address2: rowData.address2,
            City: rowData.city,
            State: rowData.state,
            Country: rowData.country,
            Zip: rowData.zipCode
        });
        setisModalOpen(true);
    }

    const openConfirm = (projectLocationId) => {
        setprojectLocationId(projectLocationId);
        setremoveAlert(true);
    }

    const openRetrieveConfirm = (projectLocationId) => {
        setprojectLocationId(projectLocationId);
        setretriveAlert(true);
    }

    const deleteLocation = (isConfirm) => {
        setremoveAlert(false);
        if (isConfirm) {
            let data = {
                Id: projectLocationId,
                Mode: "DELETE"
            }
            removeRetrieveLocation(data)
        }
    }

    const retrieveLocation = (isConfirm) => {
        setretriveAlert(false);
        if (isConfirm) {
            let data = {
                Id: projectLocationId,
                Mode: "RETRIEVE"
            }
            removeRetrieveLocation(data)
        }        
        
    }

    const removeRetrieveLocation = (data) => {

        APIPost('SkinUpload/AddProjectLocation', data, function (result) {
            if (result.code === 100) {
                toasterfun2(result.message);
                getProjectLocations();
                closeDialog();
            } else {
                toasterfun2(result.message);
            }
        });
    }

    const closeDialog = () => {
        setisModalOpen(false);
        let projectId = formData.projectId;
        setformData({
            projectId: projectId,
            Location: '',
            Address1: '',
            Address2: '',
            City: '',
            State: null,
            Country: null,
            Zip: ''
        });
        setprojectLocationId(0)
    }

    return (
        <>
            {
                isLoggedIn ?
                    <Layout>
                        <div className={"toaster-div " + (toasterCla ? "show" : "hide")}><i className="fa fa-check-circle" aria-hidden="true"></i> {toaster} </div>
                        <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid mt-5">
                            <div className="kt-portlet kt-portlet--mobile mb-0">
                                <div className="kt-portlet__body">
                                    <div className="kt-form kt-form--label-right">
                                        <div className="row">
                                            <div className="col-md-6 col-sm-6 col-6">
                                                <div className="form-group">
                                                    <label>Project</label>
                                                    <select className="form-control" name="projectId" id="projectId" onChange={handleddlchange}>
                                                        <option value="0">Select</option>
                                                        {
                                                            projects.map((item, idx) => {
                                                                return (
                                                                    <option value={item.id}>{item.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <div className="redAlert noPosition emailMan hide">* Mandatory</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 col-sm-12 col-12">
                                                <button type="button" onClick={() => addLocation()} class="btn btn-sm btn-primary pull-right"> <i class="fa fa-plus-circle" aria-hidden="true"> Add New</i> </button>
                                            </div>
                                            <div className="col-md-12 col-sm-12 col-12">
                                                <MaterialTable
                                                    title="Project Locations"
                                                    columns={[
                                                        { title: "Location", field: "location" },
                                                        { title: "Address1", field: "address1" },
                                                        { title: "Address2", field: "address2" },
                                                        { title: "City", field: "city" },
                                                        { title: "State", field: "state" },
                                                        { title: "Country", field: "country" },
                                                        { title: "Zip", field: "zipCode" }
                                                    ]}
                                                    data={projectsLocations}
                                                    class="mat-paginator-sticky"
                                                    title=""
                                                    options={{
                                                        pageSizeOptions: [5, 10, 20, 50],
                                                        pageSize: 10,
                                                        actionsColumnIndex: -1
                                                    }}
                                                    actions={[
                                                        rowData => ({
                                                            icon: 'edit',
                                                            tooltip: 'Edit Location',
                                                            onClick: (event, rowData) => editLocation(rowData),
                                                            disabled: rowData.isActive == 0
                                                        }),
                                                        rowData => (
                                                            {
                                                                icon: rowData.isActive == 1 ? 'delete' : 'add',
                                                                tooltip: rowData.isActive == 1 ? 'Remove Location' : 'Retrive Location',
                                                                onClick: (event, rowData) => rowData.isActive == 1 ? openConfirm(rowData.id) : openRetrieveConfirm(rowData.id),
                                                            })
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="divResetPassword" className={"popup-div modal " + (isModalOpen ? "show" : "hide")}>
                                    <div className="modal-title">
                                        <h4>Project Location</h4>
                                    </div>
                                    <div className="modal-body">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group text-left">
                                                    <label className="bmd-label-floating">Project Name</label>
                                                    <input type="text" autoComplete="ProjectName" id="ProjectName" className="form-control" value={projectName} disabled />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group text-left">
                                                    <label className="bmd-label-floating">Location</label>
                                                    <input type="text" autoComplete="Location" id="Location" className="form-control alphanumeric" value={formData.Location} onChange={handlechange} />
                                                </div>
                                                <div className="redAlert noPosition locationMan hide">* Mandatory</div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group text-left">
                                                    <label className="bmd-label-floating">Address 1</label>
                                                    <input type="text" autoComplete="Address1" id="Address1" className="form-control" value={formData.Address1} onChange={handlechange} />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group text-left">
                                                    <label className="bmd-label-floating">Address 2</label>
                                                    <input type="text" autoComplete="Address2" id="Address2" className="form-control" value={formData.Address2} onChange={handlechange} />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group text-left">
                                                    <label className="bmd-label-floating">City</label>
                                                    <input type="text" autoComplete="City" id="City" className="form-control alphabet" value={formData.City} onChange={handlechange} />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group text-left">
                                                    <label className="bmd-label-floating">State</label>
                                                    <select className="form-control" name="State" id="State" onChange={handleddlchange}>
                                                        <option value="0">Select</option>
                                                        {
                                                            states.map((item, idx) => {
                                                                return (
                                                                    <option value={item.stateName} selected={formData.State ? (formData.State == item.stateName ? true : false) : false}>{item.stateName}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                                <div className="redAlert noPosition stateMan hide">* Mandatory</div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group text-left">
                                                    <label className="bmd-label-floating">Country</label>
                                                    <select className="form-control" name="Country" id="Country" onChange={handleddlchange}>
                                                        <option value="0">Select</option>
                                                        {
                                                            countries.map((item, idx) => {
                                                                return (
                                                                    <option value={item.countryName}
                                                                        selected={formData.Country ? (formData.Country == item.countryName ? true : false) : (defaultCountry.includes(item.countryName.toLowerCase()) ? true : false)} >
                                                                        {item.countryName}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group text-left">
                                                    <label className="bmd-label-floating">Zip</label>
                                                    <input type="text" autoComplete="Zip" id="Zip" className="form-control numeric" value={formData.Zip} onChange={handlechange} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer text-right">
                                        <button type="button" className="btn btn-sm btn-primary mr-10" onClick={() => saveLocation()}>{isEditMode ? "Update" : "Save"}</button>
                                        <button type="button" className="btn btn-sm btn-primary mr-10" onClick={() => closeDialog()}>Close</button>
                                    </div>
                                </div>
                                <div className="modal-overlay"></div>

                    <div className={"removeAlert-div " + (removeAlert ? "show" : "hide")}>
                        <span className="alert-bg-div"></span>
                        <div className="alert-pos-x">
                            {" "}
                            <i className="fa fa-bell" aria-hidden="true"></i> Are you sure you want to Remove the Location?
                                <div className="text-center-div">
                                <div type="button" className="btn btn-sm btn-primary popupButton mr-10" onClick={() => deleteLocation(true)}>Yes</div>
                                <div type="button" className="btn btn-sm btn-primary popupButton" onClick={() => deleteLocation(false)}> No </div>
                            </div>
                        </div>
                    </div>
                    <div className={"removeAlert-div " + (retriveAlert ? "show" : "hide")}>
                        <span className="alert-bg-div"></span>
                        <div className="alert-pos-x">
                            {" "}
                            <i className="fa fa-bell" aria-hidden="true"></i> Are you sure you want to Retrieve the Location?
                                <div className="text-center-div">
                                <div type="button" className="btn btn-sm btn-primary popupButton mr-10" onClick={() => retrieveLocation(true)}>Yes</div>
                                <div type="button" className="btn btn-sm btn-primary popupButton" onClick={() => retrieveLocation(false)}> No </div>
                            </div>
                        </div>
                    </div>

                            </div>
                        </div>
                    </Layout>
                    :
                    <></>
            }

        </>
    )
}

export default AddProjectLocation


import React, { useState } from 'react';
import { Layout } from '../Layout';
import moment from 'moment';
import { DatePickerInput } from 'rc-datepicker';
import MaterialTable from "material-table";
import $ from 'jquery';
import ReactTooltip from 'react-tooltip';
import { onlyDigits } from '../Validation'
//import { Tabs, Tab } from 'react-bootstrap-tabs';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';// 'react-tabs/style/react-tabs.css';
import { APIGet, APIPost } from '../EMS_Components/APICall'
import { Dropdown } from '../EMS_Components';


export class UpdateDetails extends React.Component {

    constructor(props) {
        super(props);
       
        this.state = {
            toasterCla: false,
            toaster: '',
            isResetPasswordPopupOpen: false,
            RegistrationLink: '',
            //Lookuplist: {
            //    0: "Select", 1: "W4", 2: "I9 1st Sec", 38: "I9 2nd Sec", 3: "NC4", 4: "A4", 5: "AZ4", 6: "AR4EC", 7: "CADE4", 8: "CTW4", 9: "D4", 10: "GAG4", 11: "HW4",
            //    12: "ILW4", 13: "ILW5NR", 14: "INWH4", 15: "LAL4", 16: "LAL4E", 17: "MW507", 18: "MAM4", 19: "MIW4", 20: "W4MN", 21: "MS_893501931000", 22: "MOW4", 23: "MTW4",
            //    24: "NJW4", 25: "NMW4", 26: "IT2014", 27: "IT20141", 28: "PFLWaiver", 29: "OKW4", 30: "ORW4", 31: "CLGS326", 32: "LSTExemption", 33: "REV419EX", 34: "RIW4",
            //    35: "SCW4", 36: "VA4", 37: "WT4", 40: "IT4", 39: "IAW4", 41: "Declaration of Texas Residency", 42: "CA-Wages Theft", 43: "NY Wages Theft", 44: "Sexual Harassment",
            //    45: "VA Declaration of Residency"}
            Lookuplist: { }
            
        }
        
    }

    toasterfun2 = (e) => {
        this.setState({ toasterCla: true });
        this.setState({ toaster: e });
        setTimeout(
            function () {
                this.setState({ toasterCla: false });
            }.bind(this), 4000);
    }

    handlechange = (e) => {
        if (e.target.id == "Email") {
            this.setState({ Email : e.target.value })
        }
        else if (e.target.id == "Phone") {
            this.setState({ Phone: e.target.value })
        }
    }
    getAllUser = () => {
        if ((!this.state.Email || this.state.Email.Length == 0) && (!this.state.Phone || this.state.Phone.Length == 0)) {
            $('.manfields').removeClass('hide')
            return
        }

        let type = this.state.Email ? 1 : 2
        let data = this.state.Email ? this.state.Email : this.state.Phone ? this.state.Phone : ''

        //let dropdownL = "";//this.state.Lookuplist;

        let currentComponent = this;
        var strURL = "getAllUser";
        //let lkupList = '';
        //const clientOptions = {};


        APIGet('LogDetails/' + strURL + "/" + type + "/" + data, function (result) {
            if (result.statusCode == 100) {
                let str = '"0" : "Select",';
                result.dropdnLookList.map((item, i) => {
                    str += '"' + item.value + '":"' + item.desc + '",';
                });
                currentComponent.setState({ Lookuplist: JSON.parse('{' + str.replace(/,\s*$/, "") + '}') });
                currentComponent.setState({ rows: result.result, loading: false });
                currentComponent.toasterfun2(result.statusMessage);

            }
            else {
                currentComponent.setState({ rows: result.result, loading: false });
                currentComponent.toasterfun2(result.statusMessage);
            }
        });
    }
    

    getDetails = () => {
        if ((!this.state.Email || this.state.Email.Length == 0) && (!this.state.Phone || this.state.Phone.Length == 0)) {
            $('.manfields').removeClass('hide')
            return
        }

        let type = this.state.Email ? 1 : 2
        let data = this.state.Email ? this.state.Email : this.state.Phone ? this.state.Phone : ''

        //let dropdownL = "";//this.state.Lookuplist;

        let currentComponent = this;
        var strURL = "getUserDetails";
        //let lkupList = '';
        //const clientOptions = {};
        

        APIGet('LogDetails/' + strURL + "/" + type + "/" + data, function (result) {            
            if (result.statusCode == 100) {
                let str = '"0" : "Select",'; 
                result.dropdnLookList.map((item, i) => {
                    str += '"' + item.value + '":"' + item.desc + '",';
                });
                currentComponent.setState({ Lookuplist: JSON.parse('{' + str.replace(/,\s*$/, "") + '}') });
                currentComponent.setState({ rows: result.result, loading: false });
                currentComponent.toasterfun2(result.statusMessage);
                
            }
            else
            {
                currentComponent.setState({ rows: result.result, loading: false });
                currentComponent.toasterfun2(result.statusMessage);
            }
        });
    }

    getRegLink = (verificationID) => {
        let currentComponent = this;
        var strURL = "getRegLink";
        let data = {
            VerificationID: verificationID
        }
        APIPost('LogDetails/' + strURL, data, function (result) {
            if (result.code == 100) {
                currentComponent.setState({ isResetPasswordPopupOpen: true, RegistrationLink: result.result });
            } else {
                console.log(result.message);
                currentComponent.toasterfun2(result.message);
            }
        });
    }

    updateDetail = (resolve, oldValue, newvalue, rowData, columnDef) => {
        let currentComponent = this;
        
        if (oldValue == newvalue) {
            currentComponent.toasterfun2('Attempted to update Same Value');
            setTimeout(resolve, 1000);
            return;
        }
        if (newvalue.length == 0) {
            currentComponent.toasterfun2('Value cannot be empty');
            setTimeout(resolve, 1000);
            return;
        }
        if (newvalue == 0) {
            currentComponent.toasterfun2('Value cannot be allow zero');
            setTimeout(resolve, 1000);
            return;
        }
        let data;
        let Searchvalue = this.state.Email ? 1 : 2;
      

        if (columnDef.title == "Email") {
            var emailRegx = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            if (emailRegx.test(newvalue))
            { }
            else
            {
                currentComponent.toasterfun2('please enter a valid format mail!');
                setTimeout(resolve, 1000);
                return;
            }

            data = {
                Type: 1,
                UserID: rowData.userID,
                VerificationID: rowData.verificationID,
                PersonID: rowData.personID,
                Value: newvalue,
                Searchfield: Searchvalue
            }
        }
        else if (columnDef.title == "Phone #") {
            var phnRegx = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
            if (phnRegx.test(newvalue))
            { }
            else
            {
                currentComponent.toasterfun2('please enter a valid format phone number!');
                setTimeout(resolve, 1000);
                return;
            }
            data = {
                Type: 2,
                UserID: rowData.userID,
                VerificationID: rowData.verificationID,
                PersonID: rowData.personID,
                Value: newvalue,
                Searchfield: Searchvalue
            }
        }
        else if (columnDef.title == "Extend Days") {
            if (/\d/.test(newvalue)) {
            } else {
                currentComponent.toasterfun2('Please enter valid days.');
                setTimeout(resolve, 1000);
                return;
            }

            data = {
                Type: 3,
                UserID: rowData.userID,
                VerificationID: rowData.verificationID,
                PersonID: rowData.personID,
                Value: newvalue,
                Searchfield: Searchvalue
            }
        }
        else if (columnDef.title == "Reset Password") {
            var passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/;
            if (newvalue.match(passw))
            {

            }
            else {
                currentComponent.toasterfun2('Password must 8 to 15 characters which contain at least one numeric digit, one uppercase and one lowercase letter');
                setTimeout(resolve, 1000);
                return;
            }
            data = {
                Type: 4,
                UserID: rowData.userID,
                VerificationID: rowData.verificationID,
                PersonID: rowData.personID,
                Value: newvalue,
                Searchfield: Searchvalue
                   }
        }
        else if (columnDef.title == "Forms Reset") {

            //if (newvalue != 1 && newvalue != 2) {
            //    currentComponent.toasterfun2('Value cannot be allow, please choose I9 1st Section or Please choose I9 2nd Section ');
            //    setTimeout(resolve, 3000);
            //    return;
            //}
           
            data = {
                Type: 5,
                UserID: rowData.userID,
                VerificationID: rowData.verificationID,
                PersonID: rowData.personID,
                Value: newvalue,
                Searchfield: Searchvalue
            }
        }
        
        //data.append({Searchfield:Searchvalue});
        //debugger
        APIPost('LogDetails/updateDetail', data, function (result) {
            
           // console.log(result.result);
            //console.log(result.result.type);
            if (result.result.type == 1) {
                currentComponent.setState({ Email: result.result.value });
                currentComponent.setState({ Phone: '' });
            }
            else if (result.result.type == 2) {
                currentComponent.setState({ Phone: result.result.value });
                currentComponent.setState({ Email: '' });
            }
            currentComponent.getDetails();
            currentComponent.toasterfun2(result.message);
            setTimeout(resolve, 1000);
           
        });

        //fetch('LogDetails/updateDetail', {
        //    method: 'post',
        //    headers: {
        //        Accept: 'application/json',
        //        'Content-Type': 'application/json',
        //    },
        //    body: JSON.stringify(data)
        //}).then(function (response) {
        //    return response.json();
        //}).then(function (result) {
        //    currentComponent.toasterfun2(result.message);
        //    setTimeout(resolve, 1000);

        //}).catch(function (error) {
        //    console.log("-------- error ------- " + error);
        //    currentComponent.toasterfun2("result:" + error)
        //});       
    }

    copyToClipboard = (element) => {
        var $temp = $("<input>");
        $("body").append($temp);
        $temp.val($(element).text()).select();
        document.execCommand("copy");
        $temp.remove();
    }
    
    render() {
        return (
            <Layout>               
                <div className={"toaster-div " + (this.state.toasterCla ? "show" : "hide")}><i className="fa fa-check-circle" aria-hidden="true"></i> {this.state.toaster} </div>    

                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                    <div className="kt-portlet kt-portlet--mobile mb-0">
                        <div className="kt-portlet__body">
                            <div action="" className="kt-form kt-form--label-right">                               
                                <div className="row">
                                    <div className="col-lg-4 col-md-4">
                                        <div className="form-group text-left">
                                            <label className="bmd-label-floating">Email</label>
                                            <div class="input-group mb-3">
                                                <input type="text" autoComplete="Email" id="Email" className="form-control" value={this.state.Email} onChange={this.handlechange } />
                                            </div>
                                            <div className="redAlert noPosition emwrong hide">* Email not found</div>
                                            <div className="redAlert noPosition emnotval hide">* Is not valid </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-1 col-md-1 text-center mt-4">
                                        <span> <b>(OR)</b> </span>
                                     </div>
                                    <div className="col-lg-4 col-md-4">
                                        <div className="form-group text-left">
                                            <label className="bmd-label-floating">Phone</label>
                                            <div class="input-group mb-3">
                                                <input type="text" autoComplete="Phone" id="Phone" className="form-control" value={this.state.Phone} onChange={this.handlechange} />
                                            </div>
                                            <div className="redAlert noPosition phwrong hide">* Phone # not found</div>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-2 text-center mt-4">
                                        <button type="button" className="btn btn-primary btn-sm"  onClick={ this.getDetails }>Get Details </button>
                                    </div>
                                    <div className="col-lg-2 col-md-2 text-center mt-4 hide">
                                        <button type="button" className="btn btn-primary btn-sm" onClick={this.getAllUser}>Get All Users </button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="redAlert noPosition manfields hide">* Enter Email or Phone #</div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                        <MaterialTable
                                            title=""
                                            columns={[
                                                { title: "First Name", field: "firstName", editable: "never"  },
                                                { title: "Last Name", field: "lastName", editable: "never"  },
                                                { title: "Username", field: "userName", editable: "never" },
                                                { title: "Link Expiry Date", field: "expirydate", editable: "never" },
                                                {
                                                    title: "Extend Days", field: "AddDays", editable: "onAdd", render: rowData => <input value="0" />
 },
                                                { title: "Email", field: "email", editable: "true" },
                                                { title: "Phone #", field: "phone", editable: "true" },
                                                {
                                                    title: "Reset Password", field: "PwdReset", editable: "onAdd", render: rowData => <input value=""  />
                                                },
                                                {
                                                    title: "Forms Reset", field: "i9Sec", lookup: this.state.Lookuplist 
                                                },
                                                
                                                {
                                                    title: "Action", width: "10%", editable: "never", render: rowData => <button type="button" onClick={() => this.getRegLink(rowData.verificationID)} class="btn btn-sm btn-primary"> Reg. Link </button>
                                                },
                                            ]}
                                            data={this.state.rows}
                                            class="mat-paginator-sticky"
                                            title=""
                                            cellEditable={{
                                                onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                                                    return new Promise((resolve, reject) => {
                                                        this.updateDetail(resolve, oldValue, newValue, rowData, columnDef);
                                                    });
                                                }
                                            }}
                                            options={{
                                                pageSizeOptions: [5, 10, 20, 50],
                                                pageSize: 10
                                            }}
                                        />
                                     </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="divResetPassword" className={"popup-div modal " + (this.state.isResetPasswordPopupOpen ? "show" : "hide")}>
                    <div className="modal-title">
                        <h4>Registration Link</h4>
                    </div>
                    <div className="modal-body">
                        <div className="col-lg-12 col-md-12">
                            <span> <a href={ this.state.RegistrationLink } id="supportMail" target="_blank">{this.state.RegistrationLink}</a>
                                <i className="fa fa-copy ml-2" type="button" onClick={() => this.copyToClipboard('#supportMail')} />
                            </span>
                        </div>
                    </div>
                    <div className="modal-footer text-right">
                        <button type="button" className="btn btn-sm btn-brand mr-10" onClick={() => this.setState({ isResetPasswordPopupOpen: false })}>Close</button>
                    </div>
                </div>
            </Layout>
        );
    }
}


import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import $ from 'jquery';
import 'react-tabs/style/react-tabs.css';// 'react-tabs/style/react-tabs.css';
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers';
import { Layout } from '../Layout';
import MaterialTable from "material-table";
import { APIGet, APIPost } from '../EMS_Components/APICall'


const SkinAddProjectLocation = (props) => {

    const [toaster, settoaster] = useState('')
    const [toasterCla, settoasterCla] = useState(false)

    const [formData, setformData] = useState({})
    const [projectName, setprojectName] = useState('')
    const [projectLocationId, setprojectLocationId] = useState(0)
    const [projects, setprojects] = useState([])
    const [states, setstates] = useState([])
    const [countries, setcountries] = useState([])
    const [isModalOpen, setisModalOpen] = useState(false)
    const [projectsLocations, setprojectsLocations] = useState([])
    const [removeAlert, setremoveAlert] = useState(false)
    const [retriveAlert, setretriveAlert] = useState(false)

    const numericRegex = new RegExp(/^[0-9]*$/)
    const alphabetsRegex = new RegExp(/^[a-zA-Z]*$/)
    const alphanumericRegex = new RegExp(/^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':\"\\|,.<>\/?]*$/)
    const defaultCountry = ["united states of america", "usa", "us"]



    const emailInput = useRef(null);

    useEffect(() => {
        //debugger;
        getProjects()
        getProjectLocations();
    }, []);

    const getProjects = () => {
        //let userId = localStorage.getItem('CDCurrentUserId')

        APIGet('SkinUpload/getProjects/', function (result) {
            if (result.code == 100) {
                setprojects(result.result)
                setstates(result.commonResult.states ? result.commonResult.states : [])
                setcountries(result.commonResult.countries ? result.commonResult.countries : [])
                let dt = formData;

                dt.projectId = props.ProjectID;
                dt.Country = result.commonResult.countries.find(x => defaultCountry.includes(x.countryName.toLowerCase())).countryName;
                setformData({ ...dt })

                let projectName = result.result.find(x => x.id == props.ProjectID) ? result.result.find(x => x.id == props.ProjectID).name : ''
                setprojectName(projectName)

            }
        });
    }

    const getProjectLocations = () => {
        APIGet('SkinUpload/getProjectLocations/' + props.ProjectID, function (result) {
            if (result.code == 100) {
                setprojectsLocations(result.result)
            }
        });
    }

    const toasterfun2 = (e) => {

        settoasterCla(true);
        settoaster(e);
        setTimeout(
            function () {
                settoasterCla(false);
            }.bind(this), 4000);
    }

    const validateNumeric = (number) => {
        number = number.replace(/\s/g, '');
        if (numericRegex.test(number)) {
            return true;
        }
        else {
            return false;
        }
    }

    const validateAlphabet = (letter) => {
        letter = letter.replace(/\s/g, '');
        if (alphabetsRegex.test(letter)) {
            return true;
        }
        else {
            return false;
        }
    }

    const validateAlphaNumeric = (letter) => {
        letter = letter.replace(/\s/g, '');
        if (alphanumericRegex.test(letter)) {
            return true;
        }
        else {
            return false;
        }
    }

    const handlechange = (e) => {
        let dt = formData;
        if (e.target.classList.contains('alphabet')) {
            if (validateAlphabet(e.target.value) == true) {
                dt[e.target.id] = e.target.value
                setformData({ ...dt })
            }
            else {
                dt[e.target.id] = formData[e.target.id] ? formData[e.target.id] : "";
                setformData({ ...dt })
            }
        }
        else if (e.target.classList.contains('numeric')) {
            if (validateNumeric(e.target.value) == true) {
                dt[e.target.id] = e.target.value
                setformData({ ...dt })
            }
            else {
                dt[e.target.id] = formData[e.target.id] ? formData[e.target.id] : "";
                setformData({ ...dt })
            }
        }
        else if (e.target.classList.contains('alphanumeric')) {
            if (validateAlphaNumeric(e.target.value) == true) {
                dt[e.target.id] = e.target.value
                setformData({ ...dt })
            }
            else {
                dt[e.target.id] = formData[e.target.id] ? formData[e.target.id] : "";
                setformData({ ...dt })
            }
        }
        else {
            dt[e.target.id] = e.target.value
            setformData({ ...dt })
        }
    }

    const handleddlchange = (e) => {
        let dt = formData;
        dt[e.target.id] = e.target.value
        setformData({ ...dt })

        if (e.target.id == "projectId") {
            let projectName = projects.find(x => x.id == e.target.value) ? projects.find(x => x.id == e.target.value).name : ''
            setprojectName(projectName)
            getProjectLocations();
        }
    }

    const ValidateData = () => {
        let isvalid = true;

        if (!formData.Location || formData.Location == '') {
            $('.locationMan').removeClass('hide')
            isvalid = false
        }
        else {
            $('.locationMan').addClass('hide')
        }

        if (!formData.State || formData.State == '') {
            $('.stateMan').removeClass('hide')
            isvalid = false
        }
        else {
            $('.stateMan').addClass('hide')
        }

        if (!formData.Country || formData.Country == '0') {
            $('.countryMan').removeClass('hide')
            isvalid = false
        }
        else {
            $('.countryMan').addClass('hide')
        }

        return isvalid;
    }

    const CheckLocationAlreadyExists = () => {

        let LocationExist = projectsLocations.find(P => P.location == formData.Location && P.isActive == true);
        let LocationExistButInactive = projectsLocations.find(P => P.location == formData.Location && P.isActive == false);

        if (LocationExist && LocationExist != undefined) {
            toasterfun2("Location with the same name already exist");
            return false;
        }
        else if (LocationExistButInactive && LocationExistButInactive != undefined) {
            toasterfun2("Location  with the same name already exists, please go ahead and Retrieve from Project location screen");
            return false;
        }
        else return true
    }

    const saveLocation = () => {
        if (ValidateData() && CheckLocationAlreadyExists()) {

            let data = {
                Id: projectLocationId ? projectLocationId : 0,
                ProjectId: parseInt(formData.projectId),
                Location: formData.Location,
                Address1: formData.Address1,
                Address2: formData.Address2,
                City: formData.City,
                State: formData.State,
                Country: formData.Country == null ? countries.find(x => defaultCountry.includes(x.countryName.toLowerCase())).countryName : formData.Country,
                ZipCode: formData.Zip
            }

            APIPost('SkinUpload/AddProjectLocation', data, function (result) {
                if (result.code === 100) {
                    toasterfun2(result.message);
                    setisModalOpen(false);
                    let projectId = formData.projectId;
                    setformData({ projectId: projectId });
                    getProjectLocations()
                    toasterfun2("Created new Project Location");
                    closeDialog();
                    //  props.action();

                } else {
                    toasterfun2(result.message);
                }
            });
        }
    }

    const addLocation = () => {
        if (!formData.projectId || formData.projectId == 0 || formData.projectId == '') {
            toasterfun2('Please select a Project');
            return
        }
        setisModalOpen(true);
    }

    const editLocation = (rowData) => {
        setprojectLocationId(rowData.id);
        setformData({
            projectId: rowData.projectId,
            Location: rowData.location,
            Address1: rowData.address1,
            Address2: rowData.address2,
            City: rowData.city,
            State: rowData.state,
            Country: rowData.country,
            Zip: rowData.zipCode
        });
        setisModalOpen(true);
    }

    const openConfirm = (projectLocationId) => {
        setprojectLocationId(projectLocationId);
        setremoveAlert(true);
    }

    const openRetrieveConfirm = (projectLocationId) => {
        setprojectLocationId(projectLocationId);
        setretriveAlert(true);
    }

    const deleteLocation = (isConfirm) => {
        setremoveAlert(false);
        if (isConfirm) {
            let data = {
                Id: projectLocationId,
                Mode: "DELETE"
            }
            removeRetrieveLocation(data)
        }
    }

    const retrieveLocation = (isConfirm) => {
        setretriveAlert(false);
        if (isConfirm) {
            let data = {
                Id: projectLocationId,
                Mode: "RETRIEVE"
            }
            removeRetrieveLocation(data)
        }

    }

    const removeRetrieveLocation = (data) => {
        APIPost('SkinUpload/AddProjectLocation', data, function (result) {
            if (result.code === 100) {
                toasterfun2(result.message);
                getProjectLocations();
                closeDialog();
            } else {
                toasterfun2(result.message);
            }
        });
    }

    const closeDialog = () => {
        setisModalOpen(false);
        let projectId = formData.projectId;
        setformData({
            projectId: projectId,
            Location: '',
            Address1: '',
            Address2: '',
            City: '',
            State: null,
            Country: countries.find(x => defaultCountry.includes(x.countryName.toLowerCase())).countryName,
            Zip: ''
        });
        setprojectLocationId(0)
    }

    return (
        <div class="form-builder">


            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid mt-5">
                <div className="kt-portlet kt-portlet--mobile mb-0">

                    <div id="divResetPassword" className="popup-div modal with700 ">
                        <div className={"toaster-div " + (toasterCla ? "show" : "hide")}><i className="fa fa-check-circle" aria-hidden="true"></i> {toaster} </div>
                        <div className="modal-title">
                            <h4 className="titlebggreen">Project Location</h4>
                        </div>
                        <div class="cls-bttn hide" onClick={props.action}>x</div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-6 col-md-6">
                                    <div className="form-group text-left">
                                        <label className="bmd-label-floating">Show Name</label>
                                        <input type="text" autoComplete="ProjectName" id="ProjectName" className="form-control" value={projectName} disabled />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className="form-group text-left">
                                        <label className="bmd-label-floating">Location Name <b><i>eg.COVID Test, LOT 31, XYZ Hotel</i></b></label>
                                        <input type="text" autoComplete="Location" id="Location" className="form-control alphanumeric" value={formData.Location} onChange={handlechange} />
                                    </div>
                                    <div className="redAlert noPosition locationMan hide">* Mandatory</div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className="form-group text-left">
                                        <label className="bmd-label-floating">Address 1</label>
                                        <input type="text" autoComplete="Address1" id="Address1" className="form-control" value={formData.Address1} onChange={handlechange} />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className="form-group text-left">
                                        <label className="bmd-label-floating">Address 2</label>
                                        <input type="text" autoComplete="Address2" id="Address2" className="form-control" value={formData.Address2} onChange={handlechange} />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className="form-group text-left">
                                        <label className="bmd-label-floating">City</label>
                                        <input type="text" autoComplete="City" id="City" className="form-control alphabet" value={formData.City} onChange={handlechange} />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className="form-group text-left">
                                        <label className="bmd-label-floating">State</label>
                                        <select className="form-control" name="State" id="State" onChange={handleddlchange}>
                                            <option value="0">Select</option>
                                            {
                                                states.map((item, idx) => {
                                                    return (
                                                        <option value={item.stateName} selected={formData.State ? (formData.State == item.stateName ? true : false) : false}>{item.stateName}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="redAlert noPosition stateMan hide">* Mandatory</div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className="form-group text-left">
                                        <label className="bmd-label-floating">Country</label>
                                        <select className="form-control" name="Country" id="Country" onChange={handleddlchange}>
                                            <option value="0">Select</option>
                                            {
                                                countries.map((item, idx) => {
                                                    return (
                                                        <option value={item.countryName}
                                                            selected={formData.Country ? (formData.Country == item.countryName ? true : false) : (defaultCountry.includes(item.countryName.toLowerCase()) ? true : false)} >
                                                            {item.countryName}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                        <div className="redAlert noPosition countryMan hide">* Mandatory</div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className="form-group text-left">
                                        <label className="bmd-label-floating">Zip</label>
                                        <input type="text" autoComplete="Zip" id="Zip" className="form-control numeric" value={formData.Zip} onChange={handlechange} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer text-right green-div-btn">
                            <button type="button" className="btn btn-sm btn-brand mr-10" onClick={() => saveLocation()}>Save</button>
                            <button type="button" className="btn btn-sm btn-brand mr-10" onClick={props.action}>Close</button>
                        </div>
                    </div>
                    <div className="modal-overlay"></div>



                </div>
            </div>
        </div >
    )
}

export default SkinAddProjectLocation


export const  onlyDigits = event => {
    var key = event.which || event.keycode;
    if (key == 37 || key == 38 || key == 39 || key == 40 || key == 8 || key == 46 || key == 9) { // Left / Up / Right / Down Arrow, Backspace, Delete keys
        return;
    }
    if (((event.keyCode == 65 || event.keyCode == 86 || event.keyCode == 67) && (event.ctrlKey === true || event.metaKey === true)) ||
        // Allow: home, end, left, right, down, up
        (event.keyCode >= 35 && event.keyCode <= 40)) {
        // let it happen, don't do anything
        return;
    }
    if (key == 221 || key == 32 || key == 220) { event.preventDefault(); }
    const re = /[^a-zA-Z[$&+,:_;=?@#|'"<>`~/^*()%{}!-]+/g;
    if (!re.test(event.key)) {
        event.preventDefault();
    }
    else {
        return;
    }

}




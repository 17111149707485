import React from "react";


export class Input extends React.Component {
    constructor(props) {
        super(props);
    } 
        

    handleChange = event => {

       
       // console.log("handleChange");
        var target = event.target;  


        var name = target.getAttribute('name');        
        const detail = { ...this.props.detail };
               
        if (name == "otherDescription") { detail.otherDescription = target.value; }               
        else if (name == "otherDescription1") { detail.otherDescription1 = target.value; }        
        else if (name == "otherDescription2") { detail.otherDescription2 = target.value; }
        this.props.onChange(this.props.index, detail,event);
    };

    render() {
        return (
            <input
                type="text"
                id={this.props.name}
                name={this.props.name}
                className="form-control"
                value={this.props.value || ''}
                onChange={this.handleChange}
                autoComplete="off"                
                disabled={this.props.disabled}
            />
        );
    }
}
//  <input type="text" className="form-control" name="baseRate" onChange={(e) => { this.handleInputChange(index, row) }} autoComplete="off" value={row.baseRate} />
export default Input;

import React, { useRef } from 'react';
import ReactDOM from 'react-dom'
import { LoadTableSkinUpload } from './LoadTableSkinUpload';
import { DatePickerInput } from 'rc-datepicker';
import { Shooting } from './Shooting';
import { Bump } from './Bump';
import { BumpAgainstBG } from './BumpAgainstBG';
import { Layout } from '../Layout';
import ReactTooltip from "react-tooltip";
import TagsInput from 'react-tagsinput';
import $ from 'jquery';
import MaterialTable from "material-table";
import moment from 'moment';
import { Email } from '../Admin/Email';
import SkinAddProjectLocation from './SkinAddProjectLocation';
//import { SkinAddProjectLocation } from './SkinAddProjectLocation';
import AddMultipleTalent from './AddMultipleTalent';
import ViewTalentDetails from './ViewTalentDetails';
import { Constants, TextBox } from '../EMS_Components';
import ExportXL from '../EMS_Components/ExportExcel'
import ProgressBar from 'react-bootstrap/ProgressBar';
import MessageWindow from './MessageWindow';
import { APIGet, APIPost } from '../EMS_Components/APICall'
import Firebase from "firebase";
import { Utils } from '../EMS_Components'
//import Firebase from 'firebase/app';
//import 'firebase/database'; // If using Firebase database
//import 'firebase/storage';  // If using Firebase storage

const DatasourceConstants = require('./DatasourceConstants.json');


export class SkinUploadHome extends React.Component {
    static displayName = SkinUploadHome.name;

    constructor(props) {
        super(props);

        this.CheckLogin();
        this.LoadShooting();
        this.AccessLevels();
        this.LoadProjects();
        this.state = {
            setReadOnlyShooting: false,
            setReadOnlyProject: false,
            CDShootCreation: true,
            ProjectList: [],
            ProjectSelOption: 0,
            ProjectSelValue: "",
            LocationList: [],
            LocationId: "",
            showLocation: true,
            isOpenProjectLocation: true,
            loadingTime: false,
            DataSkinUpload: [],
            loading: true,
            uploadExcelFile: File,
            Filelabel: 'Choose file',
            ShootingList: [],
            ShootingSelOption: 0,
            ShootingSelValue: "",
            isActionButtonDisplay: false,
            CastingDirectorList: [],
            CastingDirectorSelOption: 0,
            CastingDirectorSelValue: "",
            datePickerInputDate: new Date(),
            PopupHide: true,
            DataShootingActorsMapping: [],
            isOpensensor: true,
            popupBump: true,
            popupBumpAgainstBG: true,
            popupSendEmail: true,
            popupViewBumpAgainstBG: true,
            bumpHide: true,
            toasterCla: false,
            toaster: '',
            removeAlert: false,
            removeAlertAnotherShoot: false,
            removeAlertInsBulkPerson: false,
            removeAlertDeleteShoot: false,
            removehidden: '',
            NotificationButtonShow: false,
            removeAllSkins: false,
            chkAccessLevel: false,
            rowsBumpAgaistBG: [],
            IsEditShootEnabled: true,
            isMoreActionOpen: false,
            isModalOpen: false,
            openModalView: false,
            adjustmentsTags: [],
            compensationsTags: [],
            SelectedTalent: 0,

            allChecked: false,
            editTalentData: {},
            editMode: false,
            editUnionNU: false,
            isMultiEdit: false,
            removeAlertDeleteTalent: false,
            removeAlertDeleteSelectedTalent: false,
            MessageConversation: [],
            MessageReadResponse: [],
            FirebaseData: [],
            isARConfimation: false,
            isShootingWrapStatus: false,
            DetailedReportResult: [],
            DetailedReportReplacekeys: {},
            DetailedReportFileName: '',
            isLoggedIn: false,
            searchData: {}
        };

        this.searchInput = React.createRef();
        //const searchInput = useRef(null);


        this.handleChange = this.handleChange.bind(this);
        this.OnDateChange = this.OnDateChange.bind(this);
        //this.handleSubmit = this.handleSubmit.bind(this);
        //this.handleSubmitCD = this.handleSubmitCD.bind(this);

        this.handleAddBulkPerson = this.handleAddBulkPerson.bind(this);

        this.PopupOpen = this.PopupOpen.bind(this);
        this.PopupOpenAddProjectLocation = this.PopupOpenAddProjectLocation.bind(this);
        this.PopupCloseAddProjectLocation = this.PopupCloseAddProjectLocation.bind(this);

        this.PopupClose = this.PopupClose.bind(this);
        this.handler = this.handler.bind(this);
        this.PopupOpenBump = this.PopupOpenBump.bind(this);
        this.PopupOpenBumpAgaintBG = this.PopupOpenBumpAgaintBG.bind(this);
        this.PopupOpenSendEmail = this.PopupOpenSendEmail.bind(this);
        this.PopupSendEmailClose = this.PopupSendEmailClose.bind(this);

        this.PopupOpenViewBumpAgaintBG = this.PopupOpenViewBumpAgaintBG.bind(this);

        this.PopupNotification = this.PopupNotification.bind(this);
        this.BtnDeleteAllSkins = this.BtnDeleteAllSkins.bind(this);
        this.BtnDeleteSelectedSkins = this.BtnDeleteSelectedSkins.bind(this);



        this.PopupBumbClose = this.PopupBumbClose.bind(this);
        this.PopupBumbAgainstBGClose = this.PopupBumbAgainstBGClose.bind(this);
        this.PopupBGEditClose = this.PopupBGEditClose.bind(this);

        this.PopupViewBumbAgainstBGClose = this.PopupViewBumbAgainstBGClose.bind(this);



        this.ClearData = this.ClearData.bind(this);
        this.handleremoveCancelbttn = this.handleremoveCancelbttn.bind(this);


        this.handleSendNotification = this.handleSendNotification.bind(this);
        this.handleAnotherShoot = this.handleAnotherShoot.bind(this);


        this.handleremoveAnotherShoot = this.handleremoveAnotherShoot.bind(this);
        this.handleremoveAlertInsBulkPerson = this.handleremoveAlertInsBulkPerson.bind(this);


        this.handleremoveAllCancel = this.handleremoveAllCancel.bind(this);
        this.handleRemoveAllSkins = this.handleRemoveAllSkins.bind(this);

        this.OnClkopenModalView = this.OnClkopenModalView.bind(this);
        this.handleRemoveSpecificRow = this.handleRemoveSpecificRow.bind(this);
    }
    handleremoveAlertDeleteTalentCancelbttn = (e) => {

        this.setState({ removeAlertDeleteTalent: false });
    }
    handleRemoveSpecificRow = (e) => {

        let currentComponent = this;
        $("html, body").animate({ scrollTop: 0 }, "slow");
        let DataSkinUploads = currentComponent.state.DataSkinUpload.map(item =>
            item.id == e ? { ...item, isChecked: true } : item
        );
        currentComponent.setState({
            DataSkinUpload: currentComponent.state.DataSkinUpload.map(item =>
                item.id == e ? { ...item, isChecked: true } : item
            ),
            removeAlertDeleteSelectedTalent: true,
            //   removeAlertDeleteTalent: true,
            removehidden: e
        });

    }
    handleremoveAlertDeleteSelectedTalentCancelbttn = (e) => {

        this.setState({ removeAlertDeleteSelectedTalent: false });
    }
    BtnDeleteSelectedTalents = (e) => {

        $("html, body").animate({ scrollTop: 0 }, "slow");
        if (this.state.DataSkinUpload.filter(SkinUpload => SkinUpload.isChecked === true).length > 0) {
            this.setState({
                removeAlertDeleteSelectedTalent: true,

            });
        }
        else {
            this.toasterfun2("Please select talent to Delete");
        }
    }



    componentDidMount() {
        // need to make the initial call to getData() to populate
        // data right away
        this.getNotification();
        !Firebase.apps.length ? this.initializeFirebase() : this.getFirebaseData();

        // Now we need to make it run at a specified interval
        //this.interval(this.getNotification, DatasourceConstants.MessagingTimeInterval[0].Value*1000*60, 2); // runs every 2  mins=120000, 1 min 60000

    }
    componentDidUpdate(prevProps, prevState) {
        let currentComponent = this;
        if (prevState.FirebaseData !== this.state.FirebaseData) {
            let FirebaseData = this.state.FirebaseData;
            FirebaseData.forEach(function (snapshotValue) {
                if (snapshotValue.key == Utils.Decryption(localStorage.getItem('FirebaseKey'))) {
                    if (snapshotValue.val() && snapshotValue.val().ShootingActorsMappingID != undefined) {
                        let DataSkinUpload = currentComponent.state.DataSkinUpload
                            .filter(skinUpload =>
                                snapshotValue.val().ShootingActorsMappingID.includes(skinUpload.id)
                            );

                        currentComponent.GetDataSkinForSpecificRows(DataSkinUpload);
                    }
                }
            });

        }
    }
    getFirebaseData = () => {
        let currentComponent = this;
        let ref = Firebase.database().ref("cd-refresh-new");
        ref.on("value", snapshot => {

            currentComponent.setState({ FirebaseData: snapshot });

        });
    };
    GetDataSkinForSpecificRows(SkinUploads) {

        let currentComponent = this;
        //  var strURL = "GetShootingDetailsById_New_List";
        //console.log(JSON.stringify(SkinUploads));

        var data = {
            ShootingId: currentComponent.state.ShootingSelOption,
            ProductionAccountantDetails: SkinUploads
        }

        APIPost('AdminSkinUpload/GetShootingDetailsById_New_List', data, function (result) {
            if (result.code === 200) {

                currentComponent.setState({ loadingTime: false });
                let DataSkinUpload = currentComponent.state.DataSkinUpload;
                for (let index = 0; index < result.result.length; index++) {
                    let shootingActorsMappingID = result.result[index].id;
                    DataSkinUpload.splice(DataSkinUpload.findIndex(skinUpload => shootingActorsMappingID == skinUpload.id), 1);
                    DataSkinUpload.push(result.result[index]);
                    currentComponent.Re_RenderDataRefresh(result.result[index], "divCheckinStatus_");
                    currentComponent.Re_RenderDataRefresh(result.result[index], "divstatus_");
                    currentComponent.Re_RenderDataRefresh(result.result[index], "spanNewMessage_");
                    currentComponent.Re_RenderDataRefresh(result.result[index], "span_jobTitle");
                    currentComponent.Re_RenderDataRefresh(result.result[index], "span_role");
                    currentComponent.Re_RenderDataRefresh(result.result[index], "span_baseRate");




                }

                currentComponent.Re_renderProgressBar(DataSkinUpload);
                currentComponent.GetAPI(currentComponent.state.ShootingSelOption);
                for (let index = 0; index < result.result.length; index++) {
                    let shootingActorsMappingID = result.result[index].id;
                    if (currentComponent.state.openModalView == true && shootingActorsMappingID == currentComponent.state.SelectedTalent) {
                        ReactDOM.unmountComponentAtNode(document.getElementById('divViewTalent'));
                        let contents = currentComponent.renderViewTalents(shootingActorsMappingID);
                        ReactDOM.render(contents, document.getElementById('divViewTalent'));
                    }
                }
                Firebase.database().ref("cd-refresh-new").child(Utils.Decryption(localStorage.getItem('FirebaseKey'))).remove();


            } else {
                currentComponent.setState({ loadingTime: false });
            }
        });
    }

    GetSkinStatus(SkinUpload) {

        let status = (SkinUpload.shootingActorStatus == "Checked In" || SkinUpload.shootingActorStatus == "Information Saved")
            ?
            (
                (
                    (moment(SkinUpload.firstMealOut).isValid() && !moment(SkinUpload.firstMealIn).isValid())
                    ||
                    (moment(SkinUpload.secondMealOut).isValid() && !moment(SkinUpload.secondMealIn).isValid())
                )

                    ? "Break"
                    : SkinUpload.shootingActorStatus
            )
            : SkinUpload.shootingActorStatus;

        return status;
    }
    Re_RenderDataRefresh = (SkinUpload, contentname) => {
        let currentComponent = this;
        ReactDOM.unmountComponentAtNode(document.getElementById(contentname + SkinUpload.id));
        let contents = "";

        if (contentname === "divCheckinStatus_") {
            contents = currentComponent.RenderDataCheckinStatusRefresh(SkinUpload);
        }
        else if (contentname === "divstatus_") {
            let status = currentComponent.GetSkinStatus(SkinUpload);
            contents = currentComponent.RenderDataStatusRefresh(SkinUpload, status);
        }
        else if (contentname === "spanNewMessage_") {
            let isnewMessage = (SkinUpload.messageReadResponse.length > 0)
            contents = currentComponent.renderspanNewMessage(SkinUpload.id, isnewMessage);
            if (isnewMessage) {
                let MessageConversation = currentComponent.state.MessageConversation;
                MessageConversation.splice(MessageConversation.findIndex(skinUpload => SkinUpload.id == skinUpload.id), 1);
                for (let index = 0; index < SkinUpload.messageResponse.length; index++) {
                    MessageConversation.push(SkinUpload.messageResponse[index]);
                }
                currentComponent.setState({ MessageConversation: MessageConversation });
            }
        }
        else if (contentname === "span_jobTitle") {
            let status = currentComponent.GetSkinStatus(SkinUpload);
            contents = currentComponent.RenderDatajobTitleRefresh(SkinUpload);
        }
        else if (contentname === "span_role") {
            let status = currentComponent.GetSkinStatus(SkinUpload);
            contents = currentComponent.RenderDataRoleRefresh(SkinUpload);
        }
        else if (contentname === "span_baseRate") {
            let status = currentComponent.GetSkinStatus(SkinUpload);
            contents = currentComponent.RenderDataRateRefresh(SkinUpload);
        }
        ReactDOM.render(contents, document.getElementById(contentname + SkinUpload.id));
    }
    RenderDatajobTitleRefresh = (SkinUpload) => {
        return (
            <>
                {SkinUpload.jobTitle}
            </>
        );
    }
    RenderDataRoleRefresh = (SkinUpload) => {
        return (
            <>
                {SkinUpload.role}
            </>
        );
    }
    RenderDataRateRefresh = (SkinUpload) => {
        return (
            <>
                {SkinUpload.baseRate}
            </>
        );
    }
    RenderDataStatusRefresh = (SkinUpload, status) => {
        return (
            <span id={"span_status_" + SkinUpload.id} className="status " style={{ "color": DatasourceConstants.StatusColour.find(B => B.Status == status).Color }}>{DatasourceConstants.StatusColour.find(B => B.Status == status).OuterStatus} </span>);
    }
    RenderDataCheckinStatusRefresh = (SkinUpload) => {
        return (
            <>
                <button id={"btnCheckinStatus_" + SkinUpload.id} type="button" className="btn btn-action" data-tip data-for={SkinUpload.isCheckedIn ? "checkInTime" + SkinUpload.id : ''}>

                    <i className="fa fa-clock" aria-hidden="true" style={{
                        'color': moment(SkinUpload.checkoutTime).isValid() ? "#f77e8b"
                            : moment(SkinUpload.checkinTime).isValid() ? "#00b26f"
                                : "#b3b3b3"

                    }}></i>

                </button>
                <div className={moment(SkinUpload.checkinTime).isValid() ? "dropdown-menu dropdown-menu-xs dropdown-menu-right menuhover w-auto" : "dropdown-menu dropdown-menu-xs dropdown-menu-right menuhover w-auto hoverhide"}>
                    <ul className="navi navi-hover">
                        <li className="navi-item">
                            <div className="d-flex justify-content-between text-center">
                                <div className="mx-2" style={{ "display": moment(SkinUpload.checkinTime).isValid() ? "block" : "none" }}>
                                    <span className="navi-text text-success font-weight-bold">Check-In</span>
                                    <span className="d-block navi-text">{moment(SkinUpload.checkinTime).isValid() ? moment(SkinUpload.checkinTime).format('MM/DD/YYYY hh:mm A') : ''}</span>
                                </div>
                                <div className="mx-2" style={{ "display": moment(SkinUpload.checkoutTime).isValid() ? "block" : "none" }}>
                                    <span className="navi-text text-danger font-weight-bold">Check-Out</span>
                                    <span className="d-block navi-text">{moment(SkinUpload.checkoutTime).isValid() ? moment(SkinUpload.checkoutTime).format('MM/DD/YYYY hh:mm A') : ''}</span>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </>
        );
    }

    Re_renderProgressBar = (DataSkinUpload) => {
        let currentComponent = this;
        ReactDOM.unmountComponentAtNode(document.getElementById("divProgressBar"));
        let contents = currentComponent.renderProgressBar(DataSkinUpload);
        ReactDOM.render(contents, document.getElementById("divProgressBar"));
    }
    OnMessageShow = (e) => {

        let currentComponent = this;
        if (currentComponent.state.MessageReadResponse.filter(s => s.shootingActorsMappingID == e && s.isRead == false).length > 0) {
            let data = [];
            let obj = {
                shootAcotrsMapingId: e,
                recipientGroup: "CDAD",
                createdOnDateTime: new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toJSON()
            }

            data.push(obj);
            //console.log(JSON.stringify(data));

            APIPost('AdminSkinUpload/UpdateMessageRead/', data, function (result) {
                if (result.code === 100) {
                    currentComponent.GetAPI(currentComponent.state.ShootingSelOption);
                }
            });
        }
    }
    refreshOnSave = () => {
        let currentComponent = this;
        currentComponent.GetAPI(currentComponent.state.ShootingSelOption);

    }
    getNotification = () => {
        let currentComponent = this;
        //console.log(currentComponent.state.ShootingSelOption);
        // do something to fetch data from a remote API.
        currentComponent.GetAPI(currentComponent.state.ShootingSelOption);

    }
    getNotificationByShootingID = (ShootingID) => {
        let currentComponent = this;
        // console.log(ShootingID);
        // do something to fetch data from a remote API.
        currentComponent.GetAPI(ShootingID);
    }

    GetAPI = (ShootingSelOption) => {
        if (ShootingSelOption != 0) {
            // console.log("GetAPI");
            let currentComponent = this;
            //APIGet('AdminSkinUpload/GetShootingMessageConversation_shootingID/' + ShootingSelOption, +'/' + localStorage.getItem('CDCurrentUserId'), function (result) {
            //APIGet('AdminSkinUpload/GetShootingMessageConversation_shootingID/' + ShootingSelOption + '/' + localStorage.getItem('CDCurrentUserId') + '/' + localStorage.getItem('CDRole') + '/' + "CDAD", function (result) {
            APIGet('AdminSkinUpload/GetShootingMessageConversation_shootingID/' + ShootingSelOption + '/' + "CDAD", function (result) {
                if (result.code == 100) {


                    if (result.result.toString() != "") {

                        currentComponent.setState({ MessageConversation: result.result });

                        //console.log(result.result);

                    }
                    else { currentComponent.setState({ MessageConversation: [] }); }

                    if (result.commonResult.toString() != "") {

                        currentComponent.setState({ MessageReadResponse: result.commonResult });

                        // console.log(result.commonResult);

                    }
                    else {

                        currentComponent.setState({ MessageReadResponse: [] });
                    }


                    for (let index = 0; index < currentComponent.state.DataSkinUpload.length; index++) {
                        let DataSkinUpload = currentComponent.state.DataSkinUpload[index];
                        let shootingActorsMappingID = DataSkinUpload.id;
                        if (result.commonResult.filter(R => R.shootingActorsMappingID == shootingActorsMappingID).length > 0) {

                            ReactDOM.unmountComponentAtNode(document.getElementById('spanNewMessage_' + shootingActorsMappingID));
                            let contents = currentComponent.renderspanNewMessage(shootingActorsMappingID, true);
                            ReactDOM.render(contents, document.getElementById('spanNewMessage_' + shootingActorsMappingID));
                        }
                        else {
                            ReactDOM.unmountComponentAtNode(document.getElementById('spanNewMessage_' + shootingActorsMappingID));
                            let contents = currentComponent.renderspanNewMessage(shootingActorsMappingID, false);
                            ReactDOM.render(contents, document.getElementById('spanNewMessage_' + shootingActorsMappingID));
                        }
                    }


                }
            });
        }
    }
    renderspanNewMessage = (shootingActorsMappingID, MessageReadResponse) => {
        return (
            <span className={MessageReadResponse ? "newMessage" : ""}></span>
        );

    }
    renderCheckallRefresh = (allchecked, DataSkinUploads) => {
        return (
            <>
                <input type="checkbox" className="custom-control-input" id="selectAll" checked={allchecked}
                    onChange={(e) => this.handleDeleteChange(e, DataSkinUploads)} name='checkAll_' />
                <label className="custom-control-label" htmlFor="selectAll">&nbsp;</label>
            </>
        );
    }
    renderCheckboxRefresh = (SkinUpload, DataSkinUploads) => {
        return (
            <>
                <input className="custom-control-input" id={"selectRow1" + SkinUpload.id}
                    type="checkbox"
                    name={"check_Delete_" + SkinUpload.bgActorID}
                    value={SkinUpload.id}
                    checked={SkinUpload.isChecked}
                    onChange={(e) => this.handleDeleteChange(e, DataSkinUploads)}

                />
                <label className="custom-control-label" htmlFor={"selectRow1" + SkinUpload.id}>&nbsp;</label>
            </>);
    }
    handleDeleteChange = (e, DataSkinUploads) => {


        let currentComponent = this;
        let itemName = e.target.name;
        let itemvalue = e.target.value;
        let checked = e.target.checked;
        if (itemName == "checkAll_") {

            ReactDOM.unmountComponentAtNode(document.getElementById('div_checkAll'));
            let contents = currentComponent.renderCheckallRefresh(checked, DataSkinUploads);
            ReactDOM.render(contents, document.getElementById('div_checkAll'));
            DataSkinUploads = DataSkinUploads.map(item =>
                1 == 1 ? { ...item, isChecked: checked } : item
            );
            //console.log(DataSkinUploads);
            for (let index = 0; index < DataSkinUploads.length; index++) {
                ReactDOM.unmountComponentAtNode(document.getElementById('div_check_Delete_' + DataSkinUploads[index].id));
                ReactDOM.render(currentComponent.renderCheckboxRefresh(DataSkinUploads[index], DataSkinUploads)
                    , document.getElementById('div_check_Delete_' + DataSkinUploads[index].id));
            }

            //let sDataSkinUploads = this.state.DataSkinUpload;
            //for (let index = 0; index < DataSkinUploads.length; index++) {
            //    for (let j = 0; j < sDataSkinUploads.length; j++) {
            //        if (sDataSkinUploads[j].id == DataSkinUploads[index].id) {
            //            sDataSkinUploads[j].isChecked = DataSkinUploads[index].isChecked;
            //        }
            //    }
            //}

            currentComponent.setState({
                DataSkinUpload: DataSkinUploads,//sDataSkinUploads,
                allChecked: checked,
            });
            //  console.log(DataSkinUploads);

            // ReactDOM.unmountComponentAtNode(document.getElementById('divtable'));

            //localStorage.setItem('DataSkinUpload', JSON.stringify(DataSkinUploads));            
            //let contents = currentComponent.state.loading
            //    ? <p><em>Loading.....</em></p>
            //    : currentComponent.renderSkinUploadTable_new(DataSkinUploads, checked);
            //ReactDOM.render(contents, document.getElementById('divtable'));
        }
        else if (itemName.includes("check_Delete_")) {

            //let DataSkinUploads = this.state.DataSkinUpload;
            DataSkinUploads = DataSkinUploads.map(item =>
                //item.bgActorID.toString() === itemName.split('_')[itemName.split('_').length - 1] ? { ...item, isChecked: checked } : item
                item.id.toString() == itemvalue.toString() ? { ...item, isChecked: checked } : item
            );
            //let delDataSkinUpload = DataSkinUploads.filter(D => itemvalue == D.id.toString());
            //for (let index = 0; index < delDataSkinUpload.length; index++) {
            //    ReactDOM.unmountComponentAtNode(document.getElementById('div_check_Delete_' + delDataSkinUpload[index].id));
            //    let contents = currentComponent.state.loading
            //        ? <p><em>Loading.....</em></p>
            //        : currentComponent.renderCheckboxRefresh(delDataSkinUpload[index], DataSkinUploads);
            //    ReactDOM.render(contents, document.getElementById('div_check_Delete_' + delDataSkinUpload[index].id));
            //}
            for (let index = 0; index < DataSkinUploads.length; index++) {

                ReactDOM.unmountComponentAtNode(document.getElementById('div_check_Delete_' + DataSkinUploads[index].id));

                ReactDOM.render(currentComponent.renderCheckboxRefresh(DataSkinUploads[index], DataSkinUploads)
                    , document.getElementById('div_check_Delete_' + DataSkinUploads[index].id));
            }

            let allchecked = DataSkinUploads.every(item => item.isChecked);
            if (allchecked != currentComponent.state.allChecked) {
                ReactDOM.unmountComponentAtNode(document.getElementById('div_checkAll'));
                let contents = currentComponent.renderCheckallRefresh(allchecked, DataSkinUploads);
                ReactDOM.render(contents, document.getElementById('div_checkAll'));
            }
            //let sDataSkinUploads = this.state.DataSkinUpload;
            //for (let index = 0; index < DataSkinUploads.length; index++) {
            //    for (let j = 0; j < sDataSkinUploads.length; j++) {
            //        if (sDataSkinUploads[j].id == DataSkinUploads[index].id) {
            //            sDataSkinUploads[j].isChecked = DataSkinUploads[index].isChecked;
            //        }
            //    }
            //}
            //console.log(DataSkinUploads);
            currentComponent.setState({
                DataSkinUpload: DataSkinUploads,//sDataSkinUploads,
                allChecked: allchecked,
            });


            //  console.log(DataSkinUploads);

            //ReactDOM.unmountComponentAtNode(document.getElementById('divtable'));

            //localStorage.setItem('DataSkinUpload', JSON.stringify(DataSkinUploads));
            //let contents = currentComponent.state.loading
            //    ? <p><em>Loading.....</em></p>
            //    : currentComponent.renderSkinUploadTable_new(DataSkinUploads, currentComponent.state.allChecked);
            //ReactDOM.render(contents, document.getElementById('divtable'));
        }
        //this.setState(prevState => {
        //    let { DataSkinUpload, allChecked } = prevState;

        //    if (itemName.includes("checkAll_")) {

        //        var i = itemName.split('checkAll_')[1];
        //        DataSkinUpload = DataSkinUpload.map(item =>
        //            item.callTime.toString() === i && !item.isCheckedIn ? { ...item, isChecked: checked } : item
        //        );
        //    } else if (itemName.includes("check_Delete_")) {

        //        DataSkinUpload = DataSkinUpload.map(item =>
        //            //item.bgActorID.toString() === itemName.split('_')[itemName.split('_').length - 1] ? { ...item, isChecked: checked } : item
        //            item.id.toString() === itemvalue ? { ...item, isChecked: checked } : item
        //        );
        //        allChecked = DataSkinUpload.every(item => item.isChecked);
        //    }
        //    localStorage.setItem('DataSkinUpload', JSON.stringify(DataSkinUpload));
        //    ReactDOM.unmountComponentAtNode(document.getElementById('divtable'));


        //    let contents = currentComponent.state.loading
        //        ? <p><em>Loading.....</em></p>
        //        : currentComponent.renderSkinUploadTable(currentComponent.state.DataSkinUpload);
        //    ReactDOM.render(contents, document.getElementById('divtable'));


        //    return { DataSkinUpload, allChecked };
        //});



    }
    openModal = () => {
        let editTalentData = this.state.editTalentData;
        let editMode = this.state.editMode;
        let isMultiEdit = this.state.isMultiEdit;
        let editUnionNU = this.state.editUnionNU;

        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ isModalOpen: true })
        this.OpenAddMultipleTalent(editTalentData, editMode, isMultiEdit, editUnionNU);
        this.setState({ editMode: false })
        $('html').addClass('noscroll');

    }

    closeModal = (e) => {
        this.setState({
            isModalOpen: false,
            editTalentData: {},
            editMode: false,
            isMultiEdit: false,
            allChecked: false,
            searchData: {},
            editUnionNU: false
        })

        $('html').removeClass('noscroll');


    }
    submitModal = (DataSkinUpload) => {
        this.setState({
            isModalOpen: false,
            DataSkinUpload: DataSkinUpload,
            loadingTime: true
        })
        //if (this.state.editMode == false) {

        ReactDOM.unmountComponentAtNode(document.getElementById('divtable'));

        let contents = this.renderSkinUploadTable_new(DataSkinUpload, this.state.allChecked);

        ReactDOM.render(contents, document.getElementById('divtable'));


        //this.GetDataShootingSel(this.state.ShootingSelOption);
        $('html').removeClass('noscroll');
        // }
        this.setState({ loadingTime: false });
    }
    ValidateUpload = (DataSkinUpload) => {
        //let DataSkinUpload = this.state.DataSkinUpload;
        let isValid = true;

        for (let i = 0; i < DataSkinUpload.length; i++) {
            let role = DataSkinUpload[i].role;
            let jobTitle = DataSkinUpload[i].jobTitle;
            let rate = DataSkinUpload[i].baseRate;
            if (role == null || role == ""
                || jobTitle == null || jobTitle == ""
                || rate == null || rate == ""
            ) {
                isValid = false;
                break;
            }
        }

        return isValid;
    }
    handleUpload = () => {

        let currentComponent = this;
        var DataSkinUpload = this.state.DataSkinUpload;
        DataSkinUpload = DataSkinUpload.filter(SkinUpload => SkinUpload.isChecked === true)
        if (DataSkinUpload.length > 0) {
            if (currentComponent.ValidateUpload(DataSkinUpload)) {
                this.setState({ loadingTime: true });
                var ShootingID = this.state.ShootingSelOption;

                let reqData = {
                    ProjectDetailsForActorModels: DataSkinUpload,
                    ShootingId: ShootingID
                }

                APIPost('AdminSkinUpload/UpdateUpload', reqData, function (result) {
                    if (result.code === 200) {
                        ReactDOM.unmountComponentAtNode(document.getElementById('divtable'));

                        let contents = currentComponent.renderSkinUploadTable_new(result.result, false);

                        ReactDOM.render(contents, document.getElementById('divtable'));
                        $("html, body").animate({ scrollTop: 0 }, "slow");
                        currentComponent.toasterfun2("Uploaded Sucessfully")
                        currentComponent.setState({
                            loadingTime: false,
                            DataSkinUpload: result.result,
                            allChecked: false,
                        });


                    } else {
                        $("html, body").animate({ scrollTop: 0 }, "slow");
                        currentComponent.toasterfun2(result.message);
                        currentComponent.setState({ loadingTime: false });
                    }
                });
            }
            else {
                $("html, body").animate({ scrollTop: 0 }, "slow");
                currentComponent.toasterfun2("Please enter Role,Rate and Job Title for all the talents");
            }
        }
        else {
            currentComponent.toasterfun2("Please select talents to Upload");
        }
    }
    EditMultipleTalent = (e) => {

        $("html, body").animate({ scrollTop: 0 }, "slow");
        var DataSkinUpload = this.state.DataSkinUpload;
        DataSkinUpload = DataSkinUpload.filter(SkinUpload => SkinUpload.isChecked == true)
        if (DataSkinUpload.length > 1) {
            this.CommonBulkSingleEdit(DataSkinUpload, true)
        }
        else {
            this.toasterfun2("Please select at least 2 or more talents to edit");
        }
    }

    EditTalent = (id) => {

        let skinUpload = this.state.DataSkinUpload.filter(a => a.id == id)[0];

        this.CommonBulkSingleEdit(skinUpload, false)
    }
    GetPersonsForEdit = (skinUpload, isMultiEditMode) => {

        let Person = [];
        let baserate = "";
        if (skinUpload.baseRate != null) {//&& skinUpload.baseRate.indexOf('/') !=-1
            baserate = skinUpload.baseRate.split('/')
        }
        if (isMultiEditMode) {

            Person = {
                firstName: skinUpload.firstName,
                lastName: skinUpload.lastName,
                email: skinUpload.email,
                phone: skinUpload.phoneNumber,

                role: skinUpload.role,
                jobTitle: skinUpload.jobTitle,
                checkRefNumber: skinUpload.checkRefNumber ? skinUpload.checkRefNumber.toString() : null,
                rate: baserate.length > 0 ? baserate[0] : "",
                demoninators: baserate.length > 1 ? baserate[1] : 8,
                isWardrobeRequired: skinUpload.isWardrobeRequired ? "Yes" : "No",
                isPropertyRequired: skinUpload.isPropertyRequired ? "Yes" : "No",
                unionId: skinUpload.unionID.includes('SAG') ? true : false,
                callTime: skinUpload.callTime == null ? "" : moment(skinUpload.callTime, 'HH:mm a')._i,
                TBD: skinUpload.callTime == null ? true : false,
            }

        }
        else {
            Person = {
                firstName: skinUpload.firstName,
                lastName: skinUpload.lastName,
                email: skinUpload.email,
                phone: skinUpload.phoneNumber,

            }
        }
        return Person;
    }
    CommonBulkSingleEdit = (skinUpload, isMultiEditMode) => {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        let currentComponent = this;
        let editUnionNU = false;
        let Persons = []
        let talentData = {}

        let baserate = "";

        if (isMultiEditMode) {
            editUnionNU = !currentComponent.CompareEditTalents(skinUpload);
            skinUpload.map(SK => {
                SK.checkRefNumber =
                    (SK.checkRefNumber == null || SK.checkRefNumber.toString() == "0")
                        ? null : SK.checkRefNumber;
            });
            for (let index = 0; index < skinUpload.length; index++) {//skinUpload.length
                //skinUpload[index].checkRefNumber =
                //    skinUpload[index].checkRefNumber.toString() == "0" ? null : skinUpload[index].checkRefNumber;

                let Person = currentComponent.GetPersonsForEdit(skinUpload[index], isMultiEditMode);
                Persons.push(Person)
            }

            if (skinUpload[0].baseRate != null) {//&& skinUpload.baseRate.indexOf('/') !=-1
                baserate = skinUpload[0].baseRate.split('/')
            }
            //talentData.role = skinUpload[0].role
            //talentData.jobTitle = skinUpload[0].jobTitle
            //talentData.checkRefNumber = skinUpload[0].checkRefNumber
            //talentData.rate = baserate.length > 0 ? baserate[0] : ""
            talentData.demoninators = baserate.length > 1 ? baserate[1] : 8
            talentData.isWardrobeRequired = false;
            talentData.isPropertyRequired = false;
            talentData.unionId = skinUpload[0].unionID.includes('SAG') ? true : false
            //talentData.callTime = skinUpload[0].callTime == null ? null : moment(skinUpload[0].callTime, 'HH:mm a')
            talentData.TBD = true;

            talentData.chkRole = false;
            talentData.chkjobTitle = false;
            talentData.chkRate = false;
            talentData.chkcallTime = false;
            talentData.chkWPBulk = false;
        }
        else {

            if (skinUpload.baseRate != null) {//&& skinUpload.baseRate.indexOf('/') !=-1
                baserate = skinUpload.baseRate.split('/')
            }

            let Person = currentComponent.GetPersonsForEdit(skinUpload, isMultiEditMode);
            Persons.push(Person)

            talentData.role = skinUpload.role
            talentData.jobTitle = skinUpload.jobTitle
            talentData.checkRefNumber = skinUpload.checkRefNumber
            talentData.rate = baserate.length > 0 ? baserate[0] : ""
            talentData.demoninators = baserate.length > 1 ? baserate[1] : 8
            talentData.isWardrobeRequired = skinUpload.isWardrobeRequired
            talentData.isPropertyRequired = skinUpload.isPropertyRequired
            talentData.unionId = skinUpload.unionID.includes('SAG') ? true : false
            talentData.callTime = skinUpload.callTime == null ? new Date() : moment(skinUpload.callTime, 'HH:mm a')
            talentData.TBD = skinUpload.callTime == null ? true : false
        }
        let adjustmentData = []
        let compensationData = []
        let othersData = []
        if (!isMultiEditMode) {
            adjustmentData = currentComponent.GetEditBumpDetails(skinUpload, "adjustment");
            compensationData = currentComponent.GetEditBumpDetails(skinUpload, "compensation");
            othersData = currentComponent.GetEditBumpDetails(skinUpload, "others");
        }
        //else {

        //   let index0 = 0;
        //    let isDataValid = currentComponent.CompareEditTalents(skinUpload);;

        //    if (!isDataValid) {
        //        currentComponent.toasterfun2("Please choose the talents with same Guild");//, Adjustment, Compensations and Other Compensations
        //        return false;
        //    }
        //    //else {
        //    //    adjustmentData = currentComponent.GetEditBumpDetails(skinUpload[0], "adjustment");
        //    //    compensationData = currentComponent.GetEditBumpDetails(skinUpload[0], "compensation");
        //    //    othersData = currentComponent.GetEditBumpDetails(skinUpload[0], "others");  
        //    //}

        //}
        let EditData = {
            Person: Persons,
            formData: talentData,
            adjustmentData: adjustmentData,
            compensationData: compensationData,
            othersData: othersData
        }
        //console.log(EditData);
        this.setState({ editTalentData: EditData })
        this.setState({ isModalOpen: true })
        this.setState({ editMode: true })
        this.setState({ isMultiEdit: isMultiEditMode });
        this.OpenAddMultipleTalent(EditData, true, isMultiEditMode, editUnionNU);
    }

    GetEditBumpDetails = (skinUpload, BumpType) => {

        let adddt = {}
        let adjustmentData = []
        let compensationData = []
        let othersData = []
        if (BumpType == "adjustment") {
            adjustmentData = DatasourceConstants.BumpList.filter(x => x.type == Constants.ModalAdjustments);
            adddt = adjustmentData.find(x => x.DbColumn == "specialAbility");

            if (adddt && skinUpload.specialAbility && skinUpload.specialAbility != 0) {
                adddt.Rate = skinUpload.specialAbility;
                adddt.Description = skinUpload.specialCompensationDesc;
            }
            else
                adjustmentData.splice(adjustmentData.findIndex(x => x.Value == adddt.Value), 1)

            adddt = adjustmentData.find(x => x.DbColumn == "smokeWork");
            if (adddt && skinUpload.smokeWork && skinUpload.smokeWork != 0)
                adddt.Rate = skinUpload.smokeWork;
            else
                adjustmentData.splice(adjustmentData.findIndex(x => x.Value == adddt.Value), 1)

            adddt = adjustmentData.find(x => x.DbColumn == "bodyMakeup");
            if (adddt && skinUpload.bodyMakeup && skinUpload.bodyMakeup != 0)
                adddt.Rate = skinUpload.bodyMakeup;
            else
                adjustmentData.splice(adjustmentData.findIndex(x => x.Value == adddt.Value), 1)

            adddt = adjustmentData.find(x => x.DbColumn == "hairCompensation");
            if (adddt && skinUpload.hairCompensation && skinUpload.hairCompensation != 0)
                adddt.Rate = skinUpload.hairCompensation;
            else
                adjustmentData.splice(adjustmentData.findIndex(x => x.Value == adddt.Value), 1)

            adddt = adjustmentData.find(x => x.DbColumn == "wetWork");
            if (adddt && skinUpload.wetWork && skinUpload.wetWork != 0)
                adddt.Rate = skinUpload.wetWork;
            else
                adjustmentData.splice(adjustmentData.findIndex(x => x.Value == adddt.Value), 1)

            adddt = adjustmentData.find(x => x.DbColumn == "beardCompensation");
            if (adddt && skinUpload.beardCompensation && skinUpload.beardCompensation != 0)
                adddt.Rate = skinUpload.beardCompensation;
            else
                adjustmentData.splice(adjustmentData.findIndex(x => x.Value == adddt.Value), 1)
        }

        if (BumpType == "compensation") {

            compensationData = DatasourceConstants.BumpList.filter(x => x.type == Constants.ModalCompensations);

            adddt = compensationData.find(x => x.DbColumn == "carAllowance");
            if (adddt && skinUpload.carAllowance && skinUpload.carAllowance != 0)
                adddt.Rate = skinUpload.carAllowance;
            else
                compensationData.splice(compensationData.findIndex(x => x.Value == adddt.Value), 1)

            adddt = compensationData.find(x => x.DbColumn == "auto");
            if (adddt && skinUpload.auto && skinUpload.auto != 0)
                adddt.Rate = skinUpload.auto;
            else
                compensationData.splice(compensationData.findIndex(x => x.Value == adddt.Value), 1)

            adddt = compensationData.find(x => x.DbColumn == "dressUniformCompensation");
            if (adddt && skinUpload.dressUniformCompensation && skinUpload.dressUniformCompensation != 0)
                adddt.Rate = skinUpload.dressUniformCompensation;
            else
                compensationData.splice(compensationData.findIndex(x => x.Value == adddt.Value), 1)

            adddt = compensationData.find(x => x.DbColumn == "wardrobeQuantity");
            if (adddt && skinUpload.wardrobeQuantity && skinUpload.wardrobeQuantity != 0) {
                adddt.Rate = skinUpload.wardrobeRate;
                adddt.Quantity = skinUpload.wardrobeQuantity;
            }
            else {
                compensationData.splice(compensationData.findIndex(x => x.Value == adddt.Value), 1)
            }

            adddt = compensationData.find(x => x.DbColumn == "mileage");
            if (adddt && skinUpload.mileageMiles && skinUpload.mileageMiles != 0) {
                adddt.Rate = skinUpload.mileage;
                adddt.Quantity = skinUpload.mileageMiles;
            }
            else {
                compensationData.splice(compensationData.findIndex(x => x.Value == adddt.Value), 1)
            }

            adddt = compensationData.find(x => x.DbColumn == "interview");
            if (adddt && skinUpload.interviewHours && skinUpload.interviewHours != 0) {
                adddt.Rate = skinUpload.interview;
                adddt.Quantity = skinUpload.interviewHours;
            }
            else {
                compensationData.splice(compensationData.findIndex(x => x.Value == adddt.Value), 1)
            }

            adddt = compensationData.find(x => x.DbColumn == "fitting");
            if (adddt && skinUpload.fittingHours && skinUpload.fittingHours != 0) {
                adddt.Rate = skinUpload.fitting;
                adddt.Quantity = skinUpload.fittingHours;
            }
            else {
                compensationData.splice(compensationData.findIndex(x => x.Value == adddt.Value), 1)
            }
        }

        if (BumpType == "others") {
            othersData = DatasourceConstants.BumpList.filter(x => x.type == Constants.ModalOther);

            adddt = othersData.find(x => x.DbColumn == "other");
            if (adddt && skinUpload.other && skinUpload.other != 0) {
                adddt.Rate = skinUpload.other;
                adddt.Description = skinUpload.otherDescription;
            }
            else
                othersData.splice(othersData.findIndex(x => x.Value == adddt.Value), 1)

            adddt = othersData.find(x => x.DbColumn == "other1");
            if (adddt && skinUpload.other1 && skinUpload.other1 != 0) {
                adddt.Rate = skinUpload.other1;
                adddt.Description = skinUpload.otherDescription1;
            }
            else
                othersData.splice(othersData.findIndex(x => x.Value == adddt.Value), 1)

            adddt = othersData.find(x => x.DbColumn == "other2");
            if (adddt && skinUpload.other2 && skinUpload.other2 != 0) {
                adddt.Rate = skinUpload.other2;
                adddt.Description = skinUpload.otherDescription2;
            }
            else
                othersData.splice(othersData.findIndex(x => x.Value == adddt.Value), 1)
        }
        if (BumpType == "adjustment") { return adjustmentData }
        else if (BumpType == "compensation") { return compensationData }
        else if (BumpType == "others") { return othersData }

    }

    CompareEditTalents = (TalentData) => {
        let isMatch = true;
        if (TalentData.length > 0) {
            let mainTalent = TalentData[0];

            for (let i = 1; i <= TalentData.length - 1; i++) {
                for (var propertyName in mainTalent) {
                    if (TalentData[i].hasOwnProperty(propertyName)) {
                        if (DatasourceConstants.ComparisionList.includes(propertyName)) {
                            if (mainTalent[propertyName] != TalentData[i][propertyName]) {
                                isMatch = false;
                                this.setState({ editUnionNU: true });
                                break;
                            }
                        }
                    }
                }
            }
        }
        return isMatch;
    }

    SortTable = (key) => {

        let order = 'asc';

        if ($('.' + key).hasClass('sorting_asc')) {
            order = 'desc'
        }
        else if ($('.' + key).hasClass('sorting_desc')) {
            order = 'asc'
        }
        let DataSkinUpload = this.state.DataSkinUpload.sort(this.SortArray(key, order));

        ReactDOM.unmountComponentAtNode(document.getElementById('divtable'));
        let contents = this.renderSkinUploadTable_new(DataSkinUpload, this.state.allChecked);
        ReactDOM.render(contents, document.getElementById('divtable'));

        if (order == 'asc') {
            $('.' + key).addClass('sorting_asc')
        }
        else {
            $('.' + key).removeClass('sorting_asc')
            $('.' + key).addClass('sorting_desc')
        }

    }

    SortArray = (key, order) => {
        return function innerSort(a, b) {
            if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
                // property doesn't exist on either object
                return 0;
            }

            const varA = (typeof a[key] === 'string')
                ? a[key].toUpperCase() : a[key];
            const varB = (typeof b[key] === 'string')
                ? b[key].toUpperCase() : b[key];

            let comparison = 0;
            if (varA > varB) {
                comparison = 1;
            } else if (varA < varB) {
                comparison = -1;
            }
            return (
                (order === 'desc') ? (comparison * -1) : comparison
            );
        };
    }

    FilterTable = () => {
        let DataSkinUpload = [];
        if (this.state.searchData && this.state.searchData.searchTalent)
            DataSkinUpload = this.FilterArray(this.state.searchData.searchTalent, this.state.DataSkinUpload);
        else
            DataSkinUpload = this.state.DataSkinUpload;

        ReactDOM.unmountComponentAtNode(document.getElementById('divtable'));
        let contents = this.renderSkinUploadTable_new(DataSkinUpload, this.state.allChecked);
        ReactDOM.render(contents, document.getElementById('divtable'));

        if (this.searchInput.current != null)
            this.searchInput.current.focus();
    }

    FilterArray = (nameKey, myArray) => {
        let filteredArray = [];
        for (var i = 0; i < myArray.length; i++) {
            if (Object.values(myArray[i]).toString().toLowerCase().includes(nameKey.toLowerCase())) {
                filteredArray.push(myArray[i]);
                continue;
            }
        }
        return filteredArray;
    }

    renderMessageBox(shootingActorsMappingID) {
        //<ReactTooltip id={"msgbox" + shootingActorsMappingID} type="light" place="top" effect="solid" border={true} borderColor="#cccccc" event="click" clickable="true" >
        //</ReactTooltip>
        return (
            <ReactTooltip id={"msgbox" + shootingActorsMappingID} type="light" place="top" effect="solid" border={true} borderColor="#cccccc" event="click" clickable="true" >
                <MessageWindow shootingActorsMappingID={shootingActorsMappingID} />
            </ReactTooltip>

        );
    }
    getDuplicateArrayElements = (arr) => {
        let sorted_arr = arr.slice().sort();
        let results = [];
        for (let i = 0; i < sorted_arr.length - 1; i++) {
            if (sorted_arr[i + 1] === sorted_arr[i]) {
                results.push(sorted_arr[i]);
            }
        }
        return results;
    }

    OnmsgboxClick = (e) => {

        ReactDOM.unmountComponentAtNode(document.getElementById('divMessageBox_' + e));
        let contents = this.renderMessageBox(e);
        ReactDOM.render(contents, document.getElementById('divMessageBox_' + e));

    }
    OnClkopenModalView = (e) => {
        ReactDOM.unmountComponentAtNode(document.getElementById('divViewTalent'));
        let contents = this.renderViewTalents(e);
        ReactDOM.render(contents, document.getElementById('divViewTalent'));

        this.setState({
            openModalView: true,
            SelectedTalent: e
        })
    }

    OnClkcloseModalView = (e) => {

        this.setState({ openModalView: false })
    }



    //handleChange(adjustmentsTags) {
    //    this.setState({ adjustmentsTags })
    //}

    //handleChange(compensationsTags) {
    //    this.setState({ compensationsTags })
    //}

    /// Confimation Alert Start
    BtnDeleteAllSkins() {
        //console.log("DeleteAllSkins");
        this.setState({ removeAllSkins: true });

    }
    PopupNotification(e) {
        this.setState({ removeAlert: true });
        this.setState({ removehidden: e })
    }

    handleremoveCancelbttn() {
        this.setState({ removeAlert: false });
    }
    handleremoveAnotherShoot() {
        this.setState({ removeAlertAnotherShoot: false });
    }
    handleremoveAlertInsBulkPerson() {
        this.setState({ removeAlertInsBulkPerson: false });
    }
    handleremoveAllCancel() {
        this.setState({ removeAllSkins: false });
    }
    setStateManually = (DataSkinUpload) => {

        this.setState({ DataSkinUpload: DataSkinUpload });
    }
    //Common function Start 
    DeleteShootingActorExists(ShootingID, BGActorID, index) {
        let currentComponent = this;
        currentComponent.setState({ loadingTime: true });

        let data = {
            ShootingId: ShootingID,
            BGActorID: BGActorID
        }
        APIPost('SkinUpload/DeleteShootingActorExists', data, function (result) {
            if (result.code === 200) {
                currentComponent.toasterfun2("Deleted Sucessfully")
                currentComponent.setState({ loadingTime: false });
            } else {
                currentComponent.toasterfun2(result.message);
                currentComponent.setState({ loadingTime: false });
            }
        });
    }
    handleremoveAlertDeleteTalentOkbttn = (e) => {

        let currentComponent = this;

        var index = currentComponent.state.removehidden;


        //console.log(index);
        //console.log(currentComponent.state.DataSkinUpload);
        currentComponent.setState({ removeAlertDeleteTalent: false });

        currentComponent.SendCancelNotification(currentComponent.state.ShootingSelOption, currentComponent.state.DataSkinUpload.filter(SkinUpload => SkinUpload.id === index)[0].bgActorID);
        currentComponent.DeleteShootingActorExists(currentComponent.state.ShootingSelOption, currentComponent.state.DataSkinUpload.filter(SkinUpload => SkinUpload.id === index)[0].bgActorID, index)

        let DataSkinUpload = currentComponent.state.DataSkinUpload.filter(SkinUpload => SkinUpload.id != index);
        currentComponent.setState({
            DataSkinUpload: DataSkinUpload
        });
        ReactDOM.unmountComponentAtNode(document.getElementById('divtable'));


        let contents = this.renderSkinUploadTable_new(DataSkinUpload, currentComponent.state.allChecked);
        ReactDOM.render(contents, document.getElementById('divtable'));


    }
    handleremoveAlertDeleteSelectedTalentOkbttn = (e) => {
        let currentcomponent = this;
        //var DataSkinUpload = JSON.parse(localStorage.getItem('DataSkinUpload'));
        var DataSkinUpload = this.state.DataSkinUpload;
        DataSkinUpload = DataSkinUpload.filter(SkinUpload => SkinUpload.isChecked === true)
        if (DataSkinUpload.filter(SkinUpload => SkinUpload.isCheckedIn === true).length > 0) {
            this.toasterfun2("Please make sure the selected talents are not checkedin")
        }
        else {

            var ShootingID = this.state.ShootingSelOption;
            var BGActorID = 0;
            var index = 0;
            if (DataSkinUpload.length > 0) {
                //for (var i = 0; i < DataSkinUpload.length; i++) {
                //    BGActorID = DataSkinUpload[i].bgActorID;
                //    index = DataSkinUpload[i].id;
                //    this.SendCancelNotification(ShootingID, BGActorID, index);

                //}
                currentcomponent.setState({
                    removeAlertDeleteSelectedTalent: false,
                });

                this.SkinUploadSendCancelNotificationList(DataSkinUpload, ShootingID, function (result) {
                    ReactDOM.unmountComponentAtNode(document.getElementById('divtable'));
                    // currentComponent.GetDataShootingSel(ShootingID);
                    var DataSkinUpload = currentcomponent.state.DataSkinUpload;
                    DataSkinUpload = DataSkinUpload.filter(SkinUpload => SkinUpload.isChecked != true)


                    let contents = currentcomponent.renderSkinUploadTable_new(DataSkinUpload, false);
                    ReactDOM.render(contents, document.getElementById('divtable'));


                    //localStorage.setItem('DataSkinUpload', JSON.stringify(DataSkinUpload));
                    currentcomponent.setState({
                        DataSkinUpload: DataSkinUpload,
                        loadingTime: false,
                        allChecked: false,
                    });
                });
            }
            else {
                this.toasterfun2("Please select talent to delete");
                this.setState({ loadingTime: false });
            }
        }

    }
    BtnDeleteSelectedSkins() {

        this.setState({ loadingTime: true });
        var DataSkinUpload = this.state.DataSkinUpload;
        DataSkinUpload = DataSkinUpload.filter(SkinUpload => SkinUpload.isChecked === true)
        var ShootingID = this.state.ShootingSelOption;
        var BGActorID = 0;
        var index = 0;
        if (DataSkinUpload.length > 0) {
            for (var i = 0; i < DataSkinUpload.length; i++) {
                BGActorID = DataSkinUpload[i].bgActorID;
                index = DataSkinUpload[i].id;
                this.SendCancelNotification(ShootingID, BGActorID, index);

            }


            ReactDOM.unmountComponentAtNode(document.getElementById('divtable'));
            // currentComponent.GetDataShootingSel(ShootingID);

            var DataSkinUpload = this.state.DataSkinUpload;
            DataSkinUpload = DataSkinUpload.filter(SkinUpload => SkinUpload.isChecked != true)

            DataSkinUpload = this.getUniqueCheckinRefNo(DataSkinUpload);
            DataSkinUpload = this.getUniqueEmailID(DataSkinUpload);
            DataSkinUpload = this.getvalidateEmail(DataSkinUpload);
            DataSkinUpload = this.CheckMandatory(DataSkinUpload);
            //this.ValidateSubmitCD();


            //this.setState({ NotificationButtonShow: true });
            if (DataSkinUpload.length > 0) {
                this.setState({ bumpHide: false });

            }
            else {
                this.setState({ bumpHide: true });
                this.setState({ NotificationButtonShow: false });
            }


            let contents = this.renderSkinUploadTable_new(DataSkinUpload, this.state.allChecked);
            ReactDOM.render(contents, document.getElementById('divtable'));


            //localStorage.setItem('DataSkinUpload', JSON.stringify(DataSkinUpload));
            this.setState({ DataSkinUpload: DataSkinUpload, loadingTime: false });
        }
        else {
            this.toasterfun2("Please select talent to delete");
            this.setState({ loadingTime: false });
        }
    }
    SkinUploadSendCancelNotificationList(DataSkinUpload, ShootingID, callback) {
        let currentComponent = this;
        currentComponent.setState({ loadingTime: true });
        //console.log(JSON.stringify(DataSkinUpload));

        let reqData = {
            ProjectDetailsForActorModels: DataSkinUpload,
            ShootingId: ShootingID
        }

        currentComponent.setState({ loadingTime: true });
        APIPost('Notification/SkinUploadSendCancelNotificationList', reqData, function (result) {
            //currentComponent.setState({ loadingTime: false });
            currentComponent.toasterfun2("Deleted Sucessfully")
            callback(result);
        });
    }
    SendCancelNotification(ShootingID, BGActorID, index) {
        let currentComponent = this;
        currentComponent.setState({ loadingTime: true });
        let data = {
            ShootingId: ShootingID,
            BGActorID: BGActorID
        }

        APIPost('Notification/SkinUploadSendCancelNotification', data, function (result) {
            if (result.code === 200) {
                currentComponent.DeleteShootingActorExists(ShootingID, BGActorID, index)
                currentComponent.setState({ loadingTime: false });
            }
        });
    }

    DeleteAllSkins() {

        let currentComponent = this;
        var ShootingID = currentComponent.state.ShootingSelOption;
        APIPost('SkinUpload/DeleteAllSkins/' + ShootingID, null, function (result) {
            if (result.code === 200) {
                currentComponent.toasterfun2("Deleted all skins and sent Notification for cancellation");
                currentComponent.setState({ removeAllSkins: false });
                currentComponent.setState({ NotificationButtonShow: true });
                currentComponent.GetDataShootingSel(ShootingID);
            }
        });

    }
    SendNotificationToAllBGsOnCancellation() {
        let currentComponent = this;
        let data = {
            ShootingId: currentComponent.state.ShootingSelOption
        }

        APIPost('Notification/SkinUploadSendCancelNotificationToAll', data, function (result) {
            if (result.code === 200) { }
        });


    }
    handleRemoveAllSkins() {
        let currentComponent = this;
        currentComponent.setState({ removeAlert: false });
        currentComponent.setState({ NotificationButtonShow: false });
        currentComponent.toasterfun2("Deleting all skins and sending Notification for cancellation..");
        //currentComponent.SendNotificationToAllBGsOnCancellation()
        currentComponent.DeleteAllSkins();


    }
    handleAnotherShoot() {
        //add code for inserting
        this.CommonSubmitForSkinSave(false, this.state.ShootingSelOption);
        this.setState({ removeAlertAnotherShoot: false });
    }

    handleAddBulkPerson(event) {
        this.setState({ loadingTime: true });
        const dataForInsertion = this.state.DataSkinUpload.filter(Data => Data.bgActorID == 0);
        this.CommonFunctionInsBulkPerson(dataForInsertion);

    }
    CommonFunctionInsBulkPerson(dataForInsertion) {

        let currentComponent = this;
        currentComponent.setState({
            removeAlertInsBulkPerson: false,

        });
        //console.log(dataForInsertion);
        let reqData = {
            SkinUploads: dataForInsertion,
            ShootingId: currentComponent.state.ShootingSelOption
        }

        APIPost('AdminSkinUpload/InsertNewPersonList', reqData, function (result) {
            if (result.code == 200) {

                var DataSkinUploads = [...currentComponent.state.DataSkinUpload];

                var resultSkinupload = result.result;
                for (var idx = 0; idx < resultSkinupload.length; idx++) {
                    for (var idy = 0; idy < DataSkinUploads.length; idy++) {

                        if (!(DataSkinUploads[idy].email != resultSkinupload[idx].email)) {
                            DataSkinUploads[idy].bgActorID = resultSkinupload[idx].bgActorID;
                            break;
                        }
                    }
                }

                ReactDOM.unmountComponentAtNode(document.getElementById('divtable'));
                currentComponent.setState({ DataSkinUpload: DataSkinUploads, loading: false });
                //localStorage.setItem('DataSkinUpload', JSON.stringify(DataSkinUploads));
                let contents = currentComponent.renderSkinUploadTable_new(currentComponent.state.DataSkinUpload, currentComponent.state.allChecked);
                ReactDOM.render(contents, document.getElementById('divtable'));

                currentComponent.toasterfun2("New person(s) Added Sucessfully");
                currentComponent.CommonSubmitForSkinSave(true, currentComponent.state.ShootingSelOption)
                currentComponent.setState({ loadingTime: false, });
            } else {
                currentComponent.toasterfun2(result.message);
                currentComponent.setState({ loadingTime: false, });
            }
        });



    }

    detailedReportDownload = (e) => {

    }
    handleSendNotification() {

        let currentComponent = this;
        currentComponent.setState({ loadingTime: true });
        currentComponent.setState({ removeAlert: false });
        currentComponent.setState({ NotificationButtonShow: false });

        currentComponent.toasterfun2("Sending Notification..");
        APIPost('Notification/SkinUploadSendNotification/' + currentComponent.state.ShootingSelOption + '/Actor', null, function (result) {
            if (result.code === 200) {
                currentComponent.toasterfun2("Notification Sent Successfully");
                currentComponent.setState({ loadingTime: false });
                currentComponent.setState({ NotificationButtonShow: true });

            }
        });


    }
    /// Confimation Alert End

    //Toasted message Start
    toasterfun2(e) {
        let currentComponent = this;
        currentComponent.setState({ toasterCla: true });
        currentComponent.setState({ toaster: e });
        setTimeout(
            function () {
                currentComponent.setState({ toasterCla: false });
            }.bind(currentComponent), 6000);
    }
    //Toaster message End

    //Funcion related to Datepicker -- start
    log = (...x) => console.log(...x)       // eslint-disable-line no-console
    onClear = () => this.setState({ datePickerDate: null })
    formatdate(Datetobeformatted) {                 // format date in MM/DD/YYYY format
        return Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        }).format(new Date(Datetobeformatted));
    }
    OnDateChange(jsDate, dateString) {

        this.setState({ datePickerInputDate: this.formatdate(dateString) });

    }
    //Funcion related to Datepicker -- End


    //Function loading at page render--start 
    initializeFirebase() {
        let currentComponent = this;

        APIGet('AdminSkinUpload/GetFirebaseConfig/DV', function (result) {
            if (result.code === 100) {
                //const firebaseConfig = result.result;
                const firebaseConfig = {
                    apiKey: result.result.fireBaseConfig.apiKey,
                    authDomain: result.result.fireBaseConfig.authDomain,
                    databaseURL: result.result.fireBaseConfig.databaseURL,
                    projectId: result.result.fireBaseConfig.projectId,
                    storageBucket: result.result.fireBaseConfig.storageBucket,
                    messagingSenderId: result.result.fireBaseConfig.messagingSenderId,
                    appId: result.result.fireBaseConfig.appId,
                    measurementId: result.result.fireBaseConfig.measurementId
                };
                //Firebase.initializeApp(firebaseConfig);
                !Firebase.apps.length ? Firebase.initializeApp(firebaseConfig) : Firebase.app();

                Firebase
                    .auth()
                    .signInWithEmailAndPassword(result.result.fireBaseConfig.userName, result.result.fireBaseConfig.password)
                    .then((userCredential) => {
                        currentComponent.getFirebaseData();
                    })
                    .catch((error) => {
                        console.log("-------- error ------- " + error.code + "----- " + error.message);
                    });

                console.log(firebaseConfig);
            }
        });
    }
    CheckLogin() {

        //let userfullName = localStorage.getItem('CDCurrentUserId');
        ////let userfullName = localStorage.getItem('userfullName');
        //if (userfullName == null) {
        //    this.props.history.push("/CastingDirector/Login");
        //}

        let currentComponent = this;
        APIGet('AdminSkinUpload/CheckLogin', function (result) {
            if (result.code === 415) {
                currentComponent.setState({ isLoggedIn: false })
                // this.props.history.push("/ProductionAccountant/Login");
            }
            else {
                currentComponent.setState({ isLoggedIn: true })
            }
        });
    }
    GetDataforSendEmail(ShootingSelOption) {
        let currentComponent = this;
        currentComponent.setState({ loadingTime: true });
        var strURL = "GetShootingDetailsforAccountantByShootingID";
        var data = { ShootingId: ShootingSelOption }
        APIPost('AdminSkinUpload/' + strURL, data, function (result) {
            if (result.code === 200) {
                currentComponent.FunctionOpenPopupSendEmail(result.result)
            }
        });
    }

    GetDataforBumpAgaistBG(ShootingSelOption, isView) {
        let currentComponent = this;
        currentComponent.setState({ loadingTime: true });
        var strURL = "GetShootingDetailsforAccountantByShootingID";
        var data = { ShootingId: ShootingSelOption }
        APIPost('AdminSkinUpload/' + strURL, data, function (result) {
            if (result.code === 200) {
                if (isView) { currentComponent.FunctionOpenPopupViewBumpAgaistBG(result.result) }
                else {
                    currentComponent.FunctionOpenPopupBumpAgaistBG(result.result)
                }
            }
        });
    }

    AccessLevels() {
        let currentComponent = this;
        //let CDUsername = localStorage.getItem('CDUsername');

        APIGet('SkinUpload/GetAccessLevels/', function (result) {
            if (result.code === 200) {
                currentComponent.setState({ chkAccessLevel: false });

            } else if (result.code === 202) {
                currentComponent.setState({ chkAccessLevel: true });//true
            }
        });




    }
    GetProjectLocation(option) {
        let currentComponent = this;
        APIGet('AdminSkinUpload/GetProjectLocation/' + option, function (result) {
            if (result.code === 200) {
                if (currentComponent.state.LocationList != result.result) {
                    currentComponent.setState({
                        LocationList: result.result,
                    });
                }
            } else {
                currentComponent.setState({
                    LocationList: [],
                    LocationId: ""
                });
            }
        });

    }

    LoadProjects() {

        let currentComponent = this;
        //let userId = localStorage.getItem('CDCurrentUserId')

        APIGet('SkinUpload/GetAllProjects/', function (result) {
            if (result.code === 200) {
                currentComponent.setState({ ProjectList: result.result });
            } else {
                currentComponent.toasterfun2(result.message);
            }
        });
    }
    LoadShooting() {
        let currentComponent = this;
        //let CDUsername = localStorage.getItem('CDUsername');
        APIGet('SkinUpload/GetAllShooting/', function (result) {
            if (result.code === 200) {
                currentComponent.setState({ ShootingList: result.result });
                return result.result;
            } else {
                currentComponent.toasterfun2(result.message);
            }
        });
    }
    ///Function loading at page render--start

    //Functions to fetch/ insert data start   
    GetData(uploadExcelFile, ShootingSelOption) {

        if (ShootingSelOption == null) { ShootingSelOption = 0; }
        let currentComponent = this;
        var formdata = new FormData();
        var strURL = "AdminSkinUpload/UploadFile";
        if (uploadExcelFile.length === 2) {
            strURL = "AdminSkinUpload/GetShootingDetailsById";
        }
        else {
            formdata.append("gatewayExcel", uploadExcelFile);
            formdata.append("ShootingId", ShootingSelOption);
        }

        APIPost(strURL, uploadExcelFile.length === 2 ? { ShootingId: ShootingSelOption } : formdata, function (result) {
            if (result.code === 200) {
                currentComponent.setState({
                    DataSkinUpload: result.result,
                    loading: false,
                    bumpHide: false
                });

                var DataSkinUpload = currentComponent.getUniqueCheckinRefNo(result.result);
                DataSkinUpload = currentComponent.getUniqueEmailID(DataSkinUpload);
                DataSkinUpload = currentComponent.getvalidateEmail(DataSkinUpload);
                //localStorage.setItem('DataSkinUpload', JSON.stringify(DataSkinUpload));
                //    currentComponent.ValidateSubmitCD();

                let contents = currentComponent.renderSkinUploadTable_new(currentComponent.state.DataSkinUpload, currentComponent.state.allChecked);
                ReactDOM.render(contents, document.getElementById('divtable'));
            } else {
                currentComponent.toasterfun2(result.message);
                currentComponent.setState(
                    { isActionButtonDisplay: false }
                );
                ReactDOM.unmountComponentAtNode(document.getElementById('divtable'))
            }
            currentComponent.setState({ loadingTime: false, });
        });
    }
    CheckMandatory(SkinUploads) {

        SkinUploads.map(SkinUpload1 => {
            SkinUpload1.isMandatory = true;
        });
        SkinUploads.map(SkinUpload1 => {
            SkinUpload1.isMandatory = this.validateMandatory(SkinUpload1);
        });
        return SkinUploads;


    }
    validateMandatory(SkinUpload) {
        if (SkinUpload.firstname == "" || SkinUpload.lastname == "" || SkinUpload.phone == ""
            || SkinUpload.email == "" || SkinUpload.gender == "" || SkinUpload.unionID == ""
            || SkinUpload.role == "" || SkinUpload.jobTitle == "" || SkinUpload.baseRate == "") {
            return false;
        }
        else { return true; }
        //First name, last name, ph#, email id, gender, Guild, role, job title, rate, W/P required
    }
    getvalidateEmail(SkinUploads) {
        //for (var i = 0; i < DataSkinUpload.length; i++) {
        //    if (!this.validateEmail(DataSkinUpload[i]["email"])) {
        //        DataSkinUpload[i]["isValidEmail"] = false;                

        //    }
        //}
        SkinUploads.map(SkinUpload1 => {
            SkinUpload1.isValidEmail = true;
        });
        SkinUploads.map(SkinUpload1 => {
            SkinUpload1.isValidEmail = this.validateEmail(SkinUpload1.email);
        });
        return SkinUploads;
    }
    getUniqueCheckinRefNo(SkinUploads) {

        var uniqueCheckinRefNo = [];
        SkinUploads.map(SkinUpload1 => {
            SkinUpload1.isCheckRefNumberDuplicate = false;
        });
        SkinUploads.map(SkinUpload => {

            if (SkinUpload.checkRefNumber != "" && uniqueCheckinRefNo.indexOf(SkinUpload.checkRefNumber) === -1) {
                uniqueCheckinRefNo.push(SkinUpload.checkRefNumber)
            }
            else if (SkinUpload.checkRefNumber != "" && uniqueCheckinRefNo.indexOf(SkinUpload.checkRefNumber) != -1) {

                SkinUploads.filter(DuplicateSkinUploads => DuplicateSkinUploads.checkRefNumber == SkinUpload.checkRefNumber).map(SkinUpload1 => {
                    SkinUpload1.isCheckRefNumberDuplicate = true;
                });
                //SkinUpload.isCheckRefNumberDuplicate = true;
            }

            if (!isNaN(SkinUpload.checkRefNumber)) {
                SkinUpload.isCheckRefNumberInteger = false;
            }
        });

        //console.log(uniqueCheckinRefNo);
        return SkinUploads;
    }
    getUniqueEmailID(SkinUploads) {

        var uniqueemail = [];
        SkinUploads.map(SkinUpload1 => {
            SkinUpload1.isemailDuplicate = false;
        });
        SkinUploads.map(SkinUpload => {

            if (SkinUpload.email != "" && uniqueemail.indexOf(SkinUpload.email) === -1) {
                uniqueemail.push(SkinUpload.email)
            }
            else if (SkinUpload.email != "" && uniqueemail.indexOf(SkinUpload.email) != -1) {

                SkinUploads.filter(DuplicateSkinUploads => DuplicateSkinUploads.email == SkinUpload.email).map(SkinUpload1 => {
                    SkinUpload1.isemailDuplicate = true;
                });
                //SkinUpload.isemailDuplicate = true;
            }


        });

        //console.log(uniqueemail);
        return SkinUploads;
    }
    GetDataShootingSel(ShootingSelOption) {

        let currentComponent = this;
        var strURL = "GetShootingDetailsById_New";
        currentComponent.getNotificationByShootingID(ShootingSelOption);

        var data = { ShootingId: ShootingSelOption }

        APIPost('AdminSkinUpload/' + strURL, data, function (result) {
            var DataSkinUpload = [];
            if (result.code === 200) {

                currentComponent.setState({ loadingTime: false });
                if (result.result.length > 0) {
                    currentComponent.setState({ bumpHide: false });
                    currentComponent.setState({ NotificationButtonShow: true });
                }


                //if (result.result.filter(r => r.isCheckedIn).length > 0) {
                //    currentComponent.setState({ IsEditShootEnabled: false });
                //}

                DataSkinUpload = currentComponent.getUniqueCheckinRefNo(result.result);
                DataSkinUpload = currentComponent.getUniqueEmailID(DataSkinUpload);
                DataSkinUpload = currentComponent.getvalidateEmail(DataSkinUpload);
                DataSkinUpload = currentComponent.CheckMandatory(DataSkinUpload);

                //localStorage.setItem('DataSkinUpload', JSON.stringify(DataSkinUpload));
                //     currentComponent.ValidateSubmitCD();
                currentComponent.setState({ DataSkinUpload: DataSkinUpload, loading: false });

                let contents = currentComponent.renderSkinUploadTable_new(currentComponent.state.DataSkinUpload, false);
                ReactDOM.render(contents, document.getElementById('divtable'));

            } else {
                currentComponent.setState({ loadingTime: false });
                currentComponent.toasterfun2(result.message);
                currentComponent.setState(
                    {
                        isActionButtonDisplay: false,
                        bumpHide: true,
                        NotificationButtonShow: false

                    }
                );
                ReactDOM.unmountComponentAtNode(document.getElementById('divtable'));
                currentComponent.setState({ DataSkinUpload: [], loading: false });

                let contents = currentComponent.renderSkinUploadTable_new([], false);
                ReactDOM.render(contents, document.getElementById('divtable'));
            }
            if (DataSkinUpload.filter(rows => rows.isCheckedIn == true).length > 0) {
                currentComponent.setState({ IsEditShootEnabled: false });
            }
            else {
                currentComponent.setState({ IsEditShootEnabled: true });
            }
        });
    }
    //Functions to fetch/ insert data end


    //Page Events start
    DeleteShootConfirm = (e) => {
        this.setState({ removeAlertDeleteShoot: true });
    }
    handleremoveDeleteShoot = (e) => {
        this.setState({ removeAlertDeleteShoot: false });
    }

    handleDeleteShoot = (e) => {
        let currentComponent = this;
        let data = {
            ShootingId: this.state.ShootingSelOption
        }

        APIPost('AdminSkinUpload/DeleteShoot', data, function (result) {
            if (result.code === 200) {
                currentComponent.LoadShooting();
                currentComponent.ClearData(e);
                currentComponent.setState({ removeAlertDeleteShoot: false });
                currentComponent.toasterfun2("Shoot Deleted sucessfully");
            } else {
                currentComponent.toasterfun2(result.message);
                currentComponent.setState({ removeAlertDeleteShoot: false });
            }
        });
    }

    ClearData(event) {
        ReactDOM.unmountComponentAtNode(document.getElementById('divtable'))
        //localStorage.removeItem('DataSkinUpload');


        this.setState({
            ShootingSelValue: "",
            DataSkinUpload: [],
            loading: true,
            uploadExcelFile: File,
            Filelabel: 'Choose file',
            //ShootingList: [],
            ShootingSelOption: 0,
            isActionButtonDisplay: false,
            CastingDirectorList: [],
            CastingDirectorSelOption: 0,
            CastingDirectorSelValue: "",
            datePickerInputDate: new Date(),
            PopupHide: true,
            DataShootingActorsMapping: [],
            bumpHide: true,
            NotificationButtonShow: false,
            ProjectSelOption: 0,
            ProjectSelValue: "",
            LocationList: [],
            LocationId: "",
            Location: "",
            setReadOnlyShooting: false,
            setReadOnlyProject: false,
            IsEditShootEnabled: true,

            isMoreActionOpen: false,
            isModalOpen: false,
            openModalView: false,
            adjustmentsTags: [],
            compensationsTags: [],
            SelectedTalent: 0,

            allChecked: false,
            editTalentData: {},
            editMode: false,
            removeAlertDeleteTalent: false,
            removeAlertDeleteSelectedTalent: false,
            MessageConversation: [],
            MessageReadResponse: [],


        });

        this.LoadShooting();
    }
    CheckValidCallTimes(DataSkinUpload) {
        // let DataSkinUpload = this.state.DataSkinUpload;

        var Valid = true;
        for (var idx = 0; idx < DataSkinUpload.length; idx++) {
            if (!moment(DataSkinUpload[idx].callTime, 'h:mm A').isValid()) {
                Valid = false;
                this.toasterfun2('"' + DataSkinUpload[idx].callTime + "\" is not a valid Call time")
                break;
            }
        }
        return Valid;
    }
    ValidateSubmitCD() {


        var err = 0;
        //if (this.state.ShootingSelOption == null || this.state.ShootingSelOption == 0) {
        //    this.toasterfun2("Select shoot to upload skin")
        //    err = err + 1;

        //}
        let DataSkinUpload = this.state.DataSkinUpload;
        DataSkinUpload = this.getUniqueCheckinRefNo(DataSkinUpload);
        DataSkinUpload = this.getUniqueEmailID(DataSkinUpload);
        DataSkinUpload = this.getvalidateEmail(DataSkinUpload);
        DataSkinUpload = this.CheckMandatory(DataSkinUpload);
        if (!this.CheckValidCallTimes(DataSkinUpload)) {
            err = err + 1;
        }
        //let bgDataSkinUpload= DataSkinUpload.filter(SkinUpload => SkinUpload.bgActorID === 0)
        //if (bgDataSkinUpload.length > 0) {
        //    this.toasterfun2("There are new BG's highlighted to be be added or deleted before proceeding to submit");
        //    this.toasterfun2("There are new BG's highlighted to be be added or deleted before proceeding to submit");
        //    err = err + 1;

        //}
        var den_valid = new Array("1", "2", "3", "4", "5", "6", "7", "8", "10", "12", "14");

        let hrDataSkinUpload = DataSkinUpload.filter(SkinUpload => den_valid.indexOf(SkinUpload.baseRate.toString().split('/')[1]) < 0)
        if (hrDataSkinUpload.length > 0) {
            this.toasterfun2("There are BG's highlighted-  Base rate should have guaranteed hours 1,2,3,4,5,6,7,8,10,12 and 14.");
            err = err + 1;
        }
        let isCheckRefNumberDuplicate = DataSkinUpload.filter(SkinUpload => SkinUpload.isCheckRefNumberDuplicate === true)
        if (isCheckRefNumberDuplicate.length > 0) {
            this.toasterfun2("There are BG's highlighted with duplicate Checkin#, please change before proceeding to submit");
            err = err + 1;

        }

        let isemailDuplicate = DataSkinUpload.filter(SkinUpload => SkinUpload.isemailDuplicate === true)
        if (isemailDuplicate.length > 0) {
            this.toasterfun2("There are Talents highlighted with duplicate EmailID, please change before proceeding to submit");
            err = err + 1;

        }
        let isCheckRefNumberInteger = DataSkinUpload.filter(SkinUpload => SkinUpload.isCheckRefNumberInteger === true)
        if (isCheckRefNumberInteger.length > 0) {
            this.toasterfun2("There are BG's highlighted invalid Checkin#, please change before proceeding to submit");
            err = err + 1;

        }
        let isMandatory = DataSkinUpload.filter(SkinUpload => SkinUpload.isMandatory === false)
        if (isMandatory.length > 0) {
            this.toasterfun2("There are BG's highlighted First name, last name, ph#, email id, gender, Guild, role, job title, rate, W/P  column is mandatory, please change before proceeding to submit");
            err = err + 1;

        }

        for (var i = 0; i < DataSkinUpload.length; i++) {
            if (!this.validateEmail(DataSkinUpload[i]["email"])) {
                DataSkinUpload[i]["isValidEmail"] = false;
                err = err + 1;
                this.toasterfun2("There are Talents highlighted invalid emailid, please change before proceeding to submit");
            }
        }
        $("html, body").animate({ scrollTop: 0 }, "slow");
        return err;
    }
    validateEmail(email) {
        const emailRegex = new RegExp(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})+$/)
        email = email.replace(/\s/g, '');
        if (emailRegex.test(email) || email == '') {
            return true;
        }
        else {
            return false;
        }
    }
    ValidateSubmit() {


        var err = 0;
        if (this.state.ShootingSelOption == null || this.state.ShootingSelOption == 0) {
            this.toasterfun2("Select shoot to upload skin")
            err = err + 1;

        }
        let DataSkinUpload = this.state.DataSkinUpload;
        DataSkinUpload = this.getUniqueCheckinRefNo(DataSkinUpload);
        DataSkinUpload = this.getUniqueEmailID(DataSkinUpload);
        DataSkinUpload = this.getvalidateEmail(DataSkinUpload);
        DataSkinUpload = this.CheckMandatory(DataSkinUpload);
        if (!this.CheckValidCallTimes(DataSkinUpload)) {
            err = err + 1;
        }
        //let bgDataSkinUpload= DataSkinUpload.filter(SkinUpload => SkinUpload.bgActorID === 0)
        //if (bgDataSkinUpload.length > 0) {
        //    this.toasterfun2("There are new BG's highlighted to be be added or deleted before proceeding to submit");
        //    this.toasterfun2("There are new BG's highlighted to be be added or deleted before proceeding to submit");
        //    err = err + 1;

        //}
        var den_valid = new Array("1", "2", "3", "4", "5", "6", "7", "8", "10", "12", "14");

        let hrDataSkinUpload = DataSkinUpload.filter(SkinUpload => den_valid.indexOf(SkinUpload.baseRate.toString().split('/')[1]) < 0)
        if (hrDataSkinUpload.length > 0) {
            this.toasterfun2("There are BG's highlighted-  Base rate should have guaranteed hours 1,2,3,4,5,6,7,8,10,12 and 14.");
            err = err + 1;
        }
        let isCheckRefNumberDuplicate = DataSkinUpload.filter(SkinUpload => SkinUpload.isCheckRefNumberDuplicate === true)
        if (isCheckRefNumberDuplicate.length > 0) {
            this.toasterfun2("There are BG's highlighted with duplicate Checkin#, please change before proceeding to submit");
            err = err + 1;

        }
        let isCheckRefNumberInteger = DataSkinUpload.filter(SkinUpload => SkinUpload.isCheckRefNumberInteger === true)
        if (isCheckRefNumberInteger.length > 0) {
            this.toasterfun2("There are BG's highlighted invalid Checkin#, please change before proceeding to submit");
            err = err + 1;

        }
        let isemailDuplicate = DataSkinUpload.filter(SkinUpload => SkinUpload.isemailDuplicate === true)
        if (isemailDuplicate.length > 0) {
            this.toasterfun2("There are Talents highlighted with duplicate EmailID, please change before proceeding to submit");
            err = err + 1;

        }
        let isMandatory = DataSkinUpload.filter(SkinUpload => SkinUpload.isMandatory === false)
        if (isMandatory.length > 0) {
            this.toasterfun2("There are BG's highlighted First name, last name, ph#, email id, gender, Guild, role, job title, rate, W/P  column is mandatory, please change before proceeding to submit");
            err = err + 1;

        }
        for (var i = 0; i < DataSkinUpload.length; i++) {
            if (!this.validateEmail(DataSkinUpload[i]["email"])) {
                DataSkinUpload[i]["isValidEmail"] = false;
                err = err + 1;
            }
        }

        $("html, body").animate({ scrollTop: 0 }, "slow");
        return err;
    }
    CommonSubmitForSkinSave(TobeChecked, shootingid) {

        $("html, body").animate({ scrollTop: 0 }, "slow");
        let DataSkinUpload = this.state.DataSkinUpload;
        //event.preventDefault();
        let currentComponent = this;
        currentComponent.setState({ loadingTime: true, });
        if (currentComponent.ValidateSubmit() != 0) {
            currentComponent.setState({ loadingTime: false });
            return false;

        }
        else {
            //let data = '';
            //data = JSON.stringify(DataSkinUpload)
            //console.log(data);
            //let userfullName = localStorage.getItem('userfullName');
            let userfullName = ''; //localStorage.getItem('CDCurrentUserId');

            let reqData = {
                Username: userfullName,
                ToBeChecked: TobeChecked,
                SkinUploads: DataSkinUpload,
                ShootingId: shootingid
            }

            APIPost('AdminSkinUpload/InsertShootingActorsMapping', reqData, function (result) {
                if (result.code == 200) {
                    if (result.message == "Sucess") {
                        currentComponent.toasterfun2("Skins Uploaded Sucessfully")
                        currentComponent.setState({ bumpHide: false });
                        currentComponent.setState({ NotificationButtonShow: true });
                        ReactDOM.unmountComponentAtNode(document.getElementById('divtable'));
                        currentComponent.GetDataShootingSel(shootingid);
                    }
                    else {
                        //currentComponent.toasterfun2(result.message);
                        currentComponent.setState({ removeAlertAnotherShoot: true });
                        ReactDOM.unmountComponentAtNode(document.getElementById('divtable'));
                        currentComponent.setState({ DataSkinUpload: result.result, loading: false });
                        //localStorage.setItem('DataSkinUpload', JSON.stringify(result.result));
                        let contents = currentComponent.renderSkinUploadTable_new(currentComponent.state.DataSkinUpload, currentComponent.state.allChecked);
                        ReactDOM.render(contents, document.getElementById('divtable'));
                        return false;
                    }

                } else {
                    currentComponent.toasterfun2(result.message);
                    currentComponent.setState({ loadingTime: false, });
                }
            });

        }
    }
    createShootCD = e => {
        if (this.state.ProjectSelOption == 0 || this.state.ProjectSelOption == null || this.state.LocationId == "" || this.state.LocationId == null || this.state.datePickerInputDate == "") {// || this.state.LocationId == ""
            this.toasterfun2("Please select Show name and Shoot Date before proceeding with shoot creation");
            return false;
        }
        else {
            let currentComponent = this;
            currentComponent.setState({ loadingTime: true });


            //new Date(this.state.datePickerInputDate.getTime() - (this.state.datePickerInputDate.getTimezoneOffset() * 60000)).toJSON()
            var datePickerInputDate = new Date(this.state.datePickerInputDate);
            
            let DataShooting = {
                "movieId": parseInt(this.state.ProjectSelOption),
                "locationId": this.state.LocationId == "" ? 0 : parseInt(this.state.LocationId),
                "startDateTime": new Date(datePickerInputDate.getTime() - (datePickerInputDate.getTimezoneOffset() * 60000)).toJSON(),//new Date(this.state.datePickerInputDate),                
                "Id": parseInt(this.state.ShootingSelOption)
            }
            //console.log(JSON.stringify(DataShooting));


            APIPost('AdminSkinUpload/InsertShootingCD', DataShooting, function (result) {
                if (result.code == 500) {
                    currentComponent.toasterfun2(result.message);
                }
                else if (result.code == 409 || result.code == 100) {
                    if (result.code == 409)
                        currentComponent.toasterfun2("Shoot already exist");
                    else if (result.code == 100 && !(parseInt(currentComponent.state.ShootingSelOption) != 0)) {

                        currentComponent.toasterfun2("Shoot created");
                    }
                    else if (result.code == 100 && (parseInt(currentComponent.state.ShootingSelOption) != 0)) {
                        currentComponent.toasterfun2("Shoot Updated");
                    }

                    currentComponent.setState({
                        ShootingSelOption: result.result,
                        setReadOnlyProject: true

                    })
                    //currentComponent.GetDataShootingSel(result.result);

                    currentComponent.LoadShootingDetails(result.result)
                    var ShootingSelOption = result.result;

                    APIGet('SkinUpload/GetAllShooting/', function (result) {
                        if (result.code === 200) {
                            currentComponent.setState({
                                ShootingList: result.result,
                                ShootingSelValue: result.result.filter(ShootingList => ShootingList.id == ShootingSelOption)[0]["shootingName"]
                            });
                            return result.result;

                        } else {
                            currentComponent.toasterfun2(result.message);
                        }
                        currentComponent.setState({ loadingTime: false });
                    });
                    
                }
                else {
                    currentComponent.toasterfun2(result.message);
                    currentComponent.setState({ loadingTime: false });
                }
            });

        }
    }
    CommonSubmitForSaveCD() {

        //new Date(this.state.datePickerInputDate.getTime() - (this.state.datePickerInputDate.getTimezoneOffset() * 60000)).toJSON()
        var datePickerInputDate = new Date(this.state.datePickerInputDate);
        let currentComponent = this;
        let DataShooting = {
            "movieId": parseInt(this.state.ProjectSelOption),
            "locationId": parseInt(this.state.LocationId),
            "startDateTime": new Date(datePickerInputDate.getTime() - (datePickerInputDate.getTimezoneOffset() * 60000)).toJSON(),//new Date(this.state.datePickerInputDate),
            "CreatedBy": '', //localStorage.getItem('CDCurrentUserId'),
            "Id": parseInt(this.state.ShootingSelOption)
        }
        //console.log(JSON.stringify(DataShooting));
        if (this.state.IsEditShootEnabled) {
            APIPost('AdminSkinUpload/InsertShootingCD', DataShooting, function (result) {
                if (result.code == 409 || result.code == 100) {

                    let ShootingSelOption = currentComponent.state.ShootingSelOption;
                    //if (!(parseInt(currentComponent.state.ShootingSelOption) != 0)) {
                    //    currentComponent.toasterfun2("Shoot created");
                    //}
                    //else if ((parseInt(currentComponent.state.ShootingSelOption) != 0)) {
                    //    currentComponent.toasterfun2("Shoot Updated");
                    //}

                    if (result.code == 409 && (parseInt(currentComponent.state.ShootingSelOption) != 0) && result.result != parseInt(currentComponent.state.ShootingSelOption))
                        currentComponent.toasterfun2("Shoot already exist");
                    else if (result.code == 100 && !(parseInt(currentComponent.state.ShootingSelOption) != 0)) {
                        currentComponent.toasterfun2("Shoot created");
                    }
                    else if (result.code == 100 && (parseInt(currentComponent.state.ShootingSelOption) != 0)) {
                        currentComponent.toasterfun2("Shoot Updated");
                    }
                    currentComponent.setState({
                        ShootingSelOption: result.result,

                        // ShootingSelValue: currentComponent.state.ShootingList.filter(ShootingList => ShootingList.id == result.result)[0]["shootingName"]

                    });
                    //currentComponent.CommonSubmitForSkinSave(true, result.result);

                    //if (result.result != parseInt(ShootingSelOption)) {
                    ReactDOM.unmountComponentAtNode(document.getElementById('divShooting'));
                    //currentComponent.GetDataShootingSel(result.result);

                    currentComponent.LoadShootingDetails(result.result)
                    currentComponent.GetData(currentComponent.state.uploadExcelFile, result.result)
                    //ShootingSelOption = result.result;
                    //}

                    ShootingSelOption = result.result;
                    //let CDUsername = localStorage.getItem('CDUsername');

                    APIGet('SkinUpload/GetAllShooting', function (result) {
                        if (result.code === 200) {
                            currentComponent.setState({
                                ShootingList: result.result,
                                ShootingSelValue: result.result.filter(ShootingList => ShootingList.id == ShootingSelOption)[0]["shootingName"]
                            });
                            return result.result;

                        } else {
                            currentComponent.toasterfun2(result.message);
                        }
                    });

                    let DataSkinUpload = this.state.DataSkinUpload;
                    let bgDataSkinUpload = DataSkinUpload.filter(SkinUpload => SkinUpload.bgActorID === 0)
                    if (bgDataSkinUpload.length > 0) {
                        currentComponent.setState({ removeAlertInsBulkPerson: true });
                    }
                    currentComponent.CommonSubmitForSkinSave(true, ShootingSelOption);
                    currentComponent.setState({ loadingTime: false, });
                }

                else {
                    currentComponent.toasterfun2(result.message);
                    currentComponent.setState({ loadingTime: false, });
                }
            });




        }
        else {
            currentComponent.CommonSubmitForSkinSave(true, parseInt(currentComponent.state.ShootingSelOption));
        }
    }
    handleSubmitCD = event => {

        if (this.ValidateSubmitCD() != 0) {
            this.setState({ loadingTime: false });
            return false;
        }
        else {
            this.CommonSubmitForSaveCD(true);

        }

    }

    LoadShootingDetails(ShootingID) {
        if (ShootingID != 0) {
            let currentComponent = this;

            let data = {
                ShootingId: ShootingID
            }
            APIPost('SkinUpload/GetShootingByShootingID', data, function (result) {
                if (result.code === 200) {
                    var strstartdate = result.result[0].shootingName.toString().split(' ');
                    var startdate = strstartdate[strstartdate.length - 1];
                    var strProject = result.result[0].shootingName.toString().split(startdate.toString());
                    var Project = strProject[0].replace(result.result[0].projectLoc.location, " ").trim();
                    currentComponent.GetProjectLocation(result.result[0].movieId);
                    currentComponent.setState({
                        ProjectSelValue: Project,
                        ProjectSelOption: result.result[0].movieId,
                        Location: result.result[0].projectLoc.location,

                        LocationId: result.result[0].projectLoc.id,
                        isARConfimation: result.result[0].arConfimation,
                        isShootingWrapStatus: result.result[0].shootingWrapStatus,

                        DetailedReportResult: result.result[0].detailedReportResponseModels,
                        DetailedReportReplacekeys: JSON.parse(result.commonResult),
                        DetailedReportFileName: result.fileName,
                        // LocationId: result.result[0].locationId,

                        // //Location: result.result[0].projectLoc.location,
                        //// LocationId: result.result[0].locationId,
                        // locationId: result.result[0].locationId,
                    });

                    if (document.getElementById('div_GridActions') != null) {
                        ReactDOM.unmountComponentAtNode(document.getElementById('div_GridActions'));
                        let contents = currentComponent.renderGridActions();
                        ReactDOM.render(contents, document.getElementById('div_GridActions'));
                    }
                    currentComponent.GetDataShootingSel(ShootingID);
                    //currentComponent.GetCastingDirectors(Project, ShootingID);
                    //currentComponent.GetProjectLocation(result.result[0].movieId)

                } else {
                    currentComponent.toasterfun2(result.message);
                }
            });
        }
        else {
            this.setState({
                isEdit: false,
                ProjectSelValue: "",
                ProjectSelOption: 0,
                Location: "",
                LocationId: "",
            });
        }
    }

    renderGridActions = () => {
        return (
            <>
                <button type="button" className={this.state.isShootingWrapStatus == true ? "btn btn-addTalent add-clr-btn hide" : "btn btn-addTalent add-clr-btn"} onClick={() => this.openModal()}><i className="fa fa-user-plus"></i> Add Talents</button> {/*la la-plus*/}
                <button type="button" className={this.state.isShootingWrapStatus == true ? "btn btn-editTalent hide" : "btn btn-editTalent"} onClick={() => this.EditMultipleTalent()}><i className="la la-edit"></i> Edit Talent</button>
                <button type="button" className={this.state.isShootingWrapStatus == true ? "btn btn-deleteTalent del-clr-btn hide" : "btn btn-deleteTalent del-clr-btn"} onClick={this.BtnDeleteSelectedTalents}><i className="la la-trash"></i> Delete Talent</button>
                { /*<button type="button" className={this.state.isShootingWrapStatus == true ? "btn btn-sendNotification hide" : "btn btn-sendNotification hide"} onClick={this.handleSendNotification}><img className="align-middle" src="/css/assets/img/notification.png" /> Send Notification</button> { /*la la-envelope*/}
                <button type="button" className={this.state.isShootingWrapStatus == true ? "btn btn-sendNotification send-clr-btn hide" : "btn btn-sendNotification send-clr-btn"} onClick={this.PopupOpenSendEmail}><i className="fa fa-envelope-open"></i> Send Email</button>{ /*la la-envelope*/}
                <button type="button" className={this.state.isShootingWrapStatus == true ? "btn btn-editTalent hide" : "btn btn-editTalent"} onClick={this.PopupOpenBump}><img className="align-middle" src="/css/assets/img/compensation.png" /> Compensation</button>{ /*la la-envelope*/}

                <ExportXL className={this.state.isARConfimation == true ? "btn-download la la-download" : "btn-download la la-download hide"} style={{ marginTop: "-0.5%" }}
                    data={[
                        { SheetName: "Detailed_Report", Data: this.state.DetailedReportResult, ReplaceKeys: this.state.DetailedReportReplacekeys }
                    ]}
                    fileName={(this.state.DetailedReportFileName && this.state.DetailedReportFileName != '') ? this.state.DetailedReportFileName : 'Detailed_Report'}
                    label="Detailed Report"
                />
            </>
        );
    }
    handleSubmit = event => {
        //if (this.state.chkAccessLevel) {
        //    if (this.ValidateSubmit() != 0) {
        //        this.setState({ loadingTime: false });
        //        return false;
        //    }
        //    else {
        //        let DataSkinUpload = JSON.parse(localStorage.getItem('DataSkinUpload'));
        //        let bgDataSkinUpload = DataSkinUpload.filter(SkinUpload => SkinUpload.bgActorID === 0)
        //        if (bgDataSkinUpload.length > 0) {
        //            this.setState({ removeAlertInsBulkPerson: true });

        //        }
        //        else {
        //            this.CommonSubmitForSkinSave(true, this.state.ShootingSelOption);
        //        }
        //    }
        //}
        //else 
        {
            this.setState({ loadingTime: true });
            if (this.ValidateSubmitCD() != 0) {
                this.setState({ loadingTime: false });
                return false;
            }
            else {
                this.CommonSubmitForSaveCD(true);

            }
        }
        //if (this.state.ShootingSelOption == 0) {
        //    this.setState({ loadingTime: true });
        //    if (this.ValidateSubmitCD() != 0) {
        //        this.setState({ loadingTime: false });
        //        return false;
        //    }
        //    else {
        //        this.CommonSubmitForSaveCD(true);

        //    }
        //}
        //else if (this.state.ProjectSelValue == "") {

        //}

    }
    handleChange(event) {
        var target = event.target;
        var type = target.type;
        var name = target.getAttribute('name');



        if (type == "select-one") {

            var index = event.target.selectedIndex;
            var optionElement = event.target.childNodes[index];
            var option = optionElement.getAttribute('data-id');
            var SelValue = optionElement.getAttribute('value');

            if (name == "CastingDirector") {
                this.setState({ CastingDirectorSelOption: option });
                this.setState({ CastingDirectorSelValue: SelValue });
            }
            else if (name == "Shooting") {
                this.setState({ loadingTime: true });
                ReactDOM.unmountComponentAtNode(document.getElementById('divtable'));
                this.setState(
                    { isActionButtonDisplay: false }
                );
                this.setState({
                    ShootingSelOption: option,
                    ShootingSelValue: SelValue
                });
                var strstartdate = optionElement.getAttribute('value').toString().split(' ');
                var startdate = '';
                if (new Date(strstartdate[strstartdate.length - 1]) != "Invalid Date" && !isNaN(new Date(strstartdate[strstartdate.length - 1]))) {
                    startdate = this.formatdate(strstartdate[strstartdate.length - 1]);
                }
                this.setState({ datePickerInputDate: startdate });
                this.setState({
                    uploadExcelFile: File,
                    Filelabel: 'Choose file'
                });
                if (option != null) {
                    //this.GetDataShootingSel(option);

                    this.LoadShootingDetails(option)
                }
                else {
                    //this.GetDataShootingSel(0);

                    this.LoadShootingDetails(0)
                    this.setState({ loadingTime: false });
                }

                this.setState({
                    //ProjectSelOption: 0,
                    //ProjectSelValue: "",
                    setReadOnlyProject: true,
                });

            }
            else if (name == "Project") {
                this.setState({
                    ProjectSelOption: option,
                    ShootingSelOption: 0,
                    setReadOnlyShooting: true,
                });
                this.setState({
                    ProjectSelValue: SelValue,
                    showProject: true
                });
                //this.setState({ ShootingName: SelValue + " " + this.state.Location + " " + this.state.StartDate });
                //this.GetCastingDirectors(SelValue, 0);
                if (option != null && option != 0) {
                    this.GetProjectLocation(option);
                }
            }
            else if (name == "Location") {
                this.setState({
                    Location: SelValue,
                    LocationId: option,
                });
            }
        }
        else if (name == 'UploadFile') {
            this.setState({ loadingTime: true, });
            var uploadedExcel = event.target.files;
            var validExts = new Array(".xlsx", ".xls");
            var fileExt = uploadedExcel[0].name;
            fileExt = fileExt.substring(fileExt.lastIndexOf('.'));
            if (validExts.indexOf(fileExt) < 0) {
                this.toasterfun2("Please choose upload excel file. Invalid file selected, valid files are of " +
                    validExts.toString() + " types.");
                return false;
            }
            else {

                ReactDOM.unmountComponentAtNode(document.getElementById('divtable'));

                if (uploadedExcel.length > 0) {
                    this.setState({ uploadExcelFile: uploadedExcel[0] });
                    this.setState({ Filelabel: uploadedExcel[0].name });
                }
                var files = uploadedExcel[0];
                var ShootingSelOption = this.state.ShootingSelOption;
                if (files.length === 2) {
                    this.toasterfun2("Please choose upload excel file!");
                }
                else {
                    this.GetData(files, ShootingSelOption);
                }
            }

        }

    }
    PopupOpenAddProjectLocation(event) {

        event.preventDefault();
        var ProjectID = this.state.ProjectSelOption;
        //console.log(ProjectID);
        if (ProjectID == 0 || ProjectID == null) {
            this.toasterfun2("Choose a Project for adding Project Location");
            //this.PopupCloseAddProjectLocation(event);
            this.setState({ isOpenProjectLocation: true });
            return false;
        }
        else {
            this.setState({ isOpenProjectLocation: false });
            ReactDOM.unmountComponentAtNode(document.getElementById("divProjectLocation"));
            let contents = this.renderAddProjectLocation(ProjectID);
            ReactDOM.render(contents, document.getElementById('divProjectLocation'));
        }

    }
    PopupCloseAddProjectLocation(e) {
        //  e.preventDefault();
        this.setState({ isOpenProjectLocation: true });
        this.GetProjectLocation(this.state.ProjectSelOption)
    }
    PopupOpen(event) {
        this.setState({ isOpensensor: false });
        let currentComponent = this;
        var target = event.target;
        var type = target.type;
        var name = target.getAttribute('name');
        var ShootingId = name == "Edit" ? this.state.ShootingSelOption : 0;
        if (name == "Edit" && (ShootingId == 0 || ShootingId == null)) {
            this.toasterfun2("Choose a Shooting for edit");
            this.PopupClose(event);
        }
        else {

            let contents = this.renderShootingEdit(ShootingId);
            ReactDOM.render(contents, document.getElementById('divShooting'));
        }
    }
    PopupClose(e) {
        e.preventDefault();
        this.setState({ isOpensensor: true });
    }
    PopupOpenBump(event) {
        let currentComponent = this;
        this.setState({ popupBump: false });
        let contents = this.renderBumps(this.state.ShootingSelOption);
        ReactDOM.render(contents, document.getElementById('divBumps'));
    }
    PopupOpenBumpAgaintBG(event) {

        let currentComponent = this;
        currentComponent.setState({ popupBumpAgainstBG: false });

        currentComponent.GetDataforBumpAgaistBG(this.state.ShootingSelOption, false);


    }
    PopupOpenSendEmail(event) {

        let currentComponent = this;
        currentComponent.setState({ popupSendEmail: false });

        currentComponent.GetDataforSendEmail(this.state.ShootingSelOption);


    }
    OpenPopupViewBumpAgaistBG(rowsBumpAgaistBG) {

        let currentComponent = this;

        let contents = currentComponent.renderViewBumpsAgainstBG(rowsBumpAgaistBG);
        ReactDOM.render(contents, document.getElementById('divViewBumpsAgainstBG'));
        currentComponent.setState({ loadingTime: false });
    }
    FunctionOpenPopupBumpAgaistBG(rowsBumpAgaistBG) {

        let currentComponent = this;
        //currentComponent.setState({ rowsBumpAgaistBG: result.result });
        var DataSkinUpload = this.state.DataSkinUpload;
        DataSkinUpload = DataSkinUpload.filter(SkinUpload => SkinUpload.isChecked === true)

        //let rowsBumpAgaistBG = result.result;//this.state.rowsBumpAgaistBG;
        let DataForBumpAgaistBG = [];
        for (let i = 0; i < DataSkinUpload.length; i++) {
            DataForBumpAgaistBG.push({

                ...(rowsBumpAgaistBG.find((itmInner) => itmInner.id === DataSkinUpload[i].shootingActorsMappingID))
            }
            );
        }
        currentComponent.setState({ rowsBumpAgaistBG: DataForBumpAgaistBG });


        let contents = currentComponent.renderBumpsAgainstBG(currentComponent.state.ShootingSelOption, DataForBumpAgaistBG);
        ReactDOM.render(contents, document.getElementById('divBumpsAgainstBG'));
        currentComponent.setState({ loadingTime: false });
    }
    OpenPopupSendEmail = (e) => {
        var DataSkinUpload = this.state.DataSkinUpload;
        DataSkinUpload = DataSkinUpload.filter(SkinUpload => SkinUpload.id === e)
        this.setState({ rowsBumpAgaistBG: DataSkinUpload });
        let contents = this.renderSendEmail(this.state.ShootingSelOption, DataSkinUpload);
        ReactDOM.render(contents, document.getElementById('divSendEmail'));
        this.setState({ popupSendEmail: false });
    }
    OpenAddMultipleTalent = (editTalentData, editMode, isMultiEdit, editUnionNU) => {
        let currentComponent = this;
        ReactDOM.unmountComponentAtNode(document.getElementById("addMultipleTalent"));
        let contents = currentComponent.renderAddMultipleTalent(editTalentData, editMode, isMultiEdit, editUnionNU);
        ReactDOM.render(contents, document.getElementById("addMultipleTalent"));
    }
    renderAddMultipleTalent = (editTalentData, editMode, isMultiEdit, editUnionNU) => {
        return (
            <AddMultipleTalent action={this.submitModal} actionclose={this.closeModal} shootingId={this.state.ShootingSelOption} editData={editTalentData} isEditMode={editMode} isMultiEdit={isMultiEdit} editUnionNU={editUnionNU} allSkinData={this.state.DataSkinUpload} />
        );
    }
    FunctionOpenPopupSendEmail(rowsBumpAgaistBG) {

        let currentComponent = this;
        //currentComponent.setState({ rowsBumpAgaistBG: result.result });
        var DataSkinUpload = this.state.DataSkinUpload;
        DataSkinUpload = DataSkinUpload.filter(SkinUpload => SkinUpload.isChecked === true)

        //let rowsBumpAgaistBG = result.result;//this.state.rowsBumpAgaistBG;
        let DataForBumpAgaistBG = [];
        for (let i = 0; i < DataSkinUpload.length; i++) {
            DataForBumpAgaistBG.push({

                ...(rowsBumpAgaistBG.find((itmInner) => itmInner.id === DataSkinUpload[i].id))
            }
            );
        }
        currentComponent.setState({ rowsBumpAgaistBG: DataForBumpAgaistBG });


        let contents = currentComponent.renderSendEmail(currentComponent.state.ShootingSelOption, DataForBumpAgaistBG);
        ReactDOM.render(contents, document.getElementById('divSendEmail'));
        currentComponent.setState({ loadingTime: false });
    }
    PopupOpenViewBumpAgaintBG(event) {

        let currentComponent = this;
        this.setState({ popupViewBumpAgainstBG: false });

        this.GetDataforBumpAgaistBG(this.state.ShootingSelOption, true);

    }
    PopupViewBumbAgainstBGClose(e) {
        e.preventDefault();
        this.setState({ popupViewBumpAgainstBG: true });
        ReactDOM.unmountComponentAtNode(document.getElementById('divViewBumpsAgainstBG'))
    }

    PopupBumbAgainstBGClose(e) {
        e.preventDefault();
        this.setState({ popupBumpAgainstBG: true });
        //ReactDOM.unmountComponentAtNode(document.getElementById('divBumpsAgainstBG'))
        //ReactDOM.unmountComponentAtNode(document.getElementById('divtable'));
        //this.GetDataShootingSel(this.state.ShootingSelOption);
    }
    PopupSendEmailClose(e) {
        {/*e.preventDefault();*/ }
        this.setState({ popupSendEmail: true });
        ReactDOM.unmountComponentAtNode(document.getElementById('divSendEmail'))
        //ReactDOM.unmountComponentAtNode(document.getElementById('divtable'));
        //this.GetDataShootingSel(this.state.ShootingSelOption);
    }
    PopupBGEditClose(e) {



        ReactDOM.unmountComponentAtNode(document.getElementById('divtable'));
        this.GetDataShootingSel(this.state.ShootingSelOption);
    }

    PopupBumbClose(e) {
        e.preventDefault();
        this.setState({ popupBump: true });
        ReactDOM.unmountComponentAtNode(document.getElementById('divBumps'))
    }
    handler() {
        this.setState({
            isOpensensor: true
        });
        ReactDOM.unmountComponentAtNode(document.getElementById('divShooting'));
        this.LoadShooting();
    }
    showMoreAction = () => {
        let moreaction = this.state.isMoreActionOpen;

        this.setState({ isMoreActionOpen: moreaction == true ? true : false })
    }
    //Page Events end    

    //Render function --start
    render() {
        if (this.state.ShootingList === []) {
            this.LoadShooting();
        }
        const isActionButtonDisplay = this.state.isActionButtonDisplay;
        var landing = '/css/assets/img/ems-logo.gif';
        //+ (this.state.chkAccessLevel ? "show" : "hide")

        return (
            <>
                {this.state.isLoggedIn ?
                    <Layout FullName={this.props.FullName} FirstLetter={this.props.FirstLetter}>
                        <span className={"loading " + (this.state.loadingTime ? "show" : "hide")} ><span className="loading-div-d"></span> <img src={process.env.PUBLIC_URL + landing} /> </span>
                        <ReactTooltip type="light" place="top" effect="solid" border={true} borderColor="#cccccc" />

                        <div className={"removeAlert-div " + (this.state.removeAllSkins ? "show" : "hide")}><span className="alert-bg-div"></span>
                            <div className="alert-pos-x"> <i className="fa fa-bell" aria-hidden="true"></i> Are you sure you wish to delete all skins?
                                <div className="text-center-div">
                                    <div className="bttn add-bttn" onClick={this.handleRemoveAllSkins}>
                                        Yes

                                    </div>
                                    <div className="bttn cancel-btn" onClick={this.handleremoveAllCancel}>
                                        No
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={"removeAlert-div " + (this.state.removeAlertDeleteSelectedTalent ? "show" : "hide")}><span className="alert-bg-div"></span>
                            <div className="alert-pos-x"> <i className="fa fa-bell" aria-hidden="true"></i> Are you sure to delete selected talent(s)?
                                <div className="text-center-div">
                                    <div className="bttn add-bttn" onClick={this.handleremoveAlertDeleteSelectedTalentOkbttn}>
                                        Yes

                                    </div>
                                    <div className="bttn cancel-btn" onClick={this.handleremoveAlertDeleteSelectedTalentCancelbttn}>
                                        No
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={"removeAlert-div " + (this.state.removeAlertDeleteTalent ? "show" : "hide")}><span className="alert-bg-div"></span>
                            <div className="alert-pos-x"> <i className="fa fa-bell" aria-hidden="true"></i> Are you sure to delete selected talent(s)?
                                <div className="text-center-div">
                                    <div className="bttn add-bttn" onClick={this.handleremoveAlertDeleteTalentOkbttn}>
                                        Yes

                                    </div>
                                    <div className="bttn cancel-btn" onClick={this.handleremoveAlertDeleteTalentCancelbttn}>
                                        No
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={"removeAlert-div " + (this.state.removeAlert ? "show" : "hide")}><span className="alert-bg-div"></span>
                            <div className="alert-pos-x"> <i className="fa fa-bell" aria-hidden="true"></i> Are you sure to send notification to all BGActor?
                                <div className="text-center-div">
                                    <div className="bttn add-bttn" onClick={this.handleSendNotification}>
                                        Yes

                                    </div>
                                    <div className="bttn cancel-btn" onClick={this.handleremoveCancelbttn}>
                                        No
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className={"removeAlert-div " + (this.state.removeAlertDeleteShoot ? "show" : "hide")}><span className="alert-bg-div"></span>
                            <div className="alert-pos-x"> <i className="fa fa-bell" aria-hidden="true"></i> Are you sure to delete this shoot, deleted shoot cannot be retreived?
                                <div className="text-center-div">
                                    <div className="bttn add-bttn" onClick={this.handleDeleteShoot}>
                                        Yes

                                    </div>
                                    <div className="bttn cancel-btn" onClick={this.handleremoveDeleteShoot}>
                                        No
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"removeAlert-div " + (this.state.removeAlertAnotherShoot ? "show" : "hide")}><span className="alert-bg-div"></span>
                            <div className="alert-pos-x"> <i className="fa fa-bell" aria-hidden="true"></i>
                                Highlighted BGActors already exists in other Shooting. On pressing OK you are agreeing to add these BG's to this Shoot as well, else click Cancel and make changes accordingly.
                                <div className="text-center-div">
                                    <div className="bttn add-bttn" onClick={this.handleAnotherShoot}>
                                        OK

                                    </div>
                                    <div className="bttn cancel-btn" onClick={this.handleremoveAnotherShoot}>
                                        Cancel
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"removeAlert-div " + (this.state.removeAlertInsBulkPerson ? "show" : "hide")}><span className="alert-bg-div"></span>
                            <div className="alert-pos-x"> <i className="fa fa-bell" aria-hidden="true"></i>
                                Highlighted BGActors are new talents. On pressing OK you are agreeing to add these BG's and send a email notification, else click cancel and make changes accordingly.
                                <div className="text-center-div">
                                    <div className="bttn add-bttn" onClick={this.handleAddBulkPerson}>
                                        OK

                                    </div>
                                    <div className="bttn cancel-btn" onClick={this.handleremoveAlertInsBulkPerson}>
                                        Cancel
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"toaster-div " + (this.state.toasterCla ? "show" : "hide")}><i className="fa fa-check-circle" aria-hidden="true"></i> {this.state.toaster} </div>
                        <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                            <div className="kt-portlet kt-portlet--mobile mb-0">
                                <div className="kt-portlet__body">
                                    <div action="" className="kt-form kt-form--label-right">

                                        <div className="row" >
                                            <div className="col-lg-6 col-md-7 mb-4 plus-bttn-x">
                                                <div className="form-group">
                                                    <label>Shoot</label>
                                                    <select className="form-control" name="Shooting" onChange={this.handleChange} id="ddlShooting" name="Shooting" disabled={this.state.setReadOnlyShooting}
                                                        value={this.state.ShootingSelValue}
                                                    >
                                                        <option value="">Select Shoot</option>
                                                        {this.state.ShootingList.filter(S => S.shootingWrapStatus == false).map(Shooting =>
                                                            <option key={Shooting.id} value={Shooting.shootingName} data-id={Shooting.id}>{Shooting.shootingName}</option>
                                                        )}
                                                    </select>
                                                    <span className="btn-plus1 hide" style={{ "display": this.state.ShootingSelOption == 0 && this.state.IsEditShootEnabled ? "inline" : "none", "marginLeft": "10px" }} onClick={this.createShootCD} name="AddNew" data-tip="Create Shoot" > + </span>
                                                    <span className="btn-plus hide" onClick={this.PopupOpen} name="AddNew" data-tip="Create New Shoot"> + </span>
                                                    <span className="btn-eidt hide" onClick={this.PopupOpen} name="Edit" data-tip="Edit Shoot"> <img name="Edit" src={process.env.PUBLIC_URL + '/css/assets/img/edit-bttn.png'} alt="" /> </span>
                                                    <button type="reset" className="btn btn-sm btn-brand can-gre-btn" style={{ "marginLeft": "10px" }} onClick={this.ClearData}>Cancel</button>
                                                    <button type="button" className={(this.state.DataSkinUpload.length == 0 && this.state.ShootingSelOption != 0) ? "btn btn-deleteTalent del-clr-btn py-1" : "btn btn-deleteTalent del-clr-btn py-1 hide"} onClick={this.DeleteShootConfirm}><i className="la la-trash"></i> </button>

                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-5 mb-4 plus-bttn-x">
                                                <div className="form-group">
                                                    <label>Submitted Shoot</label>
                                                    <select className="form-control" name="Shooting" onChange={this.handleChange} id="ddlShooting" name="Shooting" disabled={this.state.setReadOnlyShooting}
                                                        value={this.state.ShootingSelValue}
                                                    >
                                                        <option value="">Select Shoot</option>
                                                        {this.state.ShootingList.filter(S => S.shootingWrapStatus == true).map(Shooting =>
                                                            <option key={Shooting.id} value={Shooting.shootingName} data-id={Shooting.id}>{Shooting.shootingName}</option>
                                                        )}
                                                    </select>
                                                </div>
                                            </div>



                                            <div className="col-lg-2 col-md-3" style={{ 'display': 'none' }}>

                                                <div className="form-group">
                                                    <label>Shoot Date</label>

                                                    <DatePickerInput
                                                        displayFormat='MM/DD/YYYY'
                                                        returnFormat='MM/DD/YYYY'
                                                        className='my-react-component'
                                                        onChange={this.OnDateChange}
                                                        onShow={this.log.bind(this, 'show')}
                                                        onHide={this.log.bind(this, 'hide')}
                                                        showOnInputClick
                                                        locale='de'
                                                        onClear={this.onClear}
                                                        value={this.state.datePickerInputDate}
                                                        style={{ "height": "39px !important" }}
                                                    />
                                                    { /*minDate={new Date()}*/}
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-3" style={{ 'display': 'none' }}>
                                                <div className="form-group">
                                                    <label>Skin List</label>
                                                    <div className="custom-file">
                                                        <input type="file" className="custom-file-input" id="customFile" name="UploadFile"
                                                            accept=".xls,.xlsx"
                                                            onChange={(event) => {
                                                                this.handleChange(event)
                                                            }}
                                                            onClick={(event) => {
                                                                event.target.value = null
                                                            }}
                                                        />
                                                        <label className="custom-file-label text-left text-truncate"
                                                            htmlFor="customFile"> {this.state.Filelabel}</label>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="AdminShootCreation" style={{ 'display': this.state.chkAccessLevel ? 'inline' : 'none' }}>
                                        </div>
                                        <div id="CDShootCreation" style={{ 'display': 'inline' }}>

                                            <div className="row">
                                                <div className="col-lg-3 col-md-3">
                                                    <div className="form-group">
                                                        <label>Show Name</label>

                                                        <select className="form-control " name="Project" id="ddlProject" name="Project" onChange={this.handleChange} value={this.state.ProjectSelValue} disabled={this.state.setReadOnlyProject || !this.state.IsEditShootEnabled}>
                                                            <option value="">Select Show Name</option>
                                                            {this.state.ProjectList.map(Project =>
                                                                <option key={Project.id} value={Project.name} data-id={Project.id}>{Project.name}</option>
                                                            )}
                                                        </select>




                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-md-5 plus-bttn-x">

                                                    <label>Location</label>


                                                    <select className="form-control " id="ddlLocation" name="Location" onChange={this.handleChange} value={this.state.Location} disabled={!this.state.IsEditShootEnabled}>

                                                        <option value="">Select Location</option>
                                                        {this.state.LocationList.map(Location =>
                                                            <option key={Location.location} value={Location.location}
                                                                data-id={Location.id} defaultValue={this.state.LocationId ? (this.state.LocationId == Location.id ? true : false) : false}>
                                                                {Location.location}</option>
                                                        )}
                                                    </select>
                                                    <span className="btn-plus1 " onClick={this.PopupOpenAddProjectLocation} name="AddNew" data-tip="Create New Location"
                                                        style={{ "display": this.state.IsEditShootEnabled ? "inline" : "none" }}
                                                    > + </span>


                                                </div>

                                                <div className="col-lg-3 col-md-3">

                                                    <div className="form-group">
                                                        <label>Shoot Date</label>

                                                        <DatePickerInput
                                                            displayFormat='MM/DD/YYYY'
                                                            returnFormat='MM/DD/YYYY'
                                                            className='my-react-component'
                                                            onChange={this.OnDateChange}
                                                            onShow={this.log.bind(this, 'show')}
                                                            onHide={this.log.bind(this, 'hide')}
                                                            showOnInputClick
                                                            locale='de'
                                                            //onClear={this.onClear}
                                                            value={this.state.datePickerInputDate}
                                                            style={{ "height": "39px !important" }}
                                                            disabled={!this.state.IsEditShootEnabled}
                                                            readOnly={true}
                                                            
                                                        />
                                                        { /*minDate={new Date()}*/}
                                                    </div>
                                                </div>
                                                <div className="col-lg-1 col-md-1" style={{ "display": this.state.ShootingSelOption == 0 && this.state.IsEditShootEnabled ? "inline" : "none" }}>
                                                    <div className="form-group full-label">
                                                        <label>&nbsp;</label>
                                                        <span className="btn-plus1" onClick={this.createShootCD} name="AddNew" data-tip="Create Shoot" > + </span>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1 col-md-1" style={{ "display": !this.state.ShootingSelOption == 0 && this.state.IsEditShootEnabled ? "inline" : "none" }}>
                                                    <div className="form-group ">
                                                        <label className="d-block text-left">Edit Show</label>
                                                        <span className="btn-eidt " onClick={this.createShootCD} name="Edit" data-tip="Edit Shoot"> <img name="Edit" src={process.env.PUBLIC_URL + '/css/assets/img/edit-bttn.png'} alt="" /> </span>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="row hide" style={{ display: 'none' }}>
                                            <div className="col-lg-12 col-md-12" style={{ 'margin': '0px 0px 10px 0px' }}>
                                                <div className="form-group">
                                                    <div className={"btn-bumb " + (this.state.bumpHide ? "hide" : "show")} onClick={this.PopupOpenBump}> Compensation </div>

                                                    <div className={"btn-bumb " + (this.state.bumpHide ? "hide" : "show")} style={{ margin: "10px 5px 0px 14px" }} onClick={this.BtnDeleteSelectedSkins}> Delete Selected Talents </div>
                                                    <div className={"btn-bumb " + (!this.state.NotificationButtonShow ? "hide" : "show")} style={{ margin: "10px 5px 0px 14px" }} onClick={this.PopupNotification}> Send Notification To Talents </div>
                                                    <div className={"btn-bumb hide"} style={{ margin: "10px 5px 0px 14px" }} onClick={this.BtnDeleteAllSkins}> Delete all Skins </div>
                                                    <div className={"btn-bumb " + (!this.state.NotificationButtonShow ? "hide" : "show")} style={{ margin: "10px 5px 0px 14px" }} onClick={this.PopupOpenBumpAgaintBG}> Compensations Against Talent </div>
                                                    <div className={"btn-bumb " + (!this.state.NotificationButtonShow ? "hide" : "show")} onClick={this.PopupOpenViewBumpAgaintBG}> View Compensations Against Talent </div>
                                                    <div className={"btn-bumb " + (!this.state.NotificationButtonShow ? "hide" : "show")} style={{ margin: "10px 5px 0px 14px" }} onClick={this.PopupOpenSendEmail}> Send Email </div>
                                                    <div className={"btn-bumb hide"} style={{ margin: "10px 5px 0px 14px" }} onClick={this.PopupOpenSendEmail}> Send Email </div>

                                                </div>

                                            </div>
                                        </div>

                                        <div className="row"></div>
                                        <div className="row" style={{ display: 'none' }}>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label>Casting Director</label>
                                                    <select className="form-control" name="CastingDirector" id="ddlCastingDirector" onChange={this.handleChange} value={this.state.CastingDirectorSelValue} >
                                                        <option value="">Select</option>
                                                        {this.state.CastingDirectorList.map(CastingDirector =>
                                                            <option key={CastingDirector.castingDirectorID} value={CastingDirector.firstname} data-id={CastingDirector.castingDirectorID}>{CastingDirector.firstname + " " + CastingDirector.middleName + " " + CastingDirector.lastname}</option>
                                                        )}
                                                    </select>

                                                </div>
                                            </div>
                                            <div className="col-lg-2 col-md-3">
                                                <div className="form-group">

                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-3">
                                                <div className="form-group">

                                                </div>
                                            </div>
                                        </div>
                                        <div className="my-3  kt-separator kt-separator--border-dashed kt-separator--space-lg kt-separator--portlet-fit" style={{ display: 'none' }}>
                                        </div>



                                        <div className="employee-wrap" id="divtable">

                                        </div>

                                    </div>
                                </div>
                                <div className="kt-portlet__foot employee-actions" style={{ display: isActionButtonDisplay ? 'block' : 'none' }}  >
                                    <div className="kt-form__actions" >
                                        <div className="row">
                                            <div className="col-lg-12">



                                                <button type="submit" className="btn btn-sm btn-brand mr-10 hide" onClick={this.handleSubmit}  >Submit</button>
                                                <button type="reset" className="btn btn-sm btn-secondary hide" onClick={this.ClearData}>Cancel</button>
                                                <button type="submit" className="btn btn-sm btn-brand hide" onClick={this.handleAddBulkPerson} style={{ display: 'none' }}>Add Bulk Person</button>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>







                        </div>
                        <div id="divShooting" className={"popup-div modal " + (this.state.isOpensensor ? "hide" : "show")}>

                        </div>

                        <div id="divProjectLocation" className={"popup-div modal with700 " + (this.state.isOpenProjectLocation ? "hide" : "show")} style={{ "width": "700px !important" }}>

                        </div>



                        <div id="divBumps" className={"popup-div modal with700 " + (this.state.popupBump ? "hide" : "show")}>

                        </div>

                        <div id="divBumpsAgainstBG" className={"popup-div modal with700 " + (this.state.popupBumpAgainstBG ? "hide" : "show")}>

                        </div>

                        <div id="divViewBumpsAgainstBG" className={"popup-div modal with700 " + (this.state.popupViewBumpAgainstBG ? "hide" : "show")}>



                        </div>
                        <div id="divSendEmail" className={"popup-div modal with700 " + (this.state.popupSendEmail ? "hide" : "show")}>

                        </div>



                        <div id="addMultipleTalent" className={"vertical-sc modal fade " + (this.state.isModalOpen ? "show" : "hide")} data-backdrop="static">

                        </div>
                        <div className={"modal-backdrop fade " + (this.state.isModalOpen ? "show" : "hide")}></div>
                        <div id="divViewTalent" className={"modal fade " + (this.state.openModalView ? "show" : "hide")}>


                        </div>

                    </Layout>
                    :
                    <></>
                }

            </>
        );
    }
    renderAddProjectLocation(ProjectID) {
        return (
            <div >
                <div className="hide-screen" onClick={this.PopupCloseAddProjectLocation}></div>
                <SkinAddProjectLocation action={this.PopupCloseAddProjectLocation} ProjectID={ProjectID} LoginUser={this.props.FullName} />
            </div >

        );
    }
    renderShootingEdit(ShootingSelOption) {



        return (
            <div >
                <div className="hide-screen" onClick={this.PopupClose}></div>
                <Shooting action={this.handler} dataFromParent={ShootingSelOption} LoginUser={this.props.FullName} />
            </div >

        );
    }
    renderBumpsAgainstBG(ShootingSelOption, DataSkinSelectedBGs) {

        return (
            <div >
                <div className="hide-screen" onClick={this.PopupBumbAgainstBGClose}></div>
                <BumpAgainstBG action={this.PopupBumbAgainstBGClose} dataFromParent={ShootingSelOption} SelectedBGs={DataSkinSelectedBGs} />
            </div >

        );
    }
    renderViewTalents(SelectedTalent) {
        return (<ViewTalentDetails action={this.OnClkcloseModalView} formData={this.state.DataSkinUpload.filter(S => S.id == SelectedTalent)} />);
    }
    renderSendEmail(ShootingSelOption, DataSkinSelectedBGs) {

        let emails = [];
        Array.prototype.forEach.call(DataSkinSelectedBGs, function (item) {
            emails.push(item.email)
        })
        return (

            <div >
                <div className="hide-screen" onClick={this.PopupSendEmailClose}></div>
                <Email action={this.PopupSendEmailClose} dataFromParent={ShootingSelOption} SelectedBGs={emails} />
            </div >

        );
    }
    // { title: 'Email', field: 'email' },         
    renderViewBumpsAgainstBG(DataBGs) {

        return (
            <div >
                <span className="loading-div"></span>
                <div className="form-builder full-width bgwhite">
                    <h4 className='pull-left full-width'>View Compensations Against Talent</h4>
                    <div className="cls-bttn" onClick={this.PopupViewBumbAgainstBGClose}>x</div>
                    <div className="col-md-12 bulk-div-x popup-bulk">
                        <MaterialTable
                            title=""
                            columns={[

                                { title: 'Last Name', field: 'lastName' },
                                { title: 'First Name', field: 'firstName' },
                                { title: 'Check In#', field: 'checkRefNumber' },
                                { field: "carAllowance", title: "Per Diem" },
                                { field: "auto", title: "Auto" },
                                { field: "specialAbility", title: "Special compensation" },
                                { field: "smokeWork", title: "Smoke work" },
                                { field: "bodyMakeup", title: "Body Make - up" },
                                { field: "hairCompensation", title: "Hair" },
                                { field: "wetWork", title: "Wet Work" },
                                { field: "beardCompensation", title: "Beard" },
                                { field: "dressUniformCompensation", title: "Dress or Uniform" },
                                { field: "other", title: "Other Amt" },
                                { field: "otherDescription", title: "Other Description" },
                                { field: "other1", title: "Other1 Amt" },
                                { field: "otherDescription1", title: "Other Description1" },
                                { field: "other2", title: "Other2 Amt" },
                                { field: "otherDescription2", title: "Other Description2" },

                            ]}
                            data={DataBGs}
                            className="mat-paginator-sticky"
                            options={{
                                search: false,
                                pageSizeOptions: [15, 30, 45],
                                pageSize: 15,
                                maxBodyHeight: 280
                            }}
                            pageSize={(DataBGs.length > 10) ? 10 : DataBGs}


                        />
                    </div>
                </div>
            </div>
        );
    }
    renderBumps(ShootingSelOption) {

        return (
            <div >
                <div className="hide-screen" onClick={this.PopupBumbClose}></div>
                <Bump action={this.PopupBumbClose} dataFromParent={ShootingSelOption} />
            </div >

        );
    }
    renderSkinUploadTable(SkinUploads) {
        //console.log(SkinUploads);
        let currentComponent = this;

        if (SkinUploads.length > 0) {
            currentComponent.setState(
                { isActionButtonDisplay: true }
            );
        }
        return (
            <div >


                <LoadTableSkinUpload dataFromParent={SkinUploads} ShootingID={this.state.ShootingSelOption} setStateManually={this.setStateManually} action={this.PopupBGEditClose} />
            </div>


        );
    }

    renderProgressBar(SkinUploads) {
        let CheckinCount = 0;
        let totalCount = 0;
        let ProgressBarValue = 0;
        if (SkinUploads != null && SkinUploads != undefined) {
            if (SkinUploads.filter(S => S.isCheckedIn === true) != undefined)
                CheckinCount = SkinUploads.filter(S => S.isCheckedIn === true).length;
            totalCount = SkinUploads.length;

            if (totalCount != 0) {
                ProgressBarValue = parseFloat((CheckinCount / totalCount) * 100).toFixed(0);
            }
        }
        return (
            <div className="check-in-count">{"Checked-In " + CheckinCount + " of " + totalCount + " Talents"}

                <ProgressBar variant="warning" now={ProgressBarValue} label={`${ProgressBarValue}%`} />
            </div>
        );
    }

    renderSkinUploadTable_new(SkinUploads, allchecked) {


        let currentComponent = this;

        //if (SkinUploads.length > 0) {
        //    currentComponent.setState(
        //        { isActionButtonDisplay: true }
        //    );
        //}
        let CheckinCount = 0;
        let totalCount = 0;
        let ProgressBarValue = 0;
        if (SkinUploads != null && SkinUploads != undefined) {
            if (SkinUploads.filter(S => S.isCheckedIn === true) != undefined)
                CheckinCount = SkinUploads.filter(S => S.isCheckedIn === true).length;
            totalCount = SkinUploads.length;

            if (totalCount != 0) {
                ProgressBarValue = parseFloat((CheckinCount / totalCount) * 100).toFixed(0);
            }
        }
        return (
            <div >
                <div className="grid-Toolbar">
                    <div id="divProgressBar">
                        <div className="check-in-count">{"Checked-In " + CheckinCount + " of " + totalCount + " Talents"}

                            <ProgressBar variant="warning" now={ProgressBarValue} label={`${ProgressBarValue}%`} />

                        </div>
                    </div>
                    <div className="grid-actions" >
                        <div className="bulk-actions" id="div_GridActions">

                            <button type="button" className={currentComponent.state.isShootingWrapStatus == true ? "btn btn-addTalent add-clr-btn hide" : "btn btn-addTalent add-clr-btn"} onClick={() => this.openModal()}><i className="fa fa-user-plus"></i> Add Talents</button> {/*la la-plus*/}
                            <button type="button" className={currentComponent.state.isShootingWrapStatus == true ? "btn btn-editTalent hide hide" : "btn btn-editTalent"} onClick={() => this.EditMultipleTalent()}><i className="la la-edit"></i> Edit Talent</button>
                            <button type="button" className={currentComponent.state.isShootingWrapStatus == true ? "btn btn-deleteTalent del-clr-btn hide" : "btn btn-deleteTalent del-clr-btn"} onClick={this.BtnDeleteSelectedTalents}><i className="la la-trash"></i> Delete Talent</button>
                            {/*  <button type="button" className={currentComponent.state.isShootingWrapStatus == true ? "btn btn-sendNotification hide" : "btn btn-sendNotification"} onClick={this.handleSendNotification}><img className="align-middle" src="/css/assets/img/notification.png" /> Send Notification</button> { /*la la-envelope*/}
                            <button type="button" className={currentComponent.state.isShootingWrapStatus == true ? "btn btn-sendNotification send-clr-btn hide" : "btn btn-sendNotification send-clr-btn"} onClick={this.PopupOpenSendEmail}><i className="fa fa-envelope-open"></i> Send Email</button>{ /*la la-envelope*/}
                            <button type="button" className={currentComponent.state.isShootingWrapStatus == true ? "btn btn-editTalent hide" : "btn btn-editTalent"} onClick={this.PopupOpenBump}><img className="align-middle" src="/css/assets/img/compensation.png" /> Compensation</button>{ /*la la-envelope*/}

                            <ExportXL className={currentComponent.state.isARConfimation == true ? "btn-download la la-download" : "btn-download la la-download hide"} style={{ marginTop: "-0.5%" }}
                                data={[
                                    { SheetName: "Detailed_Report", Data: currentComponent.state.DetailedReportResult, ReplaceKeys: currentComponent.state.DetailedReportReplacekeys }
                                ]}
                                fileName={(currentComponent.state.DetailedReportFileName && currentComponent.state.DetailedReportFileName != '') ? currentComponent.state.DetailedReportFileName : 'Detailed_Report'}
                                label="Detailed Report"
                            />
                        </div>
                        <div className="filter-actions hide">
                            <div>
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id="notCheckedIn" />
                                    <label className="custom-control-label" htmlFor="notCheckedIn">Not Checked-In</label>
                                </div>
                            </div>
                            <div>
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id="documentsCompleted" />
                                    <label className="custom-control-label" htmlFor="documentsCompleted">Documents Completed</label>
                                </div>
                            </div>
                            <div>
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id="i9Confirmed" />
                                    <label className="custom-control-label" htmlFor="i9Confirmed">I9 Confirmed</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3 offset-md-9 col-lg-2 offset-lg-10">
                        <div className="form-group">
                            <TextBox placeholder="Search" id="searchTalent" formdata={this.state.searchData} onChange={() => this.FilterTable()} refValue={this.searchInput} />
                        </div>
                    </div>
                </div>
                <div className="mainGrid table-responsive">
                    <table className="table mb110">
                        <thead>
                            <tr>
                                <th>
                                    <div className="custom-control custom-checkbox" id="div_checkAll">
                                        <input type="checkbox" className="custom-control-input" id="selectAll" checked={allchecked}
                                            onChange={(e) => this.handleDeleteChange(e, SkinUploads)} name='checkAll_' />
                                        <label className="custom-control-label" htmlFor="selectAll">&nbsp;</label>
                                    </div>
                                </th>
                                <th className="sorting checkRefNumber" onClick={() => this.SortTable('checkRefNumber')} >#</th>
                                <th className="sorting jobTitle" onClick={() => this.SortTable('jobTitle')}>Job Title</th>
                                <th className="sorting role" onClick={() => this.SortTable('role')}>Role</th>
                                <th className="sorting unionID" onClick={() => this.SortTable('unionID')}>Guild</th>
                                <th className="sorting firstName" onClick={() => this.SortTable('firstName')}>Name</th>
                                <th >I9</th>
                                <th className="sorting email" onClick={() => this.SortTable('email')}>Email Address</th>
                                <th className="sorting callTime" onClick={() => this.SortTable('callTime')}>Call Time</th>
                                <th className="sorting baseRate" onClick={() => this.SortTable('baseRate')}>Rate</th>
                                <th style={{ 'display': 'none' }}>Adjustments</th>
                                <th style={{ 'display': 'none' }}>Allowances</th>
                                <th>Message</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>

                            {SkinUploads.map(SkinUpload =>
                                <tr style={{ backgroundColor: SkinUpload.isShootActorUpload ? 'rgba(133, 217, 191, 0.2)' : 'white' }}>
                                    <td>
                                        <div className="custom-control custom-checkbox d-inline-block" id={"div_check_Delete_" + SkinUpload.id}>

                                            <input className="custom-control-input" id={"selectRow1" + SkinUpload.id}
                                                type="checkbox"
                                                name={"check_Delete_" + SkinUpload.bgActorID}
                                                value={SkinUpload.id}
                                                checked={SkinUpload.isChecked}
                                                onChange={(e) => this.handleDeleteChange(e, SkinUploads)}

                                            />
                                            <label className="custom-control-label" htmlFor={"selectRow1" + SkinUpload.id}>&nbsp;</label>
                                        </div>
                                    </td>
                                    <td className="text-nowrap">{(SkinUpload.checkRefNumber != null && SkinUpload.checkRefNumber.toString() != "0") ? SkinUpload.checkRefNumber : ""}</td>
                                    <td className="text-nowrap"><span className="talent-type-bg" id={"span_jobTitle" + SkinUpload.id}>{SkinUpload.jobTitle}</span></td>
                                    <td className="text-nowrap"><span className="talent-role-actor" id={"span_role" + SkinUpload.id}>{SkinUpload.role}</span></td>
                                    <td className="text-nowrap">{SkinUpload.unionID == "Non Union" ? <span className="badge badge-pill badge-danger">NU</span> : <span className="badge badge-pill badge-success">U</span>}</td>
                                    <td className="text-nowrap">{SkinUpload.firstName + " " + SkinUpload.lastName}
                                        <div id={"divstatus_" + SkinUpload.id}>
                                            <span className="status " style={{ "color": DatasourceConstants.StatusColour.find(B => B.Status == currentComponent.GetSkinStatus(SkinUpload)).Color }}>{DatasourceConstants.StatusColour.find(B => B.Status == currentComponent.GetSkinStatus(SkinUpload)).OuterStatus} </span>
                                        </div>
                                    </td>
                                    <td className="text-nowrap">
                                        <div className="dropdown dropdown-inline onhoveraction">
                                            <button type="button" className={SkinUpload.i9FormStatus == 0 ? "btn btn-action btn-i9 pending" : SkinUpload.i9FormStatus == 1 ? "btn btn-action btn-i9 inprogress" : "btn btn-action btn-i9 completed"} data-tip data-for={"i9Status" + SkinUpload.rowId}></button>
                                            <div className="dropdown-menu dropdown-menu-xs dropdown-menu-right menuhover">
                                                <ul className="navi navi-hover">
                                                    <li className="navi-item">
                                                        <a className="navi-link">
                                                            <span className="navi-text">{SkinUpload.i9FormStatus == 0 ? "I9 Pending" : SkinUpload.i9FormStatus == 1 ? "I9 Talent completed" : "I9 Completed"}</span>
                                                        </a>
                                                    </li>
                                                </ul>

                                            </div>
                                        </div>
                                        {/* <ReactTooltip id={"i9Status" + SkinUpload.rowId} type="light" place="top" effect="solid" border={true} borderColor="#cccccc">
                                            {SkinUpload.i9FormStatus == 0 ? "I9 Pending" : SkinUpload.i9FormStatus == 1 ? "I9 Talent completed" : "I9 Completed"}
                                        </ReactTooltip>
                                        */}
                                    </td>
                                    <td className="text-nowrap">{SkinUpload.email}</td>
                                    <td className="text-nowrap">{SkinUpload.callTime ? SkinUpload.callTime : "TBD"}</td>
                                    <td><span id={"span_baseRate" + SkinUpload.id}>{SkinUpload.baseRate}</span></td>


                                    <td className="msgbox-div"  >



                                        <a data-event='click' data-for={'overTime' + SkinUpload.id} data-tip>
                                            <i className="fa fa-comments" style={{ "color": "#85d9bf" }} aria-hidden="true"></i>
                                            <div id={"spanNewMessage_" + SkinUpload.id}>
                                                <span className={currentComponent.state.MessageReadResponse.filter(s => s.shootingActorsMappingID == SkinUpload.id && s.isRead == false).length > 0 ? "newMessage" : ""}></span>
                                            </div>
                                        </a>

                                        <ReactTooltip id={'overTime' + SkinUpload.id}
                                            type="light" place="top" effect="solid" border={true} borderColor="#cccccc"
                                            //globalEventOff="click"
                                            clickable={true}
                                            isCapture={true}
                                            ref={el => this.tooltip = el}
                                            afterShow={(e) => this.OnMessageShow(SkinUpload.id)}
                                            afterHide={(e) => this.getNotificationByShootingID(currentComponent.state.ShootingSelOption)}
                                            getContent={[() => {
                                                return (
                                                    <div>
                                                        <div className="messageHeader">Message CD - AD</div>
                                                        <button type="button" className="btn btn-action btn-chat-close m-0" onClick={() => {
                                                            this.tooltip.tooltipRef = null;
                                                            ReactTooltip.hide();
                                                        }}>
                                                            <i className="la la-close m-0 p-0" style={{ "color": "#f65062" }} aria-hidden="true"></i>
                                                        </button>
                                                        <MessageWindow shootingActorsMappingID={SkinUpload.id} modelData={currentComponent.state.MessageConversation.filter(s => s.shootingActorsMappingID == SkinUpload.id)} refresh={this.refreshOnSave} />
                                                    </div>
                                                );
                                            }, 2000]}
                                        />

                                    </td>
                                    <td className="text-nowrap">


                                        <div className="dropdown dropdown-inline onhoveraction">
                                            <div className="d-inline-block" id={"divCheckinStatus_" + SkinUpload.id}>
                                                <button id={"btnCheckinStatus_" + SkinUpload.id} type="button" className="btn btn-action" data-tip data-for={SkinUpload.isCheckedIn ? "checkInTime" + SkinUpload.id : ''}>

                                                    <i className="fa fa-clock" aria-hidden="true" style={{
                                                        'color': moment(SkinUpload.checkoutTime).isValid() ? "#f77e8b"
                                                            : moment(SkinUpload.checkinTime).isValid() ? "#00b26f"
                                                                : "#b3b3b3"

                                                    }}></i>

                                                </button>
                                                <div className={moment(SkinUpload.checkinTime).isValid() ? "dropdown-menu dropdown-menu-xs dropdown-menu-right menuhover w-auto" : "dropdown-menu dropdown-menu-xs dropdown-menu-right menuhover w-auto hoverhide"}>
                                                    <ul className="navi navi-hover">
                                                        <li className="navi-item">
                                                            <div className="d-flex justify-content-between text-center">
                                                                <div className="mx-2" style={{ "display": moment(SkinUpload.checkinTime).isValid() ? "block" : "none" }}>
                                                                    <span className="navi-text text-success font-weight-bold">Check-In</span>
                                                                    <span className="d-block navi-text">{moment(SkinUpload.checkinTime).isValid() ? moment(SkinUpload.checkinTime).format('MM/DD/YYYY hh:mm A') : ''}</span>
                                                                </div>
                                                                <div className="mx-2" style={{ "display": moment(SkinUpload.checkoutTime).isValid() ? "block" : "none" }}>
                                                                    <span className="navi-text text-danger font-weight-bold">Check-Out</span>
                                                                    <span className="d-block navi-text">{moment(SkinUpload.checkoutTime).isValid() ? moment(SkinUpload.checkoutTime).format('MM/DD/YYYY hh:mm A') : ''}</span>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                                {/*<ReactTooltip id={"checkInTime" + SkinUpload.id} type="light" place="top" effect="solid" border={true} borderColor="#cccccc">
                                                <ul className="checkInOutTime list-inline m-0">
                                                    <li className="list-inline-item">
                                                        <span className="checkIn">Check-In</span>

                                                        {moment(SkinUpload.checkinTime).isValid() ? moment(SkinUpload.checkinTime).format('MM/DD/YYYY hh:mm A') : ''}
                                                    </li>
                                                    <li className="list-inline-item" style={{ "display": moment(SkinUpload.checkoutTime).isValid() ? "inline-block" : "none" }}>
                                                        <span className="checkOut">Check-Out</span>
                                                        {moment(SkinUpload.checkoutTime).isValid() ? moment(SkinUpload.checkoutTime).format('MM/DD/YYYY hh:mm A') : ''}
                                                    </li>
                                                </ul>
                                            </ReactTooltip>*/}
                                            </div>
                                        </div>
                                        <div className="dropdown dropdown-inline onhoveraction">
                                            <button type="button" className="btn btn-action" onClick={(e) => this.OpenPopupSendEmail(SkinUpload.id)} data-tip data-for='btnMail'>
                                                <i className="fa fa-envelope-open" style={{ "color": "#d899ef" }}></i></button>
                                            <div className="dropdown-menu dropdown-menu-xs dropdown-menu-right menuhover">
                                                <ul className="navi navi-hover">
                                                    <li className="navi-item">
                                                        <a className="navi-link">
                                                            <span className="navi-text">Send Email</span>
                                                        </a>
                                                    </li>
                                                </ul>

                                            </div>
                                        </div>

                                        <div className="dropdown dropdown-inline onhoveraction">
                                            <button type="button" className="btn btn-action btn-more"></button>

                                            <div className="dropdown-menu dropdown-menu-xs dropdown-menu-right menuhover">
                                                <ul className="navi navi-hover">
                                                    <li className="navi-item">
                                                        <a className="navi-link">
                                                            <span className="navi-icon"><i className="la la-eye"></i></span>
                                                            <span className="navi-text" onClick={(e) => this.OnClkopenModalView(SkinUpload.id)}> View Details</span>
                                                        </a>
                                                    </li>
                                                    <li className={(currentComponent.state.isShootingWrapStatus) ? "navi-item hide" : "navi-item "}> { /*moment(SkinUpload.checkoutTime).isValid() || */}
                                                        <a className="navi-link">
                                                            <span className="navi-icon"><i className="la la-edit"></i></span>
                                                            <span className="navi-text" onClick={(e) => this.EditTalent(SkinUpload.id)}>Edit Details</span>
                                                        </a>
                                                    </li>
                                                    <li className={SkinUpload.isCheckedIn ? "navi-item hide" : "navi-item "}>
                                                        <a className="navi-link">
                                                            <span className="navi-icon"><i className="la la-trash"></i></span>
                                                            <span className="navi-text" onClick={(e) => this.handleRemoveSpecificRow(SkinUpload.id)}>Delete Talent</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>

                </div>




            </div>


        );
    }

    //<LoadTableSkinUpload dataFromParent={SkinUploads} ShootingID={this.state.ShootingSelOption} setStateManually={this.setStateManually} action={this.PopupBGEditClose} />
    //Render function --End

    //    <div className={this.state.isShootingWrapStatus == true ? "position-relative d-none" : "position-relative d-none"}> {/*d-inline-block*/}
    //    <button type="button" className="btn btn-upload" onClick={this.handleUpload}><i className="la la-upload"></i> Upload</button>{ /*la la-envelope*/}
    //    <div className="custom-tooltip">
    //        To make the skin <br />available to AD, <br />click on Upload
    //                    </div>
    //</div>


}





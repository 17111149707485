import React, { useState, useEffect } from "react";
import { DatePickerInput } from 'rc-datepicker';

const EMSDatePicker = (props) => {
    const [error, seterror] = useState(props.error ? props.error : {})
    const [fieldValue, setfieldValue] = useState(props.formdata[props.id] ? props.formdata[props.id] : null)

    useEffect(() => {
        seterror(props.error ? props.error : {});
        setfieldValue(props.formdata[props.id] ? props.formdata[props.id] : null);
    }, [props])

    const handleChange = (jsDate,date) => {
        props.formdata[props.id] = date
        setfieldValue(date);
    };
  
    const onClear = () => {
        props.formdata[props.id] = null
        setfieldValue(null);
    }
    return (
        <div className="form-group">
            { props.label ? <label>{props.label} {props.required ? <span className="redAlert">*</span> : <></>}</label> : <></>}
            <div>
                {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <TimePicker
                        id={props.id}
                        clearable
                        variant="inline"
                        label=""
                        value={fieldValue}
                        onChange={handleChange}
                        autoOk
                    />
                </MuiPickersUtilsProvider> */}

                <DatePickerInput
                    displayFormat='MM/DD/YYYY'
                    returnFormat='MM/DD/YYYY'
                    className='my-react-component'
                    onChange={handleChange}                  
                    showOnInputClick
                    locale='de'
                    onClear={onClear}
                    value={fieldValue}
                    minDate={props.minDate ? props.minDate : null}
                    style={{ "height": "39px !important" }}
                />
            </div>
            <div className="redAlert noPosition">{error[props.id]}</div>
        </div>
    );
}

export default EMSDatePicker
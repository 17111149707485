import React, { useState } from 'react'
import MaterialTable from 'material-table'
import { Layout } from '../../Layout';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers';
import ExportExcel from '../../EMS_Components/ExportExcel'
import { APIPost } from '../../EMS_Components/APICall'
const moment  = require('moment')

const TalentStatus = (props) => {
    const [toaster, settoaster] = useState('')
    const [toasterCla, settoasterCla] = useState(false)

    const [fromDate, setfromDate] = useState(new Date())
    const [toDate, settoDate] = useState(new Date())
    const [reportResult, setreportResult] = useState([])
    const [replaceKeys, setreplaceKeys] = useState({})

    const toasterfun2 = (e) => {
        settoasterCla(true);
        settoaster(e);
        setTimeout(
            function () {
                settoasterCla(false);
            }.bind(this), 4000);
    }
    const handleFromDateChange = (date) => {
        setfromDate(date);
    };

    const handleToDateChange = (date) => {
        settoDate(date);
    };

    const getReport = () => {
        let data = {
            FromDate: fromDate,
            ToDate : toDate
        }
        APIPost('Report/GetReport_TalentStatus', data, function (result) {
            if (result.code === 100) {
                setreportResult(result.result)
                setreplaceKeys(JSON.parse(result.commonResult))
                toasterfun2(result.message);
            } else {
                toasterfun2(result.message);
            }
        });
    }


    return (
        <Layout>
            <div className={"toaster-div " + (toasterCla ? "show" : "hide")}><i className="fa fa-check-circle" aria-hidden="true"></i> {toaster} </div>
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid mt-5">
                <div className="kt-portlet kt-portlet--mobile mb-0">
                    <div className="kt-portlet__body">
                        <div className="kt-form kt-form--label-right">
                            <div className="row">
                                <div className="col-md-3">
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            margin="normal"
                                            id="fromDate"
                                            label="From Date"
                                            format="MM/dd/yyyy"
                                            variant="inline"
                                            value={fromDate}
                                            onChange={handleFromDateChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                            autoOk={true}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div className="col-md-3">
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            margin="normal"
                                            id="toDate"
                                            label="To Date"
                                            format="MM/dd/yyyy"
                                            variant="inline"
                                            value={toDate}
                                            onChange={handleToDateChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                            autoOk={true}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div className="col-md-3">
                                    <button type="button" className="btn btn-sm btn-primary mt-3" onClick={getReport}>Submit</button>
                                    <ExportExcel className="pull-right" style={{ marginTop: "-0.5%" }}
                                        data={[
                                            { SheetName: "Talent_Status", Data: reportResult, ReplaceKeys: replaceKeys}
                                        ]}
                                        fileName={'Talent_Status'}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <MaterialTable
                                        title="Talent Status"
                                        columns={[
                                            { title: "Project Name", field: "projectName" },
                                            { title: "First Name", field: "firstName" },
                                            { title: "Middle Name", field: "middleName" },
                                            { title: "Last Name", field: "lastName" },
                                            { title: "UserName", field: "userName" },                                            
                                            { title: "Email", field: "email" },
                                            { title: "Phone", field: "phone" },
                                            { title: "Skin Uploaded Date", field: "skinuploadeddate" },
                                            { title: "Registration Status", field: "registrationStatus" },
                                            { title: "I9 Status", field: "i9Status" },
                                            { title: "I9 Completed on", field: "i9completedon" },
                                            { title: "I9 AD Status", field: "i9ADStatus" },
                                            { title: "I9 AD Completed on", field: "i9ADcompletedon" },
                                            { title: "W4Status", field: "w4Status" },
                                            { title: "W4 Completed on", field: "w4completedon" }
                                        ]}
                                        data={reportResult}
                                        class="mat-paginator-sticky"
                                        title=""
                                        options={{
                                            pageSizeOptions: [5, 10, 20, 50],
                                            pageSize: 10,
                                            exportButton: { csv: true },
                                            exportAllData: true,
                                            exportFileName: 'Talent_Status_' + moment(fromDate).format('MMDDYYYY') + '_' + moment(toDate).format('MMDDYYYY')
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout >
    )

}
export default TalentStatus
import React, { Component } from 'react';
import { Route } from 'react-router';
import { Switch } from 'react-router-dom';
//import { CastLogin } from './components/SkinUpload/Login';
//import { PALogin } from './components/ProdAccountant/Login';
import { SkinUploadHome } from './components/SkinUpload/SkinUploadHome';
import { TimeCardFileExport } from './components/ProdAccountant/TimeCardFileExport';
import { AddUser_Role } from './components/Admin/AddUser_Role';
import { BGActorAudit } from './components/Admin/BGActorAudit';
import { CommonLogin } from './components/Login';
import { LoginDetailsReport } from './components/Admin/LoginDetailsReport';
import { UnlockUser } from './components/Admin/UnlockUser';
import { UpdateDetails } from './components/Admin/UpdateDetails';
import { ShootWiseVoucher } from './components/Admin/ShootWiseVoucher';
import DownloadForms from './components/Admin/DownloadForms';
import { Email } from './components/Admin/Email';
import { AddBGActor, AddProjectLocation } from './components/Admin'
import { TalentStatus } from './components/Admin/Reports'
import { CategoryReport } from './components/Admin/Reports'
import ErrorPage from './components/ErrorPage'
import PageNotFound from './components/PageNotFound'
import ForgotPassword from './components/Admin/ForgotPassword';



import './custom.css'
import 'rc-datepicker/lib/style.css';

export default class App extends Component {
    static displayName = App.name;
    constructor() {
        super();
        this.refreshCacheAndReload();
    }

    refreshCacheAndReload = () => {
        console.log("Cache clear has been started");
        if (caches) {
            // Service worker cache should be cleared with caches.delete()
            caches.keys().then(function (names) {
                for (let name of names) caches.delete(name);
            });
            console.log("Cache cleared");
        }
        let isloaded = localStorage.getItem('isReloaded');
        if (!isloaded) {
            localStorage.setItem('isReloaded', true);
            window.location.reload(true);
        }
        // delete browser cache and hard reload
    }

    getBuild = () => {

        let currentcomponent = this;
        fetch('/meta.json')
            .then(function (response) {
                return response.json();
            }).then(function (meta) {
                const latestVersion = meta.version;
                console.log('App Version - ' + latestVersion);

                let data = JSON.stringify({ "AppName": 'CD', "LatestVersion": latestVersion })
                fetch('api/login/CheckVersion', {
                    method: 'post',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: data
                }).then(function (response) {
                    return response.json();
                }).then(function (result) {
                    if (result.statusMessage == "Success") {
                        console.log('Latest Version - ' + result.result.currentLiveVersion);
                        console.log('Force Update - ' + result.result.doForceUpdate);
                        if (result.result.doForceUpdate == true) {
                            currentcomponent.refreshCacheAndReload();
                        }
                    }
                }).catch(function (error) {

                    console.log("-------- error ------- " + error);
                });
            }).catch(function (error) {
                console.log("File Not Found")
                let isloaded = localStorage.getItem('isReloaded');
                if (!isloaded) {
                    currentcomponent.refreshCacheAndReload();
                }
            });;

    }


    render() {
        return (


            <Switch>               

                <Route
                    exact
                    path='/CastingDirector/Login'
                    render={props => (
                        <CommonLogin {...props} />
                    )}
                />
                <Route
                    exact
                    path='/SkinUploadHome'
                    render={props => (
                        <SkinUploadHome {...props} />
                    )} />




                <Route
                    exact
                    path='/ProductionAccountant/login'
                    render={props => (
                        <CommonLogin {...props} />
                    )} />
                <Route
                    exact
                    path='/TimeCardFileExport'
                    render={props => (
                        <TimeCardFileExport {...props} />
                    )} />
                <Route
                    exact
                    path='/AddUser_Role'
                    render={props => (
                        <AddUser_Role {...props} />
                    )} />

                <Route
                    exact
                    path='/BGActorAudit'
                    render={props => (
                        <BGActorAudit {...props} />
                    )} />

                <Route
                    exact
                    path='/LoginDetailsReport'
                    render={props => (
                        <LoginDetailsReport {...props} />
                    )} />

                <Route
                    exact
                    path='/UnlockUser'
                    render={props => (
                        <UnlockUser {...props} />
                    )} />

                <Route
                    exact
                    path='/UpdateDetails'
                    render={props => (
                        <UpdateDetails {...props} />
                    )} />

                <Route
                    exact
                    path='/AddBGActor/:shootingId/:userId'
                    render={props => (
                        <AddBGActor {...props} />
                    )} />


                <Route
                    exact
                    path='/AddProjectLocation'
                    render={props => (
                        <AddProjectLocation {...props} />
                    )} />
                <Route
                    exact
                    path='/Email'
                    render={props => (
                        <Email {...props} />
                    )} />

                <Route
                    exact
                    path='/TalentStatus'
                    render={props => (
                        <TalentStatus {...props} />
                    )} />

                <Route
                    exact
                    path='/ShootWiseVoucher'
                    render={props => (
                        <ShootWiseVoucher {...props} />
                    )} />
                <Route
                    exact
                    path='/CategoryReport'
                    render={props => (
                        <CategoryReport {...props} />
                    )} />

                <Route
                    exact
                    path='/DownloadForms'
                    render={props => (
                        <DownloadForms {...props} />
                    )} />

                
                <Route
                    exact
                    path='/ForgotPassword'
                    render={props => (
                        <ForgotPassword {...props} />
                    )} />
                <Route
                    exact
                    path='/ErrorPage'
                    render={props => (
                        <ErrorPage {...props} />
                    )} />

                <Route
                    path='/static'
                    render={props => (
                        <PageNotFound {...props} />
                    )} />
                <Route
                    path='/'
                    render={props => (
                        <CommonLogin {...props} />
                    )} />
                <Route
                    render={props => (
                        <PageNotFound {...props} />
                    )} />
               

                </Switch>
        );
    }
}


// Tested with react-data-grid v5.0.4, earlier versions MAY NOT HAVE cellRangeSelection
// And it won't show any errors if you try this with an earlier version, so use at least v5.0.4
import React, { Component } from 'react';
import { range } from 'lodash';
import ReactDataGrid from 'react-data-grid'; // Tested with v5.0.4, earlier versions MAY NOT HAVE cellRangeSelection
import { Utils } from '../EMS_Components'
import moment from 'moment';
import { Editors, Formatters } from 'react-data-grid-addons';
const DatasourceConstants = require('./DatasourceConstants.json');

const { AutoComplete: AutoCompleteEditor, DropDownEditor } = Editors
const { DropDownFormatter } = Formatters
const jobTitleNames = DatasourceConstants.Jobtitle
const jobTitleNamesEditor = <AutoCompleteEditor options={jobTitleNames} style=" min-width: auto!important;" />

const demoninators = DatasourceConstants.demoninators
const demoninatorsEditor = <AutoCompleteEditor options={demoninators} style=" min-width: auto!important;" />

const wardrobePropRequired = DatasourceConstants.wardrobePropRequired
const wardrobePropRequiredEditor = <AutoCompleteEditor options={wardrobePropRequired} style=" min-width: auto!important;" />

const PhoneNumber = ({ value }) => {
    if (value != null) {
        let isPhone = Utils.validatePhoneNumber(value);
        if (isPhone)
            value = Utils.MaskPhoneNumber(value);

        return <span className={isPhone ? "" : "text-danger"}>{value}</span>
    }
    else
        return value

};
const RateNumericValue = ({ value }) => {
    if (value != null) {
        let isNumericValue = Utils.validateDecimal(value);//&& parseFloat(value.length) <= 999999


        return <span className={isNumericValue ? "" : "text-danger"}>{value}</span>
    }
    else
        return value

};

const JobTitleFormatter = ({ value }) => {

    if (value != null) {
        let isValidJobTitle = DatasourceConstants.Jobtitle.filter(J => J.title == value).length > 0;
        //if (isValidJobTitle)
        //    value = Utils.MaskPhoneNumber(value);

        return <span className={isValidJobTitle ? "" : "text-danger"}>{value}</span>
    }
    else
        return value

};
const DenominatorFormatter = ({ value }) => {
    if (value != null) {
        let isValiddemoninators = DatasourceConstants.demoninators.filter(J => J.title == value).length > 0;
        return <span className={isValiddemoninators ? "" : "text-danger"}>{value}</span>
    }
    else
        return value
};
const WPFormatter = ({ value }) => {
    if (value != null) {
        let isValidWP = wardrobePropRequired.filter(J => J.title == value).length > 0;
        return <span className={isValidWP ? "" : "text-danger"}>{value}</span>
    }
    else
        return value
};
const Email = ({ value }) => {
    if (value != null) {
        let isEmail = Utils.validateEmail(value);
        value = value.trim();
        return <span className={isEmail ? "" : "text-danger"}>{value}</span>
    }
    else {
        return value
    }
}
const Time = ({ value }) => {

    //value = Utils.MaskTime(value);
    if (value != null) {
        let isTime = false;

        isTime = moment(value, 'h:mm A', true).isValid() || moment(value, 'HH:mm', true).isValid() || moment(value, 'h:mmA', true).isValid();
        return <span className={isTime ? "" : "text-danger"}>{value}</span>
    }
    else
        return value
};
const checkRefNumber = ({ value }) => {
    if (value != null) {
        let isNumber = Utils.validateNumeric(value);
        return <span className={isNumber ? "" : "text-danger"}>{value}</span>
    }
    else
        return value
};


const columns = [
    {
        key: 'checkRefNumber',
        name: '#',
        editable: true,
        width: 50,
        formatter: checkRefNumber
    },
    {
        key: 'lastName',
        name: 'Lastname',
        editable: false,
        width: 100,

    },
    {
        key: 'firstName',
        name: 'Firstname',
        editable: false,
        width: 100,
    },
    {
        key: 'phone',
        name: 'Phone',
        editable: false,
        formatter: PhoneNumber,
        width: 120,
    },
    {
        key: 'email',
        name: 'Email',
        editable: false,
        formatter: Email,
        width: 175,
    },


    {
        key: 'role',
        name: 'Role',
        editable: true,
        width: 125,

    },
    {
        key: 'jobTitle',
        name: 'Job Title',
        editable: true,
        width: 125,
        editor: jobTitleNamesEditor,
        formatter: JobTitleFormatter
    },
    {
        key: 'rate',
        name: 'Rate',
        editable: true,
        width: 50,
        formatter: RateNumericValue
    },
    {
        key: 'demoninators',
        name: 'Hours',
        editable: true,
        width: 50,
        editor: demoninatorsEditor,
        formatter: DenominatorFormatter
    },
    {
        key: 'callTime',
        name: 'Call Time',
        editable: true,
        width: 75,
        formatter: Time
    },
    {
        key: 'isWardrobeRequired',
        name: 'Is Wardrobe',
        editable: true,
        width: 100,
        editor: wardrobePropRequiredEditor,
        formatter: WPFormatter
    },
    {
        key: 'isPropertyRequired',
        name: 'Is Property',
        editable: true,
        width: 100,
        editor: wardrobePropRequiredEditor,
        formatter: WPFormatter
    },
    //,
    //{
    //    key: "action",
    //    name: "Action"
    //}

]
//.map(c => ({ ...c, ...defaultColumnProperties }));


const initialRows = Array.from(Array(1).keys(), (_, x) => (
    //{ id: x, title: x * 2, count: x * 3, sarah: x * 4, jessica: x * 5 }
    { lastName: "", firstName: "", phone: "", email: "" }
));

const defaultParsePaste = str => (
    str.split(/\r\n|\n|\r/)
        .map(row => row.split('\t'))
);


export class EditReactDataGridComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rows: initialRows,
            topLeft: {},
            botRight: {},
        };

        // Copy paste event handler
        //document.addEventListener('copy', this.handleCopy);
        //document.addEventListener('paste', this.handlePaste);


    }
    componentDidMount() {
        document.getElementById("divReactDataGrid").addEventListener('paste', this.handlePaste);
    }
    componentWillUnmount() {
        this.removeAllListeners();
    }

    componentWillReceiveProps() {

        let Person = this.props.setformdata.Person;

        if (!Person || Person.length == 0)
            this.setState({ rows: initialRows })
        else {
            this.setState({ rows: Person })
        }
    }

    removeAllListeners = () => {
        //document.removeEventListener('copy', this.handleCopy);
        //document.removeEventListener('paste', this.handlePaste);
    }

    rowGetter = (i) => {
        const { rows } = this.state;
        return rows[i];
    }

    updateRows = (startIdx, newRows) => {
        this.setState((state) => {
            const rows = state.rows.slice();
            for (let i = 0; i < newRows.length; i++) {
                if (startIdx + i < rows.length) {
                    rows[startIdx + i] = { ...rows[startIdx + i], ...newRows[i] };
                }
                else {
                    rows.push(newRows[i]);
                }
            }

            this.props.formdata[this.props.id] = rows;
            this.props.setformdata.Person = rows;

            return { rows };
        });

    }

    handleCopy = (e) => {
        console.debug('handleCopy Called');
        e.preventDefault();
        const { topLeft, botRight } = this.state;

        // Loop through each row
        const text = range(topLeft.rowIdx, botRight.rowIdx + 1).map(
            // Loop through each column
            rowIdx => columns.slice(topLeft.colIdx, botRight.colIdx + 1).map(
                // Grab the row values and make a text string
                col => this.rowGetter(rowIdx)[col.key],
            ).join('\t'),
        ).join('\n');
        console.debug('text', text);
        e.clipboardData.setData('text/plain', text);
    }

    handlePaste = (e) => {

        console.debug('handlePaste Called');
        e.preventDefault();
        const { topLeft } = this.state;

        const newRows = [];
        const pasteData = defaultParsePaste(e.clipboardData.getData('text/plain'));

        console.debug('pasteData', pasteData);

        pasteData.forEach((row) => {
            const rowData = {};
            // Merge the values from pasting and the keys from the columns
            columns.slice(topLeft.colIdx, topLeft.colIdx + row.length)
                .forEach((col, j) => {
                    // Create the key-value pair for the row
                    rowData[col.key] = row[j];
                });
            // Push the new row to the changes
            newRows.push(rowData);
        });

        console.debug('newRows', newRows);

        this.updateRows(topLeft.rowIdx, newRows);
    }
    getCellActions = (column, row) => {

        const cellActions = [
            {
                icon: <span style={{ "border-left": "1px solid #eee" }}><i class="fa fa-trash text-danger" aria-hidden="true" style={{ 'display': this.state.rows.indexOf(row) != this.state.rows.length - 1 ? 'inline' : 'none' }}></i></span>,
                callback: () => {
                    if (this.state.rows.indexOf(row) != this.state.rows.length - 1) {
                        const rows = [...this.state.rows];
                        rows.splice(this.state.rows.indexOf(row), 1); //
                        this.props.formdata[this.props.id] = rows;
                        this.props.setformdata.Person = rows;
                        this.setState({ rows: rows });
                        //alert("Deleting:" + row.firstname + ":" + this.state.rows.indexOf(row));
                    }
                }
            }
        ];
        return column.key === "isPropertyRequired" ? cellActions : null;
    };

    onGridRowsUpdated = ({ fromRow, toRow, updated, action }) => {

        console.log('onGridRowsUpdated!', action);
        console.log('updated', updated);
        if (action !== 'COPY_PASTE') {

            this.setState((state) => {
                const rows = state.rows.slice();

                //if (rows.length == toRow + 1) {
                //    //for (let i = 0; i < columns.length; i++) {
                //    if (rows[fromRow][0] != "" && rows.length != fromRow) {
                //        rows.push(initialRows);
                //    }
                //    // }

                //}

                for (let i = fromRow; i <= toRow; i++) {
                    rows[i] = { ...rows[i], ...updated };
                }
                this.props.formdata[this.props.id] = rows;
                this.props.setformdata.Person = rows;
                return { rows };
            });

            //const rows = this.state.rows;
            ////for (let i = 0; i < columns.length; i++) {
            //    if (rows[rows.length - 1][columns[0]["key"]] != "") {

            //        rows.push(initialRows);
            //    }
            ////}

        }
    };

    setSelection = (args) => {

        this.setState({
            topLeft: {
                rowIdx: args.topLeft.rowIdx,
                colIdx: args.topLeft.idx,
            },
            botRight: {
                rowIdx: args.bottomRight.rowIdx,
                colIdx: args.bottomRight.idx,
            },
        });
    };

    render() {

        const { rows } = this.state;

        return (
            <div onPaste={this.handlePaste} id="divReactDataGrid" >
                <ReactDataGrid onPaste={this.handlePaste}
                    columns={columns}
                    rowGetter={i => rows[i]}
                    rowsCount={rows.length}
                    onGridRowsUpdated={this.onGridRowsUpdated}
                    enableCellSelect

                    onCellSelected={s => this.setSelection({ topLeft: s, bottomRight: s })}

                />



            </div>

        );
    }
}



//cellRangeSelection = {{
//    onComplete: this.setSelection,
//                    }}
//minColumnWidth={50}
//getCellActions={this.getCellActions}